import { gql } from 'apollo-angular';

export const GET_PROJECTS_TITLES = gql`
  query ($limit: Int, $skip: Int) {
    projects(limit: $limit, skip: $skip) {
      total
      items {
        id
        title
      }
    }
  }
`;
