import { createAction, props } from '@ngrx/store';
import {
  GQLCreateProjectInput,
  GQLEditProjectInput,
  GQLProject,
  GQLProjectList,
  GQLRemoveInput,
  GQLRemovePayload,
  QueryToProjectsArgs,
} from '../../../../schemas/schemas';
import { EViewType } from '../../helpers';

function scoped(templateString: TemplateStringsArray) {
  return `[Projects] ${templateString[0]}`;
}

// project store

export const clearCurrentProjectId = createAction(
  scoped`Clear Current Project Id`,
);

export const loadProjectsList = createAction(
  scoped`Load Projects List`,
  props<{ input: QueryToProjectsArgs }>(),
);

export const loadProjectsListSuccess = createAction(
  scoped`Load Active Data Sources Success`,
  props<{ projectsList: GQLProjectList }>(),
);

export const loadProjectsListsFailure = createAction(
  scoped`Load Active Data Sources Failure`,
  props<{ error: unknown }>(),
);

export const createProject = createAction(
  scoped`Create project`,
  props<{ projectInput: GQLCreateProjectInput }>(),
);

export const createProjectSuccess = createAction(
  scoped`Create Project Success`,
  props<{ project: GQLProject }>(),
);

export const createProjectFailure = createAction(
  scoped`Create Project Failure`,
  props<{ error: unknown }>(),
);

export const editProject = createAction(
  scoped`Edit Project`,
  props<{ projectInput: GQLEditProjectInput }>(),
);

export const editProjectSuccess = createAction(
  scoped`Edit Project Success`,
  props<{ project: GQLProject }>(),
);

export const editProjectFailure = createAction(
  scoped`Edit Project Failure`,
  props<{ error: unknown }>(),
);

export const deleteProject = createAction(
  scoped`Delete Project`,
  props<{ input: GQLRemoveInput }>(),
);

export const deleteProjectSuccess = createAction(
  scoped`Delete Project Success`,
  props<{ removeProject: GQLRemovePayload }>(),
);

export const deleteProjectFailure = createAction(
  scoped`Delete Project Failure`,
  props<{ error: unknown }>(),
);

export const getOpenProjectIdUsersPopup = createAction(
  scoped`Get Open Project Id Users Popup`,
  props<{ activeProjectId: string }>(),
);

export const removeInvitation = createAction(
  scoped`Remove Invitation`,
  props<{ input: GQLRemoveInput }>(),
);

export const removeInvitationSuccess = createAction(
  scoped`Remove Invitation Success`,
  props<{ removeInvitation: GQLRemovePayload }>(),
);

export const removeInvitationFailure = createAction(
  scoped`Remove Invitation Failure`,
  props<{ error: unknown }>(),
);

export const getInitProjectListView = createAction(
  scoped`Get Initial Project List View`,
);

export const getInitProjectListViewSuccess = createAction(
  scoped`Get Initial Project List View Success`,
  props<{ viewType: EViewType }>(),
);

export const toggleProjectListView = createAction(
  scoped`Toggle Project List View`,
  props<{ viewType: EViewType }>(),
);

export const toggleProjectListViewSuccess = createAction(
  scoped`Toggle Project List View Success`,
  props<{ viewType: EViewType }>(),
);

// common
export const saveCurrentProjectId = createAction(
  scoped`Save Current Project Id`,
  props<{ currentProjectId: string }>(),
);

export const loadProjectsTitles = createAction(scoped`Load Projects Titles`);

export const loadProjectsTitlesSuccess = createAction(
  scoped`Load Projects Titles Success`,
  props<{ projectsTitles: GQLProjectList }>(),
);

export const loadProjectsTitlesFailure = createAction(
  scoped`Load Projects Titles Failure`,
  props<{ error: unknown }>(),
);

export const loadProject = createAction(scoped`Load Project List`);

export const loadProjectSuccess = createAction(
  scoped`Load Project Success`,
  props<{ project: GQLProject }>(),
);

export const loadProjectFailure = createAction(
  scoped`Load Project Failure`,
  props<{ error: unknown }>(),
);

export const clearProject = createAction(scoped`Clear Project Object`);
