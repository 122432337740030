import { Injectable, Query } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  GQLCreateFileInput,
  GQLCreateFileVersionInput,
  GQLFile,
  GQLFileCollection,
  GQLFileGroup,
  GQLFileMarkerStatusEnum,
  GQLFileStatusEnum,
  GQLFileTypeEnum,
  GQLFileVersion,
  GQLMarkFileVersionAsViewedInput,
  GQLMarkFileVersionAsViewedPayload,
} from '@schemas';
import {
  REMOVE_FILE,
  APPROVE_FILE_VERSION,
  CREATE_FILE,
  CREATE_FILE_VERSION,
  DISAPPROVE_FILE_VERSION,
  MARK_VERSION_AS_VIEWED,
  REMOVE_FILE_VERSION,
  MARK_APPROVED_FILE_AS_VIEWED,
} from '@common/graphQL/graphql-requests/mutations';
import { ConfirmDeleteFileModalComponent } from '@common/modules/confirm-delete-file-modal/components/confirm-delete-file-modal/confirm-delete-file-modal.component';
import { EconfirmModalType } from '@common/modules/confirm-modal/components/confirm-modal/confirm-modal.component';
import { BaseApi } from '@common/services/base-api';
import { IFilterRequest } from '@common/types';
import {
  GET_FILE,
  GET_MARKERS,
  GET_THREAD_FILES,
  GET_THREAD_FILES_IDS,
  GET_SIMPLIFIED_FILE_COLLECTION,
  GET_PROJECT_FILES_IDS,
  GET_THREAD_FILES_IDS_WITH_ORIGIN_ORDER,
  GET_PROJECT_LIST_IN_FILE_COLLECTION,
  GET_FILE_COLLECTION,
  GET_FILE_VERSION,
  GET_PROJECT_FILES,
  GET_FILE_VERSION_MARKER_AUTO_INCREMENT,
} from '@queries';
import { ShortenerPipe } from '@common/pipes/shortener/shortener.pipe';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(
    private readonly api: BaseApi,
    private readonly dialogService: DialogService,
    private readonly shortenerPipe: ShortenerPipe,
  ) {}

  loadProjectFiles(filterOptions: IFilterRequest): Observable<GQLFileGroup[]> {
    const variables: IFilterRequest = {
      id: filterOptions.id,
      ...(filterOptions.status && { status: filterOptions.status }),
      ...(this.fileTypesList(filterOptions).length > 0 && {
        types: this.fileTypesList(filterOptions),
      }),
      ...(filterOptions.unviewedOnly && {
        unviewedOnly: filterOptions.unviewedOnly,
      }),
      ids: filterOptions.ids,
      searchQuery: filterOptions.searchQuery,
    };

    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PROJECT_FILES,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.projectFiles;
        }),
      );
  }

  loadProjectFilesIds(variables: {
    id: string;
    status?: GQLFileStatusEnum;
    unviewedOnly?: boolean;
    image: boolean;
    video: boolean;
    unreal: boolean;
    webgl: boolean;
    otherFiles: boolean;
    searchQuery?: string;
  }): Observable<GQLFileGroup[]> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PROJECT_FILES_IDS,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.projectFiles;
        }),
      );
  }

  loadThreadFiles(filterOptions: IFilterRequest): Observable<GQLFile[]> {
    const variables: IFilterRequest = {
      id: filterOptions.id,
      ...(filterOptions.status && { status: filterOptions.status }),
      ...(filterOptions.unviewedOnly && {
        unviewedOnly: filterOptions.unviewedOnly,
      }),
      ...(this.fileTypesList(filterOptions).length > 0 && {
        types: this.fileTypesList(filterOptions),
      }),
      ids: filterOptions.ids,
      searchQuery: filterOptions.searchQuery,
      dateRange: filterOptions.dateRange,
    };

    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_THREAD_FILES,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.threadFiles;
        }),
      );
  }

  loadThreadFilesWithOriginOrder(id: string): Observable<GQLFile[]> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_THREAD_FILES_IDS_WITH_ORIGIN_ORDER,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.threadFilesWithOriginOrder;
        }),
      );
  }

  loadThreadFilesIds(variables: {
    id: string;
    status?: GQLFileStatusEnum;
    unviewedOnly?: boolean;
    image: boolean;
    video: boolean;
    model3D: boolean;
    otherFiles: boolean;
  }): Observable<GQLFile[]> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_THREAD_FILES_IDS,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.threadFiles;
        }),
      );
  }

  loadFile(fileId: string): Observable<GQLFile> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_FILE,
        variables: {
          id: fileId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map(({ data: { file } }: any) => file));
  }

  loadMarkerAutoincrementValue(
    fileVersionId: string,
  ): Observable<{ markerAutoincrementValue: number }> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_FILE_VERSION_MARKER_AUTO_INCREMENT,
        variables: {
          id: fileVersionId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.fileVersion;
        }),
      );
  }

  tryRemoveFile(
    fileStatus: GQLFileStatusEnum | null,
    isFileVersion = false,
    fileVersions: GQLFileVersion[] = [],
    fileName: string = '',
  ): Observable<any> {
    let dialogConfig = {};
    const shortFileName = this.shortenerPipe.transform(fileName);
    if (fileStatus === GQLFileStatusEnum.APPROVED) {
      dialogConfig = {
        data: {
          id: '',
          title: 'title',
          buttonText: `OK`,
          text: `You cannot delete version ${shortFileName} because approved files can't be deleted`,
          type: EconfirmModalType.WARNING,
        },
        header: `Cannot Delete version`,
        styleClass: 'service-modal service-modal--warning',
      };
    } else {
      dialogConfig = {
        data: {
          id: '',
          title: 'title',
          buttonText: `Delete`,
          text: `This file will be deleted permanently!`,
          type: EconfirmModalType.DELETE,
          isPossibleDeleteAllVersions: isFileVersion && fileVersions.length > 1,
          fileVersions,
        },
        header: `Delete file ${shortFileName}?`,
        styleClass: 'service-modal',
      };
    }

    const ref = this.dialogService.open(
      ConfirmDeleteFileModalComponent,
      dialogConfig,
    );
    return ref.onClose.pipe(filter((res) => !!res));
  }

  removeFile(id: string): Observable<unknown> {
    return this.api
      .mutate(REMOVE_FILE, { input: { id } })
      .pipe(map(({ data }) => data.removeFile.id));
  }

  loadFileVersion(
    versionId: string | undefined,
    markerStatus: GQLFileMarkerStatusEnum | undefined,
  ): Observable<GQLFileVersion> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_FILE_VERSION,
        variables: {
          id: versionId,
          markerStatus: markerStatus ?? GQLFileMarkerStatusEnum.ACTIVE,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.fileVersion;
        }),
      );
  }

  loadMarkers(
    versionId: string | undefined,
    markerStatus: GQLFileMarkerStatusEnum | undefined,
  ): Observable<GQLFileVersion> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_MARKERS,
        variables: {
          id: versionId,
          markerStatus: markerStatus ?? GQLFileMarkerStatusEnum.ACTIVE,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.fileVersion;
        }),
      );
  }

  removeFileVersion(id: string): Observable<unknown> {
    return this.api.mutate(REMOVE_FILE_VERSION, { input: { id } }).pipe(
      map(({ data }) => {
        return data.removeFileVersion.id;
      }),
    );
  }

  getAllProjectInFileCollection() {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PROJECT_LIST_IN_FILE_COLLECTION,
        fetchPolicy: 'network-only',
      })
      .pipe(map((data: any) => data.data.files));
  }

  markVersionAsViewed(
    input: GQLMarkFileVersionAsViewedInput,
  ): Observable<GQLMarkFileVersionAsViewedPayload> {
    return this.api
      .mutate(MARK_VERSION_AS_VIEWED, { input })
      .pipe(map(({ data }: any) => data.markFileVersionAsViewed));
  }

  getFileCollection(
    filterOptions: IFilterRequest,
  ): Observable<GQLFileCollection> {
    let variables: IFilterRequest = {};

    variables = {
      ...(filterOptions.status && { status: filterOptions.status }),
      ...(filterOptions.unviewedOnly && {
        unviewedOnly: filterOptions.unviewedOnly,
      }),
      ...(this.fileTypesList(filterOptions).length > 0 && {
        types: this.fileTypesList(filterOptions),
      }),
      ids: filterOptions.ids,
      searchQuery: filterOptions.searchQuery,
      dateRange: filterOptions.dateRange,
    };

    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_FILE_COLLECTION,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(map((data: any) => data.data.files));
  }

  getFileCollectionIds(
    filterOptions: IFilterRequest,
  ): Observable<GQLFileCollection> {
    const variables: IFilterRequest = {
      ...(filterOptions.status && { status: filterOptions.status }),
      ...(filterOptions.image && { image: filterOptions.image }),
      ...(filterOptions.video && { video: filterOptions.video }),
      ...(filterOptions.unreal && { model3D: filterOptions.unreal }),
      ...(filterOptions.webgl && { model3D: filterOptions.webgl }),
      ...(filterOptions.otherFiles && { otherFiles: filterOptions.otherFiles }),
      ...(filterOptions.unviewedOnly && {
        unviewedOnly: filterOptions.unviewedOnly,
      }),
      ids: filterOptions.ids,
    };

    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_SIMPLIFIED_FILE_COLLECTION,
        variables,
        fetchPolicy: 'network-only',
      })
      .pipe(map((data: any) => data.data.files));
  }

  markApprovedFileAsViewed(fileId: string): Observable<GQLFile> {
    return this.api
      .mutate(MARK_APPROVED_FILE_AS_VIEWED, {
        input: { id: fileId },
      })
      .pipe(map(({ data }) => data.markFileApproveAsViewed.file));
  }

  createFileVersion(
    fileVersionInput: GQLCreateFileVersionInput,
  ): Observable<GQLFileVersion> {
    return this.api
      .mutate(
        CREATE_FILE_VERSION,
        { input: fileVersionInput },
        { useMultipart: true },
      )
      .pipe(map(({ data }) => data.createFileVersion.fileVersion));
  }

  approveDisaproveFileVersion(
    isVersionApproved: boolean,
    id: string | undefined,
    markerStatus: GQLFileMarkerStatusEnum,
  ): Observable<GQLFileVersion> {
    return this.api
      .mutate(
        isVersionApproved ? DISAPPROVE_FILE_VERSION : APPROVE_FILE_VERSION,
        {
          input: { id },
          markerStatus,
        },
      )
      .pipe(
        map(
          ({ data }) =>
            data[
              isVersionApproved ? 'disapproveFileVersion' : 'approveFileVersion'
            ],
        ),
        map((data) => data.fileVersion),
      );
  }

  createFile(fileInput: GQLCreateFileInput): Observable<GQLFileVersion> {
    return this.api.mutate(
      CREATE_FILE,
      { input: fileInput },
      { useMultipart: true },
    );
  }

  private fileTypesList(filterOptions: IFilterRequest): GQLFileTypeEnum[] {
    const fileTypes: GQLFileTypeEnum[] = [];

    if (filterOptions.image) {
      fileTypes.push(GQLFileTypeEnum.IMAGE);
    }

    if (filterOptions.video) {
      fileTypes.push(GQLFileTypeEnum.VIDEO);
    }

    if (filterOptions.unreal) {
      fileTypes.push(GQLFileTypeEnum.STREAM);
    }

    if (filterOptions.webgl) {
      fileTypes.push(GQLFileTypeEnum.MODEL);
    }

    if (filterOptions.otherFiles) {
      fileTypes.push(GQLFileTypeEnum.OTHER);
    }
    return fileTypes;
  }
}
