import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import { GQLProfile, GQLTokensPayload } from '../../../schemas/schemas';
import { getCurrentFileId } from '../store/files/files.selectors';
import { APP_CONFIG, isSharingHash } from '../helpers';
import { REFRESH_TOKEN, TOKEN } from '../helpers/constants/token.constants';
import { AppConfig } from '../types';
import { CookieHandlerService } from './cookies-handler.service';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService implements OnDestroy {
  client!: ApolloClient<NormalizedCacheObject>;
  currentUser: GQLProfile | null = null;
  fileHash = '';
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly cookieHandler: CookieHandlerService,
    @Inject(WINDOW) private readonly windowRef: Window,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {
    this.store
      .select(getCurrentFileId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((hash) => {
        this.fileHash = hash;
      });
  }

  logout(returnUrl?: string): void {
    this.clearStorage();

    this.client.resetStore();
    this.client.clearStore();
    this.currentUser = null;
    this.router
      .navigate(['/login'], {
        replaceUrl: true,
        queryParams: { returnUrl },
      })
      .then(() => {
        document.location.reload();
        // reload page need for fix autofill bug: cursor do not set in login fields without reload
      });
  }

  navigateToNoAccess() {
    const isHash = isSharingHash(this.fileHash);
    if (isHash) {
      return;
    }
    this.router.navigate(['no-access']);
  }

  setToken(data: GQLTokensPayload): void {
    if (data?.token) {
      this.cookieHandler.set(TOKEN(this.appConfig.tokenPrefix), data.token);
    }

    if (data?.refreshToken) {
      this.cookieHandler.set(
        REFRESH_TOKEN(this.appConfig.tokenPrefix),
        data.refreshToken,
      );
    }
  }

  get isLoggedIn(): boolean {
    return (
      !!this.getToken(TOKEN(this.appConfig.tokenPrefix)) ||
      !!this.getToken(REFRESH_TOKEN(this.appConfig.tokenPrefix))
    );
  }

  getToken(key: string): string {
    return this.cookieHandler.get(key);
  }

  clearStorage(): void {
    this.cookieHandler.delete(TOKEN(this.appConfig.tokenPrefix));
    this.cookieHandler.delete(REFRESH_TOKEN(this.appConfig.tokenPrefix));

    this.windowRef.sessionStorage.removeItem('identify');
    this.windowRef.localStorage.removeItem('identify');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
