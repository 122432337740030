import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AvatarModule } from 'ngx-avatars';
import { TooltipModule } from 'primeng/tooltip';
import { SubscribersComponent } from './components/subscribers/subscribers.component';
import { MiniUsersListComponent } from './components/mini-users-list/mini-users-list.component';
import { AppIconModule } from '../icon';

@NgModule({
  declarations: [SubscribersComponent, MiniUsersListComponent],
  imports: [
    CommonModule,
    ScrollPanelModule,
    AvatarModule,
    TooltipModule,
    AppIconModule,
  ],
  exports: [SubscribersComponent, MiniUsersListComponent],
})
export class SubscribersModule {}
