/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/17/21, 6:31 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const EDIT_PROJECT = gql`
  mutation ($input: EditProjectInput!) {
    editProject(input: $input) {
      project {
        createdAt
        description
        expirationDate
        id
        logoPath
        startDate
        status
        invitations {
          id
          email
          role
          status
        }
        threads {
          items {
            id
            title
          }
          total
        }
        title
        updatedAt
      }
    }
  }
`;
