import { gql } from 'apollo-angular';

export const UPDATE_PROFILE = gql`
  mutation ($input: EditProfileInput!) {
    editProfile(input: $input) {
      profile {
        id
        email
        firstName
        lastName
        fullName
        avatarPath
        role
      }
    }
  }
`;
