import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileType',
  pure: true,
})
export class FileTypePipe implements PipeTransform {
  fileTypes: any = {
    IMAGE: {
      extension: [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'bmp',
        'ico',
        'cur',
        'tif',
        'tiff',
      ],
      iconUrl: 'assets/images/plugs/uil_file-blank.svg',
    },
    VIDEO: {
      extension: [
        'mpg',
        'mpeg',
        'avi',
        'wmv',
        'mov',
        'rm',
        'ram',
        'swf',
        'flv',
        'ogg',
        'webm',
        'mp4',
      ],
      iconUrl: 'assets/images/plugs/video-file.svg',
    },
    DOC: {
      extension: ['doc'],
      iconUrl: 'assets/images/plugs/doc-file.svg',
    },
    DOCX: {
      extension: ['docx'],
      iconUrl: 'assets/images/plugs/docx-file.svg',
    },
    PDF: {
      extension: ['pdf'],
      iconUrl: 'assets/images/plugs/pdf-file.svg',
    },
    TXT: {
      extension: ['txt'],
      iconUrl: 'assets/images/plugs/txt-file.svg',
    },
    EMBED: {
      extension: ['embed'],
      iconUrl: 'assets/images/plugs/uil_file-blank.svg',
    },
    undefined: {
      extension: [],
      iconUrl: 'assets/images/plugs/uil_file-blank.svg',
    },
  };

  transform(
    name: string | undefined,
    iconUrl: boolean = false,
  ): string | undefined {
    if (name) {
      const extension: string = name
        // eslint-disable-next-line no-bitwise
        .slice(((name.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLocaleLowerCase();
      const knownFileExtension =
        Object.keys(this.fileTypes).find(
          (type: string) =>
            this.fileTypes[type].extension.findIndex(
              (el: string) => extension === el,
            ) !== -1,
        ) || 'undefined';
      if (iconUrl) {
        return this.fileTypes[knownFileExtension].iconUrl;
      }
      return knownFileExtension;
    }
    if (iconUrl) {
      return 'assets/images/plugs/uil_file-blank.svg';
    }
    return 'default text';
  }
}
