/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 5/24/21, 8:26 PM
 * Copyright 2021 3D Source
 */

export const NON_WHITESPACE = /\S+/;
export const EMAIL_REGEXP = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

/*
  at least one number,
  one upper and one lower case letters,
  no spaces,
  can contains special symbols,
  only latin letters
  min symbols - 8
  max symbols - 128
 */
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[a-zA-Z0-9\~\!\?\@\#\$\%\^\&\*\_\-\+\(\)\[\]\{\}\>\<\/\\\|\"\'\.\,\:\;]{8,128}$/;
export const ONLY_DIV_BR = /^((?!(\s|&nbsp;|<div><br><\/div>)+$).)*$/;
