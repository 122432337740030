<ng-container *ngIf="notifications$ | async as notificationList">
  <button
    class="text-button clear-notifications"
    (click)="clearAll()"
    [disabled]="isListEmpty(notificationList)"
    data-test-id="clearNotificationsButton"
  >
    Clear all
  </button>
  <p-tabView
    class="notification-tabs"
    [(activeIndex)]="tabIndex"
    (onChange)="updateNotificationsList()"
    data-test-id="updateNotificationsList"
  >
    <p-tabPanel header="All">
      <ng-scrollbar scrollToDuration="0" #scrollbarRef class="scroll-event">
        <ng-container *ngTemplateOutlet="tab"></ng-container>
      </ng-scrollbar>
    </p-tabPanel>
    <p-tabPanel header="Unread" [selected]="true">
      <ng-scrollbar
        scrollToDuration="0"
        #unreadScrollbarRef
        class="scroll-event"
      >
        <ng-container *ngTemplateOutlet="tab"></ng-container>
      </ng-scrollbar>
    </p-tabPanel>
  </p-tabView>
  <ng-template #tab>
    <div
      class="notification-list"
      [class.loading]="isNotificationsLoading$ | async"
    >
      <ng-container *ngIf="!isListEmpty(notificationList); else emptyList">
        <div
          class="notification-list__item"
          *ngFor="let notification of notificationList; let i = index"
        >
          <app-notification-item
            [id]="'notification' + i"
            [notification]="notification"
            [hasClass]="isActiveClassNeed(notification)"
            [notificationList]="notificationList"
          ></app-notification-item>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #emptyList>
  <div class="empty-message">No notifications</div>
</ng-template>
