<form
  class="search"
  [class.active]="isSearchFieldOpen"
  [formGroup]="searchForm"
>
  <div class="search__input">
    <button
      type="button"
      class="search__toggle"
      (click)="toggleSearchInput()"
      data-test-id="searchInputApplyButton"
    >
      <app-icon href="#icon-search"></app-icon>
    </button>
    <input
      #search
      formControlName="search"
      type="text"
      placeholder="Search"
      data-test-id="searchInput"
    />
    <button
      *ngIf="!isSearchEmpty"
      type="button"
      class="search__clear"
      (click)="clearSearchInput()"
      data-test-id="searchInputClearButton"
    >
      <app-icon href="#icon-close"></app-icon>
    </button>
  </div>
</form>
<ng-content></ng-content>
