/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/9/21, 2:35 PM
 * Copyright 2021 3D Source
 */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roleShortener',
  pure: true,
})
export class RolesShortenerPipe implements PipeTransform {
  transform(role: string | undefined): string {
    if (role) {
      return role.toLowerCase().replace('role_', '').replace('_', ' ');
    }
    return '';
  }
}
