import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetLatestFileVersionPipe } from './get-latest-file-version.pipe';

@NgModule({
  declarations: [GetLatestFileVersionPipe],
  imports: [CommonModule],
  exports: [GetLatestFileVersionPipe],
})
export class GetLatestFileVersionModule {}
