/* eslint-disable rxjs/no-nested-subscribe */
/* eslint-disable @typescript-eslint/no-shadow */
import { Component, Inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, map, take } from 'rxjs/operators';
import { GQLThreadStatusEnum } from '../schemas/schemas';
import {
  clearProject,
  loadProject,
  saveCurrentProjectId,
} from '@common/store/projects/projects.actions';
import { getCurrentProjectId } from '@common/store/projects/projects.selectors';
import {
  clearThread,
  clearThreadsList,
  loadThread,
  loadThreadsList,
  saveCurrentThreadId,
} from '@common/store/threads/threads.actions';
import { getCurrentThreadId } from '@common/store/threads/threads.selectors';
import { AppConfig, AppEnv } from '@common/types';
import { APP_CONFIG } from '@common/helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  queryParams = {
    groupBy: GQLThreadStatusEnum.ACTIVE,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.rootRoute(this.route)),
        filter((route: ActivatedRoute) => route.outlet === 'primary'),
      )
      .subscribe((route: ActivatedRoute) => {
        // if (!route.snapshot.paramMap.get('threadId')) {
        this.projectRoute(route);
        // }
        this.threadRoute(route);
      });
  }

  get isDevMode(): boolean {
    return (
      this.appConfig.app_env === AppEnv.DEV ||
      this.appConfig.app_env === AppEnv.LOCAL
    );
  }
  // TODO REMOVE THIS
  isDevModeLabelOpen = true;
  // TODO REMOVE THIS

  private projectRoute(route: ActivatedRoute): void {
    const projectId = route.snapshot.paramMap.get('projectId') || '';
    this.store
      .select(getCurrentProjectId)
      .pipe(take(1))
      .subscribe((currentProjectId) => {
        if (
          currentProjectId !== route.snapshot.paramMap.get('projectId') ||
          route.snapshot.queryParams.groupBy !== this.queryParams.groupBy
        ) {
          this.store.dispatch(
            saveCurrentProjectId({
              currentProjectId: projectId,
            }),
          );
          if (projectId) {
            this.store.dispatch(loadProject());
            this.store.dispatch(
              loadThreadsList({
                projectId,
                sortBy:
                  route.snapshot.queryParams.sortBy ||
                  GQLThreadStatusEnum.ACTIVE,
                searchQuery: '',
              }),
            );
          } else {
            this.store.dispatch(clearProject());
            this.store.dispatch(clearThreadsList());
          }
        }
      });
  }
  private threadRoute(route: ActivatedRoute): void {
    const threadId = route.snapshot.params.threadId || '';
    if (threadId) {
      this.store.dispatch(loadThread({ threadId }));
    }
    this.store
      .select(getCurrentThreadId)
      .pipe(take(1))
      .subscribe((currentThreadId) => {
        if (currentThreadId !== threadId) {
          this.store.dispatch(
            saveCurrentThreadId({
              currentThreadId: threadId,
            }),
          );
          if (threadId) {
            this.store.dispatch(loadThread({ threadId }));
          } else {
            this.store.dispatch(clearThread());
          }
        }
      });
  }

  // eslint-disable-next-line class-methods-use-this
  private rootRoute(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      // eslint-disable-next-line no-param-reassign
      route = route.firstChild;
    }
    return route;
  }
}
