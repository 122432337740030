import { Action, createReducer, on } from '@ngrx/store';

import {
  GQLFile,
  GQLFileCollectionItem,
  GQLFileGroup,
  GQLFileMarkerStatusEnum,
  GQLFileVersion,
} from '../../../schemas/schemas';
import { ESidebarTabs } from '../constants/commenting-tool.constants';
import { ITimeInfo } from '@common/types/video-tool.models';
import {
  clearCurrentVideoTimePoint,
  collapseFullScreen,
  collapseVideoFullScreen,
  expandFullScreen,
  expandVideoFullScreen,
  fitCurrentVersion,
  resetCommentingToolState,
  setCurrentMarkersStatus,
  setCurrentVideoTimePoint,
  setStreamAvailability,
  setZoomSize,
  toggleVersionsList,
  turnCommentingModeOff,
  turnCommentingModeOn,
  turnDrawingModeOff,
  turnDrawingModeOn,
  loadProjectFilesWithToolSuccess,
  loadThreadFilesWithToolSuccess,
  resetCommentingToolStateWithoutFiles,
  resetCommentingToolStateWithoutFilesAndFullScreen,
  updateFilesWithTools,
  turnMarkersVisibleOn,
  loadAllFileWithToolSuccess,
  loadThreadFilesWithOriginOrderSuccess,
  selectVideoTab,
  setImageCommentingToolRendering,
  turnMarkersVisibleOff,
  reCenterModel,
  setProductLoader,
} from './commenting-tools.actions';
import { isLowHeightLaptop } from '@common/helpers';

export interface CommentingToolState {
  isFullScreen: boolean;
  isVideoFullScreen: boolean;
  versionsListCollapsed: boolean;
  fitCurrentVersion: unknown;
  reCenterModel: unknown;
  markersStatus: GQLFileMarkerStatusEnum;
  zoom: number;
  isDrawingMode: boolean;
  imageCommentingToolRendering: boolean;
  isMarkersVisible: boolean;
  isCommentingMode: boolean;
  isStreamIsNotAvailable?: boolean;
  currentVideoTimePoint?: ITimeInfo;
  threadFilesWithTool: Pick<GQLFile, 'id' | 'versions' | 'type'>[];
  threadFilesWithOriginOrder: Pick<GQLFile, 'id' | 'versions' | 'type'>[];
  projectFilesWithTool: Pick<GQLFile, 'id' | 'versions' | 'type'>[];
  allFilesWithTool: Pick<GQLFile, 'id' | 'versions' | 'type'>[];
  videoSidebarTab: ESidebarTabs;
  imageSidebarTab: ESidebarTabs;
  productLoading: boolean;
}

export const initialCommentingToolState: CommentingToolState = {
  isFullScreen: false,
  isVideoFullScreen: false,
  versionsListCollapsed: isLowHeightLaptop(),
  fitCurrentVersion: null,
  reCenterModel: null,
  markersStatus: GQLFileMarkerStatusEnum.ALL,
  zoom: 100,
  imageCommentingToolRendering: false,
  isDrawingMode: false,
  isCommentingMode: false,
  isMarkersVisible: false,
  threadFilesWithTool: [],
  threadFilesWithOriginOrder: [],
  projectFilesWithTool: [],
  allFilesWithTool: [],
  videoSidebarTab: ESidebarTabs.COMMENTS,
  imageSidebarTab: ESidebarTabs.COMMENTS,
  productLoading: false,
};

function updateFilesWithToolsNewVersion(
  files: Pick<GQLFile, 'id' | 'versions' | 'type'>[],
  fileId: string,
  fileVersion: GQLFileVersion,
): Pick<GQLFile, 'id' | 'versions' | 'type'>[] {
  return files.map((file: Pick<GQLFile, 'id' | 'versions' | 'type'>) =>
    file.id === fileId
      ? {
          ...file,
          versions: [fileVersion, ...(file.versions || [])],
        }
      : file,
  );
}

const commentingToolReducer = createReducer(
  initialCommentingToolState,

  on(setProductLoader, (state, { loading }) => {
    return {
      ...state,
      productLoading: loading,
    };
  }),
  on(setImageCommentingToolRendering, (state, { rendering }) => {
    return {
      ...state,
      imageCommentingToolRendering: rendering,
    };
  }),
  on(selectVideoTab, (state, { tab }) => {
    return {
      ...state,
      videoSidebarTab: tab,
    };
  }),
  on(updateFilesWithTools, (state, { fileVersion, fileId }) => {
    return {
      ...state,
      threadFilesWithTool: updateFilesWithToolsNewVersion(
        state.threadFilesWithTool,
        fileId,
        fileVersion,
      ),
      threadFilesWithOriginOrder: updateFilesWithToolsNewVersion(
        state.threadFilesWithOriginOrder,
        fileId,
        fileVersion,
      ),
      projectFilesWithTool: updateFilesWithToolsNewVersion(
        state.projectFilesWithTool,
        fileId,
        fileVersion,
      ),
      allFilesWithTool: updateFilesWithToolsNewVersion(
        state.allFilesWithTool,
        fileId,
        fileVersion,
      ),
    };
  }),
  on(expandFullScreen, (state) => {
    return {
      ...state,
      isFullScreen: !state.isFullScreen,
    };
  }),
  on(collapseFullScreen, (state) => {
    return {
      ...state,
      isFullScreen: false,
    };
  }),
  on(expandVideoFullScreen, (state) => {
    return {
      ...state,
      isVideoFullScreen: !state.isFullScreen,
    };
  }),
  on(collapseVideoFullScreen, (state) => {
    return {
      ...state,
      isVideoFullScreen: false,
    };
  }),
  on(setStreamAvailability, (state, { status }) => {
    return {
      ...state,
      isStreamIsNotAvailable: status,
    };
  }),
  on(turnCommentingModeOn, (state) => {
    return {
      ...state,
      isCommentingMode: true,
      isMarkersVisible: true,
      isDrawingMode: false,
    };
  }),
  on(turnMarkersVisibleOn, (state) => {
    return {
      ...state,
      isCommentingMode: false,
      isMarkersVisible: true,
      isDrawingMode: false,
    };
  }),
  on(turnCommentingModeOff, turnMarkersVisibleOff, (state) => {
    return {
      ...state,
      isCommentingMode: false,
      isMarkersVisible: false,
      isDrawingMode: false,
    };
  }),
  on(turnDrawingModeOn, (state) => {
    return {
      ...state,
      isDrawingMode: true,
      isMarkersVisible: true,
      isCommentingMode: false,
    };
  }),
  on(turnDrawingModeOff, (state) => {
    return {
      ...state,
      isDrawingMode: false,
      isMarkersVisible: false,
      isCommentingMode: false,
    };
  }),
  on(toggleVersionsList, (state) => {
    return {
      ...state,
      versionsListCollapsed: !state.versionsListCollapsed,
    };
  }),
  on(fitCurrentVersion, (state) => {
    return {
      ...state,
      fitCurrentVersion: {},
    };
  }),
  on(reCenterModel, (state) => {
    return {
      ...state,
      reCenterModel: {},
    };
  }),
  on(setCurrentMarkersStatus, (state, { status }) => {
    return {
      ...state,
      markersStatus: status,
    };
  }),

  on(setZoomSize, (state, { zoomSize }) => ({
    ...state,
    zoom: zoomSize,
  })),
  on(setCurrentVideoTimePoint, (state, { timeInfo }) => ({
    ...state,
    currentVideoTimePoint: timeInfo,
  })),
  on(clearCurrentVideoTimePoint, (state) => ({
    ...state,
    currentVideoTimePoint: undefined,
  })),
  on(resetCommentingToolState, (state) => ({
    ...initialCommentingToolState,
    markersStatus: state.markersStatus,
  })),
  on(resetCommentingToolStateWithoutFiles, (state) => ({
    ...initialCommentingToolState,
    versionsListCollapsed: state.versionsListCollapsed,
    projectFilesWithTool: state.projectFilesWithTool,
    threadFilesWithTool: state.threadFilesWithTool,
    allFilesWithTool: state.allFilesWithTool,
    threadFilesWithOriginOrder: state.threadFilesWithOriginOrder,
    markersStatus: state.markersStatus,
  })),
  on(resetCommentingToolStateWithoutFilesAndFullScreen, (state) => ({
    ...initialCommentingToolState,
    projectFilesWithTool: state.projectFilesWithTool,
    threadFilesWithTool: state.threadFilesWithTool,
    allFilesWithTool: state.allFilesWithTool,
    threadFilesWithOriginOrder: state.threadFilesWithOriginOrder,
    isFullScreen: state.isFullScreen,
    markersStatus: state.markersStatus,
    versionsListCollapsed: state.versionsListCollapsed,
    videoSidebarTab: state.videoSidebarTab,
  })),
  on(loadThreadFilesWithToolSuccess, (state, { filesList }) => ({
    ...state,
    threadFilesWithTool: filesList,
  })),
  on(loadThreadFilesWithOriginOrderSuccess, (state, { filesList }) => ({
    ...state,
    threadFilesWithOriginOrder: filesList,
  })),
  on(loadProjectFilesWithToolSuccess, (state, { filesList }) => ({
    ...state,
    projectFilesWithTool: filesList.flatMap(({ files }) => files),
  })),
  on(loadAllFileWithToolSuccess, (state, { fileCollection }) => ({
    ...state,
    allFilesWithTool: fileCollection.items
      .flatMap((items: GQLFileCollectionItem) => items.files)
      .flatMap((fileGroup: GQLFileGroup) => fileGroup.files),
  })),
);

export function CommentingToolReducer(
  state: CommentingToolState | undefined,
  action: Action,
): CommentingToolState {
  return commentingToolReducer(state, action);
}
