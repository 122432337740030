import { HttpClient } from '@angular/common/http';
import { Injectable, Query } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CREATE_MESSAGE } from '../../graphQL/graphql-requests/mutations/CREATE_MESSAGE';
import { EDIT_MESSAGE } from '../../graphQL/graphql-requests/mutations/EDIT_MESSAGE';
import { MARK_MESSAGE_AS_VIEWED } from '../../graphQL/graphql-requests/mutations/MARK_MESSAGE_AS_VIEWED';
import { REMOVE_MESSAGE } from '../../graphQL/graphql-requests/mutations/REMOVE_MESSAGE';
import { GET_MESSAGES } from '../../graphQL/graphql-requests/queries/GET_MESSAGES';
import { BaseApi } from '../../services/base-api';
import { editThreadFailure, loadThread } from '../threads/threads.actions';
import { getCurrentThreadId } from '../threads/threads.selectors';
import {
  createMessage,
  createMessageFailure,
  createMessageSuccess,
  deleteMessage,
  deleteMessageFailure,
  deleteMessageSuccess,
  editMessage,
  editMessageFailure,
  editMessageSuccess,
  loadMessagesList,
  loadMessagesListsFailure,
  loadMessagesListSuccess,
  markMessageAsViewed,
  markMessageAsViewedSuccess,
} from './messages.actions';

@Injectable()
export class MessagesEffects {
  constructor(
    public actions$: Actions,
    public http: HttpClient,
    private api: BaseApi,
    private store: Store,
  ) {}

  loadMessagesList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMessagesList),
      withLatestFrom(this.store.select(getCurrentThreadId)),
      switchMap(([_, threadId]) =>
        threadId
          ? this.api.apollo
              .query<QueryRef<Query>>({
                query: GET_MESSAGES,
                variables: {
                  id: threadId,
                  limit: 1000,
                  skip: 0,
                }, // TODO remove hardcode limit and skip
                fetchPolicy: 'network-only',
              })
              .pipe(
                map(({ data }: any) =>
                  loadMessagesListSuccess({
                    messagesList: data.thread.messages,
                  }),
                ),
                catchError((error: unknown) =>
                  of(loadMessagesListsFailure({ error })),
                ),
              )
          : of(loadMessagesListsFailure({ error: 'No threadId specified' })),
      ),
    ),
  );

  createMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMessage),
      withLatestFrom(this.store.select(getCurrentThreadId)),
      exhaustMap(([{ input }, threadId]) =>
        this.api.mutate(CREATE_MESSAGE, { input }, { useMultipart: true }).pipe(
          mergeMap(({ data }) => [
            createMessageSuccess(data.createMessage),
            loadThread({ threadId }),
            // TODO refactor this - think about do not load thread this case
          ]),
          catchError((error: unknown) => of(createMessageFailure({ error }))),
        ),
      ),
    ),
  );

  editMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editMessage),
      withLatestFrom(this.store.select(getCurrentThreadId)),
      exhaustMap(([{ input }, threadId]) =>
        this.api.mutate(EDIT_MESSAGE, { input }, { useMultipart: true }).pipe(
          mergeMap(({ data }) => [
            editMessageSuccess(data.editMessage),
            loadThread({ threadId }),
            // TODO refactor this - think about do not load thread this case
            loadMessagesList(),
          ]),
          catchError((error: unknown) => of(editMessageFailure({ error }))),
        ),
      ),
    ),
  );

  markMessageAsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markMessageAsViewed),
      exhaustMap((input) =>
        this.api.mutate(MARK_MESSAGE_AS_VIEWED, input).pipe(
          mergeMap(({ data }) => [
            loadMessagesList(),
            markMessageAsViewedSuccess(data.markMessageAsViewed),
          ]),
          catchError((error: unknown) => of(editThreadFailure({ error }))),
        ),
      ),
    ),
  );

  deleteMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMessage),
      withLatestFrom(this.store.select(getCurrentThreadId)),
      exhaustMap(([{ input }, threadId]) =>
        this.api.mutate(REMOVE_MESSAGE, { input }).pipe(
          mergeMap(({ data }) => [
            loadThread({ threadId }),
            deleteMessageSuccess(data),
          ]),
          catchError((error: unknown) => of(deleteMessageFailure({ error }))),
        ),
      ),
    ),
  );
}
