import { GQLFile, GQLFileVersion } from '../../../schemas/schemas';

export function getFileName(file: GQLFile | null): string {
  const extension: string | undefined = file?.extension?.toLowerCase();
  return extension ? `${file?.name}.${extension}` : `${file?.name}`;
}

export function getVersionName(version: GQLFileVersion | null): string {
  const extension: string | undefined = version?.extension?.toLowerCase();
  return extension ? `${version?.title}.${extension}` : `${version?.title}`;
}
