import {
  GQLActivityList,
  GQLEditNotificationSettingsInput,
  GQLMarkActivityAsViewedInput,
  GQLMarkActivityAsViewedPayload,
  GQLNotificationSettings,
} from '../../../schemas/schemas';
import { Injectable, Query } from '@angular/core';
import { filter, map, tap } from 'rxjs/operators';

import { BaseApi } from '@common/services/base-api';
import { CLEAR_ACTIVITIES } from '@common/graphQL/graphql-requests/mutations/CLEAR_ACTIVITIES';
import { EDIT_NOTIFICATIONS_SETTINGS } from '@common/graphQL/graphql-requests/mutations/EDIT_NOTIFICATIONS_SETTINGS';
import { GET_NOTIFICATIONS } from '@common/graphQL/graphql-requests/queries';
import { GET_NOTIFICATIONS_SETTINGS } from '@common/graphQL/graphql-requests/queries/GET_NOTIFICATIONS_SETTINGS';
import { MARK_NOTIFICATIONS_AS_VIEWED } from '@common/graphQL/graphql-requests/mutations';
import { Observable } from 'rxjs';
import { QueryRef } from 'apollo-angular';
import { TypedAction } from '@ngrx/store/src/models';
import { isDefined } from '../../common/helpers';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private api: BaseApi) {}

  loadNotifications(
    skip: number,
    limit: number,
    unreadOnly?: boolean,
  ): Observable<GQLActivityList> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_NOTIFICATIONS,
        variables: {
          limit,
          skip,
          unreadOnly,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(isDefined),
        map(({ data }: any) => {
          return data.activities;
        }),
      );
  }

  markNotificationsAsViewed(
    input: {
      input: GQLMarkActivityAsViewedInput;
    } & TypedAction<string>,
  ): Observable<string[]> {
    return this.api.mutate(MARK_NOTIFICATIONS_AS_VIEWED, input).pipe(
      map(({ data }: any) => {
        return (
          data.markActivityAsViewed as GQLMarkActivityAsViewedPayload
        ).activities.map(({ id }) => id);
      }),
    );
  }

  getNotificationSettings(): Observable<GQLNotificationSettings> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_NOTIFICATIONS_SETTINGS,
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.me.notificationSettings;
        }),
      );
  }

  editNotificationSettings(
    input: {
      input: GQLEditNotificationSettingsInput;
    } & TypedAction<string>,
  ): Observable<GQLNotificationSettings> {
    return this.api
      .mutate(EDIT_NOTIFICATIONS_SETTINGS, input)
      .pipe(
        map(
          ({ data }) => data.editNotificationSettings?.me?.notificationSettings,
        ),
      );
  }

  /*

  @kostya druzheruchenko Додав фільтр для activities для unread

query activities(projectId: ID, limit: Int, skip: Int, unreadOnly: Boolean): ActivityList

Також реалізував мутацію

mutation clearActivities: ClearActivitiesPayload - там інпута не має взагалі, його не треба
   */

  clearAllNotifications(): Observable<any> {
    // TODO fix this
    return this.api.mutate(CLEAR_ACTIVITIES).pipe(
      map(
        ({ data }) => data.activities,
        // TODO fix this
      ),
    );
  }
}
