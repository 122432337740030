import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '../index';
import { GQLAccessLevelEnum, GQLFile, GQLThread } from '@schemas';
import { ThreadsState } from './threads.reducer';

export const getThreadsState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.threadState,
);

export const getThreadsList = createSelector(
  getThreadsState,
  (state: ThreadsState): GQLThread[] => {
    return state.threadsList?.items || [];
  },
);
export const getThreadsCount = createSelector(
  getThreadsState,
  (state: ThreadsState): number => {
    return state.threadsList?.total || 0;
  },
);

export const getThreadFileCount = createSelector(
  getThreadsState,
  (state: ThreadsState): number => {
    return state.thread?.files.total || 0;
  },
);

export const getIsLoadingThreads = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.isLoadingThreads;
  },
);

export const getThread = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.thread;
  },
);

export const canAddFileToThread = createSelector(
  getThread,
  (thread: GQLThread | null): boolean => {
    return !!thread?.canAddFile;
  },
);

export const getThreadOwnFiles = createSelector(
  getThreadsState,
  (state: ThreadsState): GQLFile[] => {
    return state.thread?.ownFiles || [];
  },
);

export const getThreadFiles = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.thread?.files || null;
  },
);

export const getThreadFilesTotal = createSelector(
  getThreadsState,
  (state: ThreadsState): number | null => {
    return state.thread?.files?.total || null;
  },
);

export const getThreadMainInfo = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return {
      id: state.thread?.id,
      serialNumber: state.thread?.serialNumber,
    };
  },
);

export const threadSidebarModel = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return {
      author: state.thread?.author.fullName || '',
      createdAt: state.thread?.createdAt || 0,
    };
  },
);

export const getCurrentThreadId = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.currentThreadId;
  },
);

export const getCurrentThread = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.thread;
  },
);

export const getCurrentThreadTitle = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.thread?.title || '';
  },
);

export const isCurrentThreadPublic = createSelector(
  getCurrentThread,
  (thread) => {
    return thread?.accessLevel === GQLAccessLevelEnum.PUBLIC;
  },
);

export const getThreadCreateLoader = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state?.isThreadLoading || false;
  },
);

export const canTreadMessageEdit = createSelector(
  getThreadsState,
  (state: ThreadsState) => {
    return state.isThreadMessageEditorOpen;
  },
);
