<div class="user">
  <button
    type="button"
    class="user__button"
    [class.userMenuOpen]="userMenuOpen"
    (click)="toggleMenu()"
    data-test-id="toggleUserMenu"
  >
    <div class="user__avatar">
      <ngx-avatars
        class="my-avatar"
        [src]="userAvatar$ | async"
        [name]="userAvatarName$ | async"
        data-test-id="userAvatarImage"
      ></ngx-avatars>
    </div>
    <div class="user__button-text">Profile</div>
  </button>
  <div class="user__panel" *ngIf="userMenuOpen && (userRole$ | async)">
    <button class="user__panel-item button-profile" (click)="goToUserProfile()" data-test-id="goToUserProfileButton">
      Your profile
    </button>
    <button
      class="user__panel-item button-settings"
      (click)="goToNotificationSettings()"
      data-test-id="goToNotificationSettingsButton"
    >
      Notification settings
    </button>
    <button
      *ngIf="canChangeRoles"
      class="user__panel-item button-roles"
      (click)="openRolesPopup()"
      data-test-id="openRolesPopupButton"
    >
      Roles
    </button>
    <button
      type="button"
      (click)="logout()"
      class="user__panel-item button-logout"
      data-test-id="logoutButton"
    >
      Logout
    </button>
  </div>
</div>
