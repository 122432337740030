import { Actions, ofType } from '@ngrx/effects';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  FormBuilder,
  FormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  GQLAccessLevelEnum,
  GQLCreateThreadInput,
  GQLEditThreadInput,
  GQLFile,
  GQLFileTypeEnum,
  GQLFileUpload,
  GQLProfile,
  GQLThread,
  GQLThreadStatusEnum,
} from '@schemas';
import { Observable, Subject } from 'rxjs';
import {
  createThread,
  createThreadSuccess,
  editThread,
  editThreadSuccess,
} from '@common/store/threads/threads.actions';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
  getCurrentUser,
  getCurrentUserAvatar,
  getCurrentUserAvatarName,
  getCurrentUserFullName,
  getCurrentUserPermissions,
  getProjectUsers,
} from '@common/store/users/users.selectors';

import Autolinker from 'autolinker';
import { IMAGE_DETECTION_REGEXP } from '@common/helpers/constants/regexp.constants';
import { IUserPermissions } from '@common/types/IUserPermisions';
import { NON_WHITESPACE } from '@common/helpers';
import { Store } from '@ngrx/store';
import { SubscriberProfile } from '@common/types';
import { ToastrService } from 'ngx-toastr';
import { getCurrentProjectId } from '@common/store/projects/projects.selectors';
import { getThreadCreateLoader } from '@common/store/threads/threads.selectors';
import { getUniqueListBy } from '@common/helpers/utils';

@Component({
  selector: 'app-thread-add',
  templateUrl: './thread-add.component.html',
  styleUrls: ['./thread-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreadAddComponent implements OnInit, OnDestroy {
  placeholder = 'Enter description';
  isPrivate = false;
  threadEditForm!: FormGroup;
  thread: GQLThread = {} as GQLThread; // common thread data object

  subscribers: Observable<GQLProfile[] | null> =
    this.store.select(getProjectUsers);

  isLoading$: Observable<boolean> = this.store.select(getThreadCreateLoader);

  canCreatePrivate$: Observable<boolean> = this.store
    .select(getCurrentUserPermissions)
    .pipe(
      map(
        (permissions: IUserPermissions) =>
          !!permissions?.thread?.create_private,
      ),
    );

  userName$: Observable<string> = this.store.select(getCurrentUserFullName);

  currentUserAvatarName$: Observable<string> = this.store.select(
    getCurrentUserAvatarName,
  );

  userAvatar$: Observable<string> = this.store.select(getCurrentUserAvatar);

  readonly fileTypeEnum = GQLFileTypeEnum;

  isEditing: boolean | undefined;
  fileUrls: Array<any> = [];
  inputFilesUrls: Array<any> = [];
  attachments: GQLFile[] = [];
  selectedPrivateUsers: Array<SubscriberProfile> = [];

  private filesIdsForDeleting: string[] = [];
  private currentUser: GQLProfile | null = null;
  private projectId = '';
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public ref: DynamicDialogRef,
    public inputData: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private toasts: ToastrService,
    private store: Store,
    private updates$: Actions,
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCurrentUser)
      .pipe(filter((user) => !!user))
      .subscribe((user: GQLProfile | null) => {
        this.currentUser = user;
      });

    this.updates$
      .pipe(ofType(editThreadSuccess), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.threadEditForm.reset();
        this.close();
      });

    this.updates$
      .pipe(ofType(createThreadSuccess), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.threadEditForm.reset();
        this.close();
      });

    this.getCurrentProjectId();
    this.initInputData();
    this.initThreadForm();
    this.getThreadUsers();
  }

  private getThreadUsers(): void {
    if (this.thread?.contributors) {
      this.selectedPrivateUsers = this.thread.contributors.map((user) => ({
        ...user,
        isActive: true,
      }));
    }

    if (this.currentUser && !this.isEditing) {
      this.selectedPrivateUsers.push({ ...this.currentUser, isActive: true });
    }

    if (
      this.currentUser &&
      this.isEditing &&
      this.selectedPrivateUsers.length === 0
    ) {
      this.selectedPrivateUsers.push({ ...this.currentUser, isActive: true });
    }

    this.selectedPrivateUsers = getUniqueListBy(
      this.selectedPrivateUsers,
      'id',
    );
  }

  private getCurrentProjectId(): void {
    this.store
      .select(getCurrentProjectId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((projectId: string) => {
        this.projectId = projectId;
      });
  }

  private initInputData(): void {
    this.thread = this.inputData.data.thread ?? this.thread;
    this.isEditing = this.inputData.data.isEditing;
    this.isPrivate = this.thread?.accessLevel === GQLAccessLevelEnum.PRIVATE;

    this.inputFilesUrls =
      this.thread?.ownFiles?.map((file: any) => {
        return {
          ...file,
          previewPath: file?.versions[file?.versions?.length - 1]?.previewPath,
          path: file?.versions[file?.versions?.length - 1]?.path,
        };
      }) || [];
  }

  private initThreadForm(): void {
    this.threadEditForm = new FormGroup({
      title: new UntypedFormControl(this.thread?.title || '', [
        Validators.maxLength(60),
        Validators.pattern(NON_WHITESPACE),
      ]),
      body: new UntypedFormControl(this.thread?.body || ''),
    });

    this.threadEditForm
      .get('body')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((body: string | null) => {
        if (body && IMAGE_DETECTION_REGEXP.test(body)) {
          const message = body?.replaceAll(IMAGE_DETECTION_REGEXP, '');
          this.threadEditForm
            .get('body')
            ?.setValue(message, { emitEvent: false });
        }
      });
  }

  get isTitleInvalid(): boolean {
    return (
      this.threadEditForm.controls.title.invalid &&
      (this.threadEditForm.controls.title.dirty ||
        this.threadEditForm.controls.title.touched)
    );
  }

  submitForm(): void {
    const bodyWithUrls =
      Autolinker.link(this.threadEditForm.controls.body.value, {
        email: false,
        phone: true,
        newWindow: true,
        stripPrefix: false,
        stripTrailingSlash: true,
      }) || '';
    if (this.isEditing) {
      this.editThread(bodyWithUrls);
    } else {
      this.createTread(bodyWithUrls);
    }
  }

  createTread(bodyTextWithLinks: string): void {
    const input: GQLCreateThreadInput = {
      accessLevel: this.isPrivate
        ? GQLAccessLevelEnum.PRIVATE
        : GQLAccessLevelEnum.PUBLIC,
      body: bodyTextWithLinks,
      contributors: this.selectedPrivateUsers.map((user) => user.id),
      title: this.threadEditForm.controls.title.value,
      projectId: this.projectId,
      attachments: this.attachmentsForRequest,
    };
    this.store.dispatch(createThread({ input }));
  }

  get attachmentsForRequest(): Array<{
    file: GQLFileUpload;
    useTools: boolean;
  }> {
    return this.attachments.map((fileData) => {
      return {
        file: (fileData as any).file,
        useTools: fileData.useTools,
        type: fileData.type,
      };
    });
  }

  editThread(bodyTextWithLinks: string): void {
    const input: GQLEditThreadInput = {
      accessLevel: this.isPrivate
        ? GQLAccessLevelEnum.PRIVATE
        : GQLAccessLevelEnum.PUBLIC,
      id: this.thread?.id,
      title: this.threadEditForm.controls.title.value,
      body: bodyTextWithLinks,
      contributors: this.isPrivate
        ? this.selectedPrivateUsers.map((user) => user.id)
        : [],
      attachments: this.attachmentsForRequest,
      removedFiles: this.filesIdsForDeleting,
    };

    if (this.thread.status === GQLThreadStatusEnum.ACTIVE) {
      this.store.dispatch(editThread({ threadInput: input }));
    } else {
      this.toasts.error(
        'Error: You can`t edit a thread that has been approved',
      );
    }
  }

  toggleVisibility(): void {
    this.isPrivate = !this.isPrivate;
  }

  setPrivateUsers(users: Array<SubscriberProfile>): void {
    this.selectedPrivateUsers = users;
  }

  close(): void {
    this.ref.close();
  }

  filesAttached(files: GQLFile[]): void {
    this.attachments = files;
  }

  filesIdsForDeletingOutput(filesIds: string[]): void {
    this.filesIdsForDeleting = [
      ...new Set([...this.filesIdsForDeleting, ...filesIds]),
    ];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
