/*
 *  3D Source AMS/VPS
 *  http://3dsource.com/
 *
 *  3D Source is the only company that exists exclusively to help product manufacturers
 *  evolve their sales and marketing assets from photography to photorealistic
 *  3D and CGI.
 *  3D Source provides access to a scalable network of content developers and
 *  groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 *  Copyright 2020 3D Source
 */

import { Inject, Injectable } from '@angular/core';
import { ApolloClient } from '@apollo/client/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import {
  GQLAcceptInvitationInput,
  GQLTokensPayload,
  GQLCreateResetPasswordRequestInput,
  GQLCreateResetPasswordRequestPayload,
  GQLResetPasswordInput,
} from '../../../schemas/schemas';
import {
  ACCEPT_INVITATION,
  CREATE_RESET_PASSWORD,
  RESET_PASSWORD,
} from '../graphQL/graphql-requests/mutations';
import { AUTHENTICATE } from '../graphQL/graphql-requests/mutations/AUTHENTICATE';
import { REFRESH_TOKEN } from '../graphQL/graphql-requests/mutations/REFRESH_TOKEN';
import { GET_ME } from '../graphQL/graphql-requests/queries/GET_ME';
import { APP_CONFIG } from '../helpers';
import {
  TOKEN,
  REFRESH_TOKEN as REFRESH_TOKEN_CONSTANT,
} from '../helpers/constants/token.constants';
import { isJSON } from '../helpers/operators';
import { AppConfig } from '../types';
import { BaseApi } from './base-api';
import { CookieHandlerService } from './cookies-handler.service';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  client!: ApolloClient<any>;
  cookieTokens!: GQLTokensPayload;

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly api: BaseApi,
    private readonly cookieHandler: CookieHandlerService,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {}

  getMe(): void {
    this.api.query(GET_ME, null, 'network-only').subscribe(({ data }: any) => {
      this.currentUserService.currentUser = {
        ...data.me,
        permissions: data.me?.permissions
          ? JSON.parse(data.me.permissions)
          : null,
      };
    });
  }

  getMyPermissions(): Observable<any> {
    return this.api.query(GET_ME, null, 'network-only').pipe(
      map((data: any) => data?.data?.me?.permissions),
      filter((permissions) => isJSON(permissions)),
      map((permissions) => JSON.parse(permissions)),
    );
  }

  authenticate(email: string, password: string): Observable<GQLTokensPayload> {
    return this.api.mutate(AUTHENTICATE, { email, password }).pipe(
      tap((response: any) =>
        this.currentUserService.setToken(response.data.generateToken),
      ),
      tap(() => this.getMe()),
    );
  }

  refreshToken(refreshToken: string): Observable<GQLTokensPayload> {
    return this.api
      .mutate(REFRESH_TOKEN, { input: { refreshToken } })
      .pipe(
        tap((response: any) =>
          this.currentUserService.setToken(response.data.refreshToken),
        ),
      );
  }

  acceptInvitation(
    input: GQLAcceptInvitationInput,
  ): Observable<GQLTokensPayload> {
    return this.api.mutate(ACCEPT_INVITATION, { input }, 'network-only').pipe(
      tap((resp: any) => {
        this.currentUserService.setToken(resp.data.acceptInvitation);
      }),
    );
  }

  resetPassword(input: GQLResetPasswordInput): Observable<GQLTokensPayload> {
    return this.api.mutate(RESET_PASSWORD, { input }, 'network-only').pipe(
      tap((resp: any) => {
        this.currentUserService.setToken(resp.data.resetPassword.token);
      }),
    );
  }

  createResetPassword(
    input: GQLCreateResetPasswordRequestInput,
  ): Observable<GQLCreateResetPasswordRequestPayload> {
    return this.api.mutate(CREATE_RESET_PASSWORD, { input }, 'network-only');
  }

  isAuthenticated(): boolean {
    let token: string | null = '';
    // const helper = new JwtHelperService();

    if (this.isRedirectedFromDashboard) {
      token = this.cookieHandler.get(TOKEN(this.appConfig.tokenPrefix));

      this.cookieTokens = {
        token,
        refreshToken: this.cookieHandler.get(
          REFRESH_TOKEN_CONSTANT(this.appConfig.tokenPrefix),
        ),
      };

      this.currentUserService.setToken(this.cookieTokens);

      if (token) {
        // this.cookieHandler.delete(TOKEN(this.appConfig.tokenPrefix));
        // this.cookieHandler.delete(
        //   REFRESH_TOKEN_CONSTANT(this.appConfig.tokenPrefix),
        // );
        this.cookieHandler.delete('fromDashboard');
      }
    } else {
      token = this.cookieHandler.get(TOKEN(this.appConfig.tokenPrefix));
    }

    // if (token) {
    //   return !helper.isTokenExpired(token);
    // }
    return !!token;
  }

  get isRedirectedFromDashboard(): boolean {
    return !!this.cookieHandler.get('fromDashboard');
  }
}
