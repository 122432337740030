import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { EconfirmModalType } from '../../../confirm-modal/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-confirm-delete-file-modal',
  templateUrl: './confirm-delete-file-modal.component.html',
  styleUrls: ['./confirm-delete-file-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDeleteFileModalComponent implements OnDestroy {
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  title: string = this.config.data.title;
  warningText: string = this.config.data.text;
  typeStyles: string = this.config.data.type;
  btnText: string = this.config.data.buttonText ?? 'Confirm';
  deleteAllVersion = false;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  get isPossibleDeleteAllVersions(): boolean {
    return this.config.data.isPossibleDeleteAllVersions;
  }

  get isWarning(): boolean {
    return this.config.data.type === EconfirmModalType.WARNING;
  }

  confirm(): void {
    if (this.isWarning) {
      this.close();
    } else {
      this.ref.close({
        deleteAllVersion:
          this.deleteAllVersion || this.config.data.fileVersions?.length === 1,
      });
    }
  }

  close(): void {
    this.ref.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
