import { createAction, props } from '@ngrx/store';
import {
  GQLEditProfileInput,
  GQLEditProfilePayload,
  GQLInvitationActionStatusEnum,
  GQLInvitationStatus,
  GQLInvitationStatusEnum,
  GQLProfile,
  GQLProfilePermissions,
  GQLUpdateMeInput,
  GQLUpdateMePayload,
  GQLUpdatePasswordInput,
  GQLUpdatePasswordPayload,
} from '@schemas';
import { IgraphQLErrorsObject } from '../../types/IgraphQLErrorsObject';
import { IUserPermissions } from '@common/types/IUserPermisions';

function scoped(templateString: TemplateStringsArray) {
  return `[Users] ${templateString[0]}`;
}

// LOAD CURRENT USER
export const loadCurrentUser = createAction(scoped`Load Current User`);

export const loadCurrenUserSuccess = createAction(
  scoped`Load Current User Success`,
  props<{ profile: GQLProfile }>(),
);

export const loadCurrenUserFailure = createAction(
  scoped`Load Current User Failure`,
  props<{ error: unknown }>(),
);

// LOAD CURRENT USER CAN CHANGE ROLES
export const loadCurrentUserRolePermissions = createAction(
  scoped`Load Current User Role Permissions`,
);

export const loadCurrentUserRolePermissionsSuccess = createAction(
  scoped`Load Current User Role Permissions Success`,
  props<{ permissions: GQLProfilePermissions }>(),
);

export const loadCurrentUserRolePermissionsFailure = createAction(
  scoped`Load Current User Role Permissions Failure`,
  props<{ error: unknown }>(),
);

// LOAD CURRENT USERS
export const loadWorkspaceUsers = createAction(scoped`Load Workspace Users`);

export const loadWorkspaceUsersSuccess = createAction(
  scoped`Load Workspace Users Success`,
  props<{ users: GQLProfile[] }>(),
);

export const loadWorkspaceUsersFailure = createAction(
  scoped`Load Workspace Users Failure`,
  props<{ error: unknown }>(),
);

// LOAD PROJECT USERS
export const loadProjectUsers = createAction(
  scoped`Load Project Users`,
  props<{ projectId: string }>(),
);

export const loadProjectUsersSuccess = createAction(
  scoped`Load Project Users Success`,
  props<{ users: GQLProfile[] }>(),
);

export const loadProjectUsersFailure = createAction(
  scoped`Load Project Users Failure`,
  props<{ error: unknown }>(),
);

export const loadThreadUsersSuccess = createAction(
  scoped`Load Thread Users Success`,
  props<{ users: GQLProfile[] }>(),
);

export const loadThreadUsersFailure = createAction(
  scoped`Load Thread Users Failure`,
  props<{ error: unknown }>(),
);

export const setCurrenUserPermissionsSuccess = createAction(
  scoped`Load Permissions Success`,
  props<{ permissions: IUserPermissions }>(),
);

export const clearProjectUsers = createAction(scoped`Clear Project Users`);

export const clearThreadUsers = createAction(scoped`Clear Thread Users`);

export const updateUserProfileWithPassword = createAction(
  scoped`Update My Profile Info With Password`,
  props<{
    profileInput: GQLEditProfileInput;
    passwordInput: GQLUpdatePasswordInput;
  }>(),
);
export const updateUserProfileWithPasswordSuccess = createAction(
  scoped`Update My Profile Info With Password Success`,
  props<{ profile: GQLProfile }>(),
);

export const updateUserProfileWithPasswordFailure = createAction(
  scoped`Update My Profile Info With Password Failure`,
  props<{ error: IgraphQLErrorsObject }>(),
);

export const updateMyProfileInfo = createAction(
  scoped`Update My Profile Info`,
  props<{ input: GQLEditProfileInput }>(),
);

export const updateMyProfileInfoSuccess = createAction(
  scoped`Update My Profile Info Success`,
  props<{ profile: GQLProfile }>(),
);

export const updateMyProfileInfoFailure = createAction(
  scoped`Update My Profile Info Failure`,
  props<{ error: unknown }>(),
);

export const updateMyPassword = createAction(
  scoped`Update My Password`,
  props<{ input: GQLUpdatePasswordInput }>(),
);

export const updateMyPasswordSuccess = createAction(
  scoped`Update My Password Success`,
  props<{ me: GQLUpdatePasswordPayload }>(),
);

export const updateMyPasswordFailure = createAction(
  scoped`Update My Password Failure`,
  props<{ error: IgraphQLErrorsObject }>(),
);

export const updateUserProfileInfo = createAction(
  scoped`Update User Profile Info`,
  props<{ input: GQLEditProfileInput[] }>(),
);

export const updateUserProfileInfoSuccess = createAction(
  scoped`Update User Profile Info Success`,
);

export const updateUserProfileInfoFailure = createAction(
  scoped`Update User Profile Info Failure`,
  props<{ error: unknown; profile: GQLEditProfileInput }>(),
);

export const checkInvitationStatus = createAction(
  scoped`Check Invitation Status`,
  props<{ hash: string }>(),
);

export const checkInvitationStatusSuccess = createAction(
  scoped`Check Invitation Status Success`,
  props<{ status: GQLInvitationActionStatusEnum }>(),
);

export const checkInvitationStatusFailure = createAction(
  scoped`Check Invitation Status Failure`,
  props<{ error: IgraphQLErrorsObject }>(),
);
