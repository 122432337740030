<div class="layout layout--notifications">
  <div class="layout__heading">
    <div class="layout__title">
      <button type="button" class="back-link">
        <svg class="icon" width="24" height="24">
          <use xlink:href="#icon-chevrone-left"></use>
        </svg>
      </button>
      <h2>Notifications</h2>
    </div>
  </div>
  <div class="layout__content">
    <app-notification-list [isSeparatePage]="true"></app-notification-list>
  </div>
</div>
