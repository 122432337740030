export enum MetaBoxApiAction {
  APP_LOADED = 'appLoaded',
  MENU_DATA = 'menuData',
  VIDEO_SIZE = 'videoSize',
  STORE_ACTION = 'storeAction',
  UNREAL_RESPONSE = 'unrealResponse',
  CHANGE_MATERIAL = 'changeMaterial',
  ADD_RESPONSE = 'addResponse',
  SELECTED_MATERIALS = 'selectedMaterials',
}
