import { gql } from 'apollo-angular';
import { AUTHOR, FILE } from '../variables';

export const GET_FILE = gql`
  query($id: ID!) {
    file(id: $id) {
      ${FILE}
      author{
        ${AUTHOR}
      }
    }
  }
`;
