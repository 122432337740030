import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShortenerPipe } from './shortener.pipe';

@NgModule({
  declarations: [ShortenerPipe],
  providers: [ShortenerPipe],
  imports: [CommonModule],
  exports: [ShortenerPipe],
})
export class ShortenerModule {}
