import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLDateRange,
  GQLFile,
  GQLFileCollection,
  GQLFileGroup,
  GQLFileStatusEnum,
  GQLFileVersion,
  GQLFileVersionStatusEnum,
  GQLMarkerGroup,
} from '../../../../schemas/schemas';
import {
  changeHasNewVersionInFile,
  EViewType,
  nextFileVersion,
} from '../../helpers';
import {
  createMarkerGroupSuccess,
  markMarkerAsViewedSuccess,
  updateMarkerGroupImage,
} from '../markers/markers.actions';
import {
  addFiles,
  addFilesFailure,
  addFilesSuccess,
  clearAddFileError,
  clearCurrentFileId,
  clearFile,
  createFileVersion,
  createFileVersionFailure,
  createFileVersionSuccess,
  getAllFileCollection,
  getAllFileCollectionFailure,
  getAllFileCollectionSuccess,
  getInitFileListViewSuccess,
  loadFile,
  loadFileFailure,
  loadFileSuccess,
  loadFileVersion,
  loadFileVersionFailure,
  loadFileVersionSuccess,
  loadMarkerAutoincrementValueSuccess,
  loadProjectFiles,
  loadProjectFilesFailure,
  loadProjectFilesSuccess,
  loadProjectsFiles,
  loadProjectsFilesFailure,
  loadProjectsFilesSuccess,
  loadThreadFiles,
  loadThreadFilesFailure,
  loadThreadFilesSuccess,
  markApprovedFileAsViewedFailure,
  markApprovedFileAsViewedSuccess,
  markVersionAsViewedFailure,
  markVersionAsViewedSuccess,
  removeFileFailure,
  removeFileVersionFailure,
  removeFileVersionSuccess,
  resetFileLoadingFlag,
  saveCurrentFileId,
  selectMarkersGroup,
  setCurrent3dScreenshot,
  setCurrentFileListStatus,
  setFile,
  toggleFileListViewSuccess,
  toggleFileVersionStatusFailure,
  toggleFileVersionStatusSuccess,
  unselectActiveGroup,
} from './files.actions';

export interface FilesState {
  projectFilesList: GQLFileGroup[];
  threadFilesList: GQLFile[];
  currentFileListStatus: GQLFileStatusEnum;
  isLoadingFiles: boolean;
  currentFileId: string;
  addFilesError: string;
  error?: unknown;
  file: GQLFile | null;
  addFilesLoading: boolean;
  loadFileLoading: boolean;
  versionCreateLoading: boolean;
  markerAutoincrementValue: number;
  activeFileVersion: GQLFileVersion | null;
  activeFileVersionLoading: boolean;
  activeMarkersGroup: GQLMarkerGroup | null;
  current3dScreenshot: string;
  isLoadingFileCollection: boolean;
  fileCollection: GQLFileCollection | null;
  totalProjectListInFileCollection: GQLFileCollection | null;
  fileListView: EViewType;
  filterDateRange: GQLDateRange | null;
}

export const initialFilesState: FilesState = {
  projectFilesList: [],
  threadFilesList: [],
  currentFileListStatus: GQLFileStatusEnum.ACTIVE,
  isLoadingFiles: false,
  currentFileId: '',
  addFilesError: '',
  file: null,
  addFilesLoading: false,
  loadFileLoading: false,
  versionCreateLoading: false,
  activeFileVersion: null,
  activeFileVersionLoading: false,
  activeMarkersGroup: null,
  markerAutoincrementValue: 0,
  current3dScreenshot: '',
  isLoadingFileCollection: false,
  fileCollection: null,
  totalProjectListInFileCollection: null,
  fileListView: EViewType.CARD,
  filterDateRange: null,
};

function unreadMakersLength(version: GQLFileVersion): number {
  return version.markerGroups.filter(
    (group) =>
      group.markers.filter((marker) => marker.hasNewComment).length > 0,
  ).length;
}

const filesReducer = createReducer(
  initialFilesState,
  on(setCurrent3dScreenshot, (state, { current3dScreenshot }) => ({
    ...state,
    current3dScreenshot,
  })),
  on(saveCurrentFileId, (state, { currentFileId }) => ({
    ...state,
    currentFileId,
  })),
  on(clearCurrentFileId, (state) => ({
    ...state,
    currentFileId: '',
  })),
  on(loadProjectFiles, (state, { dateRange }) => ({
    ...state,
    projectFilesList: [],
    isLoadingFiles: true,
    filterDateRange: dateRange,
  })),
  on(loadProjectFilesSuccess, (state, { filesList }) => {
    return {
      ...state,
      projectFilesList: filesList,
      isLoadingFiles: false,
    };
  }),
  on(loadProjectFilesFailure, (state, error) => ({
    ...state,
    error,
    isLoadingFiles: false,
  })),
  on(loadThreadFiles, (state, { dateRange }) => ({
    ...state,
    threadFilesList: [],
    isLoadingFiles: true,
    filterDateRange: dateRange,
  })),
  on(loadThreadFilesSuccess, (state, { filesList }) => {
    return {
      ...state,
      threadFilesList: filesList,
      isLoadingFiles: false,
    };
  }),
  on(loadThreadFilesFailure, (state, error) => {
    console.error(error, 'loadThreadFilesFailure');
    return {
      ...state,
      error,
      isLoadingFiles: false,
    };
  }),
  on(addFiles, (state) => ({
    ...state,
    addFilesLoading: true,
  })),
  on(addFilesSuccess, (state) => ({
    ...state,
    addFilesLoading: false,
    versionCreateLoading: false,
    addFilesError: initialFilesState.addFilesError,
  })),
  on(addFilesFailure, (state, { error }) => {
    return {
      ...state,
      addFilesLoading: false,
      versionCreateLoading: false,
      addFilesError: error.graphQLErrors[0].message,
    };
  }),
  on(clearAddFileError, (state) => {
    return {
      ...state,
      addFilesError: initialFilesState.addFilesError,
    };
  }),
  on(loadFile, (state) => ({
    ...state,
    file: null,
    activeFileVersion: null,
    activeMarkersGroup: null,
    loadFileLoading: true,
    activeFileVersionLoading: true,
  })),
  on(loadFileSuccess, (state, { file }) => ({
    ...state,
    file,
    loadFileLoading: false,
  })),
  on(loadFileFailure, (state, error) => ({
    ...state,
    error,
    loadFileLoading: false,
  })),
  on(resetFileLoadingFlag, (state) => ({
    ...state,
    addFilesLoading: false,
    versionCreateLoading: false,
  })),
  on(createFileVersion, (state) => ({
    ...state,
    versionCreateLoading: true,
  })),
  on(createFileVersionSuccess, (state, { fileVersion }) => ({
    ...state,
    file: state.file
      ? {
          ...state.file,
          versions: [fileVersion, ...(state.file?.versions || [])],
        }
      : state.file,
    versionCreateLoading: false,
  })),
  on(createFileVersionFailure, (state) => ({
    ...state,
    versionCreateLoading: false,
  })),

  on(markVersionAsViewedFailure, (state) => ({
    ...state,
    versionCreateLoading: false,
  })),
  on(toggleFileVersionStatusSuccess, (state, { fileVersion }) => {
    return {
      ...state,
      file: state.file?.versions
        ? {
            ...state.file,
            status:
              fileVersion.status === GQLFileVersionStatusEnum.APPROVED
                ? GQLFileStatusEnum.APPROVED
                : GQLFileStatusEnum.ACTIVE,
            versions: state.file.versions.map((version) => {
              if (version.id === fileVersion.id) {
                return {
                  ...version,
                  status:
                    version.status === GQLFileVersionStatusEnum.APPROVED
                      ? GQLFileVersionStatusEnum.ACTIVE
                      : GQLFileVersionStatusEnum.APPROVED,
                };
              }
              return version;
            }),
          }
        : state.file,
    };
  }),
  on(clearFile, (state) => ({
    ...state,
    file: null,
  })),
  on(loadFileVersion, (state) => ({
    ...state,
    activeFileVersion: null,
    activeMarkersGroup: null,
    loadFileLoading: true,
    activeFileVersionLoading: true,
  })),
  on(loadFileVersionFailure, (state) => ({
    ...state,
    loadFileLoading: false,
    activeFileVersionLoading: false,
  })),
  on(loadFileVersionSuccess, (state, { fileVersion }) => {
    return {
      ...state,
      activeFileVersion: fileVersion,
      markerAutoincrementValue: fileVersion.markerAutoincrementValue,
      loadFileLoading: false,
      activeFileVersionLoading: false,
    };
  }),
  on(
    loadMarkerAutoincrementValueSuccess,
    (state, { markerAutoincrementValue }) => ({
      ...state,
      markerAutoincrementValue,
    }),
  ),

  on(toggleFileVersionStatusFailure, (state, { error }) => ({
    ...state,
    error,
  })),

  on(setCurrentFileListStatus, (state, { status }) => ({
    ...state,
    currentFileListStatus: status,
  })),
  on(removeFileFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(removeFileVersionSuccess, (state, { id }) => {
    const { file } = state;
    return {
      ...state,
      activeFileVersion: nextFileVersion(file?.versions, id),
      file: file
        ? {
            ...file,
            versions: (file?.versions || []).filter(
              (fileVersion) => fileVersion.id !== id,
            ),
          }
        : file,
    };
  }),
  on(removeFileVersionFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(selectMarkersGroup, (state, { markersGroup }) => {
    return {
      ...state,
      activeMarkersGroup: markersGroup,
    };
  }),
  on(unselectActiveGroup, (state) => {
    return {
      ...state,
      activeMarkersGroup: null,
      current3dScreenshot: '',
    };
  }),
  on(createMarkerGroupSuccess, (state, { markerGroup }) => {
    return {
      ...state,
      activeMarkersGroup: markerGroup,
    };
  }),
  on(updateMarkerGroupImage, (state, { imagePath, previewPath, id }) => {
    return {
      ...state,
      ...(state.activeMarkersGroup && {
        activeMarkersGroup: {
          ...state.activeMarkersGroup,
          imagePath,
          previewPath,
        },
      }),
    };
  }),
  // FILE COLLECTION
  on(getAllFileCollection, (state, { dateRange }) => {
    return {
      ...state,
      isLoadingFileCollection: true,
      filterDateRange: dateRange,
    };
  }),
  on(getAllFileCollectionSuccess, (state, { fileCollection }) => {
    return {
      ...state,
      isLoadingFileCollection: false,
      fileCollection,
    };
  }),
  on(getAllFileCollectionFailure, (state) => {
    return {
      ...state,
      isLoadingFileCollection: false,
    };
  }),
  // TOTAL PROJECT LIST IN FILE COLLECTION
  on(loadProjectsFiles, (state) => {
    return {
      ...state,
    };
  }),
  on(loadProjectsFilesSuccess, (state, { totalProjectList }) => {
    return {
      ...state,
      totalProjectListInFileCollection: totalProjectList,
    };
  }),
  on(loadProjectsFilesFailure, (state) => {
    return {
      ...state,
    };
  }),
  on(markApprovedFileAsViewedSuccess, (state, { file }) => {
    const { fileCollection } = state;
    return {
      ...state,
      fileCollection: fileCollection
        ? {
            ...fileCollection,
            items: fileCollection.items.map((fileCollectionItem) => ({
              ...fileCollectionItem,
              files: fileCollectionItem.files.map((fileGroup) => ({
                ...fileGroup,
                files: fileGroup.files.filter(
                  ({ id }) =>
                    id !== file.id &&
                    !file.hasNewApprove &&
                    !file.hasNewComment &&
                    !file.hasNewVersion,
                ),
              })),
            })),
          }
        : state.fileCollection,
    };
  }),
  on(markApprovedFileAsViewedFailure, (state, { error }) => {
    return {
      ...state,
      error,
    };
  }),
  on(markMarkerAsViewedSuccess, (state, { markerId }) => {
    const { file } = state;
    return {
      ...state,
      file: file
        ? {
            ...file,
            versions: (file.versions || [])
              .map((version: GQLFileVersion) => ({
                ...version,
                markerGroups: version.markerGroups.map((group) => ({
                  ...group,
                  markers: group.markers.map((marker) =>
                    marker.id === markerId
                      ? { ...marker, hasNewComment: false }
                      : marker,
                  ),
                })),
              }))
              .map((version) => ({
                ...version,
                hasNewComment: unreadMakersLength(version) > 0,
                isViewed: unreadMakersLength(version) === 0,
              })),
          }
        : file,
    };
  }),

  on(markVersionAsViewedSuccess, (state, { versions }) => {
    const { file, projectFilesList, threadFilesList, fileCollection } = state;

    return {
      ...state,
      file: file
        ? {
            ...file,
            versions: (file.versions || []).map(
              (fileVersion) =>
                versions.find(({ id }) => id === fileVersion.id) || fileVersion,
            ),
          }
        : file,
      projectFilesList: projectFilesList.map(
        (fileGroup: GQLFileGroup): GQLFileGroup => ({
          ...fileGroup,
          files: fileGroup.files.map((groupFile: GQLFile) =>
            changeHasNewVersionInFile(groupFile, versions),
          ),
        }),
      ),
      threadFilesList: threadFilesList.map((groupFile: GQLFile) =>
        changeHasNewVersionInFile(groupFile, versions),
      ),
      fileCollection: fileCollection
        ? {
            ...fileCollection,
            items: fileCollection.items.map((fileCollectionItem) => ({
              ...fileCollectionItem,
              files: fileCollectionItem.files.map((fileGroup) => ({
                ...fileGroup,
                files: fileGroup.files.map((groupFile: GQLFile) =>
                  changeHasNewVersionInFile(groupFile, versions),
                ),
              })),
            })),
          }
        : state.fileCollection,
    };
  }),
  // FILES LIST VIEW
  on(
    getInitFileListViewSuccess,
    toggleFileListViewSuccess,
    (state, { viewType }) => ({
      ...state,
      fileListView: viewType,
    }),
  ),
  on(setFile, (state, { file }) => ({
    ...state,
    file,
  })),
  on(createFileVersionSuccess, (state, { fileVersion, fileId }) => {
    const { projectFilesList, threadFilesList, fileCollection } = state;
    return {
      ...state,
      projectFilesList: projectFilesList
        ? projectFilesList.map(
            (fileGroup: GQLFileGroup): GQLFileGroup => ({
              ...fileGroup,
              files: fileGroup.files.map((file: GQLFile) => {
                if (file && file.id === fileId) {
                  return {
                    ...file,
                    versions: [
                      fileVersion,
                      ...(file.versions as GQLFileVersion[]),
                    ],
                  };
                }
                return file;
              }),
            }),
          )
        : projectFilesList,
      threadFilesList: threadFilesList.map((file: GQLFile) => {
        if (file && file.id === fileId) {
          return {
            ...file,
            versions: [fileVersion, ...(file.versions as GQLFileVersion[])],
          };
        }
        return file;
      }),
      fileCollection: fileCollection
        ? {
            ...fileCollection,
            items: fileCollection.items.map((fileCollectionItem) => ({
              ...fileCollectionItem,
              files: fileCollectionItem.files.map((fileGroup) => ({
                ...fileGroup,
                files: fileGroup.files.map((file: GQLFile) => {
                  if (file && file.id === fileId) {
                    return {
                      ...file,
                      versions: [
                        fileVersion,
                        ...(file.versions as GQLFileVersion[]),
                      ],
                    };
                  }
                  return file;
                }),
              })),
            })),
          }
        : state.fileCollection,
      isLoadingFiles: false,
    };
  }),
);

export function FilesReducer(
  state: FilesState | undefined,
  action: Action,
): FilesState {
  return filesReducer(state, action);
}
