import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GQLFile } from '../../../schemas/schemas';
import {
  EFromFilePlace,
  setQueryParametersForFilesPage,
  setQueryParametersForImageTools,
} from '../helpers';

@Injectable({
  providedIn: 'root',
})
export class NavigationBackService {
  constructor(private readonly router: Router) {}
  /*
    if file in commenting tools - navigate to commenting tools and method navigate() return false
    else method return true for open file viewer
  */
  isNavigateToTools(file: GQLFile, from: EFromFilePlace): Promise<boolean> {
    const isFileInUseTools: boolean = file.useTools;
    const extras = setQueryParametersForImageTools(
      file.versions?.[0].id || '',
      `${this.router.url}`,
      from,
    );
    // `${this.router.url}?openedFile=${file.id}`,

    if (isFileInUseTools) {
      return this.router.navigate(['tools', file?.id], extras);
    }
    return Promise.resolve(false);
  }

  navigateToFilesPage(): void {
    const extras = setQueryParametersForFilesPage(this.router.url);
    this.router.navigate(['files'], extras);
  }
}
