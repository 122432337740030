import {
  getActiveMarkersGroup,
  getFile,
  getFileLoading,
} from '@common/store/files/files.selectors';

import { GQLFileTypeEnum } from '../../../schemas/schemas';
import { createSelector } from '@ngrx/store';
import { getCurrentUser } from '@common/store/users/users.selectors';
import { getMarkerGroupsCreating } from '@common/store/markers/markers.selectors';
import { getPlatformState } from '@common/store';

export const getCommentingToolState = createSelector(
  getPlatformState,
  (state) => state.commentingToolState,
);

export const getImageCommentingToolRendering = createSelector(
  getCommentingToolState,
  (state) => {
    return state.imageCommentingToolRendering;
  },
);

export const selectProductLoader = createSelector(
  getCommentingToolState,
  (state) => {
    return state.productLoading;
  },
);

export const getImageCommentingToolLoading = createSelector(
  getImageCommentingToolRendering,
  getMarkerGroupsCreating,
  getFileLoading,
  selectProductLoader,
  (
    imageCommentingToolRendering,
    markerGroupsCreating,
    fileLoading,
    productLoading,
  ) => {
    return (
      imageCommentingToolRendering ||
      markerGroupsCreating ||
      fileLoading ||
      productLoading
    );
  },
);

export const getAllFilesWithTool = createSelector(
  getCommentingToolState,
  (state) => state.allFilesWithTool,
);

export const getVideoSidebarTab = createSelector(
  getCommentingToolState,
  (state) => state.videoSidebarTab,
);

export const getThreadFilesWithOriginOrder = createSelector(
  getCommentingToolState,
  (state) => state.threadFilesWithOriginOrder,
);

export const getProjectFilesWithTool = createSelector(
  getCommentingToolState,
  (state) => state.projectFilesWithTool,
);

export const getThreadFilesWithTool = createSelector(
  getCommentingToolState,
  (state) => state.threadFilesWithTool,
);

export const getThreadOrProjectToolFiles = createSelector(
  getCommentingToolState,
  ({
    threadFilesWithTool,
    projectFilesWithTool,
    allFilesWithTool,
    threadFilesWithOriginOrder,
  }) => {
    let files = [];
    if (threadFilesWithTool.length > 0) {
      files = threadFilesWithTool;
    } else if (projectFilesWithTool.length > 0) {
      files = projectFilesWithTool;
    } else if (threadFilesWithOriginOrder.length > 0) {
      files = threadFilesWithOriginOrder;
    } else {
      files = allFilesWithTool;
    }

    return files.filter((file) => (file.versions?.length || 0) > 0);
  },
);

export const currentFileIndexInThreadOrProjectFiles = createSelector(
  getThreadOrProjectToolFiles,
  getFile,
  (threadOrProjectToolFiles, currentFile): number =>
    threadOrProjectToolFiles.findIndex((file) => currentFile?.id === file.id),
);

export const isPrevButtonDisabled = createSelector(
  currentFileIndexInThreadOrProjectFiles,
  (currentFileIndex): boolean => {
    return currentFileIndex <= 0;
  },
);

export const isNextButtonDisabled = createSelector(
  getThreadOrProjectToolFiles,
  currentFileIndexInThreadOrProjectFiles,
  (threadOrProjectToolFiles, currentFileIndex): boolean =>
    currentFileIndex >= threadOrProjectToolFiles.length - 1,
);

export const getPrevNextButtonDisableStatus = createSelector(
  isPrevButtonDisabled,
  isNextButtonDisabled,
  (disablePrevButton, disableNextButton) => ({
    disablePrevButton,
    disableNextButton,
  }),
);

export const getScreenViewState = createSelector(
  getCommentingToolState,
  (state) => state.isFullScreen,
);

export const getVideoScreenViewState = createSelector(
  getCommentingToolState,
  (state) => state.isVideoFullScreen,
);

export const getVersionsListCollapsed = createSelector(
  getCommentingToolState,
  (state) => state.versionsListCollapsed,
);

export const getFitCurrentVersion = createSelector(
  getCommentingToolState,
  (state) => state.fitCurrentVersion,
);

export const selectReCenterModel = createSelector(
  getCommentingToolState,
  (state) => state.reCenterModel,
);

export const getMarkersStatus = createSelector(
  getCommentingToolState,
  (state) => state.markersStatus,
);

export const getZoom = createSelector(
  getCommentingToolState,
  (state): number => {
    return state.zoom;
  },
);

export const getDrawingMode = createSelector(
  getCommentingToolState,
  (state): boolean => {
    return state.isDrawingMode;
  },
);

export const getIsCommentingMode = createSelector(
  getCommentingToolState,
  ({ isCommentingMode }) => {
    return isCommentingMode;
  },
);

export const getIsCommentingAndDrawingModeOff = createSelector(
  getDrawingMode,
  getIsCommentingMode,
  (isDrawingMode, isCommentingMode) => {
    return { isDrawingMode, isCommentingMode };
  },
);

export const getIsMarkersVisible = createSelector(
  getCommentingToolState,
  ({ isMarkersVisible }) => {
    return isMarkersVisible;
  },
);

export const getStreamAvailability = createSelector(
  getCommentingToolState,
  ({ isStreamIsNotAvailable }) => {
    return isStreamIsNotAvailable;
  },
);

export const getCurrentTimePoint = createSelector(
  getCommentingToolState,
  ({ currentVideoTimePoint }) => {
    return currentVideoTimePoint;
  },
);

export const getIsVideoType = createSelector(getFile, (file) => {
  return file?.type === GQLFileTypeEnum.VIDEO;
});

export const getIsStreamType = createSelector(getFile, (file) => {
  return file?.type === GQLFileTypeEnum.STREAM;
});

export const getIsEmbedType = createSelector(getFile, (file) => {
  return file?.type === GQLFileTypeEnum.EMBED;
});

export const getIsImageType = createSelector(getFile, (file) => {
  return file?.type === GQLFileTypeEnum.IMAGE;
});

export const getIsModelType = createSelector(getFile, (file) => {
  return file?.type === GQLFileTypeEnum.MODEL;
});

export const getFileType = createSelector(getFile, (file) => {
  return file?.type;
});

export const getImageCommentingToolLoadingWithoutVideo = createSelector(
  getImageCommentingToolLoading,
  getIsVideoType,
  (imageCommentingToolRendering, isVideoType) => {
    return imageCommentingToolRendering && !isVideoType;
  },
);

export const hideImageCommeningToolByStream = createSelector(
  getImageCommentingToolLoading,
  getIsStreamType,
  getIsEmbedType,
  (imageCommentingToolLoading, isStreamType, isEmbedType) => {
    return imageCommentingToolLoading && (isStreamType || isEmbedType);
  },
);

export const getIsVideoOrStreamType = createSelector(getFile, (file) => {
  return (
    file?.type === GQLFileTypeEnum.VIDEO ||
    file?.type === GQLFileTypeEnum.STREAM
  );
});

export const getMainContainerViewModel = createSelector(
  getActiveMarkersGroup,
  getIsStreamType,
  getIsEmbedType,
  getIsVideoType,
  getIsImageType,
  getCurrentUser,
  (
    activeMarkersGroup,
    isStreamType,
    isEmbedType,
    isVideoType,
    isImageType,
    isAuth,
  ) => {
    return {
      isStreamType,
      isEmbedType,
      isVideoType,
      isImageType,
      activeMarkersGroup: !!activeMarkersGroup,
      disabledSomeContolsButton:
        (isStreamType || isVideoType) && !activeMarkersGroup,
      isAuth,
    };
  },
);

export const getCommentingToolViewModel = createSelector(
  getIsVideoType,
  getIsStreamType,
  getIsEmbedType,
  getIsImageType,
  getDrawingMode,
  getIsCommentingMode,
  getFileType,
  getActiveMarkersGroup,
  (
    isVideoType,
    isStreamType,
    isEmbedType,
    isImageType,
    isDrawingMode,
    isCommentingMode,
    fileType,
    activeMarkersGroup,
  ) => {
    const isVideoScreenshot =
      isVideoType && (isDrawingMode || isCommentingMode);
    const isStreamScreenshot =
      (isStreamType || isEmbedType) && activeMarkersGroup?.imagePath;

    return {
      isVideoScreenshot,
      isStreamScreenshot,
      isStreaming: isStreamType && !isDrawingMode && !isCommentingMode,
      isImageScreenshot: isImageType && (isDrawingMode || isCommentingMode),
      isVideoType,
      isImageType,
      isStreamType,
      isEmbedType,
      hideImageTool: !(
        isVideoScreenshot ||
        isImageType ||
        isStreamScreenshot ||
        fileType === GQLFileTypeEnum.OTHER ||
        !fileType
      ),
    };
  },
);
