import {
  EUIInteractionCommand,
  EntryProject,
  IUIInteractionData,
  LOAD_LEVEL_COMMAND,
  LOAD_LEVEL_COMMAND_UNREAL_5,
  ResponsesFromUnreal,
} from '@common/helpers/constants';
import { map, take, takeUntil } from 'rxjs/operators';

import { IUIIteractionDescriptor } from '@common/types';
import { Injectable } from '@angular/core';
import { Signal } from '@common/services/Signal';
import { Size } from '@common/utils/utils';
import { Subject } from 'rxjs';

export const FOV = 25;

@Injectable({
  providedIn: 'root',
})
export class UnrealService {
  scope: any = window as any;
  descriptor!: IUIIteractionDescriptor;
  videoSize: Size = {
    w: 0,
    h: 0,
  };
  unsubscribe$: Subject<boolean> = new Subject<boolean>();

  protected eventListeners: Array<{ event: string; func: () => void }> = [];

  async sendDataToUnreal(data: any): Promise<void> {
    await this.checkUnrealStatus();
    this.scope.emitUIInteraction(data);
  }

  checkUnrealStatus(): Promise<string> {
    return new Promise((resolve, reject) => {
      Signal.on(ResponsesFromUnreal.PONG)
        .pipe(
          take(1),
          map((res: string) => res),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(resolve);

      const data: IUIInteractionData = {
        command: EUIInteractionCommand.PING,
      };
      this.scope.emitUIInteraction(data);
    });
  }

  sendWithOutPing(data: any) {
    this.scope.emitUIInteraction(data);
  }

  addNewEventListeners(event: string, func: () => void) {
    this.scope.addEventListener(event, func);
    this.eventListeners.push({ event, func });
  }

  initView = (isLoadLevelForUnreal5?: boolean): void => {
    this.initEntryPoint();
    this.loadLevelWithDescription(this.descriptor, isLoadLevelForUnreal5);
  };

  loadLevelWithDescription(
    descriptor: IUIIteractionDescriptor,
    isLoadLevelForUnreal5?: boolean,
  ) {
    this.changeResolution();
    this.loadLevel(isLoadLevelForUnreal5);
    this.sendDescriptor(descriptor);
  }

  async initEntryPoint() {
    const screenShootDescriptor: IUIInteractionData = {
      command: EUIInteractionCommand.PLATFORM_INIT,
      payload: {
        type: EntryProject.PLATFORM,
      },
    };

    this.sendWithOutPing(screenShootDescriptor);
  }

  private async loadLevel(isLoadLevelForUnreal5?: boolean) {
    this.sendDataToUnreal(
      isLoadLevelForUnreal5 ? LOAD_LEVEL_COMMAND_UNREAL_5 : LOAD_LEVEL_COMMAND,
    );
    this.sendFovDataToUnreal();
  }

  sendFovDataToUnreal() {
    const data = {
      command: EUIInteractionCommand.CHANGE_FOV,
      fov: FOV,
    };
    this.sendDataToUnreal(data);
  }

  private sendDescriptor(descriptor: IUIIteractionDescriptor) {
    this.sendDataToUnreal(descriptor);
  }

  async resetScene() {
    const screenShootDescriptor: IUIInteractionData = {
      command: EUIInteractionCommand.REMOVE_ALL_PRODUCTS,
    };
    this.sendDataToUnreal(screenShootDescriptor);
  }

  createScreenShot(url: string) {
    const screenShootDescriptor: IUIInteractionData = {
      command: EUIInteractionCommand.TAKE_RENDER,
      payload: {
        size: {
          x: this.videoSize.w,
          y: this.videoSize.h,
        },
        url,
        //Required fields for VPS5 +
        name: 'VPS5',
        pathTrace: false,
        format: 'png',
        transparentBackground: false,
        samples: 100,
        frames: 1,
      },
    };

    this.sendWithOutPing(screenShootDescriptor);
  }

  changeResolution() {
    const videoElement = document.getElementById('player');

    this.scope.setupNormalizeAndQuantize();
    const videoSize = {
      w: videoElement?.offsetWidth || 0,
      h: videoElement?.offsetHeight || 0,
    };

    this.videoSize = videoSize;
    if (!videoSize.w || !videoSize.h) {
      return;
    }
    const changeResolution: IUIInteractionData = {
      command: EUIInteractionCommand.CHANGE_RESOLUTION,
      width: videoSize.w,
      height: videoSize.h,
    };

    this.sendDataToUnreal(changeResolution);
  }

  changeZoom(zoomAdd: number) {
    const changeResolution: IUIInteractionData = {
      command: EUIInteractionCommand.ZOOM,
      zoomAdd,
    };

    this.sendDataToUnreal(changeResolution);
  }

  setZoom(zoomVal: number) {
    const changeResolution: IUIInteractionData = {
      command: EUIInteractionCommand.ZOOM,
      zoomVal,
    };

    this.sendDataToUnreal(changeResolution);
  }

  fitToObject(): void {
    const changeResolution: IUIInteractionData = {
      command: EUIInteractionCommand.FIT,
    };

    this.sendDataToUnreal(changeResolution);
  }

  addResponseEventListener(
    name: EUIInteractionCommand,
    listener: (data: any) => void,
  ) {
    this.scope.addResponseEventListener(name, listener);
  }

  removeResponseEventListener(name: EUIInteractionCommand) {
    this.scope?.removeResponseEventListener(name);
  }
}
