/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 7/30/21, 8:30 PM
 * Copyright 2021 3D Source
 */

export const isMobile = (): boolean => {
  const screenWidth = document.body.offsetWidth;
  const mobileBreakpoint = 1280;
  return screenWidth < mobileBreakpoint;
};

export const isLowHeightLaptop = (): boolean => {
  const screenWidth = document.body.offsetWidth;
  const screenHeight = document.body.offsetHeight;
  const laptopBreakpointWidth = 1280;
  const laptopBreakpointHeight = 700;

  return (
    screenWidth < laptopBreakpointWidth || screenHeight < laptopBreakpointHeight
  );
};
