/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/23/21, 3:03 PM
 * Copyright 2021 3D Source
 */

export enum ELoginErrors {
  MAIL_REQUIRED = 'Email is required',
  MAIL_PATTERN = 'Incorrect format of e-mail',
  MAIL_SHORT = 'Email must be at least 7 symbols',
  MAIL_LONG = "E-mail can't contain more than 255 symbols",
  PASS_REQUIRED = 'Password is required',
  PASS_SHORT = 'Password must contain at least 8 symbols',
  PASS_LONG = "Password can't contain more than 100 symbols",
  WRONG_SYMBOLS = 'Password only needs to enter numbers, letters and symbol _',
  PASS_NOT_CONFIRM = 'Passwords do not match',
  PASS_SYMBOLS = 'wrong symbols',
  PASS_WRONG_SYMBOLS = 'Your password must be between 8 and 128 characters long, contain at least one number and a mix of upper and lower case English letters with no spaces.',
  PASS_NON_LATIN = 'Invalid symbols',
  USER_ALREADY_EXIST = 'This user already exists',
  EMAIL_MAX_LENGTH = "E-mail can't contain more than 255 symbols.",
  FIRST_NAME_REQUIRED = 'First name is required',
  LAST_NAME_REQUIRED = 'Last name is required',
  FIRST_NAME_SHORT = 'First name must be at least 2 symbols',
  FIRST_NAME_LONG = "First name can't contain more than 100 symbols",
  LAST_NAME_SHORT = 'Last name must be at least 2 symbols',
  LAST_NAME_LONG = "Last name can't contain more than 100 symbols",
}

export enum EImagesErrors {
  WRONG_LOGO_FORMAT = 'File format is not supported.',
}
