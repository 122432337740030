<ng-container *ngIf="file">
  <a
    *ngIf="!isOpenGallery"
    [class.file-card--md]="!bigSize"
    class="file-card ut-file-card"
    [ngClass]="{
      line: isListView,
      unreaded: unreaded,
      approved: isFileApproved,
      removed: isRemovedFile
    }"
    [routerLink]="isRemovedFile ? null : ['/tools', file?.id]"
    [queryParams]="queryParams"
    [queryParamsHandling]="'merge'"
    [skipLocationChange]="skipLocationChange">
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
  </a>
  <div
    *ngIf="isOpenGallery"
    [class.file-card--md]="!bigSize"
    class="file-card ut-file-card"
    [ngClass]="{
      line: isListView,
      unreaded: unreaded,
      approved: isFileApproved
    }"
    (click)="openGallery()">
    <ng-container *ngTemplateOutlet="cardBody"></ng-container>
  </div>
</ng-container>

<ng-template #cardBody>
  <div class="file-card__logo">
    <img
      *ngIf="getFileVersion?.previewPath && !embedImg"
      [src]="getFileVersion?.previewPath"
      alt="3D Source" />
    <img
      *ngIf="!getFileVersion?.previewPath && !embedImg"
      [src]="fileName | fileType: true" />
    <img
      *ngIf="!!embedImg"
      [src]="embedImg" />

    <div
      class="file-card__menu comment-menu"

      [class.active]="editMenuIsOpen"
      *ngIf="!isListView && !isFileApproved && !isRemovedFile">
      <button
        type="button"
        class="comment-menu__toggle"
        (click)="
          $event.stopPropagation();
          $event.preventDefault();
          toggleEditMenu($event)
        ">
        <app-icon href="#icon-dots-vertical"></app-icon>
      </button>

      <p-overlayPanel
        #op
        appendTo="body"
        [autoZIndex]="false"
        (onShow)="toggleButtonVisibility()"
        (onHide)="toggleButtonVisibility()"
        styleClass="comment-menu-overlay">
        <ng-template pTemplate>
          <ul class="comment-menu__list">
            <li *ngIf="file.type !== fileType.OTHER" class="comment-menu__item">
              <button class="comment-menu__button" (click)="addVersion()">
                Add version
              </button>
            </li>
            <li class="comment-menu__item">
              <button class="comment-menu__button" (click)="removeFile($event)">
                Delete
              </button>
            </li>
          </ul>
        </ng-template>
      </p-overlayPanel>
    </div>
  </div>

  <span class="file-card__title">
    <span [pTooltip]="tooltipText(fileName, 16)">
      {{ fileName | shortener: 15 }}
    </span>
  </span>

  <div class="file-card__update" *ngIf="!bigSize && !isRemovedFile">
    {{ getFileVersion?.size | fileSizePipe }}
  </div>
  <ng-container *ngIf="bigSize">
    <div class="file-card__date">
      <div class="date">
        <span class="date__label">Created:</span><span>{{ createdAt | date: 'M/d/yyyy' }}</span>
      </div>
      <div class="date">
        <span class="date__label">Updated:</span><span>{{ lastUpdated | date: 'M/d/yyyy' }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="isRemovedFile">
    <div class="file-card__update">File was deleted.</div>
  </ng-container>

  <div class="file-card__creator">
    <span class="file-card__creator-wrap" *ngIf="fileAuthorFullName">
      {{ fileAuthorFullName }}
    </span>
  </div>

  <div class="file-card__versions" *ngIf="isVersionsIconNeed && !isRemovedFile">
    {{ fileVersionQuantity }}
    <div class="file-card__versions-label"></div>
  </div>

  <div class="file-card__comments">{{ getComments }}</div>

  <div class="file-card__actions">
    <button
      class="button-icon"
      (click)="
        $event.stopPropagation(); $event.preventDefault(); removeFile($event)
      ">
      <app-icon href="#icon-remove"></app-icon>
    </button>
  </div>
</ng-template>
