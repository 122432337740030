/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/3/21, 5:05 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const REMOVE_THREAD = gql`
  mutation ($input: RemoveInput!) {
    removeThread(input: $input) {
      id
      success
    }
  }
`;
