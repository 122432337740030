import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isToday, formatDistanceToNow } from 'date-fns';

@Pipe({
  name: 'timeCreated',
})
export class TimeCreatedPipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {}

  transform(date: Date): string | null {
    const dateToString = Date.parse(date?.toString() || '');
    const isTodayDate = isToday(dateToString);

    if (isTodayDate) {
      return `${formatDistanceToNow(dateToString)} ago`;
    }

    return this.datePipe.transform(date, 'M/d/yyyy');
  }
}
