<div class="message">
  <div class="message__heading message-heading">
    <div class="message-heading__image">
      <p-skeleton size="58" class="my-avatar" shape="circle"></p-skeleton>
    </div>
    <div class="message-heading__author">
      <strong>
        <p-skeleton></p-skeleton>
      </strong>
      <div class="message-heading__info">
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </div>
  <!-- /message-heading -->
  <div class="message__body message-body">
    <div class="message-body__heading">
      <h2><p-skeleton></p-skeleton></h2>
      <span class="dot-divider"></span>
      <span><p-skeleton></p-skeleton></span>
    </div>
    <div class="message-body__content">
      <p-skeleton></p-skeleton>
      <p-skeleton></p-skeleton>
    </div>
  </div>
  <!-- /message-body -->

  <div class="message__footer message-footer">
    <div class="message-footer__content">
      <div class="message-footer__content-element message-counter">
        <p-skeleton class="sk-icon"></p-skeleton>
        <p-skeleton></p-skeleton>
      </div>
      <div class="message-footer__content-element file-counter">
        <p-skeleton class="sk-icon"></p-skeleton>
        <p-skeleton></p-skeleton>
      </div>

      <div class="message-footer__content-element approval">
        <p-skeleton class="sk-icon"></p-skeleton>
        <p-skeleton></p-skeleton>
      </div>
    </div>
  </div>
</div>
