<div class="subscribers">
  <!-- Button with Plus icon -->
  <button
    type="button"
    class="subscribers__button"
    data-test-id="toggleSubscribersSelector"
    (click)="toggleSubscribersSelector()"
  >
    <svg class="icon" width="24" height="24">
      <use xlink:href="#icon-user"></use>
    </svg>
    <span class="plus">
      <svg class="icon" width="12" height="12">
        <use xlink:href="#icon-plus"></use>
      </svg>
    </span>
  </button>
  <!-- SELECTED USERS -->
  <ul class="subscribers-list" *ngIf="selectedUsers.length > 0">
    <li
      *ngFor="let selectedUser of selectedUsers.slice(0, 3); let i = index"
      class="subscribers-list__item"
      pTooltip="{{ selectedUser?.fullName }}"
      tooltipZIndex="10000000"
      [attr.data-test-id]="'selectedUser-id-' + selectedUser?.id"
    >
      <ngx-avatars
        class="my-avatar"
        size="34"
        [src]="selectedUser?.avatarPath"
        [name]="getAuthorAvatarName(selectedUser)"
      ></ngx-avatars>
    </li>

    <!-- Button More -->
    <li
      class="subscribers-list__item subscribers-list__item--more"
      *ngIf="(selectedUsers || []).length > 3"
    >
      <button
        type="button"
        class="button-more"
        data-test-id="toggleMoreSelectedUsers"
        [class.active]="isMoreSelectedUsersPopupOpen"
        (click)="toggleMoreSelectedUsers()"
      >
        <svg
          class="icon icon-more"
          width="28"
          height="28"
          fill="none"
          viewBox="0 0 28 28"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.25 14c0 7.318-5.932 13.25-13.25 13.25S.75 21.318.75 14 6.682.75 14 .75 27.25 6.682 27.25 14Z"
            id="round"
            stroke-width="1.5"
          />
          <path
            d="M15.7 20.4a1.6 1.6 0 1 0-3.2 0 1.6 1.6 0 0 0 3.2 0ZM15.7 14a1.6 1.6 0 1 0-3.2 0 1.6 1.6 0 0 0 3.2 0ZM14.1 6a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z"
            id="dots"
          />
        </svg>
      </button>
    </li>
  </ul>
  <!-- END SELECTED USERS -->

  <div class="droplist add-subscribers" *ngIf="isSubscribersSelectorOpen">
    <div class="droplist__mobile-header">
      <button
        type="button"
        class="close"
        data-test-id="closeSubscribersSelector"
        (click)="closeSubscribersSelector()"
      >
        <svg class="icon" width="24" height="24">
          <use xlink:href="#icon-chevrone-left"></use>
        </svg>
      </button>
      Set Assignees
    </div>

    <div class="droplist__search">
      <input
        data-test-id="filterUsers"
        (input)="filterSubscribers($event)"
        type="text"
        placeholder="Search"
      />
    </div>

    <div class="droplist__content">
      <p-scrollPanel>
        <div class="add-subscribers__list">
          <div
            class="add-subscribers__item add-subscribers__item--all"
            data-test-id="selectAllUsers"
            (click)="selectAllUsers()"
            [class.active]="isAllUsersSelected"
          >
            <div class="image">
              <div class="avatar-container">
                <img
                  src="./assets/images/logo.svg"
                  class="avatar-content"
                  alt="all"
                />
              </div>
            </div>
            <div class="name">All</div>
          </div>
          <div
            *ngFor="let subscriber of filteredSubscribers; let i = index"
            class="add-subscribers__item"
            [attr.data-test-id]="'userId-' + subscriber.id"
            [class.active]="subscriber.isActive"
            (click)="selectUser(subscriber)"
          >
            <div class="image">
              <div
                class="avatar-container"
                *ngIf="subscriber.avatarPath; else defaultUserImg"
              >
                <img
                  class="avatar-content"
                  [src]="subscriber.avatarPath"
                  [alt]="subscriber.firstName"
                />
              </div>
            </div>

            <ng-template #defaultUserImg>
              <!-- TODO: change data-content to user initials -->
              <!-- <div class="avatar" data-content="UA"></div> -->
              <ngx-avatars
                class="my-avatar"
                [name]="getAuthorAvatarName(subscriber)"
              ></ngx-avatars>
            </ng-template>

            <div class="name">{{ subscriber.fullName }}</div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>

  <div
    class="droplist add-subscribers add-subscribers--readonly"
    [hidden]="!isMoreSelectedUsersPopupOpen"
  >
    <div class="droplist__mobile-header">
      <button
        type="button"
        class="close"
        data-test-id="moreSelectedUsersPopupClose"
        (click)="closeMoreSelectedUsersPopup()"
      >
        <svg class="icon" width="24" height="24">
          <use xlink:href="#icon-chevrone-left"></use>
        </svg>
      </button>
      List of users
    </div>
    <div class="droplist__title">List of users</div>
    <div class="droplist__content">
      <p-scrollPanel [style]="{ maxHeight: '190px' }">
        <div class="add-subscribers__list">
          <div
            class="add-subscribers__item"
            *ngFor="let subscriber of selectedUsers; let i = index"
          >
            <div class="image" #subscriberImage>
              <img
                *ngIf="subscriber.avatarPath; else defaultUserImg"
                [src]="subscriber.avatarPath"
                [alt]="subscriber.firstName"
              />
            </div>

            <ng-template #defaultUserImg>
              <ngx-avatars
                class="my-avatar"
                [name]="getAuthorAvatarName(subscriber)"
              ></ngx-avatars>
            </ng-template>

            <div class="name">{{ subscriber.fullName }}</div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</div>
