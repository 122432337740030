import { createAction, props } from '@ngrx/store';
import {
  GQLActivity,
  GQLEditNotificationSettingsInput,
  GQLMarkActivityAsViewedInput,
  GQLNotificationSettings,
} from '@schemas';
import { ActivityList } from '@common/types/acivity.model';

function scoped(templateString: TemplateStringsArray) {
  return `[Notifications] ${templateString[0]}`;
}

export const loadNotifications = createAction(
  scoped`Load Notifications`,
  props<{ unreadOnly: boolean }>(),
);

export const loadNotificationsSuccess = createAction(
  scoped`Load Notifications Success`,
  props<{ activities: ActivityList }>(),
);

export const loadNotificationsFailure = createAction(
  scoped`Load Notifications Failure`,
  props<{ error: unknown }>(),
);

export const loadMoreNotifications = createAction(
  scoped`Load More Notifications`,
);

export const loadMoreNotificationsSuccess = createAction(
  scoped`Load More Notifications Success`,
  props<{ activities: ActivityList }>(),
);

export const loadMoreNotificationsFailure = createAction(
  scoped`Load More Notifications Failure`,
  props<{ error: unknown }>(),
);

export const resetPagination = createAction(
  scoped`Reset Notification Pagination`,
);

export const openNotifications = createAction(scoped`Open Notification`);

export const closeNotifications = createAction(scoped`Close Notification`);

export const markNotificationsAsViewed = createAction(
  scoped`Mark Notifications As Viewed`,
  props<{ input: GQLMarkActivityAsViewedInput }>(),
);

export const markNotificationsAsViewedSuccess = createAction(
  scoped`Mark Notifications As Viewed Success`,
  props<{ ids: string[] }>(),
);

export const markNotificationsAsViewedFailure = createAction(
  scoped`mark Notifications As Viewed Failure`,
  props<{ error: unknown }>(),
);

export const resetNotificationsState = createAction(
  scoped`Reset Notifications State`,
);

export const editNotificationSettings = createAction(
  scoped`Edit Notifications Settings`,
  props<{ input: GQLEditNotificationSettingsInput }>(),
);

export const editNotificationSettingsSuccess = createAction(
  scoped`Edit Notifications Settings Success`,
  props<{ settings: GQLNotificationSettings }>(),
);

export const editNotificationSettingsFailure = createAction(
  scoped`Edit Notifications Settings Failure`,
  props<{ error: unknown }>(),
);

export const getNotificationSettings = createAction(
  scoped`Get Notifications Settings`,
);

export const getNotificationSettingsSuccess = createAction(
  scoped`Get Notifications Settings Success`,
  props<{ settings: GQLNotificationSettings }>(),
);

export const getNotificationSettingsFailure = createAction(
  scoped`Get Notifications Settings Failure`,
  props<{ error: unknown }>(),
);

export const clearAllNotifications = createAction(
  scoped`Clear All Notifications`,
);

export const clearAllNotificationsSuccess = createAction(
  scoped`Clear All Notifications`,
  props<{ activities: GQLActivity }>(),
  // TODO fix this any
);

export const clearAllNotificationsFailure = createAction(
  scoped`Clear All Notifications`,
  props<{ error: unknown }>(),
);
