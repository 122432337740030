import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setQueryParametersForFilesPage } from '@common/helpers';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Output()
  isNavigationDisabled: EventEmitter<boolean> = new EventEmitter<boolean>(
    false,
  );

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {}

  get queryParams() {
    const { queryParams } = setQueryParametersForFilesPage(this.router.url);
    return queryParams;
  }

  isLinkActive(url: string): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl =
      queryParamsIndex === -1
        ? this.router.url
        : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === url;
  }

  closeMenu(): void {
    this.isNavigationDisabled.emit(true);
  }
}
