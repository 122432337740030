import { Pipe, PipeTransform } from '@angular/core';
import { FileTypePipe } from '../file-tipes-pipe/file-tipes.pipe';
import { GQLFile } from '../../../../schemas/schemas';
import { getFileName } from '@common/helpers';

@Pipe({
  name: 'getLatestFileVersionPreview',
})
export class GetLatestFileVersionPipe implements PipeTransform {
  constructor(private fileTypePipe: FileTypePipe) {}

  transform(file: GQLFile): string | undefined {
    let filePreview = this.fileTypePipe.transform(getFileName(file), true);
    if (
      file.versions &&
      file.versions.length > 0 &&
      file.versions[0].previewPath
    ) {
      filePreview = file.versions[0].previewPath;
    }

    return filePreview;
  }
}
