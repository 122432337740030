import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TooltipModule } from 'primeng/tooltip';
import { FileTipesPipeModule } from '../../pipes/file-tipes-pipe/file-tipes.pipe.module';
import { ShortenerModule } from '../../pipes/shortener/shortener.module';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { ToolsToggleComponent } from './components/tools-toggle/tools-toggle.component';
import { GetLatestFileVersionModule } from '../../pipes/get-latest-file-version/get-latest-file-version.module';
import { FileTypePipe } from '../../pipes/file-tipes-pipe/file-tipes.pipe';
import { GeneralFileTipesPipeModule } from '../../pipes/general-file-type-pipe/general-file-types.pipe.module';

@NgModule({
  declarations: [FileUploaderComponent, ToolsToggleComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    FileTipesPipeModule,
    ShortenerModule,
    GetLatestFileVersionModule,
    TooltipModule,
    GeneralFileTipesPipeModule,
  ],
  providers: [FileTypePipe],
  exports: [FileUploaderComponent],
})
export class FileUploaderModule {}
