import { Injectable, Query } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { GQLFile, GQLThreadStatusEnum } from '@schemas';
import {
  APPROVE_THREAD,
  CREATE_THREAD,
  DISAPPROVE_THREAD,
  EDIT_THREAD,
  MARK_THREAD_AS_VIEWED,
  REMOVE_THREAD,
} from '@common/graphQL/graphql-requests/mutations';
import { GET_THREADS } from '@queries';
import { BaseApi } from '../../services/base-api';
import { getCurrentProjectId } from '../projects/projects.selectors';
import {
  approveThread,
  approveThreadFailure,
  createThread,
  createThreadFailure,
  createThreadSuccess,
  deleteThread,
  deleteThreadFailure,
  deleteThreadSuccess,
  disapproveThread,
  disapproveThreadFailure,
  disapproveThreadSuccess,
  editThread,
  editThreadFailure,
  editThreadSuccess,
  loadThread,
  loadThreadFailure,
  loadThreadsList,
  loadThreadsListsFailure,
  loadThreadsListSuccess,
  loadThreadSuccess,
  markAsViewed,
} from './threads.actions';
import { loadProject } from '../projects/projects.actions';
import { ThreadsService } from './services/threads.service';
import { getThreadsList } from './threads.selectors';

@Injectable()
export class ThreadsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly api: BaseApi,
    private readonly toastr: ToastrService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly threadsService: ThreadsService,
  ) {}

  loadThreadsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadThreadsList),
      switchMap(({ projectId, sortBy, searchQuery }) =>
        projectId
          ? this.api.apollo
              .query<QueryRef<Query>>({
                query: GET_THREADS,
                variables: {
                  id: projectId,
                  limit: 1000,
                  skip: 0,
                  status: sortBy,
                  searchQuery,
                }, // TODO remove hardcoded limit and skip
                fetchPolicy: 'network-only',
              })
              .pipe(
                map(({ data }: any) =>
                  loadThreadsListSuccess({
                    projectId,
                    threadsList: data.project.threads,
                  }),
                ),
                catchError((error: unknown) =>
                  of(loadThreadsListsFailure({ error })),
                ),
              )
          : of(loadThreadsListsFailure({ error: 'No projectId specified' })),
      ),
    ),
  );

  loadThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadThread),
      switchMap(({ threadId }) =>
        threadId
          ? this.threadsService.loadThread(threadId).pipe(
              map((thread) => loadThreadSuccess({ thread })),
              catchError((error: unknown) => of(loadThreadFailure({ error }))),
            )
          : of(loadThreadFailure({ error: 'No threadId specified' })),
      ),
    ),
  );

  createThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createThread),
      withLatestFrom(this.store.select(getCurrentProjectId)),
      exhaustMap(([{ input }, projectId]) =>
        this.api.mutate(CREATE_THREAD, { input }, { useMultipart: true }).pipe(
          mergeMap(({ data }) => [
            loadThreadsList({
              projectId,
              sortBy: GQLThreadStatusEnum.ACTIVE,
              searchQuery: '',
            }),
            createThreadSuccess(data.createThread),
            loadProject(),
          ]),
          tap(() => {
            this.router.navigate([], {
              relativeTo: this.route,
              queryParams: { sortBy: GQLThreadStatusEnum.ACTIVE },
              queryParamsHandling: 'merge',
            });
          }),
          catchError((error: unknown) => {
            return of(createThreadFailure({ error }));
          }),
        ),
      ),
    ),
  );

  editThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editThread),
      withLatestFrom(this.store.select(getCurrentProjectId)),
      exhaustMap(([{ threadInput }, projectId]) =>
        this.api
          .mutate(EDIT_THREAD, { input: threadInput }, { useMultipart: true })
          .pipe(
            mergeMap(({ data }) => [
              loadThreadsList({
                projectId,
                sortBy: GQLThreadStatusEnum.ACTIVE,
                searchQuery: '',
              }),
              editThreadSuccess(data.editThread),
              loadProject(),
            ]),
            catchError((error: unknown) => of(editThreadFailure({ error }))),
          ),
      ),
    ),
  );

  markAsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markAsViewed),
      exhaustMap((input) =>
        this.api.mutate(MARK_THREAD_AS_VIEWED, input).pipe(
          map(({ data }) => editThreadSuccess(data.markThreadAsViewed)),
          catchError((error: unknown) => of(editThreadFailure({ error }))),
        ),
      ),
    ),
  );

  deleteThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteThread),
      withLatestFrom(this.store.select(getThreadsList)),
      exhaustMap(([input, threadsList]) =>
        this.api.mutate(REMOVE_THREAD, input).pipe(
          mergeMap(({ data }) => {
            this.toastr.success(
              `Thread ${input.input.id} was successfully deleted`,
            );
            const filesIds = (
              threadsList.find((thread) => thread.id === input.input.id)?.files
                .items || []
            ).map((file: GQLFile) => file.id);
            return [loadProject(), deleteThreadSuccess(data)];
          }),
          catchError((error: unknown) => {
            this.toastr.error('Error deleting', input.input.id);
            return of(deleteThreadFailure({ error }));
          }),
        ),
      ),
    ),
  );

  approveThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(approveThread),
      exhaustMap((input) =>
        this.api.mutate(APPROVE_THREAD, input).pipe(
          map(({ data }: any) =>
            deleteThreadSuccess({ removeThread: data.approveThread.thread }),
          ),
          catchError((error: unknown) => of(approveThreadFailure({ error }))),
        ),
      ),
    ),
  );

  disapproveThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(disapproveThread),
      exhaustMap((input) =>
        this.api.mutate(DISAPPROVE_THREAD, input).pipe(
          map(({ data }) => disapproveThreadSuccess(data.approveThread.thread)),
          catchError((error: unknown) =>
            of(disapproveThreadFailure({ error })),
          ),
        ),
      ),
    ),
  );
}
