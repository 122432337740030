<nav class="navigation">
  <ul class="navigation__list">
    <!-- Temporary hidden -->
    <!-- <li class="navigation__item" routerLink="/dashboard" routerLinkActive="active">
        Dashboard
    </li> -->
    <li class="navigation__item" (click)="closeMenu()">
      <a
        class="navigation__item-link"
        routerLink="/projects"
        routerLinkActive="active"
      >
        Projects
      </a>
    </li>
    <li class="navigation__item" (click)="closeMenu()">
      <a
        class="navigation__item-link"
        routerLink="/files"
        [class.active]="isLinkActive('/files')"
        [queryParams]="queryParams"
      >
        Files
      </a>
    </li>
    <!-- Temporary hidden -->
    <!-- <li class="navigation__item" routerLink="/schedule" routerLinkActive="active">
        Schedule
    </li>
    <li class="navigation__item" routerLink="/tools" routerLinkActive="active">
        Tools
    </li>
    <li class="navigation__item" routerLink="/archive" routerLinkActive="active">
        Archive
    </li> -->
  </ul>
</nav>
