import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TreadsListComponent } from './components/thread-list/treads-list.component';
import { EFileListType } from '@common/types/EFileListType';
import { FileListInThreadComponent } from './components/file-list-in-thread/file-list-in-thread.component';

const routes: Routes = [
  {
    path: '',
    component: TreadsListComponent,
  },
  {
    path: ':threadId',
    loadChildren: () =>
      import('../tread/tread.module').then((m) => m.TreadModule),
  },
  {
    path: ':threadId/files',
    component: FileListInThreadComponent,
    // loadChildren: () =>
    //   import('./components/file-list-in-thread/file-list-in-thread.module').then(
    //     (m) => m.FileListInThreadModule,
    //   ),
    data: {
      fileListType: EFileListType.THREAD,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class TreadsListRoutingModule {}
