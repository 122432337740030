/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 7/21/21, 3:16 PM
 * Copyright 2021 3D Source
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorsApiInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const request = req.clone();
    const isMutation: boolean = req?.body?.query?.includes('mutation');

    return next.handle(request).pipe(
      tap((event) => {
        if (event instanceof HttpResponse) {
          const hasErrors = !!event.body.errors;
          if (hasErrors) {
            const hasErrorCode: string = event.body?.errors[0].code;
            if (hasErrors && hasErrorCode && !isMutation) {
              this.router.navigate(['not-found'], {
                skipLocationChange: true,
              });
            }
          }
        }
      }),
    );
  }
}
