import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLInvitationActionStatusEnum,
  GQLProfile,
  GQLProfilePermissions,
} from '@schemas';
import {
  clearProjectUsers,
  clearThreadUsers,
  loadCurrentUser,
  loadCurrenUserFailure,
  loadCurrenUserSuccess,
  loadProjectUsersFailure,
  loadProjectUsersSuccess,
  loadThreadUsersFailure,
  loadThreadUsersSuccess,
  loadWorkspaceUsersSuccess,
  setCurrenUserPermissionsSuccess,
  updateMyPassword,
  updateMyPasswordFailure,
  updateMyPasswordSuccess,
  updateMyProfileInfo,
  updateMyProfileInfoFailure,
  updateMyProfileInfoSuccess,
  loadCurrentUserRolePermissions,
  loadCurrentUserRolePermissionsSuccess,
  loadCurrentUserRolePermissionsFailure,
  updateUserProfileWithPassword,
  updateUserProfileWithPasswordSuccess,
  updateUserProfileWithPasswordFailure,
  checkInvitationStatusSuccess,
  checkInvitationStatusFailure,
  checkInvitationStatus,
} from './users.actions';
import { IgraphQLErrorsObject } from '../../types/IgraphQLErrorsObject';
import { IUserPermissions } from '@common/types/IUserPermisions';

export interface UsersState {
  currentUser: GQLProfile | null;
  currentUserLoading: boolean;
  isMyProfileInfoSaved: boolean;
  isMyPasswordUpdated: boolean;
  isMyProfileAndPasswordUpdated: boolean;
  permissions: IUserPermissions | null;
  error?: IgraphQLErrorsObject | undefined;
  workspaceUsers: GQLProfile[];
  projectUsers: GQLProfile[];
  threadUsers: GQLProfile[];
  rolePermissions: GQLProfilePermissions | null;
  invitationStatus: GQLInvitationActionStatusEnum | null;
  invitationStatusError: string | null;
  checkInvitationStatusLoading: boolean;
}

export const initialUsersState: UsersState = {
  currentUser: null,
  currentUserLoading: false,
  isMyProfileInfoSaved: false,
  isMyPasswordUpdated: false,
  isMyProfileAndPasswordUpdated: false,
  permissions: null,
  workspaceUsers: [],
  projectUsers: [],
  threadUsers: [],
  rolePermissions: null,
  invitationStatus: null,
  invitationStatusError: null,
  checkInvitationStatusLoading: false,
};

const usersReducer = createReducer(
  initialUsersState,
  // LOAD Current user
  on(loadCurrentUser, (state) => ({
    ...state,
    isLoadingMessages: true,
  })),
  on(loadCurrenUserSuccess, (state, { profile }) => {
    return {
      ...state,
      currentUser: { ...profile },
      currentUserLoading: false,
    };
  }),
  on(setCurrenUserPermissionsSuccess, (state, { permissions }) => ({
    ...state,
    permissions,
  })),
  on(loadCurrenUserFailure, (state, { error }) => ({
    ...state,
    currentUserLoading: false,
  })),

  // LOAD Current user role permissions
  on(loadCurrentUserRolePermissions, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(loadCurrentUserRolePermissionsSuccess, (state, { permissions }) => ({
    ...state,
    rolePermissions: { ...permissions },
    isLoading: false,
  })),
  on(loadCurrentUserRolePermissionsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
  })),

  on(loadWorkspaceUsersSuccess, (state, { users }) => ({
    ...state,
    workspaceUsers: users,
  })),
  on(loadCurrenUserFailure, (state, { error }) => ({
    ...state,
    currentUserLoading: false,
  })),

  on(loadProjectUsersSuccess, (state, { users }) => {
    return {
      ...state,
      projectUsers: users,
    };
  }),
  on(loadProjectUsersFailure, (state, { error }) => ({
    ...state,
    currentUserLoading: false,
  })),

  on(loadThreadUsersSuccess, (state, { users }) => {
    return {
      ...state,
      threadUsers: users,
    };
  }),
  on(clearThreadUsers, (state) => {
    return {
      ...state,
      threadUsers: [],
    };
  }),
  on(clearProjectUsers, (state) => {
    return {
      ...state,
      projectUsers: [],
    };
  }),
  on(loadThreadUsersFailure, (state, { error }) => ({
    ...state,
    currentUserLoading: false,
  })),
  // UPDATE MAIN INFO
  on(updateMyProfileInfo, (state, { input }) => {
    return {
      ...state,
      isMyProfileInfoSaved: false,
      currentUserLoading: false,
    };
  }),
  on(updateMyProfileInfoSuccess, (state, { profile }) => {
    return {
      ...state,
      currentUser: {
        ...profile,
      },
      isMyProfileInfoSaved: true,
      currentUserLoading: true,
    };
  }),
  on(updateMyProfileInfoFailure, (state, { error }) => ({
    ...state,
    isMyProfileInfoSaved: false,
    currentUserLoading: false,
  })),
  // UPDATE PASSWORD
  on(updateMyPassword, (state, { input }) => {
    return {
      ...state,
      isMyPasswordUpdated: false,
      currentUserLoading: false,
    };
  }),
  on(updateMyPasswordSuccess, (state, { me }) => {
    return {
      ...state,
      isMyPasswordUpdated: true,
      currentUserLoading: true,
    };
  }),
  on(updateMyPasswordFailure, (state, { error }) => ({
    ...state,
    error,
    isMyPasswordUpdated: false,
    currentUserLoading: false,
  })),

  on(updateUserProfileWithPassword, (state) => {
    return {
      ...state,
      isMyProfileAndPasswordUpdated: false,
      currentUserLoading: false,
    };
  }),

  on(updateUserProfileWithPasswordSuccess, (state, { profile }) => {
    return {
      ...state,
      currentUser: {
        ...profile,
      },
      isMyProfileAndPasswordUpdated: true,
      currentUserLoading: true,
    };
  }),
  on(updateUserProfileWithPasswordFailure, (state, { error }) => ({
    ...state,
    error,
    isMyProfileAndPasswordUpdated: false,
    currentUserLoading: false,
  })),
  on(checkInvitationStatus, (state) => ({
    ...state,
    checkInvitationStatusLoading: true,
  })),
  on(checkInvitationStatusSuccess, (state, { status }) => ({
    ...state,
    checkInvitationStatusLoading: false,
    invitationStatus: status,
  })),
  on(checkInvitationStatusFailure, (state, { error }) => ({
    ...state,
    checkInvitationStatusLoading: false,
    invitationStatusError: error.message,
  })),
);

export function UsersReducer(
  state: UsersState | undefined,
  action: Action,
): UsersState {
  return usersReducer(state, action);
}
