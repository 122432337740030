<ng-container *ngIf="projectId$ | async as projectId">
  <a
    class="message"
    [attr.data-test-id]="'threadId-' + thread.id"
    [class.new]="!thread.isViewed"
    [routerLink]="['/projects', projectId, thread.id]"
    [queryParams]="getQueryParams"
    [skipLocationChange]="skipLocationChange"
  >
    <div class="message__heading message-heading">
      <div class="message-heading__image">
        <ngx-avatars
          class="my-avatar"
          [src]="author?.avatarPath"
          [name]="authorAvatarName"
          [attr.data-test-id]="'authorAvatar-id' + author?.id"
        ></ngx-avatars>
      </div>
      <div class="message-heading__author">
        <strong>{{ author?.fullName }}</strong>
        <div class="message-heading__info">
          {{ thread.updatedAt | timeFromNow }} ago
          <ng-container *ngIf="thread.accessLevel === accessLevel.PRIVATE">
            <span class="dot-divider"></span>
            {{ thread.accessLevel | titlecase }}
          </ng-container>
        </div>
      </div>

      <div
        class="message-heading__action"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      >
        <div class="comment-menu" *ngIf="thread.canEdit">
          <button
            type="button"
            data-test-id="openThreadDotMenu"
            class="comment-menu__toggle"
            [class.active]="isDotsActive"
            (click)="op.toggle($event)"
          >
            <app-icon href="#icon-dots-vertical"></app-icon>
          </button>

          <p-overlayPanel
            #op
            [autoZIndex]="false"
            appendTo="body"
            styleClass="comment-menu-overlay"
          >
            <ng-template pTemplate>
              <ul class="comment-menu__list">
                <li
                  class="comment-menu__item"
                  *ngIf="!isApproved && thread.canEdit"
                >
                  <button
                    class="comment-menu__button"
                    type="button"
                    data-test-id="openThreadEditModal"
                    (click)="openEditModal(thread)"
                    [disabled]="isApproved"
                  >
                    Edit
                  </button>
                </li>
                <li class="comment-menu__item">
                  <button
                    class="comment-menu__button"
                    data-test-id="openThreadConfirmModal"
                    (click)="openConfirmModal(thread)"
                    *ngIf="thread.canRemove"
                  >
                    Remove
                  </button>
                </li>
              </ul>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </div>
    <!-- /message-heading -->
    <div class="message__body message-body">
      <div class="message-body__heading">
        <h2>{{ thread.title }}</h2>
        <span class="dot-divider"></span>
        <span>Thread #{{ thread.serialNumber }}</span>
      </div>
      <div class="message-body__content" [innerHTML]="thread.body"></div>
    </div>
    <!-- /message-body -->

    <div class="message__footer message-footer">
      <div class="message-footer__content">
        <div class="message-footer__content-element message-counter">
          <app-icon width="16" height="16" href="#icon-comment"></app-icon>
          {{ thread?.messages?.total }} messages
          <span *ngIf="!!thread?.messages?.unread" class="message-counter__new">
            (+{{ thread?.messages?.unread }})
          </span>
        </div>
        <div class="message-footer__content-element file-counter">
          <app-icon width="16" height="16" href="#icon-file"></app-icon>
          {{ thread?.files?.items?.length }} Files
        </div>
        <div
          class="message-footer__content-element user-counter"
          *ngIf="thread.accessLevel === accessLevel.PRIVATE"
          data-test-id="privateThreadUserCounter"
        >
          <span>Users:</span>

          <div (click)="$event.stopPropagation(); $event.preventDefault()">
            <app-mini-users-list
              [parent]="'thread'"
              [profiles]="thread?.contributors || []"
              data-test-id="appThreadMiniUsersList"
            ></app-mini-users-list>
          </div>
        </div>

        <!-- </div> -->
        <div class="message-footer__content-element approval">
          <button
            type="button"
            class="approve-control"
            [disabled]="isApproveBtnDisabled"
            [class.approved]="isApproved"
            (click)="
              $event.stopPropagation();
              $event.preventDefault();
              openApproveModal(thread, $event)
            "
            [attr.data-test-id]="'approveThreadButton-threadId-' + thread?.id"
          >
            <span class="circle">
              <svg
                viewBox="0 0 16 16"
                width="16"
                height="16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 14.4A6.4 6.4 0 1 1 8 1.6a6.4 6.4 0 0 1 0 12.8Z"
                  id="circle"
                />
                <path
                  d="m6.9 8.935 3.65-3.686a.847.847 0 0 1 1.385.28.862.862 0 0 1-.187.937l-4.252 4.285a.847.847 0 0 1-1.2 0L4.297 8.73a.856.856 0 0 1-.238-.964.858.858 0 0 1 .481-.485.845.845 0 0 1 .957.24L6.9 8.935Z"
                  id="check"
                />
              </svg>
            </span>

            {{ isApproved ? 'Approved' : 'Approve' }}
          </button>
        </div>
      </div>
    </div>
    <!-- /message-footer -->
  </a>
</ng-container>
