import { gql } from 'apollo-angular';

export const GET_NOTIFICATIONS_SETTINGS = gql`
  query {
    me {
      id
      fullName
      notificationSettings {
        isEnabled
        onThreadCreate {
          isAppEnabled
          isEmailEnabled
        }
        onThreadApprove {
          isAppEnabled
          isEmailEnabled
        }
        onMessageCreate {
          isAppEnabled
          isEmailEnabled
        }
        onFileUpdate {
          isAppEnabled
          isEmailEnabled
        }
        onFileComment {
          isAppEnabled
          isEmailEnabled
        }
        onFileApprove {
          isAppEnabled
          isEmailEnabled
        }
      }
    }
  }
`;
