import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ThreadAddComponent } from '../thread-add/thread-add.component';
import { GQLThreadStatusEnum } from '@schemas';
import { IFilterOptions } from '@common/types/interfaces';
import { getThreadsCount } from '@common/store/threads/threads.selectors';
import { loadThreadsList } from '@common/store/threads/threads.actions';
import { getCurrentProjectId } from '@common/store/projects/projects.selectors';
import { AuthService } from '@common/services/auth.service';
import { IUserPermissions } from '@common/types/IUserPermisions';

@Component({
  selector: 'app-treads-filter',
  templateUrl: './treads-filter.component.html',
  styleUrls: ['./treads-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class TreadsFilterComponent implements OnInit, OnDestroy {
  threadsCounter$: Observable<number> = this.store.select(getThreadsCount);
  projectId$: Observable<string> = this.store.select(getCurrentProjectId);

  filterOptions: IFilterOptions[] = [];
  // selectedFilter: IFilterOptions = {} as IFilterOptions;
  sortBy: GQLThreadStatusEnum = GQLThreadStatusEnum.ACTIVE;
  filtersModel: FormGroup = new FormGroup({
    dropdownFilter: new UntypedFormControl(null),
  });
  projectId: string = '';
  canCreateThread = false;

  searchForm: FormGroup = this.fb.group({
    search: [''],
  });

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  options: any = {
    APPROVED: {
      title: 'Approved',
      type: GQLThreadStatusEnum.APPROVED,
    },
    ACTIVE: {
      title: 'Last Updated',
      type: GQLThreadStatusEnum.ACTIVE,
    },
  };

  constructor(
    private readonly dialogService: DialogService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.initDropdown();
    this.getPermissions();
    this.getProjectId();

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((queryParams) => {
        this.filtersModel.controls.dropdownFilter.patchValue(
          this.options[queryParams.sortBy],
        );
        this.sortBy = queryParams.sortBy;

        this.cd.detectChanges();
      });
  }

  openDialog(): void {
    const dialogConfig = {
      data: {
        isEditing: false,
      },
      header: 'Create new thread',
      styleClass: 'form-modal',
    };
    this.dialogService.open(ThreadAddComponent, dialogConfig);
  }

  private filterThreadList(filterLabel: GQLThreadStatusEnum): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: filterLabel },
      queryParamsHandling: 'merge',
    });
  }

  private initDropdown(): void {
    this.filterOptions = [
      { title: 'Last Updated', type: GQLThreadStatusEnum.ACTIVE },
      { title: 'Approved', type: GQLThreadStatusEnum.APPROVED },
    ];
  }

  changeValue(value: any) {
    this.filterThreadList(value.value.type);
  }

  private getProjectId(): void {
    this.projectId$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((id) => (this.projectId = id));
  }

  handleSearch(searchQuery: string): void {
    this.store.dispatch(
      loadThreadsList({
        projectId: this.projectId,
        sortBy: this.sortBy,
        searchQuery,
      }),
    );
  }

  private getPermissions(): void {
    this.authService
      .getMyPermissions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((permissions: IUserPermissions): void => {
        this.canCreateThread = !!permissions?.thread?.create;
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
