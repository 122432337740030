/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 8/5/21, 6:29 PM
 * Copyright 2021 3D Source
 */

import { GQLRolesEnum } from '@schemas';

export const userRoleTitle = (role: GQLRolesEnum): string => {
  switch (role) {
    case GQLRolesEnum.ROLE_SUPER_ADMIN:
      return 'Super Admin';
    case GQLRolesEnum.ROLE_OWNER:
      return 'Owner';
    case GQLRolesEnum.ROLE_APPROVER:
      return 'Approver';
    case GQLRolesEnum.ROLE_SUPER_MANAGER:
      return 'Super Manager';
    case GQLRolesEnum.ROLE_MANAGER:
      return 'Manager';
    case GQLRolesEnum.ROLE_SUPER_CLIENT:
      return 'Super Client';
    case GQLRolesEnum.ROLE_CLIENT:
      return 'Client';
    case GQLRolesEnum.ROLE_CLIENT_COMMUNICATOR:
      return 'Client Communicator';
    case GQLRolesEnum.ROLE_EMPLOYEE:
      return 'Employee';
    default:
      return 'Unknown';
  }
};
