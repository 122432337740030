<div class="notification-safe-container">
  <a
    #notificationElement
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    class="notification-item"
    [class.new]="hasClass"
    (click)="closeNotificationSidebar()"
  >
    <div class="notification-item__image">
      <ngx-avatars
        [src]="authorAvatarPath"
        class="my-avatar"
        [name]="authorAvatarName"
      ></ngx-avatars>
    </div>

    <div class="notification-item__body">
      <div
        class="notification-item__event"
        [innerHTML]="notificationText"
      ></div>
      <div class="notification-item__time">
        {{ notification.createdAt | timeFromNow }} ago
      </div>
    </div>
  </a>
</div>
