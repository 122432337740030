import { createAction, props } from '@ngrx/store';
import {
  GQLCreateFileInput,
  GQLCreateFileVersionInput,
  GQLDateRange,
  GQLFile,
  GQLFileCollection,
  GQLFileGroup,
  GQLFileMarkerStatusEnum,
  GQLFileStatusEnum,
  GQLFileVersion,
  GQLMarkerGroup,
  GQLMarkFileVersionAsViewedInput,
} from '@schemas';
import { EViewType } from '../../helpers';
import { IgraphQLErrorsObject } from '../../types/IgraphQLErrorsObject';

function scoped(templateString: TemplateStringsArray) {
  return `[Files] ${templateString[0]}`;
}

export const setCurrent3dScreenshot = createAction(
  scoped`Save Current 3d Screenshot`,
  props<{ current3dScreenshot: string }>(),
);

// SAVE THREAD PROJECT ID
export const saveCurrentFileId = createAction(
  scoped`Save Current File Id`,
  props<{ currentFileId: string }>(),
);

export const clearCurrentFileId = createAction(scoped`Clear Current File Id`);

// LOAD PROJECT FILES
export const loadProjectFiles = createAction(
  scoped`Load Project Files List`,
  props<{ searchQuery: string; dateRange: GQLDateRange }>(),
);

export const loadProjectFilesSuccess = createAction(
  scoped`Load Success Project Files List`,
  props<{ filesList: GQLFileGroup[] }>(),
);

export const loadProjectFilesFailure = createAction(
  scoped`Load Failure Project Files List`,
  props<{ error: unknown }>(),
);

export const loadMarkerAutoincrementValue = createAction(
  scoped`loadMarkerAutoincrementValue`,
);

export const loadMarkerAutoincrementValueSuccess = createAction(
  scoped`loadMarkerAutoincrementValue Success`,
  props<{ markerAutoincrementValue: number }>(),
);

export const loadMarkerAutoincrementValueFailure = createAction(
  scoped`loadMarkerAutoincrementValue Failure`,
  props<{ error: unknown }>(),
);

// LOAD THREAD FILES
export const loadThreadFiles = createAction(
  scoped`Load Thread Files List`,
  props<{ searchQuery: string; dateRange: GQLDateRange }>(),
);

export const loadThreadFilesSuccess = createAction(
  scoped`Load Success Thread Files List`,
  props<{ filesList: GQLFile[] }>(),
);

export const loadThreadFilesFailure = createAction(
  scoped`Load Failure Thread Files List`,
  props<{ error: unknown }>(),
);

// ADD FILES
export const addFiles = createAction(
  scoped`Add Files`,
  props<{ fileInput: GQLCreateFileInput; threadFiles: boolean }>(),
);

export const addFilesSuccess = createAction(scoped`Add Files Success`);

export const addFilesFailure = createAction(
  scoped`Add Files Failure`,
  props<{ error: IgraphQLErrorsObject }>(),
);

export const clearAddFileError = createAction(scoped`Clear File Error`);

// LOAD FILES
export const loadFile = createAction(
  scoped`Load File`,
  props<{ resetState: boolean }>(),
);

export const loadFileSuccess = createAction(
  scoped`Load File Success`,
  props<{ file: GQLFile; resetState: boolean }>(),
);

export const loadFileFailure = createAction(
  scoped`Load File Failure`,
  props<{ error: unknown }>(),
);

export const resetFileLoadingFlag = createAction(scoped`Reset Loading File`);

// ADD FILES
export const loadFileVersion = createAction(
  scoped`Load File Version`,
  props<{
    resetState: boolean;
    versionId?: string;
    markerStatus?: GQLFileMarkerStatusEnum;
  }>(),
);

export const loadFileVersionSuccess = createAction(
  scoped`Load File Version Success`,
  props<{ fileVersion: GQLFileVersion; resetState: boolean }>(),
);

export const loadFileVersionFailure = createAction(
  scoped`Load File Failure`,
  props<{ error: unknown }>(),
);

// CREATE FILES
export const createFileVersion = createAction(
  scoped`Create File Version`,
  props<{ fileVersionInput: GQLCreateFileVersionInput }>(),
);

export const createFileVersionSuccess = createAction(
  scoped`Create File Version Success`,
  props<{ fileVersion: GQLFileVersion; fileId?: string }>(),
);

export const createFileVersionFailure = createAction(
  scoped`Create File Version Failure`,
  props<{ error: unknown }>(),
);

// MARK VERSION AS VIEWED
export const markVersionAsViewed = createAction(
  scoped`markVersionAsViewed`,
  props<{ input: GQLMarkFileVersionAsViewedInput }>(),
);

export const markVersionAsViewedSuccess = createAction(
  scoped`markVersionAsViewed Success`,
  props<{ versions: GQLFileVersion[] }>(),
);

export const markVersionAsViewedFailure = createAction(
  scoped`markVersionAsViewedFailure Failure`,
  props<{ error: unknown }>(),
);

// APPROVE/UNAPPROVE FILE VERSION
export const toggleFileVersionStatus = createAction(
  scoped`Approve/Unapprove File Version`,
);

export const toggleFileVersionStatusSuccess = createAction(
  scoped`Approve/Unapprove File Version Success`,
  props<{ fileVersion: GQLFileVersion }>(),
);

export const toggleFileVersionStatusFailure = createAction(
  scoped`Approve/Unapprove File Version Failure`,
  props<{ error: unknown }>(),
);

export const tryRemoveFile = createAction(
  scoped`Try Remove File`,
  props<{ id: string; fileName?: string }>(),
);

export const removeFile = createAction(
  scoped`Remove File`,
  props<{ id: string; redirect?: boolean }>(),
);

export const removeFileSuccess = createAction(
  scoped`Remove File Success`,
  props<{ id: string; redirect?: boolean }>(),
);

export const removeFileFailure = createAction(
  scoped`Remove File Failure`,
  props<{ error: unknown }>(),
);

export const tryRemoveFileVersion = createAction(
  scoped`Try Remove File Version`,
  props<{ fileVersion: GQLFileVersion; fileId: string }>(),
);

export const removeFileVersion = createAction(
  scoped`Remove File Version`,
  props<{ id: string }>(),
);

export const removeFileVersionSuccess = createAction(
  scoped`Remove File Version Success`,
  props<{ id: string }>(),
);

export const removeFileVersionFailure = createAction(
  scoped`Remove File Version Failure`,
  props<{ error: unknown }>(),
);

export const selectMarkersGroup = createAction(
  scoped`Select Markers Group`,
  props<{ markersGroup: GQLMarkerGroup }>(),
);

export const unselectActiveGroup = createAction(scoped`Unselect Markers Group`);

// CLEAR FILE
export const clearFile = createAction(scoped`CLEAR File`);

// SELECT FILE VERSION
export const selectCurrentVersion = createAction(
  scoped`Select Current Version`,
  props<{
    activeFileVersionId: string;
    markerStatus: GQLFileMarkerStatusEnum;
    resetState: boolean;
  }>(),
);

// SET FILE LIST STATUS
export const setCurrentFileListStatus = createAction(
  scoped`Set Current File List Status`,
  props<{ status: GQLFileStatusEnum }>(),
);

// GET FILES COLLECTION

export const getAllFileCollection = createAction(
  scoped`Get All Files`,
  props<{ searchQuery: string; dateRange: GQLDateRange }>(),
);

export const getAllFileCollectionSuccess = createAction(
  scoped`Get All Files Success`,
  props<{ fileCollection: GQLFileCollection }>(),
);

export const getAllFileCollectionFailure = createAction(
  scoped`Get All Files Failure`,
  props<{ error: unknown }>(),
);
//  GET TOTAL PROJECTS FILES COLLECTION

export const loadProjectsFiles = createAction(
  scoped`Get All Projects In File Collection`,
);

export const loadProjectsFilesSuccess = createAction(
  scoped`Get All Projects In File Collection Success`,
  props<{ totalProjectList: GQLFileCollection }>(),
);

export const loadProjectsFilesFailure = createAction(
  scoped`Get All Projects In File Collection Failure`,
  props<{ error: unknown }>(),
);

// MARK APPROVED FILE AS VIEWED

export const markApprovedFileAsViewed = createAction(
  scoped`Mark Approved File As Viewed`,
  props<{ fileId: string }>(),
);

export const markApprovedFileAsViewedSuccess = createAction(
  scoped`Mark Approved File As Viewed Success`,
  props<{ file: GQLFile }>(),
);

export const markApprovedFileAsViewedFailure = createAction(
  scoped`Mark Approved File As Viewed Failure`,
  props<{ error: unknown }>(),
);

export const toggleFileListView = createAction(
  scoped`Toggle File List View`,
  props<{ viewType: EViewType }>(),
);

export const toggleFileListViewSuccess = createAction(
  scoped`Toggle File List View Success`,
  props<{ viewType: EViewType }>(),
);

export const getInitFileListView = createAction(
  scoped`Get Init File List View`,
);

export const getInitFileListViewSuccess = createAction(
  scoped`Get Init File List View Success`,
  props<{ viewType: EViewType }>(),
);

export const setFile = createAction(
  scoped`Set file`,
  props<{ file: GQLFile | null }>(),
);
