<div class="service-modal__content" data-test-id="deleteFilewarningText">
  {{ warningText }}

  <div class="checkbox-container" *ngIf="isPossibleDeleteAllVersions">
    <div class="checkbox">
      <label>
        <input
          type="checkbox"
          [(ngModel)]="deleteAllVersion"
          data-test-id="deleteAllVersionCheckbox"
        />
        <span class="jlabel" data-test-id="deleteAllVersionCheckboxLabel"
          >Delete all versions</span
        >
      </label>
    </div>
  </div>
</div>

<div class="service-modal__footer service-modal__footer--right">
  <button
    [hidden]="isWarning"
    (click)="close()"
    class="button button--clear"
    data-test-id="deleteFileCancelButton"
  >
    Cancel
  </button>
  <button
    (click)="confirm()"
    class="button"
    [class]="typeStyles"
    data-test-id="deleteFileConfirmButton"
  >
    {{ btnText }}
  </button>
</div>
