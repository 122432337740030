import { Injectable, Query } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GQLEditProfileInput,
  GQLEditProfilePayload,
  GQLInvitationActionStatusEnum,
  GQLInvitationStatus,
  GQLInvitationStatusEnum,
  GQLProfile,
  GQLProfilePermissions,
  GQLUpdatePasswordInput,
  GQLUpdatePasswordPayload,
} from '@schemas';
import { GET_PROFILES } from '@queries';
import { BaseApi } from '@common/services/base-api';
import {
  UPDATE_MY_PASSWORD,
  EDIT_USER_PROFILE,
} from '@common/graphQL/graphql-requests/mutations';
import { GET_PERMISSIONS } from '@common/graphQL/graphql-requests/queries/GET_PERMISSIONS';
import { UPDATE_PROFILE } from '@common/graphQL/graphql-requests/mutations/UPDATE_PROFILE';
import { checkInvitationStatus } from '@common/store/users/users.actions';
import { GET_INVITATION_STATUS } from '@common/graphQL/graphql-requests/queries/GET_INVITATION_STATUS';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  availableSubscribersByProject$: BehaviorSubject<Array<GQLProfile | null>> =
    new BehaviorSubject([] as Array<GQLProfile | null>);
  availableSubscribers$: BehaviorSubject<GQLProfile[]> = new BehaviorSubject(
    [] as GQLProfile[],
  );

  constructor(private api: BaseApi) {}

  getUsers(): Observable<GQLProfile[]> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PROFILES,
        variables: { limit: 999, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .pipe(map(({ data }: any) => data.profiles.items));
  }

  getCurrentUserPermissions(): Observable<GQLProfilePermissions> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PERMISSIONS,
        fetchPolicy: 'network-only',
      })
      .pipe(map(({ data }: any) => data.permissions));
  }

  editMyProfile(input: GQLEditProfileInput): Observable<GQLEditProfilePayload> {
    return this.api
      .mutate(UPDATE_PROFILE, { input }, { useMultipart: true })
      .pipe(map((data) => data.data.editProfile));
  }

  editUserProfile(
    input: GQLEditProfileInput,
  ): Observable<GQLEditProfilePayload> {
    return this.api.mutate(
      EDIT_USER_PROFILE,
      { input },
      { useMultipart: true },
    );
  }

  editMyPassword(
    input: GQLUpdatePasswordInput,
  ): Observable<GQLUpdatePasswordPayload> {
    return this.api.mutate(UPDATE_MY_PASSWORD, { input });
  }

  checkInvitationStatus(
    hash: string,
  ): Observable<GQLInvitationActionStatusEnum> {
    return this.api
      .query(GET_INVITATION_STATUS, { hash })
      .pipe(map((data) => data.data.invitationStatus.status));
  }
}
