import { GQLProfile } from '../../../schemas/schemas';

export function getCuttedUserName(
  author: GQLProfile | undefined | null,
): string {
  if (!author) {
    return '';
  }
  const fullName =
    author.firstName && author.lastName
      ? `${author.firstName.trimStart().split(' ')[0]} ${
          author.lastName.trimStart().split(' ')[0]
        }`
      : author.fullName;
  return fullName || '';
}
