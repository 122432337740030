import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'primeng/tooltip';
import { RouterModule } from '@angular/router';
import { FileCardComponent } from './components/file-card/file-card.component';
import { FileTipesPipeModule } from '../../pipes/file-tipes-pipe/file-tipes.pipe.module';
import { ShortenerModule } from '../../pipes/shortener/shortener.module';
import { FileSizePipeModule } from '../../pipes/file-size-pipe/file-size-pipe.module';
import { AppIconModule } from '../icon';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [FileCardComponent],
  imports: [
    CommonModule,
    FileTipesPipeModule,
    ShortenerModule,
    FileSizePipeModule,
    TooltipModule,
    AppIconModule,
    RouterModule,
    OverlayPanelModule,
  ],
  exports: [FileCardComponent],
})
export class FileCardModule {}
