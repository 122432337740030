import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import uniqBy from 'lodash/uniqBy';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GQLProfile } from '@schemas';
import { getCuttedUserName, noSpaces, SHORTENER_LENGTH } from '@common/helpers';
import { getCurrentUser } from '@common/store/users/users.selectors';
import { SubscriberProfile } from '@common/types';
import { MobileUiService } from '@common/services/mobile-ui.service';

@Component({
  selector: 'app-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribersComponent implements OnInit, OnDestroy {
  @Input() set alreadySelectedUsers(selectedUsers: Array<SubscriberProfile>) {
    if (selectedUsers.length > 0 && !this.selectedFlag) {
      this.selectedUsers = this.mergeSelectedUsers(selectedUsers);
      this.activateUsers();
    }
  }

  @Input() set availableSubscribers(subscribers: GQLProfile[]) {
    if (subscribers && subscribers.length > 0 && !this.selectedFlag) {
      this.subscribers =
        subscribers?.map((sub: GQLProfile) => {
          return { ...sub, isActive: false };
        }) || null;

      if (this.selectedUsers.length > 0) {
        this.activateUsers();
      } else {
        this.filteredSubscribers = this.subscribers;
      }
    }
  }

  @Input() set setConnectedUsers(users: Array<GQLProfile | null> | null) {
    if (users && users.length > 0 && !this.selectedFlag) {
      this.selectedUsers = [
        ...this.mergeSelectedUsers(this.selectedUsers),
        ...(users?.map((sub: GQLProfile | null) => {
          const user: SubscriberProfile = {
            ...(sub || ({} as GQLProfile)),
            isActive: true,
          };
          return user || ({} as SubscriberProfile);
        }) || []),
      ];
      this.selectedUsers = uniqBy(this.selectedUsers, 'id');
      this.checkIsAllUsersSelected();
      this.activateUsers();
    }
  }

  @Output() privateUsers: EventEmitter<Array<SubscriberProfile>> =
    new EventEmitter();

  isSubscribersSelectorOpen = false;
  isMoreSelectedUsersPopupOpen = false;
  isAllUsersSelected = false;

  filteredSubscribers: Array<SubscriberProfile> | null = [];

  selectedUsers: Array<SubscriberProfile> = [];

  private currentUser!: GQLProfile | null;
  private subscribers: Array<SubscriberProfile> = [];
  private selectedFlag = false;

  private unsubscribe$: Subject<boolean> = new Subject();

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (!this.elementRef.nativeElement.contains(target)) {
      this.isMoreSelectedUsersPopupOpen = false;
      this.closeSubscribersSelector();
      this.checkIsAllUsersSelected();
    }
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly store: Store,
    private readonly mobileUI: MobileUiService,
  ) {
    this.store
      .select(getCurrentUser)
      .pipe(filter((user) => !!user))
      .subscribe((user: GQLProfile | null) => {
        this.currentUser = user;
      });
  }

  ngOnInit(): void {
    this.mobileUI.hideScrollInOpenMenu(this.isMoreSelectedUsersPopupOpen);
  }

  getAuthorAvatarName(author: GQLProfile): string {
    return getCuttedUserName(author);
  }

  filterSubscribers(event: Event): void {
    const searchString = (event.target as HTMLInputElement)?.value || '';
    const normalizedSearchString = noSpaces(searchString);

    this.filteredSubscribers =
      this.subscribers
        ?.filter((subscriber) =>
          this.matchesSearchString(
            subscriber?.fullName,
            normalizedSearchString,
          ),
        )
        .map((subscriber) => ({
          ...subscriber,
          isActive: this.isSubscriberActive(subscriber.id),
        })) || null;

    this.checkIsAllUsersSelected();
  }

  closeSubscribersSelector(): void {
    this.isSubscribersSelectorOpen = false;
    this.selectAlreadySelectedUsers();
    this.mobileUI.hideScrollInOpenMenu(this.isMoreSelectedUsersPopupOpen);
  }

  closeMoreSelectedUsersPopup(): void {
    this.isMoreSelectedUsersPopupOpen = false;
    this.mobileUI.hideScrollInOpenMenu(this.isMoreSelectedUsersPopupOpen);
  }

  toggleMoreSelectedUsers(): void {
    this.isMoreSelectedUsersPopupOpen = !this.isMoreSelectedUsersPopupOpen;
    this.isSubscribersSelectorOpen = false;
    this.mobileUI.hideScrollInOpenMenu(this.isMoreSelectedUsersPopupOpen);
  }

  toggleSubscribersSelector(): void {
    this.isSubscribersSelectorOpen = !this.isSubscribersSelectorOpen;
    this.selectAlreadySelectedUsers();
    this.isMoreSelectedUsersPopupOpen = false;
    this.mobileUI.hideScrollInOpenMenu(this.isSubscribersSelectorOpen);
  }

  selectUser(user: SubscriberProfile): void {
    if (this.selectedUsers.length === 1 && user.isActive && this.currentUser) {
      if (this.currentUser?.id === user.id) {
        return;
      }
    } else {
      let newUser = user;
      if (!user.isActive) {
        newUser = { ...user, isActive: true };
        this.selectedUsers = [...this.selectedUsers, newUser];
      } else {
        newUser = { ...user, isActive: true };
        this.selectedUsers = this.selectedUsers.filter(
          (userFromArray) => userFromArray.id !== newUser.id,
        );
      }
      // TODO:refactor it
      // eslint-disable-next-line no-param-reassign
      user.isActive = !user.isActive;
      this.selectedFlag = true;
    }
    this.checkIsAllUsersSelected();
    this.privateUsers.emit(this.selectedUsers);
  }

  selectAllUsers(): void {
    this.isAllUsersSelected = !this.isAllUsersSelected;

    this.filteredSubscribers = (this.filteredSubscribers || []).map((user) => {
      if (user.id === this.currentUser?.id) {
        return {
          ...user,
          isActive: true,
        };
      }
      return {
        ...user,
        isActive: this.isAllUsersSelected,
      };
    });

    if (this.isAllUsersSelected) {
      this.selectedUsers = [...this.filteredSubscribers];
    } else if (this.currentUser) {
      this.selectedUsers = [{ ...this.currentUser, isActive: true }];
    } else {
      this.selectedUsers = [];
    }

    this.selectedFlag = true;
    this.privateUsers.emit(this.filteredSubscribers);
  }

  tooltipText(title: string | undefined, length = SHORTENER_LENGTH): string {
    if (title) {
      return title.length > length ? title : '';
    }
    return '';
  }

  private mergeSelectedUsers(
    selectedUsers: Array<SubscriberProfile>,
  ): Array<SubscriberProfile> {
    const ids = new Set(this.selectedUsers.map((d) => d.id));

    return [
      ...this.selectedUsers,
      ...selectedUsers.filter((d) => !ids.has(d.id)),
    ];
  }

  private checkIsAllUsersSelected(): void {
    this.isAllUsersSelected = (this.filteredSubscribers ?? []).every(
      (subscriber) => subscriber.isActive,
    );
  }

  private activateUsers(): void {
    this.filteredSubscribers =
      this.subscribers?.map((sub: SubscriberProfile) => {
        const newSub = sub;
        if (
          this.selectedUsers?.find(
            (contr: SubscriberProfile) => newSub.id === contr.id,
          )
        ) {
          newSub.isActive = true;
        }
        return newSub;
      }) || null;

    this.checkIsAllUsersSelected();
  }

  private selectAlreadySelectedUsers(): void {
    this.filteredSubscribers = this.subscribers.map((sub) => ({
      ...sub,
      isActive: !!this.selectedUsers?.find((fil) => fil.id === sub.id)
        ?.isActive,
    }));
  }

  private isSubscriberActive(subscriberId: string): boolean {
    return !!this.selectedUsers?.find(
      (subscriber) => subscriber.id === subscriberId,
    )?.isActive;
  }

  private matchesSearchString(
    fullName: string | undefined,
    searchString: string,
  ): boolean {
    return noSpaces(fullName || '').indexOf(searchString) > -1;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
