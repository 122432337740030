import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of, timer } from 'rxjs';
import {
  catchError,
  delayWhen,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  GQLComment,
  GQLFileMarkerStatusEnum,
  GQLMarker,
} from '../../../../schemas/schemas';
import { CREATE_MARKER } from '../../graphQL/graphql-requests/mutations/CREATE_MARKER';
import { EDIT_COMMENT } from '../../graphQL/graphql-requests/mutations/EDIT_COMMENT';
import { MARK_COMMENTS_AS_VIEWED } from '../../graphQL/graphql-requests/mutations/MARK_COMMENTS_AS_VIEWED';
import { REMOVE_COMMENT } from '../../graphQL/graphql-requests/mutations/REMOVE_COMMENT';
import { REMOVE_MARKER } from '../../graphQL/graphql-requests/mutations/REMOVE_MARKER';
import { APP_CONFIG } from '../../helpers/constants';
import { AppConfig } from '../../types';
import { BaseApi } from '../../services/base-api';
import {
  getCurrentTimePoint,
  getDrawingMode,
  getIsCommentingMode,
  getIsVideoType,
  getMarkersStatus,
  setCurrentVideoTimePoint,
  turnCommentingModeOff,
  turnCommentingModeOn,
  turnDrawingModeOff,
} from '../../../commenting-tool/store';
import {
  loadMarkerAutoincrementValue,
  selectMarkersGroup,
  setCurrent3dScreenshot,
} from '../files/files.actions';
import {
  getActiveMarkersGroup,
  getCurrent3dScreenshot,
  getCurrentFileVersion,
  getCurrentFileVersionId,
  isFileTypeImageOrModel,
} from '../files/files.selectors';
import {
  addActiveMarker,
  addChatCoordinates,
  addMarkerGroupToArray,
  createMarker,
  createMarkerComment,
  createMarkerCommentFailure,
  createMarkerCommentOrGroup,
  createMarkerCommentSuccess,
  createMarkerFailure,
  createMarkerGroup,
  createMarkerGroupFailure,
  createMarkerGroupSuccess,
  createMarkerSuccess,
  deleteMarker,
  deleteMarkerFailure,
  deleteMarkerSuccess,
  editMarkerComment,
  editMarkerCommentFailure,
  editMarkerCommentSuccess,
  getBackToStream,
  getMarkerGroup,
  getMarkerGroupFailure,
  getMarkerGroupSuccess,
  markMarkerAsViewed,
  markMarkerAsViewedSuccess,
  nextMarker,
  prevMarker,
  removeMarkerComment,
  removeMarkerCommentFailure,
  removeMarkerCommentSuccess,
  resetChatCoordinates,
  resolveMarkerFailure,
  resolveMarkerSuccess,
  setMarkerIdForOpeningFromSide,
  setMarkersFromGroup,
  toggleResolveMarker,
  unresolveMarkerSuccess,
  updateMarkerGroupImage,
} from './markers.actions';
import {
  getActiveMarker,
  getChatCoordinates,
  getNextMarkerId,
  getPrevMarkerId,
} from './markers.selectors';
import { FilesService } from './services/markers.service';
import { Signal } from '@common/services/Signal';
import { UnrealEmbedService } from '../../../commenting-tool/services/unreal-embed.service';

export const checkRunStatusIntervalMs = 500;

@Injectable()
export class MarkersEffects {
  constructor(
    public actions$: Actions,
    public http: HttpClient,
    private api: BaseApi,
    private store: Store,
    private filesService: FilesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly unrealService: UnrealEmbedService,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {}

  createMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMarker),
      withLatestFrom(
        this.store.select(getChatCoordinates),
        this.store.select(getActiveMarkersGroup),
      ),
      switchMap(([inputData, chatCoordinates, group]) => {
        const input = {
          ...inputData,
          link: `${this.appConfig.api.url}#/tools/%file_id%?version=%version_id%&markersGroupId=%group_id%&markerId=%marker_id%&commentId=%comment_id%`,
        };
        return this.api.mutate(CREATE_MARKER, input, 'network-only').pipe(
          mergeMap(({ data }) => [
            loadMarkerAutoincrementValue(),
            createMarkerSuccess({
              marker: data.createMarker.marker,
              groupId: group?.id || input.input.groupId,
            }),
            addChatCoordinates({
              chatCoordinates: {
                ...chatCoordinates,
                markerId: data.createMarker.marker.id,
              },
            }),
          ]),
          catchError((error: unknown) => of(createMarkerFailure({ error }))),
        );
      }),
    ),
  );

  resetActiveMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(turnCommentingModeOff, turnDrawingModeOff),
      map(() => resetChatCoordinates()),
    ),
  );

  markMarkerAsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        markMarkerAsViewed,
        setMarkerIdForOpeningFromSide,
        addActiveMarker,
      ),
      withLatestFrom(this.store.select(getActiveMarker)),
      filter(
        ([_, currentMarker]) => !!currentMarker && currentMarker.hasNewComment,
      ),
      map(([_, currentMarker]) => currentMarker as GQLMarker),
      switchMap((currentMarker) => {
        return this.api
          .mutate(
            MARK_COMMENTS_AS_VIEWED,
            {
              input: {
                ids: currentMarker.comments.map(
                  (comment: GQLComment) => comment.id,
                ),
              },
            },
            'network-only',
          )
          .pipe(
            map(() =>
              markMarkerAsViewedSuccess({ markerId: currentMarker.id }),
            ),
            catchError((error: unknown) => of(createMarkerFailure({ error }))),
          );
      }),
    ),
  );

  createMarkerCommentOrGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createMarkerCommentOrGroup),
      withLatestFrom(
        this.store.select(getActiveMarkersGroup),
        this.store.select(getCurrent3dScreenshot),
        this.store.select(isFileTypeImageOrModel),
      ),
      map(([{ description }, activeMarkersGroup, imageSrc, createComment]) => {
        const action: Action = imageSrc
          ? createMarkerGroup({
              description,
              imageSrc,
            })
          : createMarkerGroupFailure({
              error: 'No currentVideoTimePoint specified',
            });
        return activeMarkersGroup || createComment
          ? createMarkerComment({
              description,
              groupId: activeMarkersGroup?.id,
            })
          : action;
      }),
    );
  });

  createMarkerGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMarkerGroup),
      withLatestFrom(
        this.store.select(getCurrentFileVersionId),
        this.store.select(getCurrentTimePoint),
        this.unrealService.productSlotsMaterials$,
      ),
      switchMap(
        ([
          { description, imageSrc },
          fileVersionId,
          currentVideoTimePoint,
          embedProductConfigurationData,
        ]) =>
          fileVersionId
            ? this.filesService
                .createMarkerGroup({
                  imageSrc,
                  configuration: JSON.stringify({
                    timeInfo: currentVideoTimePoint,
                    embedProductConfigurationData: {
                      ...embedProductConfigurationData,
                      selectedMaterials:
                        embedProductConfigurationData.selectedMaterials.filter(
                          (item) =>
                            embedProductConfigurationData?.availableMaterials?.some(
                              (availableMaterial) =>
                                availableMaterial.slotName === item.slotName,
                            ),
                        ),
                    },
                  }),
                  versionId: fileVersionId,
                })
                .pipe(
                  mergeMap(({ markerGroup }) => {
                    return [
                      createMarkerGroupSuccess({
                        markerGroup,
                        description,
                      }),
                    ];
                  }),
                  catchError((error: unknown) =>
                    of(createMarkerGroupFailure({ error })),
                  ),
                )
            : of(createMarkerGroupFailure({ error: '' })),
      ),
    ),
  );

  createMarkerComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMarkerComment),
      withLatestFrom(
        this.store.select(getActiveMarker),
        this.store.select(getCurrentFileVersion),
      ),
      switchMap(([{ description, groupId }, activeMarker, fileVersion]) => {
        return activeMarker && !(activeMarker as any).isNew
          ? this.filesService
              .createMarkerComment(activeMarker.id, description)
              .pipe(
                map(({ data }) =>
                  createMarkerCommentSuccess({
                    comment: data.createComment.comment,
                    markerId: activeMarker.id,
                    groupId,
                  }),
                ),
                catchError((error: unknown) =>
                  of(createMarkerCommentFailure({ error })),
                ),
              )
          : of(
              createMarker({
                input: {
                  versionId: fileVersion?.id || '',
                  coordinates:
                    activeMarker?.coordinates ||
                    (activeMarker as unknown as GQLMarker).coordinates
                      ? JSON.stringify(
                          (activeMarker as unknown as GQLMarker).coordinates,
                        )
                      : '',
                  comment: description,
                  groupId,
                },
              }),
            );
      }),
    ),
  );

  editComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editMarkerComment),
      exhaustMap(({ input }) =>
        this.api.mutate(EDIT_COMMENT, { input }).pipe(
          map(({ data }) => editMarkerCommentSuccess(data)),
          catchError((error: unknown) =>
            of(editMarkerCommentFailure({ error })),
          ),
        ),
      ),
    ),
  );

  removeComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeMarkerComment),
      exhaustMap(({ input }) =>
        this.api.mutate(REMOVE_COMMENT, { input }).pipe(
          map(({ data }) => removeMarkerCommentSuccess(data)),
          catchError((error: unknown) =>
            of(removeMarkerCommentFailure({ error })),
          ),
        ),
      ),
    ),
  );

  removeMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMarker),
      exhaustMap(({ input }) =>
        this.api.mutate(REMOVE_MARKER, { input }).pipe(
          mergeMap(({ data }) => [
            resetChatCoordinates(),
            loadMarkerAutoincrementValue(),
            deleteMarkerSuccess(data),
          ]),
          catchError((error: unknown) => of(deleteMarkerFailure({ error }))),
        ),
      ),
    ),
  );

  toggleResolveMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleResolveMarker),
      withLatestFrom(
        this.store.select(getActiveMarker),
        this.store.select(getMarkersStatus),
      ),
      switchMap(([, currentMarker, markersStatus]) => {
        if (!currentMarker?.id) {
          return of(resolveMarkerFailure({ error: 'No current marker id' }));
        }
        const isResolved =
          currentMarker.status === GQLFileMarkerStatusEnum.RESOLVED;
        return this.filesService
          .toggleResolveMarker(isResolved, currentMarker.id)
          .pipe(
            mergeMap(({ marker }) => {
              if (isResolved) {
                return [
                  unresolveMarkerSuccess({ id: marker.id, markersStatus }),
                ];
              }
              return markersStatus === GQLFileMarkerStatusEnum.ALL
                ? [resolveMarkerSuccess({ id: marker.id, markersStatus })]
                : [
                    resetChatCoordinates(),
                    resolveMarkerSuccess({ id: marker.id, markersStatus }),
                  ];
            }),
            catchError((error: unknown) => of(resolveMarkerFailure({ error }))),
          );
      }),
    ),
  );

  nextMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nextMarker),
      withLatestFrom(this.store.select(getNextMarkerId)),
      filter(([, markerId]) => !!markerId),
      map(([, markerId]) =>
        setMarkerIdForOpeningFromSide({ markerId: markerId ?? '' }),
      ),
    ),
  );

  prevMarker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(prevMarker),
      withLatestFrom(this.store.select(getPrevMarkerId)),
      filter(([, markerId]) => !!markerId),
      map(([, markerId]) =>
        setMarkerIdForOpeningFromSide({ markerId: markerId ?? '' }),
      ),
    ),
  );

  getBackToStream$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBackToStream),
      map(() => turnDrawingModeOff()),
    ),
  );

  getMarkerGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getMarkerGroup),
      withLatestFrom(this.store.select(getIsVideoType)),
      switchMap(([{ id }, isVideoType]) =>
        this.filesService.getMarkerGroup(id).pipe(
          delayWhen(({ imagePath }) =>
            imagePath ? timer(0) : timer(checkRunStatusIntervalMs),
          ),
          mergeMap(({ imagePath, imageUploadUrl, previewPath }) => {
            const actions = isVideoType
              ? []
              : [
                  setCurrent3dScreenshot({
                    current3dScreenshot: imagePath || imageUploadUrl,
                  }),
                ];
            if (imagePath || imageUploadUrl) {
              return [
                updateMarkerGroupImage({
                  imagePath: imagePath || imageUploadUrl,
                  previewPath,
                  id,
                }),
                ...actions,
                getMarkerGroupSuccess(),
              ];
            }
            return of(getMarkerGroup({ id }));
          }),
          catchError((error: unknown) => of(getMarkerGroupFailure({ error }))),
        ),
      ),
    ),
  );

  createMarkerGroupSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMarkerGroupSuccess),
      withLatestFrom(this.store.select(getIsVideoType)),
      tap(
        ([
          {
            markerGroup: { imageUploadUrl, id },
          },
          isVideo,
        ]) => {
          if (imageUploadUrl && !isVideo) {
            Signal.broadcast('MARKER_GROUP_CREATED', { id, imageUploadUrl });
          }
        },
      ),
      mergeMap(([{ markerGroup, description }, isVideo]) => {
        const defaultActions = isVideo
          ? [addMarkerGroupToArray({ markerGroup })]
          : [
              addMarkerGroupToArray({ markerGroup }),
              selectMarkersGroup({ markersGroup: markerGroup }),
            ];

        if (isVideo && description) {
          return [
            ...defaultActions,
            createMarkerComment({
              groupId: markerGroup.id,
              description,
            }),
          ];
        }
        return defaultActions;
      }),
    ),
  );

  selectMarkersGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMarkersGroup),
      withLatestFrom(
        this.store.select(getIsCommentingMode),
        this.store.select(getDrawingMode),
      ),
      filter(
        ([, isCommentingMode, isDrawindMode]) =>
          !isCommentingMode && !isDrawindMode,
      ),
      map(() => turnCommentingModeOn()),
    ),
  );

  selectMarkersGroupWithChangeUrl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectMarkersGroup),
      tap(({ markersGroup }) => {
        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: {
            ...this.route.snapshot.queryParams,
            markersGroupId: markersGroup.id,
          },
        });
      }),
      mergeMap(({ markersGroup }) => [
        setMarkersFromGroup({ markers: markersGroup.markers }),
        resetChatCoordinates(),
      ]),
    ),
  );

  setCurrentVideoTimePoint$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setCurrentVideoTimePoint),
      map(({ current3dScreenshot }) =>
        setCurrent3dScreenshot({
          current3dScreenshot,
        }),
      ),
    ),
  );
}
