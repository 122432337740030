import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import {
  getFileListView,
  getFilesListLoading,
  getThreadFiles,
  getThreadFilesFilterRange,
  getThreadFilesLength,
} from '@common/store/files/files.selectors';
import { EFileListType } from '@common/types/EFileListType';
import { NavigationBackService } from '@common/services/navigation-back.service';
import { saveCurrentProjectId } from '@common/store/projects/projects.actions';
import {
  loadThreadFiles,
  setCurrentFileListStatus,
  tryRemoveFile,
} from '@common/store/files/files.actions';
import { getThreadMainInfo } from '@common/store/threads/threads.selectors';
import { FullscreenGalleryComponent } from '@common/modules/fullscreen-gallery/components/fullscreen-gallery/fullscreen-gallery.component';
import {
  EFileExtension,
  EFromFilePlace,
  EPrevPath,
  EViewType,
} from '@common/helpers';
import { GQLDateRange, GQLFile } from '@schemas';

export interface ItemMainInfo {
  id?: string;
  serialNumber?: number;
  title?: string;
}

@Component({
  selector: 'file-list-in-thread',
  templateUrl: './file-list-in-thread.component.html',
  styleUrls: ['./file-list-in-thread.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileListInThreadComponent implements OnInit, OnDestroy {
  readonly threadFiles$: Observable<GQLFile[]> =
    this.store.select(getThreadFiles);
  readonly threadMainInfo$: Observable<ItemMainInfo> =
    this.store.select(getThreadMainInfo);
  readonly filesListLoading$ = this.store.select(getFilesListLoading);
  readonly filesQuantity$ = this.store.select(getThreadFilesLength);
  readonly fileListView$ = this.store.select(getFileListView);
  readonly filesFilterDateRange$ = this.store
    .select(getThreadFilesFilterRange)
    .pipe(
      tap((dateRange) => {
        if (dateRange) {
          this.dateRange = dateRange;
        }
      }),
    );
  readonly fileListType: EFileListType = this.route.snapshot.data.fileListType;

  selectedFile!: GQLFile;
  isImageViewerVisible = false;
  readonly viewType = EViewType;
  readonly filePlace = EFromFilePlace;

  private readonly currentProjectId: string =
    this.route.snapshot.params.projectId;
  private readonly currentThreadId: string =
    this.route.snapshot.params.threadId;
  private searchQuery = '';
  private dateRange!: GQLDateRange;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly store: Store,
    private readonly fileNavigateService: NavigationBackService,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(
      saveCurrentProjectId({ currentProjectId: this.currentProjectId }),
    );
    this.getCurrentFileListStatus();
    this.filesFilterDateRange$.pipe(takeUntil(this.unsubscribe$)).subscribe();
  }

  toggleImgViewerVisible(): void {
    this.isImageViewerVisible = !this.isImageViewerVisible;
  }

  goBack(): void {
    const { parentPath } = this.route.snapshot.queryParams;
    if (parentPath === EPrevPath.files) {
      this.router.navigate([EPrevPath.files]);
    } else {
      this.router.navigate([
        EPrevPath.default,
        this.currentProjectId,
        this.currentThreadId,
      ]);
    }
  }

  removeFile(file: GQLFile): void {
    this.store.dispatch(tryRemoveFile({ id: file.id, fileName: file.name }));
  }

  openGallery(file: GQLFile): void {
    if (file?.versions?.[0].extension === EFileExtension.PDF) {
      this.isImageViewerVisible = !this.isImageViewerVisible;
      this.selectedFile = file;

      const dialogConfig = {
        data: {
          fileList$: this.threadFiles$,
          activeFile: this.selectedFile,
          isPrevNextArrowsNeed: false,
        },
        styleClass: 'gallery',
        width: '100%',
        height: '100%',
      };

      this.dialogService.open(FullscreenGalleryComponent, dialogConfig);
    }
  }

  setSearchQuery(searchQuery: string): void {
    this.searchQuery = searchQuery;
  }

  private loadThreadFiles(): void {
    this.store.dispatch(
      loadThreadFiles({
        searchQuery: this.searchQuery,
        dateRange: this.dateRange,
      }),
    );
  }

  private getCurrentFileListStatus(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ sortBy }) => {
        this.store.dispatch(setCurrentFileListStatus({ status: sortBy }));
        this.loadThreadFiles();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
