/*
 *  3D Source AMS/VPS
 *  http://3dsource.com/
 *
 *  3D Source is the only company that exists exclusively to help product manufacturers
 *  evolve their sales and marketing assets from photography to photorealistic
 *  3D and CGI.
 *  3D Source provides access to a scalable network of content developers and
 *  groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 *  Copyright 2020 3D Source
 */

import { gql } from 'apollo-angular';

export const REFRESH_TOKEN = gql`
  mutation refreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      token
      refreshToken
    }
  }
`;
