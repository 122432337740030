import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { GQLProfile } from '@schemas';
import { getCuttedUserName } from '@common/helpers';
import { MobileUiService } from '@common/services/mobile-ui.service';
import { Store } from '@ngrx/store';
import { getOpenProjectIdUsersPopup } from '@common/store/projects/projects.actions';
import { selectOpenProjectIdUsersPopup } from '@common/store/projects/projects.selectors';
import { filter, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-mini-users-list',
  templateUrl: './mini-users-list.component.html',
  styleUrls: ['./mini-users-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniUsersListComponent implements OnInit, OnDestroy {
  @Input() profiles: (GQLProfile | null)[] = [];
  @Input() parent = 'project';
  @Input() projectId: string = '';

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private activeProjectId$: Observable<string> = this.store.select(
    selectOpenProjectIdUsersPopup,
  );

  isSubscribersPopupOpen = false;

  @HostListener('document:click', ['$event.target'])
  onClick(target: any) {
    if (!this.elementRef.nativeElement.contains(target)) {
      this.closeSubscribersPopup();
    }
  }

  toggleSubscribersPopup() {
    this.isSubscribersPopupOpen = !this.isSubscribersPopupOpen;
    this.store.dispatch(
      getOpenProjectIdUsersPopup({ activeProjectId: this.projectId }),
    );

    this.mobileUI.hideScrollInOpenMenu(this.isSubscribersPopupOpen);
  }

  closeSubscribersPopup() {
    this.isSubscribersPopupOpen = false;
    this.mobileUI.hideScrollInOpenMenu(this.isSubscribersPopupOpen);
  }

  getAuthorAvatarName(author: GQLProfile | null): string {
    return getCuttedUserName(author);
  }

  constructor(
    private readonly elementRef: ElementRef,
    private readonly mobileUI: MobileUiService,
    private readonly store: Store,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.mobileUI.hideScrollInOpenMenu(this.isSubscribersPopupOpen);
    this.getActiveProjectId();
  }

  private getActiveProjectId(): void {
    this.activeProjectId$
      .pipe(
        filter((id) => this.projectId !== id),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.closeSubscribersPopup();
        this.cd.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
