/* eslint-disable class-methods-use-this */
/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/9/21, 2:35 PM
 * Copyright 2021 3D Source
 */

import { Pipe, PipeTransform } from '@angular/core';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

@Pipe({
  name: 'timeFromNow',
})
export class TimeFromNowPipe implements PipeTransform {
  transform(date: Date): string {
    if (date) {
      const dateToString = Date.parse(date.toString());
      return formatDistanceToNow(dateToString);
    }
    return '';
  }
}
