import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SkeletonModule } from 'primeng/skeleton';
import { RouterModule } from '@angular/router';
import { FilesWidgetComponent } from './components/files-widget/files-widget.component';
import { FileTipesPipeModule } from '../../pipes/file-tipes-pipe/file-tipes.pipe.module';
import { FileCardModule } from '../file-card/file-card.module';
import { FullscreenGalleryModule } from '../fullscreen-gallery/fullscreen-gallery.module';
import { FilesWidgetSkeletonComponent } from './components/files-widget-skeleton/files-widget-skeleton.component';

@NgModule({
  declarations: [FilesWidgetComponent, FilesWidgetSkeletonComponent],
  imports: [
    CommonModule,
    FileTipesPipeModule,
    FileCardModule,
    FullscreenGalleryModule,
    SkeletonModule,
    RouterModule,
  ],
  exports: [FilesWidgetComponent],
})
export class FilesWidgetModule {}
