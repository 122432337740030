<div class="layout layout--threads">
  <div class="layout__heading">
    <div class="layout__title">
      <button type="button" class="back-link" (click)="goToProjectList()">
        <svg class="icon" width="24" height="24">
          <use xlink:href="#icon-chevrone-left"></use>
        </svg>
      </button>
      <h2>{{ projectName$ | async }}</h2>
    </div>
  </div>
  <div class="layout__filter-row">
    <!-- Temporary hidden -->
    <!-- <div class="page-heading__navigation">
      <ul class="page-navigation">
        <li class="page-navigation__item active">
          <a>Threads</a>
        </li>

        <li class="page-navigation__item">
          <a>Users</a>
        </li>
        <li class="page-navigation__item">
          <a>Events</a>
        </li>
      </ul>
    </div>-->
    <app-treads-filter></app-treads-filter>
  </div>
  <div class="layout__sidebar">
    <threads-sidebar></threads-sidebar>
  </div>
  <div class="layout__content">
    <ng-container
      *ngIf="(threadListIsLoading$ | async) === false; else skeleton"
    >
      <ng-container *ngIf="threadList$ | async as threadList">
        <ng-container *ngIf="threadList.length > 0; else emptyList">
          <app-threads-item
            *ngFor="let thread of threadList"
            [thread]="thread"
          ></app-threads-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
<ng-template #skeleton>
  <app-threads-list-skeleton></app-threads-list-skeleton>
</ng-template>

<ng-template #emptyList>
  <div class="empty-message">No results</div>
</ng-template>
