/* eslint-disable @typescript-eslint/no-shadow */
import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLFileVersion,
  GQLProject,
  GQLProjectInvitation,
  GQLProjectList,
} from '../../../../schemas/schemas';
import {
  createFileVersionSuccess,
  markVersionAsViewedSuccess,
} from '../files/files.actions';
import {
  clearCurrentProjectId,
  clearProject,
  createProject,
  createProjectFailure,
  createProjectSuccess,
  deleteProject,
  deleteProjectFailure,
  deleteProjectSuccess,
  editProject,
  editProjectFailure,
  editProjectSuccess,
  getInitProjectListViewSuccess,
  getOpenProjectIdUsersPopup,
  loadProject,
  loadProjectFailure,
  loadProjectsList,
  loadProjectsListsFailure,
  loadProjectsListSuccess,
  loadProjectsTitles,
  loadProjectsTitlesFailure,
  loadProjectsTitlesSuccess,
  loadProjectSuccess,
  removeInvitation,
  removeInvitationFailure,
  removeInvitationSuccess,
  saveCurrentProjectId,
  toggleProjectListViewSuccess,
} from './projects.actions';
import { EViewType } from '../../helpers';

export interface ProjectsState {
  projectsList: GQLProjectList;
  isLoadingProjects: boolean;
  error?: unknown;
  project: GQLProject | null;
  projectCreating: boolean;
  currentProjectId: string;
  projectsTitles: GQLProjectList;
  projectListView: EViewType;
  openMiniUsersPopupId: string;
}

export const initialProjectsState: ProjectsState = {
  projectsList: {} as GQLProjectList,
  isLoadingProjects: false,
  project: null,
  projectCreating: false,
  currentProjectId: '',
  projectsTitles: {
    total: 10000,
    skip: 0,
    limit: 0,
    items: [],
  },
  projectListView: EViewType.CARD,
  openMiniUsersPopupId: '',
};

const projectsReducer = createReducer(
  initialProjectsState,
  on(saveCurrentProjectId, (state, { currentProjectId }) => ({
    ...state,
    currentProjectId,
  })),
  on(clearCurrentProjectId, (state) => ({
    ...state,
    currentProjectId: '',
  })),
  // LOAD PROJECTS
  on(loadProjectsList, (state) => ({
    ...state,
    isLoadingProjects: true,
  })),
  on(loadProjectsListSuccess, (state, { projectsList }) => ({
    ...state,
    projectsList,
    isLoadingProjects: false,
  })),
  on(loadProjectsListsFailure, (state, { error }) => ({
    ...state,
    isLoadingProjects: false,
  })),
  // CREATE PROJECT
  on(createProject, (state) => ({
    ...state,
    projectCreating: true,
  })),
  on(createProjectSuccess, (state, { project }) => {
    return {
      ...state,
      projectsList: {
        ...state.projectsList,
        items: [project, ...(state?.projectsList?.items || [])],
        total: (state.projectsList.total || 0) + 1,
      },
      projectCreating: false,
    };
  }),
  on(createProjectFailure, (state, { error }) => ({
    ...state,
    projectCreating: false,
  })),
  // EDIT PROJECT
  on(editProject, (state) => ({
    ...state,
    projectCreating: true,
  })),
  on(editProjectSuccess, (state, { project }) => {
    return {
      ...state,
      projectsList: {
        ...state.projectsList,
        items: (state.projectsList?.items || []).map((projectElement) =>
          project.id === projectElement.id ? project : projectElement,
        ),
      },
      project: {
        ...project,
        invitations: [...project.invitations],
      },
      projectCreating: false,
    };
  }),
  on(editProjectFailure, (state, { error }) => ({
    ...state,
    projectCreating: false,
  })),
  on(getOpenProjectIdUsersPopup, (state, { activeProjectId }) => ({
    ...state,
    openMiniUsersPopupId: activeProjectId,
  })),
  // DELETE PROJECT
  on(deleteProject, (state) => ({
    ...state,
    projectUsers: null,
  })),
  on(deleteProjectSuccess, (state, { removeProject }) => {
    return {
      ...state,
      projectsList: {
        ...state.projectsList,
        items: [
          ...(state.projectsList.items || []).filter(
            (project: GQLProject) => project?.id !== removeProject.id,
          ),
        ],
        total: (state.projectsList?.total || 0) - 1,
      },
    };
  }),
  on(deleteProjectFailure, (state, { error }) => ({
    ...state,
  })),
  // LOAD PROJECT
  on(loadProject, (state) => ({
    ...state,
    projectUsers: null,
  })),
  on(loadProjectSuccess, (state, { project }) => {
    return {
      ...state,
      projectUsers: project.profiles,
      project,
    };
  }),
  on(loadProjectFailure, (state, { error }) => ({
    ...state,
    projectUsers: null,
  })),
  on(clearProject, (state) => ({
    ...state,
    projectUsers: null,
    project: null,
  })),
  //
  on(removeInvitation, (state) => ({
    ...state,
  })),
  on(removeInvitationSuccess, (state, { removeInvitation }): any => {
    return {
      ...state,
      project: {
        ...state.project,
        invitations: [
          ...(state.project?.invitations || []).filter(
            (invitation: GQLProjectInvitation) =>
              invitation?.id !== removeInvitation.id,
          ),
        ],
      },
    };
  }),
  on(removeInvitationFailure, (state, { error }) => ({
    ...state,
  })),
  on(clearProject, (state) => ({
    ...state,
    project: null,
  })),

  on(markVersionAsViewedSuccess, (state, { versions }) => {
    const { project } = state;
    return {
      ...state,
      project: project
        ? {
            ...project,
            files: {
              ...project.files,
              items: project.files.items.map((file) => {
                return {
                  ...file,
                  hasNewVersion:
                    !file.useTools &&
                    (file.versions || []).find(
                      (version) => version.id === versions[0].id,
                    )
                      ? false
                      : file.hasNewVersion,
                };
              }),
            },
          }
        : project,
    };
  }),
  // LOAD PROJECTS TITLES
  on(loadProjectsTitles, (state) => ({
    ...state,
    isLoadingProjects: true,
  })),
  on(loadProjectsTitlesSuccess, (state, { projectsTitles }) => ({
    ...state,
    projectsTitles,
    isLoadingProjects: false,
  })),
  on(loadProjectsTitlesFailure, (state) => ({
    ...state,
    isLoadingProjects: false,
  })),
  // FILE LIST VIEW
  on(
    getInitProjectListViewSuccess,
    toggleProjectListViewSuccess,
    (state, { viewType }) => ({
      ...state,
      projectListView: viewType,
    }),
  ),
  on(createFileVersionSuccess, (state, { fileVersion, fileId }) => {
    const project = state.project;
    return {
      ...state,
      project: project
        ? {
            ...project,
            files: {
              ...project.files,
              items: project.files.items.map((file) => {
                if (file && file.id === fileId) {
                  return {
                    ...file,
                    versions: [
                      fileVersion,
                      ...(file.versions as GQLFileVersion[]),
                    ],
                  };
                }
                return file;
              }),
            },
          }
        : project,
      versionCreateLoading: false,
    };
  }),
);

export function ProjectReducer(
  state: ProjectsState | undefined,
  action: Action,
): ProjectsState {
  return projectsReducer(state, action);
}
