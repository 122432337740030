<div class="sidebar">
  <div class="sidebar__elem sidebar__elem--files">
    <app-files-widget
      [fileListType]="fileListType.PROJECT"
      [files$]="files$"
    ></app-files-widget>
  </div>
  <div class="sidebar__elem sidebar__elem--activity">
    <!-- <app-activity-widget></app-activity-widget> -->
  </div>
</div>
