import { gql } from 'apollo-angular';

export const GET_NOTIFICATIONS = gql`
  query ($limit: Int, $skip: Int, $unreadOnly: Boolean) {
    activities(limit: $limit, skip: $skip, unreadOnly: $unreadOnly) {
      total
      unread
      items {
        id
        type
        comment {
          id
          marker {
            id
            markerGroup {
              id
            }
          }
        }
        author {
          id
          fullName
          firstName
          lastName
          avatarPath
        }
        project {
          id
          title
        }
        message {
          id
          body
        }
        file {
          id
          name
        }

        fileVersion {
          createdAt
          id
          title
          path
          previewPath
          size
          isViewed
          serialNumber
          status
        }
        thread {
          id
          serialNumber
          title
        }
        createdAt
        isViewed
      }
    }
  }
`;
