<div class="filter-row">
  <div class="filter-row__counter counter">
    <span class="counter__label" data-test-id="threadCounterLabel">Threads total:&nbsp;</span>
    <ng-container
      *ngIf="
        { threadsCounter: threadsCounter$ | async } as data;
        else counterSkeleton
      "
    >
      <strong
        >{{ data.threadsCounter }}
        {{ data.threadsCounter === 1 ? 'THREAD' : 'THREADS' }}</strong
      >
    </ng-container>
    <ng-template #counterSkeleton>
      <p-skeleton width="150px" height="1.5em" borderRadius="2px"></p-skeleton>
    </ng-template>
  </div>

  <div class="filter-row__search">
    <app-search (searchQuery)="handleSearch($event)"></app-search>
  </div>
  <form class="filter-row__filter" [formGroup]="filtersModel">
    <p-dropdown
      class="filter-dropdown"
      [options]="filterOptions"
      formControlName="dropdownFilter"
      (onChange)="changeValue($event)"
      optionLabel="title"
      data-test-id="threadsFilterDropdown"
    >
    </p-dropdown>
  </form>
  <div class="filter-row__action">
    <button
      *ngIf="canCreateThread"
      type="button"
      class="button"
      (click)="openDialog()"
      data-test-id="createThreadButton"
    >
      Create thread
    </button>
  </div>
</div>
