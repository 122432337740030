/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 4/9/21, 5:01 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const GET_THREAD_FILES = gql`
  query (
    $id: ID!
    $status: FileStatusEnum
    $unviewedOnly: Boolean
    $useTools: Boolean
    $searchQuery: String
    $dateRange: DateRange
    $types: [FileTypeEnum!]
  ) {
    threadFiles(
      id: $id
      status: $status
      unviewedOnly: $unviewedOnly
      useTools: $useTools
      searchQuery: $searchQuery
      dateRange: $dateRange
      types: $types
    ) {
      author {
        id
        fullName
      }
      id
      name
      commentCount
      type
      status
      createdAt
      updatedAt
      extension
      useTools
      project {
        id
        title
      }
      thread {
        id
        title
      }
      hasNewVersion
      hasNewComment
      hasNewApprove
      streamUrl
      unrealVersion
      versions {
        id
        isViewed
        hasNewComment
        previewPath
        path
        status
        extension
        createdAt
        serialNumber
        author {
          id
          fullName
        }
        size
        markerGroups {
          id
          imagePath
          previewPath
          markers {
            comments {
              id
            }
          }
        }
      }
    }
  }
`;
