/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 2/25/21, 12:59 PM
 * Copyright 2021 3D Source
 */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from '@common/guards/auth-guard/auth-guard.guard';
import { LayoutComponent } from './layout/components/layout/layout.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./authorization/authorization.module').then(
        (m) => m.AuthorizationModule,
      ),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'no-access',
        loadChildren: () =>
          import(
            '@common/modules/no-access-to-page/no-access-to-page.module'
          ).then((m) => m.NoAccessToPageModule),
      },
      {
        path: 'not-found',
        loadChildren: () =>
          import('@common/modules/not-found/not-found.module').then(
            (m) => m.NotFoundModule,
          ),
      },
      {
        path: 'files',
        loadChildren: () =>
          import('./files-page/files-page.module').then(
            (m) => m.FilesPageModule,
          ),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./user-profile-page/user-profile-page.module').then(
            (m) => m.UserProfilePageModule,
          ),
      },
      {
        path: 'notification-settings',
        loadChildren: () =>
          import('./notification-settings/notification-settings.module').then(
            (m) => m.NotificationSettingsModule,
          ),
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./projects-list/projects-list.module').then(
            (m) => m.ProjectsListModule,
          ),
      },
      {
        path: 'tools/:toolId',
        loadChildren: () =>
          import('./commenting-tool/commenting-tool.module').then(
            (m) => m.CommentingToolModule,
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./notifications/notifications.module').then(
            (m) => m.NotificationsModule,
          ),
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
