import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmDeleteFileModalComponent } from './components/confirm-delete-file-modal/confirm-delete-file-modal.component';

@NgModule({
  declarations: [ConfirmDeleteFileModalComponent],
  imports: [CommonModule, FormsModule],
  exports: [ConfirmDeleteFileModalComponent],
})
export class ConfirmDeleteFileModalModule {}
