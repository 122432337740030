/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/12/21, 1:59 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const APPROVE_FILE_VERSION = gql`
  mutation ($input: ApproveFileVersionInput!) {
    approveFileVersion(input: $input) {
      fileVersion {
        createdAt
        id
        title
        path
        previewPath
        size
        isViewed
        serialNumber
        canDisapprove
        canApprove
        status
      }
    }
  }
`;
