import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  GQLCreateFileVersionInput,
  GQLFileUpload,
} from '../../../../schemas/schemas';
import {
  clearAddFileError,
  createFileVersion,
  createFileVersionSuccess,
  resetFileLoadingFlag,
} from '@common/store/files/files.actions';
import {
  getAddFilesError,
  getCurrentFileId,
  getFileType,
  getVersionCreateLoader,
} from '@common/store/files/files.selectors';

@Component({
  selector: 'app-add-edit-version',
  templateUrl: './add-edit-version.component.html',
  styleUrls: ['./add-edit-version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditVersionComponent implements OnInit, OnDestroy {
  file!: GQLFileUpload;
  currentFileId = '';
  isLoading$: Observable<boolean> = this.store
    .select(getVersionCreateLoader)
    .pipe(
      tap((isLoading: boolean) => {
        this.isFileUpload = !isLoading;
      }),
    );

  currentFileType$ = this.store.select(getFileType);
  addFileError$ = this.store.select(getAddFilesError);

  isFileUpload = false;
  isEmptyUploader = true;

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public ref: DynamicDialogRef,
    private store: Store,
    private updates$: Actions,
  ) {}

  ngOnInit(): void {
    this.store
      .select(getCurrentFileId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentFileId) => {
        this.currentFileId = currentFileId;
      });

    if (this.currentFileId) {
      this.updates$
        .pipe(ofType(createFileVersionSuccess), takeUntil(this.unsubscribe$))
        .subscribe(() => this.close());
    }
  }

  get isAddDisabled(): boolean {
    return !this.isFileUpload || this.isEmptyUploader;
  }

  close(): void {
    this.store.dispatch(resetFileLoadingFlag());
    this.unsubscribe$.unsubscribe();
    this.ref.close();
  }

  addVersion(): void {
    if (!this.currentFileId) {
      const data = {
        save: true,
        file: this.file[0].file,
      };
      this.ref.close(data);
    } else {
      const fileVersionInput: GQLCreateFileVersionInput = {
        id: this.currentFileId,
        file: this.file[0].file,
      };

      this.store.dispatch(createFileVersion({ fileVersionInput }));
    }
  }

  filesAttached(file: GQLFileUpload): void {
    this.file = file;
    this.isFileUpload = true;
    this.isEmptyUploader = false;
    this.store.dispatch(clearAddFileError());
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetFileLoadingFlag());
    this.store.dispatch(clearAddFileError());
  }
}
