import { Pipe, PipeTransform } from '@angular/core';

import { GQLFileTypeEnum } from '../../../../schemas/schemas';

export type IFileTypeEnum =
  | GQLFileTypeEnum.IMAGE
  | GQLFileTypeEnum.VIDEO
  | GQLFileTypeEnum.STREAM
  | GQLFileTypeEnum.MODEL
  | GQLFileTypeEnum.OTHER
  | GQLFileTypeEnum.EMBED;

@Pipe({
  name: 'generalFileType',
  pure: true,
})
export class GeneralFileTypePipe implements PipeTransform {
  fileTypes: {
    [key in IFileTypeEnum]: { extension: string[] };
  } = {
    [GQLFileTypeEnum.IMAGE]: {
      extension: [
        'apng',
        'avif',
        'gif',
        'jpg',
        'jpeg',
        'jfif',
        'pjpeg',
        'pjp',
        'png',
        'svg',
        'webp',
        'bmp',
        'ico',
        'cur',
        'tif',
        'tiff',
      ],
    },
    [GQLFileTypeEnum.VIDEO]: {
      extension: ['mp4', 'WEBM', 'OGG'],
    },
    [GQLFileTypeEnum.STREAM]: {
      extension: ['pak', 'zip'],
    },
    [GQLFileTypeEnum.OTHER]: {
      extension: ['doc', 'docx', 'pdf', 'txt'],
    },
    [GQLFileTypeEnum.MODEL]: {
      extension: ['fbx', 'glb'],
    },
    [GQLFileTypeEnum.EMBED]: {
      extension: ['embed'],
    },
  };

  transform(name: string | undefined): GQLFileTypeEnum {
    if (name) {
      const extension: string = name
        // eslint-disable-next-line no-bitwise
        .slice(((name.lastIndexOf('.') - 1) >>> 0) + 2)
        .toLocaleLowerCase();
      const knownFileExtension =
        (Object.keys(this.fileTypes) as Array<IFileTypeEnum>).find(
          (type: IFileTypeEnum) =>
            this.fileTypes[type].extension.findIndex(
              (el: string) => extension === el,
            ) !== -1,
        ) || GQLFileTypeEnum.OTHER;

      return knownFileExtension as GQLFileTypeEnum;
    }

    return GQLFileTypeEnum.OTHER;
  }
}
