import { Action, createReducer, on } from '@ngrx/store';
import { NOTIFICATIONS_PER_PAGE } from '@common/helpers';
import { Activity } from '@common/types/acivity.model';
import {
  clearAllNotifications,
  closeNotifications,
  editNotificationSettings,
  editNotificationSettingsFailure,
  editNotificationSettingsSuccess,
  getNotificationSettings,
  getNotificationSettingsFailure,
  getNotificationSettingsSuccess,
  loadMoreNotifications,
  loadMoreNotificationsSuccess,
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess,
  markNotificationsAsViewedSuccess,
  openNotifications,
  resetNotificationsState,
  resetPagination,
} from './notifications.actions';
import { GQLNotificationSettings } from '@schemas';

export interface NotificationsState {
  notifications: Activity[];
  total?: number;
  skip: number;
  limit: number;
  unreadNotifications: number;
  opened: boolean;
  loading: boolean;
  notificationSettings: GQLNotificationSettings | null;
  notificationSettingsLoading: boolean;
  errors?: unknown;
  unreadOnly: boolean;
}

export const initialNotificationsState: NotificationsState = {
  notifications: [],
  skip: 0,
  limit: 20,
  unreadNotifications: 0,
  opened: false,
  loading: false,
  notificationSettings: null,
  notificationSettingsLoading: false,
  unreadOnly: false,
};

const notificationsReducer = createReducer(
  initialNotificationsState,
  // LOAD MARKER COMMENTS
  on(loadNotifications, (state, { unreadOnly }) => ({
    ...state,
    loading: true,
    limit: initialNotificationsState.limit,
    skip: state.unreadOnly !== unreadOnly ? 0 : state.skip,
    unreadOnly,
  })),
  on(loadNotificationsSuccess, (state, { activities }) => {
    return {
      ...state,
      notifications: activities.items,
      unreadNotifications: activities.unread,
      total: activities.total,
      skip: state.skip,
      loading: false,
    };
  }),
  on(loadMoreNotifications, (state) => ({
    ...state,
    loading: true,
    skip: state.skip + NOTIFICATIONS_PER_PAGE,
    limit: state.limit + NOTIFICATIONS_PER_PAGE,
  })),
  on(loadMoreNotificationsSuccess, (state, { activities }) => {
    return {
      ...state,
      notifications: [...state.notifications, ...activities.items],
      total: activities.total,
      loading: false,
    };
  }),
  on(loadNotificationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  })),
  on(resetPagination, (state) => ({
    ...state,
  })),
  on(openNotifications, (state) => ({
    ...state,
    opened: true,
  })),
  on(closeNotifications, (state) => ({
    ...state,
    opened: false,
    skip: NOTIFICATIONS_PER_PAGE,
    limit: initialNotificationsState.limit,
    notifications:
      state.notifications.length > NOTIFICATIONS_PER_PAGE
        ? [...[...state.notifications].splice(0, NOTIFICATIONS_PER_PAGE)]
        : [...state.notifications],
  })),
  on(markNotificationsAsViewedSuccess, (state, { ids }) => {
    return {
      ...state,
      unreadNotifications:
        state.unreadNotifications >= ids.length
          ? state.unreadNotifications - ids.length
          : 0,
      notifications: state.notifications.map((notification) =>
        ids.find((id) => id === notification.id)
          ? { ...notification, isViewed: true }
          : notification,
      ),
    };
  }),
  on(resetNotificationsState, (state) => {
    return {
      ...initialNotificationsState,
    };
  }),
  // NOTIFICATION SETTINGS
  on(getNotificationSettings, (state) => {
    return {
      ...state,
      notificationSettingsLoading: true,
    };
  }),
  on(getNotificationSettingsSuccess, (state, { settings }) => {
    return {
      ...state,
      notificationSettings: settings,
      notificationSettingsLoading: false,
    };
  }),
  on(getNotificationSettingsFailure, (state, { error }) => {
    return {
      ...state,
      error,
      notificationSettingsLoading: false,
    };
  }),
  ///
  on(editNotificationSettings, (state, { input }) => {
    return {
      ...state,
      notificationSettingsLoading: true,
    };
  }),
  on(editNotificationSettingsSuccess, (state, { settings }) => {
    return {
      ...state,
      notificationSettings: settings,
      notificationSettingsLoading: false,
    };
  }),
  on(editNotificationSettingsFailure, (state, { error }) => {
    return {
      ...state,
      notificationSettingsLoading: false,
      error,
    };
  }),
  on(clearAllNotifications, (state) => {
    return {
      ...state,
      notifications: [],
      unreadNotifications: 0,
    };
  }),
);

export function NotificationsReducer(
  state: NotificationsState | undefined,
  action: Action,
): NotificationsState {
  return notificationsReducer(state, action);
}
