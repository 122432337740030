/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 2/25/21, 4:29 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';
import { AUTHOR } from '../variables';

export const GET_PROFILES = gql`
  query($limit: Int, $skip: Int) {
    profiles(limit: $limit, skip: $skip) {
      items {
        ${AUTHOR}
        city
        state
      }
      limit
      skip
      total
    }
  }
`;
