import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  styles: ['.icon { width: 100 %; height: 100%}'],
  templateUrl: './app-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppIconComponent {
  @Input() width = '24';
  @Input() height = '24';
  @Input() href!: string;
}
