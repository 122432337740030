import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '@common/store';

export const getNotificationsState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.notificationsState,
);
export const getNotifications = createSelector(
  getNotificationsState,
  (state) => state.notifications,
);

export const getSkipNotificationsAmount = createSelector(
  getNotificationsState,
  ({ skip }) => skip,
);

export const getUnreadOnly = createSelector(
  getNotificationsState,
  ({ unreadOnly }) => unreadOnly,
);

export const getLimitNotificationsAmount = createSelector(
  getNotificationsState,
  ({ limit }) => limit,
);

export const getTotalNotifications = createSelector(
  getNotificationsState,
  (state) => state.total || null,
);

export const getUnreadNotifications = createSelector(
  getNotificationsState,
  (state) => state.unreadNotifications,
);

export const isNotificationsOpened = createSelector(
  getNotificationsState,
  ({ opened }) => opened,
);

export const isNotificationsLoading = createSelector(
  getNotificationsState,
  ({ loading }) => loading,
);

export const getUnreadNotificationsList = createSelector(
  getNotificationsState,
  (state) => state.notifications,
);

export const getNotificationsViewModel = createSelector(
  getNotifications,
  getUnreadNotificationsList,
  (notifications, unreadNotifications) => ({
    notifications,
    unreadNotifications,
  }),
);

export const getNotificationsSettings = createSelector(
  getNotificationsState,
  ({ notificationSettings }) => notificationSettings,
);

export const getNotificationSettingsError = createSelector(
  getNotificationsState,
  ({ errors }) => errors,
);

export const getNotificationSettingsLoading = createSelector(
  getNotificationsState,
  ({ notificationSettingsLoading }) => notificationSettingsLoading,
);
