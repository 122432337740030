import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { isMobile } from '@common/helpers';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MobileUiService {
  private renderer: Renderer2;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly rendererFactory: RendererFactory2,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  hideScrollInOpenMenu(isPopupOpen: boolean): void {
    const bodyTag = this.document.body;
    const platform3DSourceComTag = this.document.querySelector(
      '.platform_3dsource_com',
    ) as HTMLElement;

    const overflowHidden = () => {
      this.renderer.setStyle(bodyTag, 'overflow', 'hidden');
      if (platform3DSourceComTag) {
        this.renderer.setStyle(platform3DSourceComTag, 'overflow', 'hidden');
        this.renderer.setStyle(platform3DSourceComTag, 'height', '100%');
      }
    };

    const overflowUnset = () => {
      this.renderer.removeStyle(bodyTag, 'overflow');
      if (platform3DSourceComTag) {
        this.renderer.removeStyle(platform3DSourceComTag, 'overflow');
        this.renderer.removeStyle(platform3DSourceComTag, 'height');
      }
    };

    if (isPopupOpen && isMobile()) {
      overflowHidden();
    } else {
      overflowUnset();
    }
  }
}
