import {
  GQLComment,
  GQLCreateMarkerInput,
  GQLEditCommentInput,
  GQLEditCommentPayload,
  GQLFileMarkerStatusEnum,
  GQLMarker,
  GQLMarkerGroup,
  GQLRemoveInput,
  GQLRemovePayload,
  GQLUnresolveMarkerInput,
} from '../../../../schemas/schemas';
import { createAction, props } from '@ngrx/store';

import { IMarkerPositions } from '../../types/interfaces';

function scoped(templateString: TemplateStringsArray) {
  return `[Markers] ${templateString[0]}`;
}

export const addChatCoordinates = createAction(
  scoped`Add chat coordinates`,
  props<{ chatCoordinates: any }>(),
);

export const resetChatCoordinates = createAction(
  scoped`Reset chat coordinates`,
);
export const addActiveFileVersionComments = createAction(
  scoped`addActiveFileVersionComments`,
  props<{ comments: GQLComment[] }>(),
);

export const setMarkerId = createAction(
  scoped`Add chat config`,
  props<{ id: string }>(),
);

export const getMarkerGroup = createAction(
  scoped`Get Marker Group`,
  props<{ id: string }>(),
);

export const getMarkerGroupSuccess = createAction(
  scoped`Get Marker Group Success`,
);

export const getMarkerGroupFailure = createAction(
  scoped`Get Marker Group Failure`,
  props<{ error: unknown }>(),
);

export const getBackToStream = createAction(scoped`Get back to 3D stream `);

export const updateMarkerGroupImage = createAction(
  scoped`Update Marker Group Image`,
  props<{ imagePath: string; previewPath: string; id: string }>(),
);

export const loadMarkers = createAction(
  scoped`Load markers`,
  props<{
    versionId: string;
    markerStatus: GQLFileMarkerStatusEnum;
    markersGroupId: string | null;
  }>(),
);

export const loadMarkersSuccess = createAction(
  scoped`Load markers success`,
  props<{ markers: GQLMarker[]; markersGroups: GQLMarkerGroup[] }>(),
);

export const loadMarkersFailure = createAction(
  scoped`Load markers failure`,
  props<{ error: unknown }>(),
);

export const setMarkersFromGroup = createAction(
  scoped`Set Markers From Group`,
  props<{ markers: GQLMarker[] }>(),
);

// CREATE MARKER
export const createMarker = createAction(
  scoped`Create marker`,
  props<{ input: GQLCreateMarkerInput }>(),
);

export const createMarkerSuccess = createAction(
  scoped`Create marker success`,
  props<{ marker: GQLMarker; groupId?: string | undefined }>(),
);
export const createMarkerFailure = createAction(
  scoped`Create marker failure`,
  props<{ error: unknown }>(),
);

// CREATE MARKER`S COMMENT
export const createMarkerComment = createAction(
  scoped`Create marker comment`,
  props<{ description: string; groupId?: string }>(),
);

export const createMarkerCommentOrGroup = createAction(
  scoped`Create marker comment or group`,
  props<{
    description: string;
  }>(),
);

export const createMarkerGroup = createAction(
  scoped`Create Marker Group`,
  props<{ description?: string; imageSrc?: string }>(),
);

export const createMarkerGroupSuccess = createAction(
  scoped`Create Marker Group success`,
  props<{
    markerGroup: GQLMarkerGroup;
    description?: string;
  }>(),
);

export const createMarkerGroupFailure = createAction(
  scoped`Create Marker Group failure`,
  props<{ error: unknown }>(),
);

export const createMarkerCommentSuccess = createAction(
  scoped`Create marker comment success`,
  props<{
    comment: GQLComment;
    markerId: string;
    groupId?: string | undefined;
  }>(),
);

export const createMarkerCommentFailure = createAction(
  scoped`Create marker comment failure`,
  props<{ error: unknown }>(),
);

// EDIT MARKER`S COMMENT
export const editMarkerComment = createAction(
  scoped`Edit marker comment`,
  props<{ input: GQLEditCommentInput }>(),
);

export const editMarkerCommentSuccess = createAction(
  scoped`Edit marker comment success`,
  props<{ editComment: GQLEditCommentPayload }>(),
);

export const editMarkerCommentFailure = createAction(
  scoped`Edit marker comment failure`,
  props<{ error: unknown }>(),
);

// MARK MARKER AS VIEWED
export const markMarkerAsViewed = createAction(scoped`Mark marker as viewed`);

export const markMarkerAsViewedSuccess = createAction(
  scoped`Mark marker as viewed success`,
  props<{ markerId: string }>(),
);

export const markMarkerAsViewedFailure = createAction(
  scoped`Mark marker as viewed failure`,
  props<{ error: unknown }>(),
);

// DELETE MARKER`S COMMENT
export const removeMarkerComment = createAction(
  scoped`Delete marker comment`,
  props<{ input: GQLRemoveInput }>(),
);

export const removeMarkerCommentSuccess = createAction(
  scoped`Delete marker comment success`,
  props<{ removeComment: GQLRemovePayload }>(),
);

export const removeMarkerCommentFailure = createAction(
  scoped`Delete marker comment failure`,
  props<{ error: unknown }>(),
);

// REMOVE MARKER
export const deleteMarker = createAction(
  scoped`Delete marker`,
  props<{ input: GQLRemoveInput }>(),
);

export const deleteMarkerSuccess = createAction(
  scoped`Delete marker success`,
  props<{ removeMarker: GQLRemovePayload }>(),
);

export const deleteMarkerFailure = createAction(
  scoped`Delete marker comment failure`,
  props<{ error: unknown }>(),
);

// ADD CUSTOM MARKER
export const addCustomMarker = createAction(
  scoped`addCustomMarker`,
  props<{ marker: any }>(),
);

export const addMarkerGroupToArray = createAction(
  scoped`Add Marker Group to Groups Array`,
  props<{ markerGroup: GQLMarkerGroup }>(),
);

// ADD ACTIVE MARKER
export const addActiveMarker = createAction(
  scoped`addActiveMarker`,
  props<{ marker: any }>(),
);

export const changeMarkersPosition = createAction(
  scoped`Change Markers Position`,
  props<{ markers: IMarkerPositions[] }>(),
);

export const setMarkerIdForOpeningFromSide = createAction(
  scoped`setMarkerIdForOpeningFromSide`,
  props<{ markerId: string }>(),
);

export const toggleResolveMarker = createAction(scoped`toogle resolve marker`);

// RESOLVE MARKER
export const resolveMarkerSuccess = createAction(
  scoped`Resolve marker success`,
  props<{ id: string; markersStatus: GQLFileMarkerStatusEnum }>(),
);

export const resolveMarkerFailure = createAction(
  scoped`Resolve marker failure`,
  props<{ error: unknown }>(),
);

// UNRESOLVE MARKER
export const unresolveMarker = createAction(
  scoped`Unresolve marker`,
  props<{ input: GQLUnresolveMarkerInput }>(),
);

export const unresolveMarkerSuccess = createAction(
  scoped`Unresolve marker success`,
  props<{ id: string; markersStatus: GQLFileMarkerStatusEnum }>(),
);

export const unresolveMarkerFailure = createAction(
  scoped`Unresolve marker failure`,
  props<{ error: unknown }>(),
);

export const nextMarker = createAction(scoped`Open Next Marker`);

export const prevMarker = createAction(scoped`Open Previous Marker`);

export const resetMarkersState = createAction(scoped`Reset Markers State`);
export const resetMarkers = createAction(scoped`Reset Markers`);
