import { Actions, ofType } from '@ngrx/effects';
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-loop-func */
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  EFileExtension,
  SHORTENER_LENGTH,
  getFileName,
  imageExtensions,
} from '@common/helpers';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import {
  GQLFile,
  GQLFileStatusEnum,
  GQLFileTypeEnum,
  GQLFileVersion,
  GQLProfile,
  GQLProject,
  GQLRolesEnum,
} from '@schemas';
import {
  GeneralFileTypePipe,
  IFileTypeEnum,
} from '@common/pipes/general-file-type-pipe/general-file-type.pipe';
import {
  createMessageSuccess,
  editMessageSuccess,
} from '@common/store/messages/messages.actions';

import { DomSanitizer } from '@angular/platform-browser';
import { EImagesErrors } from '@common/types/FormsErrors';
import { FileTypePipe } from '@common/pipes/file-tipes-pipe/file-tipes.pipe';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UnrealEmbedService } from '../../../../../commenting-tool/services/unreal-embed.service';
import { getCurrentUser } from '@common/store/users/users.selectors';
import { map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { v4 as uuidv4 } from 'uuid';

/**
 *  isNewFile - property for disable collaboration tool toggle. Only new files can add to tools
 *  file - property for upload file data in API
 */
interface IAdditionalFileData {
  isNewFile?: boolean;
  file?: unknown;
  path?: string;
}

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnInit, AfterViewInit {
  @Input() inputFiles: Array<GQLFile & IAdditionalFileData> = [];
  @Input() multiple = true;
  @Input() hasUseTools = true;
  @Input() isImage = false;
  @Input() fileType: GQLFileTypeEnum | null = GQLFileTypeEnum.IMAGE;

  @Output() filesAttached: EventEmitter<any> = new EventEmitter();
  @Output() filesIdsForDeletingOutput: EventEmitter<any> = new EventEmitter();
  @Output() isSingleFileError: EventEmitter<any> = new EventEmitter();

  readonly isClient$: Observable<boolean> = this.store
    .select(getCurrentUser)
    .pipe(
      map((user) => {
        return (
          user?.role === GQLRolesEnum.ROLE_CLIENT ||
          user?.role === GQLRolesEnum.ROLE_SUPER_CLIENT
        );
      }),
    );

  files: NgxFileDropEntry[] = [];
  attachments: Array<
    GQLFile & IAdditionalFileData & { type: GQLFileTypeEnum }
  > = [];
  isUploadError = false;
  filesAllowed = true;
  isLoading = false;
  embedImages: string[] = [];

  readonly fileTypeEnum = GQLFileTypeEnum;
  readonly fileExtension = EFileExtension;
  readonly logoErrors = EImagesErrors;
  readonly imageExtensions = imageExtensions
    .map((extension) => `.${extension}`)
    .join(',');

  private filesIdsForDeleting: string[] = [];

  get extensions(): string {
    return this.fileType
      ? this.generalFileTypePipe.fileTypes[
          this.fileType as IFileTypeEnum
        ].extension
          .map((extension) => `.${extension}`)
          .join(',')
      : '';
  }

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly cd: ChangeDetectorRef,
    private readonly updates$: Actions,
    private readonly fileTypePipe: FileTypePipe,
    private readonly generalFileTypePipe: GeneralFileTypePipe,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly unrealEmbedService: UnrealEmbedService,
  ) {}

  ngOnInit() {
    this.updates$
      .pipe(
        ofType(createMessageSuccess, editMessageSuccess),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.inputFiles = [];
        this.attachments = [];
        this.files = [];
        this.cd.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    const embedFiles = this.inputFiles.filter(
      (file) => file.type === GQLFileTypeEnum.EMBED,
    );

    embedFiles.forEach((file) => {
      this.unrealEmbedService
        .fetchFile(file.path || '')
        .subscribe((embedFile) => {
          this.embedImages.push(JSON.parse(embedFile).image);
          this.cd.markForCheck();
        });
    });
  }

  dropped(filesArray: NgxFileDropEntry[], isClient: boolean | null): void {
    let files: NgxFileDropEntry[] = [];
    if (!this.multiple && this.attachments.length > 0) {
      this.isUploadError = true;
      return;
    }

    if (!this.multiple && filesArray.length > 1) {
      files = filesArray.slice(0, 1);
      this.isUploadError = true;
    } else {
      files = filesArray;
    }

    this.files = files;
    for (const droppedFile of files) {
      if (
        droppedFile.fileEntry.isFile
          ? this.isFileAllowed(droppedFile.fileEntry.name)
          : true
      ) {
        this.isLoading = true;
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          const reader = new FileReader();
          const checkFileType = this.fileTypePipe.transform(file.name);
          const isImageFile = checkFileType === this.fileTypeEnum.IMAGE;

          reader.readAsDataURL(file); // read file as data url

          reader.onload = (event) => {
            if (
              this.generalFileTypePipe.transform(file.name) ===
              GQLFileTypeEnum.EMBED
            ) {
              const embedFileJson = JSON.parse(
                atob(
                  ((event.target as FileReader)?.result as string).split(
                    ',',
                  )[1],
                ),
              );
              this.embedImages.push(embedFileJson.image);
            }

            const url = (event.target as FileReader).result as string;
            this.attachments.push({
              id: uuidv4(),
              hash: '',
              name: file.name,
              type: this.generalFileTypePipe.transform(file.name),
              updatedAt: '',
              createdAt: '',
              status: GQLFileStatusEnum.ACTIVE,
              isNewFile: true,
              hasNewVersion: false,
              hasNewComment: false,
              hasNewApprove: false,
              commentCount: 0,
              canComment: true,
              canAddVersion: true,
              author: {} as GQLProfile,
              versions: [
                {
                  previewPath: isImageFile
                    ? this.sanitizer.bypassSecurityTrustResourceUrl(url)
                    : null,
                } as GQLFileVersion,
              ],
              file,
              useTools: true,
              canRemove: true,
              project: {} as GQLProject,
            } as GQLFile & { type: GQLFileTypeEnum });
            this.cd.detectChanges();
            this.filesAttached.emit(this.attachments);
            this.isLoading = false;
          };
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
      }
    }
    this.filesAttached.emit(this.attachments);
  }

  cancelUploadFiles(id: string): void {
    this.isUploadError = false;
    if (this.attachments.find((el: any) => el.id === id)) {
      this.attachments = this.attachments?.filter(
        (file: any) => file.id !== id,
      );
      this.filesAttached.emit(this.attachments);
    } else {
      this.deleteAlreadyUploadedFiles(id);
    }
  }

  deleteAlreadyUploadedFiles(id: string): void {
    this.inputFiles = this.inputFiles?.filter((file) => file.id !== id);
    this.filesIdsForDeleting.push(id);
    this.filesIdsForDeletingOutput.emit(this.filesIdsForDeleting);
  }

  tooltipText(title: string, length = SHORTENER_LENGTH): string {
    return title.length > length ? title : '';
  }

  fileName(file: GQLFile | (GQLFile & IAdditionalFileData)): string {
    return getFileName(file);
  }

  is3dPak(file: GQLFile): boolean {
    return file?.extension === this.fileExtension.PAK;
  }

  hasPakPreview(file: GQLFile): boolean {
    const is3dFile = this.is3dPak(file);

    if (file?.versions && is3dFile) {
      return !!file?.versions[0].previewPath;
    }
    return false;
  }

  private isFileAllowed(fileName: string) {
    if (!this.fileType) {
      return true;
    }
    let isFileAllowedFlag = false;
    const allowedFiles = this.extensions.split(',');
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);

    if (undefined !== extension && extension !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const ext of allowedFiles) {
        if (ext === extension[0].toLowerCase()) {
          isFileAllowedFlag = true;
        }
      }
    }
    this.filesAllowed = isFileAllowedFlag;
    return isFileAllowedFlag;
  }
}
