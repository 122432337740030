<div class="uploader" [class.loading]="isLoading">
  <ng-container *ngIf="{ isClient: (isClient$ | async) } as user">
    <ngx-file-drop
      dropZoneLabel="Drop files here"
      [accept]="extensions"
      [contentClassName]="'drag-input'"
      [multiple]="multiple"
      (onFileDrop)="dropped($event, user.isClient)"
    >
      <ng-template
        ngx-file-drop-content-tmp
        let-openFileSelector="openFileSelector"
      >
        <div
          class="uploader-box"
          (click)="openFileSelector(); $event.stopPropagation()"
        >
          <div class="uploader-box__label">
            <svg class="icon" width="24" height="24">
              <use xlink:href="#icon-attach"></use>
            </svg>
            <span>To attach files drag & drop here or </span>
            <strong class="desktop-message"
              >select files from your computer</strong
            >
            <strong class="mobile-message"
              >Select files from your gallery</strong
            >
          </div>

          <!-- FILES -->
          <div class="uploaded-grid">
            <div
              class="uploaded-grid__item"
              *ngFor="let file of inputFiles.concat(attachments); let i = index"
            >
              <!-- IMAGE ONLY -->
              <div
                *ngIf="
                  (fileName(file) | fileType) == fileTypeEnum.IMAGE ||
                  (fileName(file) | fileType) == fileTypeEnum.VIDEO ||
                  (fileName(file) | fileType) == fileTypeEnum.EMBED
                "
                class="uploader-box__uploaded uploaded-file"
              >
                <div class="uploaded-file__image">
                  <img
                    *ngIf="(fileName(file) | fileType) === fileTypeEnum.VIDEO"
                    [src]="file | getLatestFileVersionPreview"
                    alt="Uploaded file"
                  />
                  <img
                    *ngIf="(fileName(file) | fileType) === fileTypeEnum.IMAGE"
                    [src]="file | getLatestFileVersionPreview"
                    alt="Uploaded file"
                  />
                  <img
                    *ngIf="(fileName(file) | fileType) === fileTypeEnum.EMBED"
                    [src]="embedImages[i]"
                    alt="Uploaded file"
                  />
                  <button
                    (click)="
                      cancelUploadFiles(file.id); $event.stopPropagation()
                    "
                    type="button"
                    class="uploaded-file__delete"
                  >
                    <svg class="icon" width="24" height="24">
                      <use xlink:href="#icon-close"></use>
                    </svg>
                  </button>
                </div>
                <span
                  class="uploaded-file__class-name"
                  tooltipZIndex="99999999"
                  [pTooltip]="tooltipText(fileName(file))"
                >
                  {{ fileName(file) | shortener }}
                </span>
              </div>
              <!-- END IMAGE ONLY -->
              <!-- OTHER FILES TYPES -->
              <div
                class="
                  uploader-box__uploaded
                  uploaded-file uploaded-file--other
                "
                *ngIf="
                  (fileName(file) | fileType) !== fileTypeEnum.IMAGE &&
                  (fileName(file) | fileType) !== fileTypeEnum.VIDEO &&
                  (fileName(file) | fileType) !== fileTypeEnum.EMBED
                "
              >
                <div class="uploaded-file__image">
                  <ng-container *ngIf="!is3dPak(file) || !hasPakPreview(file)">
                    <img
                      [src]="fileName(file) | fileType: true"
                      alt="Uploaded file"
                    />
                  </ng-container>
                  <ng-container *ngIf="is3dPak(file) && hasPakPreview(file)">
                    <img
                      [src]="file | getLatestFileVersionPreview"
                      alt="Uploaded file"
                    />
                  </ng-container>

                  <button
                    (click)="
                      cancelUploadFiles(file.id); $event.stopPropagation()
                    "
                    type="button"
                    class="uploaded-file__delete"
                  >
                    <svg class="icon" width="24" height="24">
                      <use xlink:href="#icon-close"></use>
                    </svg>
                  </button>
                </div>
                <span
                  class="uploaded-file__class-name"
                  tooltipZIndex="99999999"
                  [pTooltip]="tooltipText(fileName(file))"
                >
                  {{ fileName(file) | shortener }}
                </span>
              </div>
              <!-- END OTHER FILES TYPES -->
            </div>
          </div>
        </div>
      </ng-template>
    </ngx-file-drop>
  </ng-container>
  <div class="uploader-error-message" *ngIf="isUploadError">
    Sorry, you can add only one version
  </div>
  <div [hidden]="filesAllowed" class="form__hint invalid">
    {{ logoErrors.WRONG_LOGO_FORMAT }}
  </div>
</div>
