/* eslint-disable @typescript-eslint/no-unused-expressions */
/*
  required image viewer input data:

  const dialogConfig = {
    data: {
      fileList$: this.projectFilesListArray$ // Observable<GQLFile[]>
      activeFile: file, // GQLFile[]
      isPrevNextArrowsNeed: true, // boolean
    },
      styleClass: 'gallery',
      width: '100%',
      height: '100%',
  };

  this.dialogService.open(FullscreenGalleryComponent, dialogConfig); // DI modal service

 */

import { DOCUMENT, Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  GQLDateTime,
  GQLFile,
  GQLFileTypeEnum,
  GQLFileVersion,
} from '../../../../../../schemas/schemas';
import { FileTypePipe } from '@common/pipes/file-tipes-pipe/file-tipes.pipe';
import { isMobile, SHORTENER_LENGTH, getFileName } from '@common/helpers';
import {
  markVersionAsViewed,
  tryRemoveFile,
} from '@common/store/files/files.actions';

enum EPdfEventListenersTypes {
  ADD,
  REMOVE,
}

@Component({
  selector: 'app-fullscreen-gallery',
  templateUrl: './fullscreen-gallery.component.html',
  styleUrls: ['./fullscreen-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenGalleryComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  readonly fileList$: Observable<GQLFile[]> = this.config.data.fileList$.pipe(
    map((files: GQLFile[]) => files.filter(({ useTools }) => !useTools)),
    tap((fileList: GQLFile[]) => {
      this.activeFileIndex = fileList.findIndex(
        (file: GQLFile) => file.id === this.activeFile.id,
      );
      this.fileListLength = fileList.length;
    }),
  );
  // Juuu: Mock number of comments and approvement state
  commentsLength = 0;
  isApproved = true;
  // end
  activeFile = this.config.data.activeFile;
  isPrevNextArrowsNeed = this.config.data.isPrevNextArrowsNeed;

  activeFileIndex = 0;
  fileListLength = 0;
  isFullscreen = false;
  isSidebarOpen = false;
  isMobileMenuOpen = false;
  imageLoading = false;
  readonly fileType = GQLFileTypeEnum;
  zoomedStep = 'zero';
  pdfZoomScale: string | number = 100;
  pdfZoomScaleAmount = 100;
  minPdfZoom = 1;
  maxPdfZoom = 3;

  private pdfZoomStep = 10;
  private checkUpdates!: any;

  private pdfPagesList!: Element[];
  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('myPinch') myPinch: any;

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.closeImageViewer();
    }
  }

  constructor(
    readonly config: DynamicDialogConfig,
    readonly ref: DynamicDialogRef,
    @Inject(DOCUMENT) private readonly documentRef: Document,
    private readonly fileTypePipe: FileTypePipe,
    private readonly cd: ChangeDetectorRef,
    private readonly store: Store,
    private readonly router: Router,
    private readonly location: Location,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.imageLoading = true;
    this.setPdfConfigInMobile();
    this.setQueryParams(this.activeFile.id);
  }

  private setQueryParams(openedFile: string): void {
    // this.router.navigate([], {
    //   relativeTo: this.route,
    //   queryParams: {
    //     ...this.route.snapshot.queryParams,
    //     openedFile,
    //   },
    //   queryParamsHandling: 'merge',
    // });
  }

  private removeQueryParams(): void {
    if (!this.router.url.includes('tools')) {
      const url = this.router
        .createUrlTree([], {
          relativeTo: this.route,
          queryParams: {
            ...this.route.snapshot.queryParams,
            openedFile: null,
          },
        })
        .toString();
      this.location.go(url);
    }
  }

  ngAfterViewInit(): void {
    this.store.dispatch(
      markVersionAsViewed({ input: { ids: [this.firstVersion.id] } }),
    );
    this.pdfViewerClickHandler();
  }

  goToTools() {
    // this.fileNavigateService.isNavigateToTools(this.activeFile).then((resp) => {
    //   this.ref.close();
    // });
  }

  tryCloseGallery(event: any) {
    if (!event.target.classList.contains('class_for_close_gallery')) {
      this.closeImageViewer();
    }
  }

  setZoom(value: number) {
    this.myPinch.setZoom({ scale: value });
  }

  getZoomValue(value: number | null) {
    if (!value) {
      return 100;
    }
    if (value > 1 && value < 1.2) {
      this.zoomedStep = 'first';
    } else if (value >= 1.2 && value < 1.4) {
      this.zoomedStep = 'second';
    } else if (value >= 1.4) {
      this.zoomedStep = 'third';
    }
    const scale: number = Math.round(value * 100);
    return scale;
  }

  get canRemove(): boolean {
    return this.activeFile.canRemove;
  }

  get isImage(): boolean {
    const checkFileType = this.fileTypePipe.transform(this.activeFile.name);
    return checkFileType === this.fileType.IMAGE;
  }

  get getScale(): number {
    const scale: number = Math.round(this.myPinch?.scale * 100) || 100;
    return scale;
  }

  get threadName(): string {
    const { thread } = this.activeFile;
    if (thread === null) {
      return 'Other files';
    }
    return thread?.title || '';
  }

  get projectName(): string {
    const { project } = this.activeFile;
    return project?.title || '';
  }

  get isFileInUseTools(): boolean {
    return this.activeFile.useTools;
  }

  imageLoaded() {
    this.imageLoading = false;
  }

  zoomIn(): void {
    this.myPinch.zoomIn();
  }

  zoomOut(): void {
    this.myPinch.zoomOut();
  }

  get firstVersion(): GQLFileVersion {
    return this.activeFile.versions[0];
  }

  // PDF files
  pdfZoomIn(): void {
    const maxScale = 290;
    if (this.pdfZoomScaleAmount <= maxScale) {
      this.pdfZoomScaleAmount += this.pdfZoomStep;
    }
    this.pdfZoomScale = `${this.pdfZoomScaleAmount}`;
  }

  pdfZoomOut() {
    const minScale = 110;
    if (this.pdfZoomScaleAmount >= minScale) {
      this.pdfZoomScaleAmount -= this.pdfZoomStep;
    }
    this.pdfZoomScale = `${this.pdfZoomScaleAmount}`;
  }

  get pdfZoomScaleValue(): number {
    return Number(this.pdfZoomScale) || 100;
  }

  setPdfZoomScale(zoomScale: number): void {
    const naturalSize = 100;
    this.pdfZoomScale = Math.round(zoomScale * naturalSize) || naturalSize;
  }

  get isPdfFile(): boolean {
    const fileExtension = 'pdf';
    return this.firstVersion?.extension?.toLowerCase() === fileExtension;
  }

  pdfClickHandler(event: Event) {
    event.stopPropagation();
  }

  get isOtherFile(): boolean {
    return !this.isImage && !this.isPdfFile;
  }

  toggleFullscreen(): void {
    const naturalSize = '100';
    this.isSidebarOpen = false;
    this.isFullscreen = !this.isFullscreen;
    if (this.isPdfFile && this.isFullscreen) {
      this.pdfZoomScale = 'page-width';
      this.cd.detectChanges();
    } else {
      this.pdfZoomScale = naturalSize;
    }
  }

  toggleSidebar(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

  toggleMobileMenu(): void {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  closeImageViewer(): void {
    this.ref.close();
  }

  prevFile(event: Event, fileList: GQLFile[]): void {
    event.stopPropagation();
    this.setZoom(1);
    this.activeFile = fileList[this.activeFileIndex - 1];
    this.activeFileIndex -= 1;
    this.imageLoading = true;
    this.setQueryParams(this.activeFile.id);
  }

  nextFile(event: Event, fileList: GQLFile[]): void {
    event.stopPropagation();
    this.setZoom(1);
    this.activeFile = fileList[this.activeFileIndex + 1];
    this.activeFileIndex += 1;
    this.imageLoading = true;
    this.setQueryParams(this.activeFile.id);
  }

  get disablePrevButton(): boolean {
    return this.activeFileIndex === 0;
  }

  get disableNextButton(): boolean {
    return this.activeFileIndex === this.fileListLength - 1;
  }

  downloadFile(file: GQLFile): void {
    fetch(file.versions?.[0].path as RequestInfo).then((t) => {
      return t.blob().then((b) => {
        const a = this.documentRef.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', file.name);
        a.click();
      });
    });
  }

  deleteFile(file: GQLFile): void {
    const fileName: string = getFileName(file);
    this.store.dispatch(tryRemoveFile({ id: file.id, fileName }));
  }

  get fileName(): string {
    return getFileName(this.activeFile);
  }

  get fileSize(): number | undefined {
    return this.activeFile.versions[0].size;
  }

  get fileAuthor(): string | undefined {
    return this.activeFile.versions[0].author?.fullName;
  }

  get fileCreatedAt(): GQLDateTime {
    return this.activeFile.versions[0].createdAt;
  }

  tooltipText(title: string, length = SHORTENER_LENGTH): string {
    return title.length > length ? title : '';
  }

  private handlePdfEventListeners(type: EPdfEventListenersTypes): void {
    if (type === EPdfEventListenersTypes.ADD) {
      this.pdfPagesList.forEach((page: Element) =>
        page.addEventListener('click', this.stopClickOnPdf),
      );
    } else {
      this.pdfPagesList.forEach((page: Element) =>
        page.removeEventListener('click', this.stopClickOnPdf),
      );
    }
  }

  private pdfViewerClickHandler(): void {
    // access to th e ngx-extended-pdf-viewer component
    const pdfBackground = document.getElementById('viewerContainer');
    const pdfDocumentPages = document.getElementsByClassName('page');
    // this library does not have a common container for all pages

    this.checkUpdates = setInterval(() => {
      if (pdfDocumentPages.length) {
        const close = (event: Event) => {
          // close if clock on pdf document
          event.stopPropagation();
          this.closeImageViewer();
        };

        this.pdfPagesList = Array.from(pdfDocumentPages);
        this.handlePdfEventListeners(EPdfEventListenersTypes.ADD);

        pdfBackground?.addEventListener('click', close, false);
        clearInterval(this.checkUpdates);
      }
    }, 100);
  }

  private stopClickOnPdf = (event: Event) => {
    // do not close if click on pdf document
    event.stopPropagation();
    event.preventDefault();
  };

  private setPdfConfigInMobile(): void {
    if (isMobile()) {
      this.pdfZoomScale = 'page-width';
      this.minPdfZoom = 0.45;
    }
  }

  ngOnDestroy(): void {
    this.myPinch.destroy();
    if (this.checkUpdates) {
      clearInterval(this.checkUpdates);
    }
    this.handlePdfEventListeners(EPdfEventListenersTypes.REMOVE);
    this.removeQueryParams();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
