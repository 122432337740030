import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { MessagesReducer, MessagesState } from './messages/messages.reducer';
import { ProjectReducer, ProjectsState } from './projects/projects.reducer';
import { ThreadReducer, ThreadsState } from './threads/threads.reducer';
import { FilesReducer, FilesState } from './files/files.reducer';
import { UsersReducer, UsersState } from './users/users.reducer';
import { MarkersReducer, MarkersState } from './markers/markers.reducer';
import {
  CommentingToolReducer,
  CommentingToolState,
} from '../../commenting-tool/store/commenting-tools.reducer';
import {
  NotificationsReducer,
  NotificationsState,
} from '../../notifications/store/notifications.reducer';

export interface PlatformState {
  projectState: ProjectsState;
  threadState: ThreadsState;
  messagesState: MessagesState;
  filesState: FilesState;
  usersState: UsersState;
  markersState: MarkersState;
  commentingToolState: CommentingToolState;
  notificationsState: NotificationsState;
}

export const reducers: ActionReducerMap<PlatformState> = {
  projectState: ProjectReducer,
  threadState: ThreadReducer,
  messagesState: MessagesReducer,
  filesState: FilesReducer,
  usersState: UsersReducer,
  markersState: MarkersReducer,
  commentingToolState: CommentingToolReducer,
  notificationsState: NotificationsReducer,
};

export const getPlatformState =
  createFeatureSelector<PlatformState>('platform-state');
