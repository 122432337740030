import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ToastrModule } from 'ngx-toastr';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AvatarModule } from 'ngx-avatars';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Prime NG modules and UI components - will be moved
import { AuthGuardService } from '@common/guards/auth-guard/auth-guard.guard';
import { LayoutModule } from './layout/layout.module';
import { SvgIconsComponent } from '@common/svg-icons/svg-icons.component';
import { reducers } from '@common/store';
import { ProjectsEffects } from '@common/store/projects/projects.effects';
import { ThreadsEffects } from '@common/store/threads/threads.effects';
import { MessagesEffects } from '@common/store/messages/messages.effects';
import { FilesEffects } from '@common/store/files/files.effects';
import { ProjectGuardService } from '@common/guards/project-guard/project-guard';
import { UsersEffects } from '@common/store/users/users.effects';
import { MarkersEffects } from '@common/store/markers/markers.effects';
import { NotificationsEffects } from './notifications/store/notifications.effects';
import { ToolNavigationService } from './commenting-tool/services/tool-navigation.service';
import { ErrorsApiInterceptor } from '@common/guards/errors-api.interceptor';
import { GlobalErrorHandler } from '@common/utils/global-error-handler';
import { CommentingToolsEffects } from './commenting-tool/store/commenting-tools.effects';
import { GraphQLModule } from '@common/graphQL/graphql.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [AppComponent, SvgIconsComponent],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    LayoutModule,
    ButtonModule,
    GraphQLModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      progressBar: true,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      progressAnimation: 'increasing',
    }),
    StoreModule.forRoot({}),
    StoreModule.forFeature('platform-state', reducers),
    StoreDevtoolsModule.instrument({
      maxAge: 100, // Retains last 100 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([
      ProjectsEffects,
      ThreadsEffects,
      MessagesEffects,
      FilesEffects,
      UsersEffects,
      MarkersEffects,
      NotificationsEffects,
      CommentingToolsEffects,
    ]),
    AvatarModule.forRoot({
      colors: [
        '#fce4ec',
        '#ede7f6',
        '#e3f2fd',
        '#e0f2f1',
        '#e8f5e9',
        '#fff3e0',
        '#fbe9e7',
      ],
    }),
  ],
  providers: [
    MessageService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    AuthGuardService,
    ProjectGuardService,
    ToolNavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorsApiInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
