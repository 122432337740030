import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiSelectModule } from 'primeng/multiselect';
import { FilesFilterComponent } from './components/files-filter/files-filter.component';
import { AppIconModule } from '../icon';
import { SearchModule } from '@common/modules/search/search.module';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [FilesFilterComponent],
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    MultiSelectModule,
    FormsModule,
    AppIconModule,
    SearchModule,
    CalendarModule,
  ],
  exports: [FilesFilterComponent],
})
export class FilesFilterModule {}
