<div class="service-modal__content">
  {{ warningText }}
</div>

<div
  class="service-modal__footer"
  [ngClass]="
    isCloseButtonVisible
      ? 'service-modal__footer--right'
      : 'service-modal__footer--center'
  "
>
  <button
    *ngIf="isCloseButtonVisible"
    (click)="close()"
    class="button button--clear"
  >
    Cancel
  </button>
  <button (click)="confirm()" class="button" [ngClass]="typeStyles">
    {{ btnText }}
  </button>
</div>
