import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarModule } from 'ngx-avatars';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TooltipModule } from 'primeng/tooltip';
import { NotificationsModule } from '../notifications/notifications.module';
import { TreadsListModule } from '../treads-list/treads-list.module';
import { HeaderComponent } from './components/header/header.component';
import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './components/layout/layout.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AppIconModule } from '@common/modules/icon';
import { ThousandSuffixModule } from '@common/pipes/thousand-suffix/thousand-suffix.module';
import { RolesComponent } from './components/roles/roles.component';
import { SearchModule } from '@common/modules/search/search.module';
import { RolesShortenerPipeModule } from '@common/pipes/roles-shortener/roles-shortener.module';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  declarations: [
    LayoutComponent,
    HeaderComponent,
    NavigationComponent,
    UserProfileComponent,
    NotificationsComponent,
    RolesComponent,
  ],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    TreadsListModule,
    DynamicDialogModule,
    NotificationsModule,
    ScrollPanelModule,
    NgScrollbarModule,
    AvatarModule,
    TooltipModule,
    AppIconModule,
    ThousandSuffixModule,
    SearchModule,
    RolesShortenerPipeModule,
    OverlayPanelModule,
  ],
  exports: [LayoutComponent, HeaderComponent],
})
export class LayoutModule {}
