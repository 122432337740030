import { gql } from 'apollo-angular';

export const GET_THREAD_FILES_IDS = gql`
  query (
    $id: ID!
    $status: FileStatusEnum
    $unviewedOnly: Boolean
    $useTools: Boolean
  ) {
    threadFiles(
      id: $id
      status: $status
      unviewedOnly: $unviewedOnly
      useTools: $useTools
    ) {
      id
      type
      versions {
        id
      }
    }
  }
`;
