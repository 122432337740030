import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class ToolNavigationService {
  private history: string[] = [];

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const lastUrlInHistory = this.history[this.history.length - 1];
        const newUrlWithoutQueryParams = event.urlAfterRedirects.split('?')[0];
        if (lastUrlInHistory !== newUrlWithoutQueryParams) {
          this.history.push(newUrlWithoutQueryParams);
        }
      }
      // TODO use back logic from api
    });
  }

  back(): void {
    this.history.pop();
    if (this.history.length > 0) {
      const previousUrl = this.history[this.history.length - 1];
      this.router.navigate([previousUrl]);
    } else {
      this.router.navigateByUrl('#');
    }
  }
}
