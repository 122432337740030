import { Pipe, PipeTransform } from '@angular/core';
import { SHORTENER_LENGTH } from '../../helpers/constants/layouts.constants';

@Pipe({
  name: 'shortener',
})
export class ShortenerPipe implements PipeTransform {
  transform(
    fileName: string = '',
    stringLength: number = SHORTENER_LENGTH,
  ): string | undefined {
    const headLength = Math.floor(stringLength / 2);
    const tailLength = Math.floor(stringLength / 3);
    if (fileName.length > stringLength) {
      return `${fileName.slice(0, headLength)}...${fileName.slice(
        -tailLength,
      )}`;
    }
    return fileName ?? '';
  }
}
