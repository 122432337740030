import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from '@common/services/auth.service';
import { getCurrentUser } from '@common/store/users/users.selectors';
import { IUserPermissions } from '@common/types/IUserPermisions';
import { Router } from '@angular/router';
import { isMobile } from '@common/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  readonly isAuth$ = this.store.select(getCurrentUser);

  isMenuOpened = false;
  canCreateProject: null | boolean = false;
  private landscape: MediaQueryList = window.matchMedia(
    '(orientation: landscape)',
  );

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly authService: AuthService,
    private readonly store: Store,
    private readonly router: Router,
  ) {
    this.landscape.addEventListener('change', () => {
      this.hideScrollInOpenMenu();
    });
  }

  ngOnInit(): void {
    this.getPermissions();
  }

  openMenu(isNavigationDisabled: boolean): void {
    this.isMenuOpened = isNavigationDisabled;
    this.hideScrollInOpenMenu();
  }

  goToCreateProject(): void {
    this.router.navigate(['projects', 'create-project']).then(() => {
      this.openMenu(false);
    });
  }

  private getPermissions(): void {
    this.authService
      .getMyPermissions()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((permissions: IUserPermissions) => {
        this.canCreateProject = !!permissions?.project?.create;
      });
  }

  private hideScrollInOpenMenu() {
    /*
    need a css-class body.js-burger-menu-open {overflow: hidden}
    * */
    const bodyTag = document.body;
    if (this.isMenuOpened && isMobile()) {
      bodyTag.classList.add('js-burger-menu-open');
    } else {
      bodyTag.classList.remove('js-burger-menu-open');
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
