import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '../index';
import {
  GQLDateRange,
  GQLFile,
  GQLFileCollectionItem,
  GQLFileGroup,
  GQLFileStatusEnum,
  GQLFileTypeEnum,
  GQLFileVersion,
  GQLFileVersionStatusEnum,
  GQLMarkerGroup,
  GQLProject,
  GQLThread,
  GQLUnrealVersionEnum,
} from '@schemas';
import { IUIIteractionDescriptor } from '../../types';
import { FilesState } from './files.reducer';
import { EFileListLabel, isDefined, nextFileVersion } from '@common/helpers';

export const getFilesState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.filesState,
);

export const getFileLoading = createSelector(
  getFilesState,
  (state): boolean => state.loadFileLoading,
);

export const getAddFilesError = createSelector(
  getFilesState,
  (state): string => state.addFilesError,
);

export const getProjectFilesList = createSelector(
  getFilesState,
  (state: FilesState): GQLFileGroup[] => {
    return state.projectFilesList || [];
  },
);

export const getOnlyProjectOtherFileList = createSelector(
  getFilesState,
  (state: FilesState): GQLFileGroup[] => {
    return (
      state.projectFilesList.filter(
        (fileGroup) => fileGroup.title === EFileListLabel.Other,
      ) || []
    );
  },
);

export const getThreadFiles = createSelector(
  getFilesState,
  (state: FilesState): GQLFile[] => {
    return state.threadFilesList;
  },
);

export const getThreadFilesFilterRange = createSelector(
  getFilesState,
  (state: FilesState): GQLDateRange | null => {
    return state.filterDateRange;
  },
);

export const getProjectFiles = createSelector(
  getProjectFilesList,
  (filesList): GQLFile[] => {
    return filesList.flatMap(({ files }) => files);
  },
);

export const getThreadFilesLength = createSelector(
  getThreadFiles,
  (files): number => {
    return files.length;
  },
);

export const getProjectFilesLength = createSelector(
  getProjectFiles,
  (files): number => {
    return files.length;
  },
);

export const getProjectFilesOtherLength = createSelector(
  getProjectFiles,
  (files): number => {
    return files.length;
  },
);

export const getFilesListLoading = createSelector(
  getFilesState,
  (state: FilesState): boolean => {
    return state.isLoadingFiles;
  },
);

export const getFilesCollectionLoading = createSelector(
  getFilesState,
  (state: FilesState): boolean => {
    return state.isLoadingFileCollection;
  },
);

export const getThreadsFromFileList = createSelector(
  getFilesState,
  ({ threadFilesList }: FilesState): GQLThread[] => {
    return threadFilesList
      ? threadFilesList.map(({ thread }) => thread).filter(isDefined)
      : [];
  },
);

export const getFileListLoading = createSelector(
  getFilesState,
  (state: FilesState): boolean => {
    return state.addFilesLoading;
  },
);

export const getVersionCreateLoader = createSelector(
  getFilesState,
  (state: FilesState): boolean => {
    return state.versionCreateLoading;
  },
);

export const getCurrentFileId = createSelector(
  getFilesState,
  (state: FilesState) => {
    return state.currentFileId;
  },
);

export const getFile = createSelector(
  getFilesState,
  (state: FilesState): GQLFile | null => {
    return state.file;
  },
);

export const canAddVersionInFile = createSelector(
  getFile,
  (file: GQLFile | null): boolean => {
    return !!file?.canAddVersion;
  },
);

export const getFileName = createSelector(
  getFile,
  (state: GQLFile | null): string => {
    const fileName: string | undefined = state?.extension
      ? `${state?.name}.${state?.extension}`
      : state?.name;
    return fileName || '';
  },
);

export const getFilesProject = createSelector(
  getFilesState,
  (state: FilesState): string => {
    return state?.file?.project.title || '';
  },
);

export const getFilesThread = createSelector(
  getFilesState,
  (state: FilesState): string => {
    return state?.file?.thread?.title || '';
  },
);

export const hasProjectOtherFiles = createSelector(
  getFilesState,
  (state: FilesState): boolean => {
    return state.file?.thread === null;
  },
);

export const getFileStatus = createSelector(
  getFile,
  (file): GQLFileStatusEnum | null => {
    return file?.status || null;
  },
);

export const getFileType = createSelector(
  getFile,
  (file): GQLFileTypeEnum | null => {
    return file?.type || null;
  },
);

export const getFileShareHash = createSelector(getFile, (state) => {
  return state?.hash ?? '';
});

export const getFileVersions = createSelector(
  getFile,
  (file: GQLFile | null): GQLFileVersion[] => {
    return file?.versions || [];
  },
);

export const getFirstVersionName = createSelector(
  getFile,
  (file: GQLFile | null): string => {
    return (
      `${file?.versions?.[0].title}.${file?.versions?.[0].extension}` || ''
    );
  },
);

export const getCurrentFileVersion = createSelector(
  getFilesState,
  (state): GQLFileVersion | null => state.activeFileVersion,
);

// TODO: delete it after test
export const getSendLoadLevelV5 = createSelector(
  getFilesState,
  (state): boolean => state.file?.unrealVersion === GQLUnrealVersionEnum.V_5,
);

export const getFileVersionShareHash = createSelector(
  getFileShareHash,
  getCurrentFileVersion,
  (fileHash, fileVersion) => {
    return fileHash + (fileVersion?.id ? `?version=${fileVersion.id}` : '');
  },
);

export const getFileVersionLoading = createSelector(
  getFilesState,
  (state): boolean => state.activeFileVersionLoading,
);

export const getActiveMarkersGroup = createSelector(
  getFilesState,
  (state): GQLMarkerGroup | null => state.activeMarkersGroup,
);

export const isFileTypeStreamOrVideo = createSelector(
  getFilesState,
  (state): boolean =>
    state.file?.type === GQLFileTypeEnum.STREAM ||
    state.file?.type === GQLFileTypeEnum.VIDEO,
);

export const isFileTypeImage = createSelector(
  getFilesState,
  (state): boolean => state.file?.type === GQLFileTypeEnum.IMAGE,
);

export const isFileTypeImageOrModel = createSelector(
  getFilesState,
  (state): boolean =>
    state.file?.type === GQLFileTypeEnum.IMAGE ||
    state.file?.type === GQLFileTypeEnum.MODEL,
);

export const isFileTypeVideo = createSelector(
  getFilesState,
  (state): boolean => state.file?.type === GQLFileTypeEnum.VIDEO,
);

export const getCurrent3dScreenshot = createSelector(
  getFilesState,
  (state: FilesState): string => {
    return state.current3dScreenshot;
  },
);

export const getCurrentFileVersionId = createSelector(
  getCurrentFileVersion,
  (fileVersion): string | null => fileVersion?.id || null,
);

export const getCurrentFileVersionNumber = createSelector(
  getCurrentFileVersion,
  (fileVersion): number | null => fileVersion?.serialNumber || null,
);

export const getCurrentFileVersionPath = createSelector(
  getFilesState,
  (state): string | null => state.activeFileVersion?.path || null,
);

export const getCurrentFileVersionTitle = createSelector(
  getCurrentFileVersion,
  (activeFileVersion): string => activeFileVersion?.title ?? '',
);

export const isCurrentFileVersionApproved = createSelector(
  getFilesState,
  (state): boolean =>
    state.activeFileVersion?.status === GQLFileVersionStatusEnum.APPROVED,
);

export const getMarkerAutoincrementValue = createSelector(
  getFilesState,
  (state): number => state.markerAutoincrementValue,
);

export const getCurrentFileListStatus = createSelector(
  getFilesState,
  (state) => {
    return state.currentFileListStatus;
  },
);

export const getStreamUrl = createSelector(getFilesState, (state): string => {
  return state.file?.streamUrl || '';
});

export const getStreamDescriptor = createSelector(
  getCurrentFileVersion,
  (fileVersion): IUIIteractionDescriptor => {
    return fileVersion && fileVersion.streamConfig
      ? JSON.parse(fileVersion.streamConfig)
      : {};
  },
);

export const getNextFileVersion = createSelector(
  getFileVersions,
  getCurrentFileVersion,
  (versions, fileVersion): GQLFileVersion | null =>
    nextFileVersion(versions, fileVersion?.id),
);

export const getCommentingToolSidebarViewModel = createSelector(
  getCurrentFileVersionTitle,
  getCurrentFileVersion,
  getFileName,
  (currentFileVersionTitle, currentFileVersion, currentFileName) => {
    return {
      currentFileVersionTitle,
      currentFileVersion,
      currentFileName,
    };
  },
);

export const getCommentingToolMainContainerViewModel = createSelector(
  getCurrentFileVersionPath,
  getCurrentFileVersion,
  getFile,
  (currentFileVersionPath, currentFileVersion, currentFile) => {
    return {
      currentFileVersionPath,
      currentFileVersion,
      currentFile,
    };
  },
);

export const getFileCollection = createSelector(
  getFilesState,
  (state: FilesState) => {
    return state.fileCollection;
  },
);

export const getFileCollectionItems = createSelector(
  getFileCollection,
  (fileCollection) => {
    return fileCollection?.items || [];
  },
);

export const getProjectFilesListArray = createSelector(
  getProjectFilesList,
  (fileGroups: GQLFileGroup[]) => {
    return fileGroups.flatMap((fileGroup: GQLFileGroup) => fileGroup.files);
  },
);

export const getFileCollectionArray = createSelector(
  getFileCollection,
  (fileCollection) => {
    return (fileCollection?.items || [])
      .flatMap((items: GQLFileCollectionItem) => items.files)
      .flatMap((fileGroup: GQLFileGroup) => fileGroup.files);
  },
);

export const getProjectOtherFilesListArray = createSelector(
  getProjectFilesList,
  (fileGroups: GQLFileGroup[]) => {
    return fileGroups
      .filter(
        (fileGroup: GQLFileGroup) => fileGroup.title === EFileListLabel.Other,
      )
      .flatMap((fileGroup: GQLFileGroup) => fileGroup.files);
  },
);

export const getProjectOtherFilesListLength = createSelector(
  getProjectOtherFilesListArray,
  (files: GQLFile[]) => files.length,
);

export const getProjectList = createSelector(
  getFilesState,
  ({ totalProjectListInFileCollection }): GQLProject[] =>
    (totalProjectListInFileCollection?.items || []).map(
      (item: GQLFileCollectionItem) => item.project,
    ),
);

export const projectsFilesLength = createSelector(
  getProjectList,
  (projects) => projects.length,
);

export const getFileVersionsViewModel = createSelector(
  getFileVersions,
  getVersionCreateLoader,
  getCurrentFileVersionId,
  getCurrentFileVersionNumber,
  canAddVersionInFile,
  (
    fileVersions,
    fileVersionsLoading,
    currentFileVersionId,
    currentFileVersionNumber,
    canAddVersion,
  ) => {
    return {
      fileVersions,
      fileVersionsLoading,
      currentFileVersionId,
      currentFileVersionNumber,
      canAddVersion,
    };
  },
);

export const getFileListView = createSelector(
  getFilesState,
  ({ fileListView }) => fileListView,
);
