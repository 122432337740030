import { gql } from 'apollo-angular';

export const REMOVE_FILE = gql`
  mutation ($input: RemoveInput!) {
    removeFile(input: $input) {
      id
      success
    }
  }
`;
