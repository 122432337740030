import { createAction, props } from '@ngrx/store';
import {
  GQLCreateMessageInput,
  GQLEditMessageInput,
  GQLMarkMessageAsViewedInput,
  GQLMarkMessageAsViewedPayload,
  GQLMessage,
  GQLMessageList,
  GQLRemoveInput,
  GQLRemovePayload,
} from '../../../../schemas/schemas';

function scoped(templateString: TemplateStringsArray) {
  return `[Messages] ${templateString[0]}`;
}
export const setIsLoadingMessages = createAction(
  scoped`Set Loading Flag`,
  props<{ isLoadingMessages: boolean }>(),
);

// LOAD THREADS
export const loadMessagesList = createAction(scoped`Load Messages List`);

export const loadMessagesListSuccess = createAction(
  scoped`Load Messages Success`,
  props<{ messagesList: GQLMessageList }>(),
);

export const loadMessagesListsFailure = createAction(
  scoped`Load Messages Failure`,
  props<{ error: unknown }>(),
);
export const clearMessagesList = createAction(scoped`Clear Messages`);

// CREATE THREAD
export const createMessage = createAction(
  scoped`Create message`,
  props<{ input: GQLCreateMessageInput }>(),
);

export const createMessageSuccess = createAction(
  scoped`Create Message Success`,
  props<{ message: GQLMessage }>(),
);

export const createMessageFailure = createAction(
  scoped`Create Message Failure`,
  props<{ error: unknown }>(),
);

// EDIT THREAD
export const editMessage = createAction(
  scoped`Edit Message`,
  props<{ input: GQLEditMessageInput }>(),
);

export const editMessageSuccess = createAction(
  scoped`Edit Message Success`,
  props<{ message: GQLMessage }>(),
);

export const editMessageFailure = createAction(
  scoped`Edit Message Failure`,
  props<{ error: unknown }>(),
);

// DELETE THREAD
export const deleteMessage = createAction(
  scoped`Delete Message`,
  props<{ input: GQLRemoveInput }>(),
);

export const deleteMessageSuccess = createAction(
  scoped`Delete Message Success`,
  props<{ removeMessage: GQLRemovePayload }>(),
);

export const deleteMessageFailure = createAction(
  scoped`Delete Message Failure`,
  props<{ error: unknown }>(),
);

// LOAD THREAD
export const loadMessage = createAction(
  scoped`Load Project List`,
  props<{ messageId: string }>(),
);

export const loadMessageSuccess = createAction(
  scoped`Load Message Success`,
  props<{ message: GQLMessage }>(),
);

export const loadMessageFailure = createAction(
  scoped`Load Project Failure`,
  props<{ error: unknown }>(),
);

// MARK_Message_AS_VIEWED
export const markMessageAsViewed = createAction(
  scoped`markMessageAsViewed`,
  props<{ input: GQLMarkMessageAsViewedInput }>(),
);

export const markMessageAsViewedSuccess = createAction(
  scoped`markMessageAsViewed Success`,
  props<{ messages: GQLMarkMessageAsViewedPayload }>(),
);

export const markMessageAsViewedFailure = createAction(
  scoped`markMessageAsViewedFailure Failure`,
  props<{ error: unknown }>(),
);
export const clearMessage = createAction(scoped`Clear Message Object`);
