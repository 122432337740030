import { createAction, props } from '@ngrx/store';
import {
  GQLFile,
  GQLFileCollection,
  GQLFileGroup,
  GQLFileMarkerStatusEnum,
  GQLFileVersion,
} from '../../../schemas/schemas';
import { EFromFilePlace } from '@common/helpers';
import { ESidebarTabs } from '../constants/commenting-tool.constants';
import { ITimeInfo } from '@common/types/video-tool.models';

function scoped(templateString: TemplateStringsArray): string {
  return `[Commenting Tool] ${templateString[0]}`;
}

export const setProductLoader = createAction(
  scoped`Set Product Loader`,
  props<{ loading: boolean }>(),
);

export const updateFilesWithTools = createAction(
  scoped`Update Files With Tools`,
  props<{ fileVersion: GQLFileVersion; fileId: string }>(),
);

export const setImageCommentingToolRendering = createAction(
  scoped`Image Commenting Tool Rendering`,
  props<{ rendering: boolean }>(),
);

export const selectVideoTab = createAction(
  scoped`Select Video Tab`,
  props<{ tab: ESidebarTabs }>(),
);

export const selectImageTab = createAction(
  scoped`Expand Image Tab`,
  props<{ tab: ESidebarTabs }>(),
);

export const expandFullScreen = createAction(scoped`Expand Full Screen`);

export const collapseFullScreen = createAction(scoped`Collapse Full Screen`);

export const nextFileInTool = createAction(
  scoped`Next File in Tool`,
  props<{ currentFileId: string }>(),
);

export const prevFileInTool = createAction(
  scoped`Prev File in Tool`,
  props<{ currentFileId: string }>(),
);

export const fitToObject = createAction(scoped`Fit To Object`);

export const expandVideoFullScreen = createAction(
  scoped`Expand Video Full Screen`,
);

export const collapseVideoFullScreen = createAction(
  scoped`Collapse Video Full Screen`,
);

export const toggleVersionsList = createAction(
  scoped`Collapse or Expand versions list`,
);

export const turnMarkersVisibleOn = createAction(
  scoped`Turn Markers Visible On`,
);

export const turnMarkersVisibleOff = createAction(
  scoped`Turn Markers Visible Off`,
);

export const turnCommentingModeOn = createAction(
  scoped`Turn Commenting Mode On`,
);

export const turnCommentingModeOff = createAction(
  scoped`turnCommentingModeOff`,
);

export const turnDrawingModeOn = createAction(scoped`Turn Drawing Mode On`);

export const turnDrawingModeOff = createAction(scoped`Turn Drawing Mode Off`);

export const turnDrawingAndCommentingModeOff = createAction(
  scoped`Turn Drawing And Commenting Mode Off`,
);

export const createVideoGroup = createAction(scoped`Create Video Group`);

export const resetCommentingToolState = createAction(
  scoped`Reset Commenting Tool State`,
);

export const resetCommentingToolStateWithoutFiles = createAction(
  scoped`Reset Commenting Tool State Without Files`,
);

export const resetCommentingToolStateWithoutFilesAndFullScreen = createAction(
  scoped`Reset Commenting Tool State Without Files And Full Screen`,
);

export const resetCommentingToolStateWithoutMarkersStatusAnd = createAction(
  scoped`Reset Commenting Tool State`,
);

export const setCurrentMarkersStatus = createAction(
  scoped`Set Markers Status`,
  props<{ status: GQLFileMarkerStatusEnum }>(),
);

export const setZoomSize = createAction(
  scoped`Set Zoom Size`,
  props<{ zoomSize: number }>(),
);

export const setStreamAvailability = createAction(
  scoped`Set Stream Availability`,
  props<{ status: boolean }>(),
);

export const fitCurrentVersion = createAction(scoped`Fit Current Version`);

export const reCenterModel = createAction(scoped`Re-center model`);

export const setCurrentVideoTimePoint = createAction(
  scoped`Set Current Video Time Point`,
  props<{ timeInfo: ITimeInfo; current3dScreenshot: string }>(),
);

export const clearCurrentVideoTimePoint = createAction(
  scoped`Clear Current Video Time Point`,
);

export const loadFilesForCarousel = createAction(
  scoped`Load Files For Carousel`,
  props<{ placeType: EFromFilePlace }>(),
);

export const loadProjectFilesWithTool = createAction(
  scoped`Load Project Files With Collaboration Tool`,
  props<{ projectId: string }>(),
);

export const loadProjectFilesWithToolSuccess = createAction(
  scoped`Load Project Files With Collaboration Tool Success`,
  props<{
    filesList: GQLFileGroup[];
  }>(),
);
export const loadThreadFilesWithToolFailure = createAction(
  scoped`Load Thread Files With Collaboration Tool Failure`,
  props<{ error: unknown }>(),
);

export const loadThreadFilesWithTool = createAction(
  scoped`Load Thread Files With Collaboration Tool`,
  props<{ threadId: string }>(),
);

export const loadThreadFilesWithToolSuccess = createAction(
  scoped`Load Thread Files With Collaboration Tool Success`,
  props<{ filesList: GQLFile[] }>(),
);

export const loadProjectFilesWithToolFailure = createAction(
  scoped`Load Project Files With Collaboration Tool Failure`,
  props<{ error: unknown }>(),
);

export const loadAllFileWithTool = createAction(
  scoped`Load All Files With Collaboration Tool`,
);

export const loadAllFileWithToolSuccess = createAction(
  scoped`Load All Files With Collaboration Tool Success`,
  props<{ fileCollection: GQLFileCollection }>(),
);

export const loadAllFileWithToolFailure = createAction(
  scoped`Load All Files With Collaboration Too Failure`,
  props<{ error: unknown }>(),
);

export const loadThreadFilesWithOriginOrder = createAction(
  scoped`load Thread Files With Origin Order`,
  props<{ threadId: string }>(),
);

export const loadThreadFilesWithOriginOrderSuccess = createAction(
  scoped`load Thread Files With Origin Order Success`,
  props<{ filesList: GQLFile[] }>(),
);

export const loadThreadFilesWithOriginOrderFailure = createAction(
  scoped`load Thread Files With Origin Order Failure`,
  props<{ error: unknown }>(),
);
