import { gql } from 'apollo-angular';

export const GET_PROJECT_LIST_IN_FILE_COLLECTION = gql`
  query files {
    files {
      skip
      limit
      total
      items {
        project {
          id
          title
        }
      }
    }
  }
`;
