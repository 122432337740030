<div class="add-thread">
  <form class="add-thread__form form">
    <div [class.loading]="isLoading$ | async">
      <div class="form__elem form__elem--uploader">
        <div class="form__input">
          <app-file-uploader
            (filesAttached)="filesAttached($event)"
            [multiple]="false"
            [hasUseTools]="false"
            [fileType]="currentFileType$ | async"
          >
          </app-file-uploader>
          <div
            *ngIf="addFileError$ | async as error"
            class="form__hint invalid"
            [innerHtml]="error"
          ></div>
        </div>
      </div>
    </div>

    <div class="form__buttons" [class.disabled]="false">
      <button
        type="reset"
        class="button button--clear"
        [disabled]="!isFileUpload"
        (click)="close()"
      >
        Cancel
      </button>
      <button
        (click)="addVersion()"
        type="button"
        class="button"
        [disabled]="isAddDisabled"
      >
        Add
      </button>
    </div>
  </form>
</div>
