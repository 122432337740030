import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, Subject, timer } from 'rxjs';
import {
  catchError,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { NOTIFICATIONS_PER_PAGE } from '@common/helpers';
import {
  clearAllNotifications,
  clearAllNotificationsFailure,
  clearAllNotificationsSuccess,
  editNotificationSettings,
  editNotificationSettingsFailure,
  editNotificationSettingsSuccess,
  getNotificationSettings,
  getNotificationSettingsFailure,
  getNotificationSettingsSuccess,
  loadMoreNotifications,
  loadMoreNotificationsSuccess,
  loadNotifications,
  loadNotificationsFailure,
  loadNotificationsSuccess,
  markNotificationsAsViewed,
  markNotificationsAsViewedFailure,
  markNotificationsAsViewedSuccess,
  resetNotificationsState,
} from './notifications.actions';
import {
  getLimitNotificationsAmount,
  getSkipNotificationsAmount,
  getTotalNotifications,
  getUnreadOnly,
} from './notifications.selectors';
import { NotificationsService } from '../services/notifications.service';

export const checkRunStatusIntervalMs = 120000;

@Injectable()
export class NotificationsEffects {
  constructor(
    public actions$: Actions,
    public http: HttpClient,
    private store: Store,
    private notificationsService: NotificationsService,
  ) {}
  stopTimer: Subject<boolean> = new Subject();

  loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNotifications),
      withLatestFrom(this.store.select(getSkipNotificationsAmount)),
      switchMap(([{ unreadOnly }, skip]) => {
        return timer(0, checkRunStatusIntervalMs).pipe(
          withLatestFrom(this.store.select(getLimitNotificationsAmount)),
          takeUntil(this.stopTimer),
          switchMap(([, limit]) =>
            this.notificationsService
              .loadNotifications(skip, limit, unreadOnly)
              .pipe(
                map((activities) => loadNotificationsSuccess({ activities })),
                catchError((error) => {
                  console.error(error);
                  this.stopTimer.next(true);
                  return of(loadNotificationsFailure({ error }));
                }),
              ),
          ),
        );
      }),
    ),
  );

  loadMoreNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMoreNotifications),
      withLatestFrom(
        this.store.select(getSkipNotificationsAmount),
        this.store.select(getTotalNotifications),
        this.store.select(getUnreadOnly),
      ),
      switchMap(([, skip, total, unreadOnly]) => {
        return total && skip >= total
          ? of(
              loadNotificationsFailure({
                error: 'skip is more than total notifications',
              }),
            )
          : this.notificationsService
              .loadNotifications(skip, NOTIFICATIONS_PER_PAGE, unreadOnly)
              .pipe(
                map((activities) =>
                  loadMoreNotificationsSuccess({ activities }),
                ),
                catchError((error) => {
                  console.error(error);
                  this.stopTimer.next(true);
                  return of(loadNotificationsFailure({ error }));
                }),
              );
      }),
    ),
  );

  markNotificationsAsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markNotificationsAsViewed),
      switchMap((input) =>
        this.notificationsService.markNotificationsAsViewed(input).pipe(
          switchMap((ids) => {
            return [
              // loadNotifications(),
              markNotificationsAsViewedSuccess({ ids }),
            ];
          }),
          catchError((error: unknown) =>
            of(markNotificationsAsViewedFailure({ error })),
          ),
        ),
      ),
    ),
  );

  resetNotificationsState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetNotificationsState),
        tap(() => this.stopTimer.next(true)),
      ),
    { dispatch: false },
  );

  getNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getNotificationSettings),
      switchMap((input) => {
        return this.notificationsService.getNotificationSettings().pipe(
          map((settings) => {
            return getNotificationSettingsSuccess({ settings });
          }),
          catchError((error: unknown) =>
            of(getNotificationSettingsFailure({ error })),
          ),
        );
      }),
    ),
  );

  editNotificationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editNotificationSettings),
      switchMap((input) => {
        return this.notificationsService.editNotificationSettings(input).pipe(
          map((settings) => {
            return editNotificationSettingsSuccess({
              settings,
            });
          }),
          catchError((error: unknown) =>
            of(editNotificationSettingsFailure({ error })),
          ),
        );
      }),
    ),
  );

  clearAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearAllNotifications),
      switchMap((input) => {
        return this.notificationsService.clearAllNotifications().pipe(
          map((activities) => {
            return clearAllNotificationsSuccess({
              activities,
            });
          }),
          catchError((error: unknown) =>
            of(clearAllNotificationsFailure({ error })),
          ),
        );
      }),
    ),
  );
}
