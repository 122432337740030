export enum EFileListLabel {
  Other = 'Other files',
}

export enum EPrevPath {
  files = 'files',
  default = 'projects',
}

export enum EViewType {
  CARD = 'card',
  LIST = 'list',
}

export enum EFromFilePlace {
  THREAD_MESSAGE = 'THREAD_MESSAGE',
  THREAD_WIDGET = 'THREAD_WIDGET',
  THREAD_FILE_PAGE = 'THREAD_FILE_PAGE',
  PROJECT_FILE_PAGE = 'PROJECT_FILE_PAGE',
  PROJECT_WIDGET = 'PROJECT_WIDGET',
  FILE_PAGE = 'FILE_PAGE',
}

export enum EFileExtension {
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  TXT = 'txt',
  PAK = 'pak',
  UNDEFINED = 'undefined',
}

export const unsupportedToolsFileExtension = [
  EFileExtension.PDF,
  EFileExtension.DOC,
  EFileExtension.DOCX,
  EFileExtension.TXT,
  EFileExtension.UNDEFINED,
];
