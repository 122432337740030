import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CurrentUserService } from '@common/services/current-user.service';
import { resetNotificationsState } from '../../../notifications/store/notifications.actions';
import {
  getCurrentUserAvatar,
  getCurrentUserAvatarName,
  getCurrentUserFullName,
  getCurrentUserRole,
} from '@common/store/users/users.selectors';
import { DialogService } from 'primeng/dynamicdialog';
import { RolesComponent } from '../roles/roles.component';
import { GQLRolesEnum } from '@schemas';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  @Output()
  isNavigationDisabled: EventEmitter<boolean> = new EventEmitter<boolean>(
    false,
  );

  userName$: Observable<string> = this.store.select(getCurrentUserFullName);

  userAvatarName$: Observable<string> = this.store.select(
    getCurrentUserAvatarName,
  );

  userAvatar$: Observable<string> = this.store.select(getCurrentUserAvatar);
  userRole$: Observable<string> = this.store
    .select(getCurrentUserRole)
    .pipe(tap((role: string) => this.checkUserRole(role)));

  canChangeRoles = false;
  userMenuOpen = false;

  @HostListener('document:click', ['$event'])
  onClick(event: Event): void {
    if (!this.elemRef.nativeElement.contains(event.target)) {
      this.userMenuOpen = false;
    }
  }

  constructor(
    private readonly currentUserService: CurrentUserService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly elemRef: ElementRef,
    private readonly dialogService: DialogService,
  ) {}

  checkUserRole(role: string): void {
    this.canChangeRoles =
      role === GQLRolesEnum.ROLE_OWNER ||
      role === GQLRolesEnum.ROLE_SUPER_MANAGER ||
      role === GQLRolesEnum.ROLE_MANAGER;
  }

  logout(): void {
    this.currentUserService.logout();
    setTimeout(() => {
      this.store.dispatch(resetNotificationsState());
    }, 100);
  }

  goToUserProfile(): void {
    const parentPath: string = this.router.routerState.snapshot.url || '';
    const extras = {
      queryParams: {
        parentPath,
      },
    };
    this.router.navigate(['profile'], extras);
    this.userMenuOpen = !this.userMenuOpen;
    this.isNavigationDisabled.emit(true);
  }

  goToNotificationSettings(): void {
    const parentPath: string = this.router.routerState.snapshot.url || '';

    if (parentPath.includes('/notification-settings')) {
      this.userMenuOpen = !this.userMenuOpen;
      return;
    }
    const extras = {
      queryParams: {
        parentPath,
      },
    };

    this.router.navigate(['notification-settings'], extras);
    this.userMenuOpen = !this.userMenuOpen;
    this.isNavigationDisabled.emit(true);
  }

  openRolesPopup(): void {
    const dialogConfig = {
      data: {
        isEditing: false,
      },
      header: 'Roles',
      styleClass: 'roles-modal',
    };
    this.dialogService.open(RolesComponent, dialogConfig);

    this.userMenuOpen = !this.userMenuOpen;
    this.isNavigationDisabled.emit(true);
  }

  toggleMenu(): void {
    this.userMenuOpen = !this.userMenuOpen;
  }
}
