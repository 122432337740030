import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralFileTypePipe } from './general-file-type.pipe';

@NgModule({
  declarations: [GeneralFileTypePipe],
  imports: [CommonModule],
  providers: [GeneralFileTypePipe],
  exports: [GeneralFileTypePipe],
})
export class GeneralFileTipesPipeModule {}
