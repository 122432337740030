import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '../index';
import {
  GQLInvitationActionStatusEnum,
  GQLInvitationStatusEnum,
  GQLProfile,
  GQLProfilePermissions,
} from '../../../../schemas/schemas';
import { isCurrentThreadPublic } from '../threads/threads.selectors';
import { UsersState } from './users.reducer';
import { IgraphQLErrorsObject } from '../../types/IgraphQLErrorsObject';
import { getCuttedUserName } from '../../helpers/user-name.utils';

export const getUsersState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.usersState,
);

export const checkInvitationStatusLoading = createSelector(
  getUsersState,
  (state: UsersState): boolean => {
    return state.checkInvitationStatusLoading;
  },
);

export const getCurrentUser = createSelector(
  getUsersState,
  (state: UsersState): GQLProfile | null => {
    return state.currentUser;
  },
);

export const getWorkspaceUsers = createSelector(
  getUsersState,
  (state: UsersState): GQLProfile[] => {
    return state.workspaceUsers;
  },
);

export const getWorkspaceUsersWithoutMe = createSelector(
  getUsersState,
  getCurrentUser,
  (state, currentUser): GQLProfile[] => {
    return state.workspaceUsers.filter(
      (workspaceUser) => currentUser?.id !== workspaceUser.id,
    );
  },
);

export const getProjectUsers = createSelector(
  getUsersState,
  (state: UsersState): GQLProfile[] => {
    return state.projectUsers;
  },
);

export const getThreadUsers = createSelector(
  getUsersState,
  (state: UsersState): GQLProfile[] => {
    return state.threadUsers;
  },
);

export const getThreadUsersWithoutMe = createSelector(
  getUsersState,
  getCurrentUser,
  (state, currentUser): GQLProfile[] => {
    return state.threadUsers.filter(
      (threadUser) => currentUser?.id !== threadUser.id,
    );
  },
);

export const getProjectUsersWithoutMe = createSelector(
  getProjectUsers,
  getCurrentUser,
  (projectUsers, currentUser): GQLProfile[] => {
    return projectUsers.filter(
      (projectUser) => currentUser?.id !== projectUser.id,
    );
  },
);

export const getCurrentUserFullName = createSelector(
  getCurrentUser,
  (user): string => {
    return user?.fullName || '';
  },
);

export const getCurrentUserAvatarName = createSelector(
  getCurrentUser,
  (user): string => {
    return getCuttedUserName(user);
  },
);

export const getCurrentUserAvatar = createSelector(
  getCurrentUser,
  (user): string => {
    return user?.avatarPath || '';
  },
);

export const getCurrentUserRole = createSelector(
  getCurrentUser,
  (user): string => {
    return user?.role || '';
  },
);

export const getCurrentUserRolePermissions = createSelector(
  getUsersState,
  (state: UsersState): GQLProfilePermissions | null => {
    return state.rolePermissions;
  },
);

export const getCurrentUserPermissions = createSelector(
  getUsersState,
  (state: UsersState): any => {
    return state.permissions;
  },
);

export const getThreadMailingUsers = createSelector(
  getProjectUsersWithoutMe,
  getThreadUsersWithoutMe,
  isCurrentThreadPublic,
  (projectUsers, threadUsers, isThreadPublic): GQLProfile[] => {
    return isThreadPublic ? projectUsers : threadUsers;
  },
);

export const isMyPasswordUpdated = createSelector(
  getUsersState,
  (state: UsersState): boolean => {
    return state.isMyPasswordUpdated;
  },
);

export const isMyProfileInfoSaved = createSelector(
  getUsersState,
  (state: UsersState): boolean => {
    return state.isMyProfileInfoSaved;
  },
);

export const isMyProfileAndPasswordUpdated = createSelector(
  getUsersState,
  (state: UsersState): boolean => {
    return state.isMyProfileAndPasswordUpdated;
  },
);

export const getUsersApiErrors = createSelector(
  getUsersState,
  (state: UsersState): IgraphQLErrorsObject | undefined => {
    return state.error;
  },
);

export const getInvitationStatus = createSelector(
  getUsersState,
  (state): GQLInvitationActionStatusEnum | null => {
    return state.invitationStatus;
  },
);

export const getInvitationStatusErrors = createSelector(
  getUsersState,
  (state): string | null => {
    return state.invitationStatusError;
  },
);
