import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TimeCreatedPipe } from '@common/pipes/time-created/time-created.pipe';

@NgModule({
  declarations: [TimeCreatedPipe],
  imports: [CommonModule],
  exports: [TimeCreatedPipe],
  providers: [DatePipe],
})
export class TimeCreatedModule {}
