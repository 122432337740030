import { HttpClient } from '@angular/common/http';
import { Injectable, Query } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { QueryRef } from 'apollo-angular';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  switchMap,
  take,
} from 'rxjs/operators';
import {
  CREATE_PROJECT,
  EDIT_PROJECT,
  REMOVE_INVITATION,
  REMOVE_PROJECT,
} from '../../graphQL/graphql-requests/mutations';
import {
  GET_PROJECT,
  GET_PROJECTS,
} from '../../graphQL/graphql-requests/queries';
import { BaseApi } from '../../services/base-api';
import { loadProjectUsersSuccess } from '../users/users.actions';
import {
  createProject,
  createProjectFailure,
  createProjectSuccess,
  deleteProject,
  deleteProjectFailure,
  deleteProjectSuccess,
  editProject,
  editProjectFailure,
  editProjectSuccess,
  getInitProjectListView,
  getInitProjectListViewSuccess,
  loadProject,
  loadProjectFailure,
  loadProjectsList,
  loadProjectsListsFailure,
  loadProjectsListSuccess,
  loadProjectsTitles,
  loadProjectsTitlesFailure,
  loadProjectsTitlesSuccess,
  loadProjectSuccess,
  removeInvitation,
  removeInvitationFailure,
  removeInvitationSuccess,
  toggleProjectListView,
  toggleProjectListViewSuccess,
} from './projects.actions';
import { getCurrentProjectId } from './projects.selectors';
import { ProjectsService } from './services/projects.service';
import { EViewType } from '../../helpers';

@Injectable()
export class ProjectsEffects {
  constructor(
    public actions$: Actions,
    public http: HttpClient,
    private api: BaseApi,
    private appStore: Store,
    private projectsService: ProjectsService,
  ) {}

  loadProjectsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectsList),
      switchMap(({ input }) =>
        this.api.apollo
          .query<QueryRef<Query>>({
            query: GET_PROJECTS,
            variables: {
              limit: 10000,
              skip: 0,
              searchQuery: input.searchQuery,
            }, // TODO remove hardcode limit and skip
            fetchPolicy: 'network-only',
          })
          .pipe(
            map(({ data }: any) =>
              loadProjectsListSuccess({ projectsList: data.projects }),
            ),
            catchError((error: unknown) =>
              of(loadProjectsListsFailure({ error })),
            ),
          ),
      ),
    ),
  );

  loadProjectsTitles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectsTitles),
      switchMap(() =>
        this.projectsService.loadProjectsTitles().pipe(
          map((projects) =>
            loadProjectsTitlesSuccess({ projectsTitles: projects }),
          ),
          catchError((error: unknown) =>
            of(loadProjectsTitlesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  createProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProject),
      exhaustMap(({ projectInput }) =>
        this.api
          .mutate(
            CREATE_PROJECT,
            { input: { ...projectInput, invitations: [] } },
            { useMultipart: true },
          )
          .pipe(
            mergeMap(({ data }) => {
              if (!!projectInput.invitations?.length) {
                const projectId = data.createProject.project.id;

                return [
                  createProjectSuccess(data.createProject),
                  editProject({
                    projectInput: {
                      ...projectInput,
                      id: data.createProject.project.id,
                      invitations: projectInput.invitations.map((invite) => ({
                        ...invite,
                        acceptLink: `${invite.acceptLink}?invitedProjectId=${projectId}`,
                      })),
                    },
                  }),
                ];
              }
              return [createProjectSuccess(data.createProject)];
            }),
            catchError((error: unknown) => of(createProjectFailure({ error }))),
          ),
      ),
    ),
  );

  editProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(editProject),
      exhaustMap((action) =>
        this.api
          .mutate(
            EDIT_PROJECT,
            { input: action.projectInput },
            { useMultipart: true },
          )
          .pipe(
            map(({ data }) => editProjectSuccess(data.editProject)),
            catchError((error: unknown) => of(editProjectFailure({ error }))),
          ),
      ),
    ),
  );

  deleteProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProject),
      exhaustMap(({ input }) =>
        this.api.mutate(REMOVE_PROJECT, { input }).pipe(
          map(({ data }) => deleteProjectSuccess(data)),
          catchError((error: unknown) => of(deleteProjectFailure({ error }))),
        ),
      ),
    ),
  );

  removeInvitation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeInvitation),
      exhaustMap(({ input }) =>
        this.api.mutate(REMOVE_INVITATION, { input }).pipe(
          map(({ data }) => removeInvitationSuccess(data)),
          catchError((error: unknown) =>
            of(removeInvitationFailure({ error })),
          ),
        ),
      ),
    ),
  );

  loadProject$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(loadProject),
      switchMap(() => {
        return this.appStore.pipe(
          select(getCurrentProjectId),
          take(1),
          switchMap((projectId: string) =>
            this.api
              .mutate(GET_PROJECT, { id: projectId, limit: 999, skip: 0 })
              .pipe(
                mergeMap(({ data }) => {
                  return [
                    loadProjectSuccess(data),
                    loadProjectUsersSuccess({ users: data.project.profiles }),
                  ];
                }),
                catchError((error: unknown) =>
                  of(loadProjectFailure({ error })),
                ),
              ),
          ),
        );
      }),
    ),
  );

  getProjectListView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getInitProjectListView),
      switchMap(() => {
        const viewType =
          (localStorage.getItem('projectListView') as EViewType) ||
          EViewType.CARD;
        return of(getInitProjectListViewSuccess({ viewType }));
      }),
    );
  });

  toggleProjectListView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleProjectListView),
      switchMap(({ viewType }) => {
        localStorage.setItem('projectListView', viewType);
        return of(toggleProjectListViewSuccess({ viewType }));
      }),
    );
  });
}
