import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import {
  closeNotifications,
  openNotifications,
  resetPagination,
} from '../../../notifications/store/notifications.actions';
import {
  getUnreadNotifications,
  isNotificationsOpened,
} from '../../../notifications/store/notifications.selectors';
import { NotificationListComponent } from '../../../notifications/components/notification-list/notification-list.component';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class NotificationsComponent {
  unreadNotifications$: Observable<number> = this.store.select(
    getUnreadNotifications,
  );

  isNotificationsOpened$: Observable<boolean> = this.store
    .select(isNotificationsOpened)
    .pipe(
      skip(1),
      tap((opened) => {
        this.panel = opened;
        if (opened) {
          this.renderer.addClass(document.body, 'no-scroll');
        } else {
          this.closeNotifications();
        }
      }),
    );

  panel = false;

  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elemRef.nativeElement.contains(event.target) && this.panel) {
      this.closeDispatch();
    }
  }

  constructor(
    public dialogService: DialogService,
    private store: Store,
    private elemRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  openNotifications(): void {
    if (!this.panel) {
      this.store.dispatch(openNotifications());
    } else {
      this.store.dispatch(closeNotifications());
    }
  }

  closeNotifications(): void {
    this.renderer.removeClass(document.body, 'no-scroll');
    this.store.dispatch(resetPagination());
  }

  closeDispatch(): void {
    this.store.dispatch(closeNotifications());
    this.closeNotifications();
  }

  openNotificationList(): void {
    const ref = this.dialogService.open(NotificationListComponent, {
      styleClass: 'notification-panel',
      width: '353px',
      header: 'Notifications',
    });
  }
}
