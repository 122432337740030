import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@common/helpers/constants/config.constants';
import { AppConfig } from '@common/types/app-config.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieHandlerService {
  domain = this.appConfig.cookieDomain;

  constructor(
    private readonly cookieService: CookieService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig,
  ) {}

  get(param: string): string {
    return this.cookieService.get(`${param}_${this.domain}`);
  }

  set(param: string, value: string): void {
    this.cookieService.set(
      `${param}_${this.domain}`,
      value,
      3600,
      '/',
      this.domain,
      true,
      'None',
    );
  }

  delete(param: string): void {
    return this.cookieService.delete(
      `${param}_${this.domain}`,
      '/',
      this.domain,
      true,
      'None',
    );
  }
}
