import { Pipe, PipeTransform } from '@angular/core';

type Unit = 'bytes' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type UnitPrecisionMap = {
  [u in Unit]: number;
};

const defaultPrecisionMap: UnitPrecisionMap = {
  bytes: 0,
  KB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
};

@Pipe({
  name: 'fileSizePipe',
})
export class FileSizePipePipe implements PipeTransform {
  private readonly units: Unit[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(
    bytes: number = 0,
    precision: number | UnitPrecisionMap = defaultPrecisionMap,
  ): string {
    if (Number.isNaN(parseFloat(String(bytes))) || !Number.isFinite(bytes))
      return '?';

    let unitIndex = 0;
    let fileSize: number = bytes;

    while (fileSize >= 1024) {
      fileSize /= 1024;
      // eslint-disable-next-line no-plusplus
      unitIndex++;
    }

    const unit = this.units[unitIndex];

    if (typeof precision === 'number') {
      return `${fileSize.toFixed(+precision)} ${unit}`;
    }
    return `${fileSize.toFixed(precision[unit])} ${unit}`;
  }
}
