import { gql } from 'apollo-angular';

export const GET_MARKER_GROUP = gql`
  query ($id: ID!) {
    markerGroup(id: $id) {
      id
      imagePath
      previewPath
      configuration
    }
  }
`;
