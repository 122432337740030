export const imageExtensions = [
  'ase',
  'art',
  'bmp',
  'blp',
  'cd5',
  'cit',
  'cpt',
  'cr2',
  'cut',
  'dds',
  'dib',
  'djvu',
  'egt',
  'exif',
  'gif',
  'gpl',
  'grf',
  'icns',
  'ico',
  'iff',
  'jng',
  'jpeg',
  'jpg',
  'jfif',
  'jp2',
  'jps',
  'lbm',
  'max',
  'miff',
  'mng',
  'msp',
  'nitf',
  'ota',
  'pbm',
  'pc1',
  'pc2',
  'pc3',
  'pcf',
  'pcx',
  'pdn',
  'pgm',
  'PI1',
  'PI2',
  'PI3',
  'pict',
  'pct',
  'pnm',
  'pns',
  'ppm',
  'psb',
  'psd',
  'pdd',
  'psp',
  'px',
  'pxm',
  'pxr',
  'qfx',
  'raw',
  'rle',
  'sct',
  'sgi',
  'rgb',
  'int',
  'bw',
  'tga',
  'tiff',
  'tif',
  'vtf',
  'xbm',
  'xcf',
  'xpm',
  '3dv',
  'amf',
  'ai',
  'awg',
  'cgm',
  'cdr',
  'cmx',
  'dxf',
  'e2d',
  'egt',
  'eps',
  'fs',
  'gbr',
  'odg',
  'svg',
  'stl',
  'vrml',
  'x3d',
  'sxd',
  'v2d',
  'vnd',
  'wmf',
  'emf',
  'art',
  'xar',
  'png',
  'webp',
  'jxr',
  'hdp',
  'wdp',
  'cur',
  'ecw',
  'iff',
  'lbm',
  'liff',
  'nrrd',
  'pam',
  'pcx',
  'pgf',
  'sgi',
  'rgb',
  'rgba',
  'bw',
  'int',
  'inta',
  'sid',
  'ras',
  'sun',
  'tga',
  'ASE',
  'ART',
  'BMP',
  'BLP',
  'CD5',
  'CIT',
  'CPT',
  'CR2',
  'CUT',
  'DDS',
  'DIB',
  'DJVU',
  'EGT',
  'EXIF',
  'GIF',
  'GPL',
  'GRF',
  'ICNS',
  'ICO',
  'IFF',
  'JNG',
  'JPEG',
  'JPG',
  'JFIF',
  'JP2',
  'JPS',
  'LBM',
  'MAX',
  'MIFF',
  'MNG',
  'MSP',
  'NITF',
  'OTA',
  'PBM',
  'PC1',
  'PC2',
  'PC3',
  'PCF',
  'PCX',
  'PDN',
  'PGM',
  'PI1',
  'PI2',
  'PI3',
  'PICT',
  'PCT',
  'PNM',
  'PNS',
  'PPM',
  'PSB',
  'PSD',
  'PDD',
  'PSP',
  'PX',
  'PXM',
  'PXR',
  'QFX',
  'RAW',
  'RLE',
  'SCT',
  'SGI',
  'RGB',
  'INT',
  'BW',
  'TGA',
  'TIFF',
  'TIF',
  'VTF',
  'XBM',
  'XCF',
  'XPM',
  '3DV',
  'AMF',
  'AI',
  'AWG',
  'CGM',
  'CDR',
  'CMX',
  'DXF',
  'E2D',
  'EGT',
  'EPS',
  'FS',
  'GBR',
  'ODG',
  'SVG',
  'STL',
  'VRML',
  'X3D',
  'SXD',
  'V2D',
  'VND',
  'WMF',
  'EMF',
  'ART',
  'XAR',
  'PNG',
  'WEBP',
  'JXR',
  'HDP',
  'WDP',
  'CUR',
  'ECW',
  'IFF',
  'LBM',
  'LIFF',
  'NRRD',
  'PAM',
  'PCX',
  'PGF',
  'SGI',
  'RGB',
  'RGBA',
  'BW',
  'INT',
  'INTA',
  'SID',
  'RAS',
  'SUN',
  'TGA',
];
