import { gql } from 'apollo-angular';
import { AUTHOR } from '../variables';

export const GET_ME = gql`
  query getMe {
    me {
      ${AUTHOR}
      permissions
    }
  }
`;
