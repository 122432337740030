/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/2/21, 2:37 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';
import { AUTHOR } from '../variables';

export const GET_THREADS = gql`
    query($id: ID!, $limit:Int, $skip:Int, $status: ThreadStatusEnum, $searchQuery: String) {
      project(id: $id) {
        id
        threads(limit: $limit, skip: $skip, status: $status, searchQuery: $searchQuery ) {
          total
          skip
          limit
          items {
            id
            title
            body
            isViewed
            status
            accessLevel
            serialNumber
            updatedAt
            canEdit
            canRemove
            canApprove
            canCreateMessage
            createdAt
            canDisapprove
            canAddFile
            author{
                ${AUTHOR}
            }
              messages{
                items{
                  id
                  isViewed
                }
                limit
                skip
                total
                unread
            }
            contributors {
                ${AUTHOR}
            }
            files(limit: 100000, skip: 0) {
              items {
                id
              }
              total
            }
          }
        }
      }
    }
`;

export const GET_THREAD = gql`
  query($id: ID!) {
     thread(id: $id) {
        id
        title
        body
        isViewed
        status
        accessLevel
        serialNumber
        updatedAt
        canEdit
        canRemove
        canApprove
        canCreateMessage
        createdAt
        canDisapprove
        canAddFile
        files(limit: 100000, skip: 0) {
          items {
            id
            name
            type
            updatedAt
            createdAt
            useTools
            project {
              id
              title
            }
            status
            canRemove
            extension
            hasNewVersion
            hasNewComment
            hasNewApprove
            versions {
              createdAt
              id
              path
              previewPath
              size
              isViewed
              hash
              title
              serialNumber
              extension
              status
              hasNewComment
              canRemove
            }
          }
          limit
          skip
          total
        }
        ownFiles {
            id
            name
            type
            updatedAt
            createdAt
            useTools
            status
            hasNewVersion
            hasNewComment
            hasNewApprove
            extension
            canRemove
              versions {
                createdAt
                id
                path
                previewPath
                size
                isViewed
                hash
                title
                serialNumber
                extension
                status
                hasNewComment
                canRemove
              }
        }
        author{
            ${AUTHOR}
        }
        messages{
          items{
            id
            isViewed
          }
          limit
          skip
          total
          unread
        }
        contributors {
            ${AUTHOR}
        }
     }
  }
`;
