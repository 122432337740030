import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import { EFromFilePlace, stringToBoolean } from '@common/helpers';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  clearFile,
  createFileVersionSuccess,
  loadFile,
  saveCurrentFileId,
  unselectActiveGroup,
} from '@common/store/files/files.actions';
import {
  createMarkerGroup,
  resetMarkers,
} from '@common/store/markers/markers.actions';
import {
  createVideoGroup,
  loadAllFileWithTool,
  loadAllFileWithToolFailure,
  loadAllFileWithToolSuccess,
  loadFilesForCarousel,
  loadThreadFilesWithOriginOrder,
  loadThreadFilesWithOriginOrderFailure,
  loadThreadFilesWithOriginOrderSuccess,
  loadThreadFilesWithTool,
  loadThreadFilesWithToolFailure,
  loadThreadFilesWithToolSuccess,
  nextFileInTool,
  prevFileInTool,
  setImageCommentingToolRendering,
  turnCommentingModeOn,
  turnDrawingModeOn,
  updateFilesWithTools,
} from './commenting-tools.actions';
import {
  currentFileIndexInThreadOrProjectFiles,
  getAllFilesWithTool,
  getIsEmbedType,
  getIsVideoType,
  getThreadFilesWithOriginOrder,
  getThreadFilesWithTool,
  getThreadOrProjectToolFiles,
} from './commenting-tools.selectors';
import {
  fitToObject,
  getIsStreamType,
  getIsVideoOrStreamType,
  loadProjectFilesWithTool,
  loadProjectFilesWithToolFailure,
  loadProjectFilesWithToolSuccess,
  setProductLoader,
  turnCommentingModeOff,
  turnDrawingModeOff,
} from './index';
import {
  getActiveMarkersGroup,
  getCurrentFileId,
  getFile,
} from '@common/store/files/files.selectors';

import { FilesService } from '@common/store/files/services/files.service';
import { GQLFileStatusEnum } from '../../../schemas/schemas';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { UnrealService } from '../services/unreal.service';
import { getCurrentProjectId } from '@common/store/projects/projects.selectors';
import { getCurrentThreadId } from '@common/store/threads/threads.selectors';
import { of } from 'rxjs';

@Injectable()
export class CommentingToolsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly filesService: FilesService,
    private readonly unrealService: UnrealService,
  ) {}

  createMarkerGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createMarkerGroup),
      withLatestFrom(
        this.store.select(getIsStreamType),
        this.store.select(getIsEmbedType),
      ),
      filter(
        ([, isStreamType, isEmbedType]) => !!isStreamType || !!isEmbedType,
      ),
      map(() => setImageCommentingToolRendering({ rendering: true })),
    ),
  );

  nextFileInTool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(nextFileInTool, prevFileInTool),
      concatLatestFrom(() => [
        this.store.select(getThreadOrProjectToolFiles),
        this.store.select(currentFileIndexInThreadOrProjectFiles),
      ]),
      filter(([action, threadOrProjectToolFiles, currentFileIndex]) =>
        currentFileIndex !== -1 && action.type === nextFileInTool.type
          ? currentFileIndex < threadOrProjectToolFiles.length
          : currentFileIndex > 0,
      ),
      map(([action, threadOrProjectToolFiles, currentFileIndex]) => {
        if (action.type === nextFileInTool.type) {
          return threadOrProjectToolFiles[currentFileIndex + 1];
        }
        return threadOrProjectToolFiles[currentFileIndex - 1];
      }),
      filter((nextFile) => !!nextFile),
      tap((nextFile) => {
        this.router.navigate(['tools', nextFile?.id], {
          queryParams: {
            ...this.route.snapshot.queryParams,
            version: nextFile.versions?.[0]?.id,
          },
        });
      }),
      concatLatestFrom(() => this.store.select(getFile)),
      mergeMap(([nextFile, currentFile]) => {
        let resetState = false;

        const areCurrentAndNextFileSameType: boolean =
          currentFile?.type === nextFile?.type;

        if (!areCurrentAndNextFileSameType) {
          resetState = true;
        }

        return [
          clearFile(),
          saveCurrentFileId({ currentFileId: nextFile.id }),
          loadFile({
            resetState,
          }),
        ];
      }),
    ),
  );

  turnVideoDrawingOrCommentingModeOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(turnCommentingModeOn, turnDrawingModeOn),
      withLatestFrom(
        this.store.select(getIsVideoType),
        this.store.select(getIsEmbedType),
        this.store.select(getActiveMarkersGroup),
      ),
      filter(
        ([, isVideoType, isEmbedType, activeMarkersGroup]) =>
          (isVideoType || isEmbedType) && !activeMarkersGroup,
      ),
      mergeMap(() => {
        return [resetMarkers(), createVideoGroup()];
      }),
    ),
  );

  turnStreamDrawingOrCommentingModeOn$ = createEffect(() =>
    this.actions$.pipe(
      ofType(turnCommentingModeOn, turnDrawingModeOn),
      withLatestFrom(
        this.store.select(getActiveMarkersGroup),
        this.store.select(getIsStreamType),
        this.store.select(getIsEmbedType),
      ),
      filter(
        ([, activeMarkersGroup, isStreamType, isEmbedType]) =>
          !activeMarkersGroup && (isStreamType || isEmbedType),
      ),
      map(() => {
        return createMarkerGroup({});
      }),
    ),
  );

  turnModesOf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(turnCommentingModeOff, turnDrawingModeOff),
      withLatestFrom(
        this.store.select(getIsVideoOrStreamType),
        this.store.select(getIsEmbedType),
      ),
      filter(([, isStreamOrVideo, isEmbed]) => isStreamOrVideo || isEmbed),
      tap(() => {
        const url = this.router
          .createUrlTree([], {
            relativeTo: this.route,
            queryParams: {
              ...this.route.snapshot.queryParams,
              markersGroupId: null,
            },
          })
          .toString();
        this.location.go(url);
      }),
      map(() => {
        return unselectActiveGroup();
      }),
    ),
  );

  loadProjectFilesWithTool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectFilesWithTool),
      withLatestFrom(this.store.select(getThreadFilesWithTool)),
      filter(([, filesWithTools]) => !filesWithTools.length),
      switchMap(([{ projectId }]) => {
        const requestInput = {
          id: projectId,
          ...this.getRequestParams(),
        };

        return projectId
          ? this.filesService.loadProjectFilesIds(requestInput).pipe(
              map((projectFiles) =>
                loadProjectFilesWithToolSuccess({
                  filesList: projectFiles,
                }),
              ),
              catchError((error: unknown) =>
                of(loadProjectFilesWithToolFailure({ error })),
              ),
            )
          : of(
              loadProjectFilesWithToolFailure({
                error: 'No project id specified',
              }),
            );
      }),
    ),
  );

  loadThreadFilesWithTool$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadThreadFilesWithTool),
      withLatestFrom(this.store.select(getThreadFilesWithTool)),
      filter(([, filesWithTools]) => !filesWithTools.length),
      switchMap(([{ threadId }]) => {
        const requestInput = {
          id: threadId,
          ...this.getRequestParams(),
        };
        return threadId
          ? this.filesService.loadThreadFilesIds(requestInput).pipe(
              map((threadFiles) =>
                loadThreadFilesWithToolSuccess({
                  filesList: threadFiles,
                }),
              ),
              catchError((error: unknown) =>
                of(loadThreadFilesWithToolFailure({ error })),
              ),
            )
          : of(
              loadThreadFilesWithToolFailure({
                error: 'No thread id specified',
              }),
            );
      }),
    ),
  );

  createFileVersionSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFileVersionSuccess),
      withLatestFrom(this.store.select(getCurrentFileId)),
      filter(([, fileId]) => !!fileId),
      map(([{ fileVersion }, fileId]) =>
        updateFilesWithTools({ fileVersion, fileId }),
      ),
    ),
  );

  loadAllFileWithTool$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAllFileWithTool),
      withLatestFrom(this.store.select(getAllFilesWithTool)),
      filter(([, filesWithTools]) => !filesWithTools.length),
      switchMap(() => {
        const { projectsIds } = this.route.snapshot.queryParams;
        const ids: string[] = projectsIds?.split(',');
        const request = {
          ...this.getRequestParams(),
          ids,
        };

        return this.filesService.getFileCollectionIds(request).pipe(
          map((fileCollection) =>
            loadAllFileWithToolSuccess({ fileCollection }),
          ),
          catchError((error: unknown) =>
            of(loadAllFileWithToolFailure({ error })),
          ),
        );
      }),
    );
  });

  loadThreadFilesWithOriginOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadThreadFilesWithOriginOrder),
      withLatestFrom(this.store.select(getThreadFilesWithOriginOrder)),
      filter(
        ([, threadFilesWithOriginOrder]) => !threadFilesWithOriginOrder.length,
      ),
      switchMap(([{ threadId }]) => {
        return this.filesService.loadThreadFilesWithOriginOrder(threadId).pipe(
          map((filesList) =>
            loadThreadFilesWithOriginOrderSuccess({ filesList }),
          ),
          catchError((error: unknown) =>
            of(loadThreadFilesWithOriginOrderFailure({ error })),
          ),
        );
      }),
    );
  });

  loadFilesForCarousel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFilesForCarousel),
      withLatestFrom(
        this.store.select(getCurrentProjectId),
        this.store.select(getCurrentThreadId),
      ),
      map(([{ placeType }, projectId, threadId]) => {
        switch (placeType) {
          case EFromFilePlace.FILE_PAGE:
            return loadAllFileWithTool();
          case EFromFilePlace.PROJECT_FILE_PAGE:
          case EFromFilePlace.PROJECT_WIDGET:
            return loadProjectFilesWithTool({ projectId });
          case EFromFilePlace.THREAD_FILE_PAGE:
          case EFromFilePlace.THREAD_WIDGET:
            return loadThreadFilesWithTool({ threadId });
          case EFromFilePlace.THREAD_MESSAGE:
            return loadThreadFilesWithOriginOrder({ threadId });
          default:
            return loadAllFileWithTool();
        }
      }),
    ),
  );

  setProductLoader$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setProductLoader),
      mergeMap(() => of(fitToObject())),
    ),
  );

  fitToObjectEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fitToObject),
        tap(() => this.unrealService.fitToObject()),
      ),
    { dispatch: false },
  );

  private getRequestParams(): any {
    const { sortBy, isUnviewed, image, video, unreal, webgl, otherFiles } =
      this.route.snapshot.queryParams;
    return {
      ...(sortBy && { status: sortBy as GQLFileStatusEnum }),
      ...(stringToBoolean(image) && {
        image: true,
      }),
      ...(stringToBoolean(video) && {
        video: true,
      }),
      ...(stringToBoolean(unreal) && {
        unreal: true,
      }),
      ...(stringToBoolean(webgl) && {
        webgl: true,
      }),
      ...(stringToBoolean(otherFiles) && {
        otherFiles: true,
      }),
      ...(stringToBoolean(isUnviewed) && {
        unviewedOnly: true,
      }),
    };
  }
}
