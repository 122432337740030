import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
/*
 confirm modal config:
 in open modal method reqiured fields:
 - text (confirmation text)
 - type ( use EconfirmModalType DELETE, CONFIRM, ...)
 - header (header text)
 use dialogService methods .open()
  ...
   public openModal(): void {
    const dialogConfig = {
      data: {
        id: this.thread.id,
        title: this.thread.title,
        text: 'This thread will be deleted permanently!',
        buttonText: 'Delete',
        type: EconfirmModalType.DELETE
      },
      header: `Delete thread #${this.thread.serialNumber}?`,
      styleClass: 'service-modal'
    };
    const ref = this.dialogService.open(ConfirmModalComponent, dialogConfig);
    // .onClose emit true if confirm
    ref.onClose.subscribe( (isConfirm) => {
      if (isConfirm) {
        this.removeThread();
      }
    });
  }
 */
export enum EconfirmModalType {
  DELETE = 'delete',
  CONFIRM = 'confirm',
  WARNING = 'warning',
  // this strings used in styles for css-classes (selector .service-modal)
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent implements OnDestroy {
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();
  title: string = this.config.data.title;
  warningText: string = this.config.data.text;
  typeStyles: string = this.config.data.type;
  btnText: string = this.config.data.buttonText ?? 'Confirm';
  isCloseButtonVisible = this.config.data.isCloseButtonVisible ?? true;

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
  ) {}

  confirm(): void {
    this.ref.close(true);
  }

  close(): void {
    this.ref.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
