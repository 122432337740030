<ng-container *ngIf="fileListView$ | async as fileListView">
  <div class="filter-row">
    <div class="filter-row__counter counter" *ngIf="!isAllFilesPage">
      <span class="counter__label">files total:&nbsp;</span
      ><strong
        >{{ filesCounter }} {{ filesCounter === 1 ? 'file' : 'files' }}</strong
      >
    </div>
    <span class="filter-row__selector selector" *ngIf="isAllFilesPage">
      <div class="selector__label">Project:</div>
      <form
        *ngIf="projectList$ | async as projectList"
        [formGroup]="projectListForm"
        class="selector__select"
      >
        <p-multiSelect
          #multiselect
          [filter]="false"
          [showToggleAll]="false"
          (onChange)="selectedProjectsHandle($event)"
          selectedItemsLabel="{0} items selected"
          [options]="projectList"
          optionLabel="title"
          formControlName="projects"
          class="filter-select"
          placeholder="All"
        >
          <ng-template pTemplate="header">
            <div
              class="selector__all-option"
              (click)="projectSelectAll(projectList)"
            >
              Select all
            </div>
            <div class="selector__all-option" (click)="projectSelectNone()">
              Select none
            </div>
          </ng-template>
        </p-multiSelect>
      </form>
    </span>
    <div class="filter-row__search">
      <div class="advanced-search" [class.not-empty]="isTouchedMarkerVisible">
        <app-search
          [searchString]="initialSearchQuery"
          (searchQuery)="handleSearch($event)"
          (isSearchOpen)="toggleDropdown()"
        ></app-search>

        <div class="advanced-search__dropblock" *ngIf="isSearchFieldOpen">
          <form [formGroup]="toolsFilterForm">
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="unviewed" />
                <span class="jlabel">Unviewed</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="image" />
                <span class="jlabel">Image</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="video" />
                <span class="jlabel">Video</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="unreal" />
                <span class="jlabel">Unreal 3D</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="webgl" />
                <span class="jlabel">WebGL 3D</span>
              </label>
            </div>
            <div class="checkbox">
              <label>
                <input type="checkbox" formControlName="otherFiles" />
                <span class="jlabel">Other files</span>
              </label>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- <div class="filter-row__collaboration">
      <form [formGroup]="toolsFilterForm" style="display: flex">
        <div class="checkbox" style="margin-right: 20px">
          <label>
            <input type="checkbox" formControlName="unviewed" />
            <span class="jlabel">Unviewed</span>
          </label>
        </div>
      </form>
    </div> -->
    <div class="date-selector" [class.active]="!isDateSelectorFormEmpty">
      <button
        type="button"
        class="date-selector__toggle"
        [class.active]="isDateSelectorOpen"
        (click)="toggleDateSelector()"
      >
        <app-icon href="#icon-calendar"></app-icon>
      </button>

      <div
        class="date-selector__box date-box"
        [class.active]="isDateSelectorOpen"
      >
        <div class="date-box__header">
          <h3>Find files</h3>
          <button
            class="clear-button"
            [disabled]="isDateSelectorFormEmpty"
            (click)="clearDateSelector()"
          >
            Clear
          </button>
        </div>
        <form class="date-box__form" [formGroup]="dateSelectorForm">
          <div class="date-box__input-box">
            <label>From</label>
            <p-calendar
              [appendTo]="calendarHolder"
              [maxDate]="maxDateFrom"
              [minDate]="minDateFrom"
              [selectOtherMonths]="true"
              panelStyleClass="calendar-box"
              formControlName="dateFrom"
            ></p-calendar>
          </div>
          <div class="date-box__divider">-</div>
          <div class="date-box__input-box">
            <label>To</label>
            <p-calendar
              [appendTo]="calendarHolder"
              [maxDate]="maxDateTo"
              [minDate]="minDateTo"
              [selectOtherMonths]="true"
              panelStyleClass="calendar-box"
              formControlName="dateTo"
            ></p-calendar>
          </div>
        </form>
        <div class="calendar-holder" #calendarHolder></div>
      </div>
    </div>
    <form class="filter-row__filter" [formGroup]="filtersStatusForm">
      <p-dropdown
        class="filter-dropdown"
        [options]="filterStatusOptions"
        formControlName="dropdownFilter"
        (onChange)="changeValue($event)"
        optionLabel="title"
      >
      </p-dropdown>
    </form>
    <div class="filter-row__mode">
      <div class="button-group">
        <button
          type="button"
          class="button-group__item"
          [class.active]="fileListView === viewType.CARD"
          (click)="toggleView(viewType.CARD)"
        >
          <app-icon href="#icon-grid-view"></app-icon>
        </button>
        <button
          type="button"
          class="button-group__item"
          (click)="toggleView(viewType.LIST)"
          [class.active]="fileListView === viewType.LIST"
        >
          <app-icon href="#icon-list-view"></app-icon>
        </button>
      </div>
    </div>
    <div class="filter-row__action" *ngIf="isAddFilesButtonVisible">
      <button
        type="button"
        class="button"
        (click)="openAddFileDialog()"
        [disabled]="isProjectDisabledForAddingFiles$ | async"
      >
        Add file
      </button>
    </div>
  </div>
</ng-container>
