<ng-container *ngIf="me$ | async as me">
  <div class="roles-modal__body">
    <div class="roles">
      <div class="roles__heading">
        <div class="roles__user">
          <div class="roles__user-info">
            <ngx-avatars
              class="avatar"
              [src]="me?.avatarPath"
              [name]="me?.fullName"
            ></ngx-avatars>
            <div>
              <span *ngIf="me?.fullName" class="name">{{ me?.fullName }}</span>
              <span
                *ngIf="!isNameEmailSame(me)"
                class="name"
                style="color: #929292"
                >{{ me?.email }}</span
              >
            </div>
          </div>
          <span class="roles__user-role">{{ me?.role | roleShortener }}</span>
        </div>
        <div class="roles__active-row">
          <h4>All users:</h4>
          <div class="roles__search">
            <app-search
              [searchString]="initialSearchQuery"
              [isSearchFieldOpen]="true"
              (searchQuery)="handleSearch($event)"
              (isSearchOpen)="toggleSearch()"
            >
            </app-search>
          </div>

          <div class="roles__filter">
            <div class="filter">
              <button
                type="button"
                #roleFilter
                class="filter__button"
                (click)="toggleFilter()"
              >
                <span class="filter__label">Filter by:</span>
                <span class="filter__selected">{{
                  selectedRole | roleShortener
                }}</span>
              </button>
            </div>
            <div class="droplist" *ngIf="isFilterOpen">
              <div class="droplist__mobile-header">
                <button type="button" class="close" (click)="toggleFilter()">
                  <app-icon href="#icon-chevrone-left"></app-icon>
                </button>
                Filters
              </div>

              <div class="droplist__content">
                <p-scrollPanel>
                  <ul class="role-list">
                    <li
                      class="role-list__item"
                      *ngFor="let role of rolesFilter"
                      [class.active]="selectedRole === role"
                    >
                      <button
                        type="button"
                        class="role-list__button"
                        (click)="filterByRole(role)"
                      >
                        {{ role | roleShortener }}
                      </button>
                    </li>
                  </ul>
                </p-scrollPanel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="users" class="roles__wrapper">
        <div
          *ngFor="let user of users; let i = index; trackBy: trackByFn"
          #userItem
          class="roles__user">
          <div class="roles__user-info">
            <ngx-avatars
              class="avatar"
              [src]="user?.avatarPath"
              [name]="user?.fullName"></ngx-avatars>
            <div>
              <span class="name">{{ user?.fullName }}</span>
              <span
                *ngIf="!isNameEmailSame(user)"
                class="name"
                style="color: #929292">{{ user?.email }}</span>
            </div>
          </div>
          <div
            class="roles__user-role"
            *ngIf="rolePermissions$ | async as rolePermissions">
            <button
              type="button"
              class="roles__user-select"
              [disabled]="!user?.canBeChanged"
              [class.active]="false"
              (click)="toggleOverlayPanel(op, $event)">
              {{ user?.role | roleShortener }}
              <span class="roles__user-change" *ngIf="user?.roleChanged">Role has been changed</span>
            </button>
            <p-overlayPanel
              #op
              [dismissable]="opDismissableValue()"
              [appendTo]="isInlineOverlay()? userItem : 'body'"
              [autoZIndex]="false"
              styleClass="menu-inline-overlay">
              <ng-template pTemplate>
                <ul class="menu-list">
                  <li
                    *ngFor="let role of user.availableRoles"
                    class="menu-list__item">
                    <button
                      class="menu-list__item-button"
                      [class.active]="user?.role === role"
                      (click)="changeRole(user, role, i, op)">
                      {{ role | roleShortener }}
                    </button>
                  </li>
                </ul>
              </ng-template>
            </p-overlayPanel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="roles-modal__footer roles-modal__footer--right">
    <button type="button" class="button button--clear" (click)="close()">
      Cancel
    </button>
    <button [disabled]="!requestsQueue.length" class="button" (click)="save()">
      Save
    </button>
  </div>
</ng-container>
