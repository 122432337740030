import { gql } from 'apollo-angular';

export const CLEAR_ACTIVITIES = gql`
  mutation {
    clearActivities {
      activities {
        id
        isViewed
      }
    }
  }
`;
