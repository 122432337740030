import { gql } from 'apollo-angular';

export const GET_FILE_VERSION_MARKER_AUTO_INCREMENT = gql`
  query ($id: ID!) {
    fileVersion(id: $id) {
      id
      markerAutoincrementValue
      markerGroups {
        id
      }
    }
  }
`;
