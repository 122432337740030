import { AsyncPipe, CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AvatarModule } from 'ngx-avatars';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { EditorModule } from 'primeng/editor';
import { FileUploadModule } from 'primeng/fileupload';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FileTipesPipeModule } from '@common/pipes/file-tipes-pipe/file-tipes.pipe.module';
import { ShortenerModule } from '@common/pipes/shortener/shortener.module';
import { TimeFromNowPipeModule } from '@common/pipes/time-from-now-pipe/time-from-now-pipe.module';
import { ConfirmDeleteFileModalModule } from '@common/modules/confirm-delete-file-modal/confirm-delete-file-modal.module';
import { ConfirmModalModule } from '@common/modules/confirm-modal/confirm-modal.module';
import { FileUploaderModule } from '@common/modules/file-uploader/file-uploader.module';
import { FilesWidgetModule } from '@common/modules/files-widget/files-widget.module';
import { SubscribersModule } from '@common/modules/subscribers/subscribers.module';
import { ThreadAddComponent } from './components/thread-add/thread-add.component';
import { TreadsFilterComponent } from './components/treads-filter/treads-filter.component';
import { TreadsItemComponent } from './components/treads-item/treads-item.component';
import { TreadsListRoutingModule } from './treads-list-routing.module';
import { TreadsListComponent } from './components/thread-list/treads-list.component';
import { ActivityWidgetComponent } from './components/activity-widget/activity-widget.component';
import { TreadsSidebarComponent } from './components/treads-sidebar/treads-sidebar.component';
import { ThreadsListSkeletonComponent } from './components/threads-list-skeleton/threads-list-skeleton.component';
import { AppIconModule } from '@common/modules/icon';
import { FileCardModule } from '@common/modules/file-card/file-card.module';
import { FullscreenGalleryModule } from '@common/modules/fullscreen-gallery/fullscreen-gallery.module';
import { FilesFilterModule } from '@common/modules/files-filter/files-filter.module';
import { FileListInThreadComponent } from './components/file-list-in-thread/file-list-in-thread.component';
import { SearchModule } from '@common/modules/search/search.module';

@NgModule({
  declarations: [
    TreadsListComponent,
    TreadsFilterComponent,
    TreadsItemComponent,
    TreadsSidebarComponent,
    ThreadAddComponent,
    ActivityWidgetComponent,
    ThreadsListSkeletonComponent,
    FileListInThreadComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    TreadsListRoutingModule,
    DynamicDialogModule,
    DialogModule,
    EditorModule,
    FileUploadModule,
    DropdownModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    TimeFromNowPipeModule,
    FileTipesPipeModule,
    FilesWidgetModule,
    ConfirmModalModule,
    ConfirmDeleteFileModalModule,
    SubscribersModule,
    ShortenerModule,
    RouterModule,
    FileUploaderModule,
    AvatarModule,
    SkeletonModule,
    TooltipModule,
    AppIconModule,
    FileCardModule,
    FullscreenGalleryModule,
    FilesFilterModule,
    OverlayPanelModule,
    SearchModule,
  ],
  providers: [DialogService, AsyncPipe],
  exports: [
    TreadsListComponent,
    TreadsFilterComponent,
    TreadsItemComponent,
    TreadsSidebarComponent,
  ],
})
export class TreadsListModule {}
