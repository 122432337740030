<header class="site-header" [class.menuOpen]="isMenuOpened">
  <button
    type="button"
    *ngIf="isMenuOpened"
    (click)="openMenu(false)"
    class="mobile-menu-button"
    [disabled]="(isAuth$ | async) === null"
  >
    <svg class="icon" width="24" height="24">
      <use xlink:href="#icon-close"></use>
    </svg>
  </button>
  <div class="site-header__logo">
    <a routerLink="/login" data-test-id="headerLogoLink">
      <img src="./assets/images/logo.svg" alt="3D Source" />
    </a>
  </div>
  <div class="site-header__navigation" *ngIf="isAuth$ | async">
    <app-navigation (isNavigationDisabled)="openMenu(false)"></app-navigation>
  </div>
  <div class="site-header__activities" *ngIf="isAuth$ | async">
    <!-- notification -->
    <app-notifications></app-notifications>
    <!-- user -->
    <app-user-profile
      (isNavigationDisabled)="openMenu(false)"
    ></app-user-profile>
    <button
      *ngIf="canCreateProject"
      type="button"
      class="button button--clear add-project"
      (click)="goToCreateProject()"
      [disabled]="(isAuth$ | async) === null"
      data-test-id="headerCreateProjectButton"
    >
      Add project
    </button>
  </div>
  <button
    type="button"
    *ngIf="!isMenuOpened"
    (click)="openMenu(true)"
    class="mobile-menu-button"
    data-test-id="openMobileMenuButton"
  >
    <svg class="icon" width="32" height="32">
      <use xlink:href="#icon-menu"></use>
    </svg>
  </button>
</header>
