import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../../services/auth.service';
import { loadCurrentUser } from '../../store/users/users.actions';
import { saveCurrentFileId } from '../../store/files/files.actions';
import { isSharingHash } from '../../helpers';
import { CurrentUserService } from '../../services/current-user.service';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private store: Store,
    private currentUserService: CurrentUserService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    const fileIdString = route.children[0].params.toolId ?? '';
    const isHash = isSharingHash(fileIdString);
    if (isHash) {
      this.store.dispatch(saveCurrentFileId(fileIdString));
      return true;
    }

    if (this.route.snapshot.paramMap.has('accept-invitation')) {
      return true;
    }

    if (this.auth.isAuthenticated()) {
      this.store.dispatch(loadCurrentUser());
      return true;
    }

    this.currentUserService.logout(state.url);
    return false;
  }
}
