import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RolesShortenerPipe } from './roles-shortener.pipe';

@NgModule({
  declarations: [RolesShortenerPipe],
  imports: [CommonModule],
  exports: [RolesShortenerPipe],
  providers: [RolesShortenerPipe],
})
export class RolesShortenerPipeModule {}
