import { gql } from 'apollo-angular';

export const GET_PROJECT_FILES_IDS = gql`
  query (
    $id: ID!
    $status: FileStatusEnum
    $unviewedOnly: Boolean
    $useTools: Boolean
  ) {
    projectFiles(
      id: $id
      status: $status
      unviewedOnly: $unviewedOnly
      useTools: $useTools
    ) {
      files {
        id
        type
        versions {
          id
        }
      }
    }
  }
`;
