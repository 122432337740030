import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLFile,
  GQLFileVersion,
  GQLMessage,
  GQLMessageList,
} from '../../../../schemas/schemas';
import { changeHasNewVersionInFile } from '../../helpers/utils';
import {
  createFileVersionSuccess,
  markApprovedFileAsViewedSuccess,
  markVersionAsViewed,
  markVersionAsViewedSuccess,
} from '../files/files.actions';
import {
  clearMessage,
  clearMessagesList,
  createMessage,
  createMessageFailure,
  createMessageSuccess,
  deleteMessage,
  deleteMessageFailure,
  deleteMessageSuccess,
  editMessage,
  editMessageFailure,
  editMessageSuccess,
  loadMessage,
  loadMessageFailure,
  loadMessagesList,
  loadMessagesListsFailure,
  loadMessagesListSuccess,
  loadMessageSuccess,
  markMessageAsViewedSuccess,
  setIsLoadingMessages,
} from './messages.actions';

export interface MessagesState {
  messagesList: GQLMessageList;
  isLoadingMessages: boolean;
  error?: unknown;
  message: GQLMessage | null;
  isMessageLoading: boolean;
}

export const initialMessagesState: MessagesState = {
  messagesList: {
    total: 0,
    skip: 0,
    limit: 0,
    unread: 0,
    items: [],
  },
  isLoadingMessages: false,
  message: null,
  isMessageLoading: false,
};

const messagesReducer = createReducer(
  initialMessagesState,
  // LOAD PROJECTS
  on(loadMessagesList, (state) => ({
    ...state,
    isLoadingMessages: true,
  })),
  on(setIsLoadingMessages, (state, { isLoadingMessages }) => ({
    ...state,
    isLoadingMessages,
  })),
  on(loadMessagesListSuccess, (state, { messagesList }) => ({
    ...state,
    messagesList,
    isLoadingMessages: false,
  })),
  on(loadMessagesListsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingMessages: false,
  })),
  on(clearMessagesList, (state) => ({
    ...state,
    messagesList: {} as GQLMessageList,
  })),
  // CREATE MESSAGE
  on(createMessage, (state) => ({
    ...state,
    isMessageLoading: true,
  })),
  on(createMessageSuccess, (state, { message }) => {
    return {
      ...state,
      messagesList: {
        ...state.messagesList,
        items: [...(state?.messagesList?.items || []), message],
        total: (state?.messagesList?.total || 0) + 1,
      },
      isMessageLoading: false,
    };
  }),
  on(createMessageFailure, (state, { error }) => ({
    ...state,
    isMessageLoading: false,
  })),
  // EDIT MESSAGE
  on(editMessage, (state) => ({
    ...state,
    isMessageLoading: true,
  })),
  on(editMessageSuccess, (state, { message }) => {
    return {
      ...state,
      messagesList: {
        ...state.messagesList,
        items: state?.messagesList?.items.map((messageElement) =>
          message.id === messageElement.id ? message : messageElement,
        ),
      },
      isMessageLoading: false,
    };
  }),
  on(editMessageFailure, (state, { error }) => ({
    ...state,
    isMessageCreating: false,
  })),
  // DELETE PROJECT
  on(deleteMessage, (state) => ({
    ...state,
  })),
  on(deleteMessageSuccess, (state, { removeMessage }) => {
    return {
      ...state,
      messagesList: {
        ...state.messagesList,
        items: [
          ...(state.messagesList.items || []).filter(
            (message: GQLMessage | null) => message?.id !== removeMessage.id,
          ),
        ],
        total: (state?.messagesList?.total || 0) - 1,
      },
    };
  }),
  on(deleteMessageFailure, (state, { error }) => ({
    ...state,
  })),
  // LOAD THREAD
  on(loadMessage, (state) => ({
    ...state,
  })),
  on(loadMessageSuccess, (state, { message }) => {
    return {
      ...state,
      message,
    };
  }),
  on(loadMessageFailure, (state, { error }) => ({
    ...state,
  })),
  on(clearMessage, (state) => ({
    ...state,
    message: null,
  })),
  on(markMessageAsViewedSuccess, (state) => ({
    ...state,
    messagesList: {
      ...state.messagesList,
      unread: state.messagesList.unread - 1,
    },
  })),
  on(markApprovedFileAsViewedSuccess, (state, { file }) => ({
    ...state,
    messagesList: {
      ...state.messagesList,
      items: (state.messagesList.items || []).map((messageFile) =>
        messageFile.id === file.id
          ? {
              ...messageFile,
              hasNewApprove: file.hasNewApprove,
              hasNewComment: file.hasNewComment,
              hasNewVersion: file.hasNewVersion,
            }
          : messageFile,
      ),
    },
  })),
  on(markVersionAsViewedSuccess, (state, { versions }) => {
    const { messagesList } = state;
    return {
      ...state,
      messagesList: {
        ...messagesList,
        items: (messagesList.items || []).map(
          (message: GQLMessage): GQLMessage => ({
            ...message,
            files: message.files.map((messageFile: GQLFile) =>
              changeHasNewVersionInFile(messageFile, versions),
            ),
          }),
        ),
      },
    };
  }),
  on(createFileVersionSuccess, (state, { fileVersion, fileId }) => {
    const { messagesList } = state;
    return {
      ...state,
      messagesList: messagesList
        ? {
            ...messagesList,
            items: (messagesList.items || []).map(
              (message: GQLMessage): GQLMessage => ({
                ...message,
                files: message.files.map((file: GQLFile) => {
                  if (file && file.id === fileId) {
                    return {
                      ...file,
                      versions: [
                        fileVersion,
                        ...(file.versions as GQLFileVersion[]),
                      ],
                    };
                  }
                  return file;
                }),
              }),
            ),
          }
        : messagesList,
    };
  }),
);

export function MessagesReducer(
  state: MessagesState | undefined,
  action: Action,
): MessagesState {
  return messagesReducer(state, action);
}
