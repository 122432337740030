import { createAction, props } from '@ngrx/store';
import {
  GQLApproveThreadInput,
  GQLCreateThreadInput,
  GQLEditThreadInput,
  GQLMarkThreadAsViewedInput,
  GQLRemoveInput,
  GQLRemovePayload,
  GQLThread,
  GQLThreadList,
} from '../../../../schemas/schemas';

function scoped(templateString: TemplateStringsArray) {
  return `[Threads] ${templateString[0]}`;
}

export const setIsLoadingThreads = createAction(
  scoped`Set Loading Flag`,
  props<{ isLoadingThreads: boolean }>(),
);

// SAVE THREAD PROJECT ID
export const saveCurrentThreadId = createAction(
  scoped`Save Current Thread Id`,
  props<{ currentThreadId: string }>(),
);
export const clearCurrentThreadId = createAction(
  scoped`Clear Current Thread Id`,
);

// LOAD THREADS
export const loadThreadsList = createAction(
  scoped`Load Threads List`,
  props<{ projectId: string; sortBy: string; searchQuery: string }>(),
);

export const loadThreadsListSuccess = createAction(
  scoped`Load Threads Success`,
  props<{ projectId: string; threadsList: GQLThreadList }>(),
);

export const loadThreadsListsFailure = createAction(
  scoped`Load Threads Failure`,
  props<{ error: unknown }>(),
);
export const clearThreadsList = createAction(scoped`Clear Threads`);

// CREATE THREAD
export const createThread = createAction(
  scoped`Create thread`,
  props<{ input: GQLCreateThreadInput }>(),
);

export const createThreadSuccess = createAction(
  scoped`Create Thread Success`,
  props<{ thread: GQLThread }>(),
);

export const createThreadFailure = createAction(
  scoped`Create Thread Failure`,
  props<{ error: unknown }>(),
);

// EDIT THREAD
export const editThread = createAction(
  scoped`Edit Thread`,
  props<{ threadInput: GQLEditThreadInput }>(),
);

export const editThreadSuccess = createAction(
  scoped`Edit Thread Success`,
  props<{ thread: GQLThread }>(),
);

export const editThreadFailure = createAction(
  scoped`Edit Thread Failure`,
  props<{ error: unknown }>(),
);

// IS THREAD COMMENT EDITABLE
export const canMessageEdit = createAction(
  scoped`Can Message Edit`,
  props<{ isMessageEditable: boolean }>(),
);

// DELETE THREAD
export const deleteThread = createAction(
  scoped`Delete Thread`,
  props<{ input: GQLRemoveInput }>(),
);

export const deleteThreadSuccess = createAction(
  scoped`Delete Thread Success`,
  props<{ removeThread: GQLRemovePayload }>(),
);

export const deleteThreadFailure = createAction(
  scoped`Delete Thread Failure`,
  props<{ error: unknown }>(),
);

// LOAD THREAD
export const loadThread = createAction(
  scoped`Load Thread`,
  props<{ threadId: string }>(),
);

export const loadThreadSuccess = createAction(
  scoped`Load Thread Success`,
  props<{ thread: GQLThread }>(),
);

export const loadThreadFailure = createAction(
  scoped`Load Project Failure`,
  props<{ error: unknown }>(),
);

// APPROVE THREAD
export const approveThread = createAction(
  scoped`Approve Thread`,
  props<{ input: GQLApproveThreadInput }>(),
);

export const approveThreadSuccess = createAction(
  scoped`Approve Thread Success`,
  props<{ thread: GQLThread }>(),
);

export const approveThreadFailure = createAction(
  scoped`Approve Thread Failure`,
  props<{ error: unknown }>(),
);

// DISAPPROVE THREAD
export const disapproveThread = createAction(
  scoped`Disapprove Thread`,
  props<{ input: GQLApproveThreadInput }>(),
);

export const disapproveThreadSuccess = createAction(
  scoped`Disapprove Thread Success`,
  props<{ thread: GQLThread }>(),
);

export const disapproveThreadFailure = createAction(
  scoped`Disapprove Thread Failure`,
  props<{ error: unknown }>(),
);

// MARK_THREAD_AS_VIEWED
export const markAsViewed = createAction(
  scoped`MarkAsViewed Thread`,
  props<{ input: GQLMarkThreadAsViewedInput }>(),
);

export const markAsViewedFailure = createAction(
  scoped`Edit Thread Failure`,
  props<{ error: unknown }>(),
);
export const clearThread = createAction(scoped`Clear Thread Object`);
