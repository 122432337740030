import { gql } from 'apollo-angular';

export const EDIT_NOTIFICATIONS_SETTINGS = gql`
  mutation ($input: EditNotificationSettingsInput!) {
    editNotificationSettings(input: $input) {
      me {
        id
        fullName
        notificationSettings {
          isEnabled
          onThreadCreate {
            isAppEnabled
            isEmailEnabled
          }
          onThreadApprove {
            isAppEnabled
            isEmailEnabled
          }
          onMessageCreate {
            isAppEnabled
            isEmailEnabled
          }
          onFileUpdate {
            isAppEnabled
            isEmailEnabled
          }
          onFileComment {
            isAppEnabled
            isEmailEnabled
          }
          onFileApprove {
            isAppEnabled
            isEmailEnabled
          }
        }
      }
    }
  }
`;
