import { gql } from 'apollo-angular';
import { FILE_VERSION } from '../variables';

export const GET_FILE_COLLECTION = gql`
  query files(
    $status: FileStatusEnum
    $useTools: Boolean
    $unviewedOnly: Boolean
    $searchQuery: String
    $dateRange: DateRange
    $limit: Int
    $skip: Int
    $ids: [ID!]
    $types: [FileTypeEnum!]
  ) {
    files(
      status: $status
      useTools: $useTools
      unviewedOnly: $unviewedOnly
      searchQuery: $searchQuery
      dateRange: $dateRange
      limit: $limit
      skip: $skip
      ids: $ids
      types: $types
    ) {
      skip
      limit
      total
      items {
        project {
          id
          title
        }
        files {
          count
          title
          thread {
            id
            title
            serialNumber
          }
          files {
            id
            commentCount
            status
            useTools
            type
            name
            extension
            updatedAt
            createdAt
            hasNewApprove
            extension
            hasNewComment
            hasNewVersion
            canRemove
            versions {
              id
              path
              previewPath
              title
              serialNumber
              extension
              status
              hasNewComment
              canRemove
            }
          }
        }
      }
    }
  }
`;
