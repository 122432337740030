/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 3/12/21, 7:00 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const REMOVE_INVITATION = gql`
  mutation ($input: RemoveInput!) {
    removeInvitation(input: $input) {
      id
      success
    }
  }
`;
