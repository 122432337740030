import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/common/helpers/constants/config.constants';
import { AppConfig } from './app/common/types/app-config.model';
import { environment } from './environments/environment';
import mixpanel from 'mixpanel-browser';

async function loadAppConfig(): Promise<AppConfig> {
  return fetch('assets/config.json')
    .then((response) => response.json())
    .catch((error) => {
      console.error('App configuration load error', error);
    });
}

loadAppConfig()
  .then((config: AppConfig) => {
    if (environment.production) {
      enableProdMode();
    }

    mixpanel.init(environment.mixpanelToken, { debug: true, ignore_dnt: true });
    mixpanel.track('Platform started');

    return platformBrowserDynamic([
      { provide: APP_CONFIG, useValue: config },
    ]).bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
