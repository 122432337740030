import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLFile,
  GQLFileVersion,
  GQLThread,
  GQLThreadList,
} from '../../../../schemas/schemas';
import {
  createFileVersionSuccess,
  markVersionAsViewedSuccess,
} from '../files/files.actions';
import {
  approveThreadFailure,
  canMessageEdit,
  clearCurrentThreadId,
  clearThread,
  clearThreadsList,
  createThread,
  createThreadFailure,
  createThreadSuccess,
  deleteThreadFailure,
  deleteThreadSuccess,
  disapproveThreadFailure,
  disapproveThreadSuccess,
  editThread,
  editThreadFailure,
  editThreadSuccess,
  loadThread,
  loadThreadFailure,
  loadThreadsList,
  loadThreadsListsFailure,
  loadThreadsListSuccess,
  loadThreadSuccess,
  markAsViewed,
  markAsViewedFailure,
  saveCurrentThreadId,
  setIsLoadingThreads,
} from './threads.actions';

export interface ThreadsState {
  threadsList: GQLThreadList;
  isLoadingThreads: boolean;
  error?: unknown;
  thread: GQLThread | null;
  isThreadLoading: boolean;
  isThreadMessageEditorOpen: boolean;
  currentThreadId: string;
}

export const initialThreadsState: ThreadsState = {
  threadsList: {} as GQLThreadList,
  isLoadingThreads: false,
  thread: null,
  isThreadLoading: false,
  isThreadMessageEditorOpen: false,
  currentThreadId: '',
};

const unselectViewedFiles = (files: GQLFile[], id: string) => {
  return files.map((file) => ({
    ...file,
    hasNewVersion:
      !file.useTools && file.versions?.find((version) => version.id === id)
        ? false
        : file.hasNewVersion,
  }));
};

const threadsReducer = createReducer(
  initialThreadsState,
  on(saveCurrentThreadId, (state, { currentThreadId }) => ({
    ...state,
    currentThreadId,
  })),
  on(clearCurrentThreadId, (state) => ({
    ...state,
    currentThreadId: '',
  })),

  // LOAD PROJECTS
  on(loadThreadsList, (state) => ({
    ...state,
    isLoadingThreads: true,
  })),
  on(setIsLoadingThreads, (state, { isLoadingThreads }) => ({
    ...state,
    isLoadingThreads,
  })),
  on(loadThreadsListSuccess, (state, { threadsList }) => ({
    ...state,
    threadsList,
    isLoadingThreads: false,
  })),
  on(loadThreadsListsFailure, (state, { error }) => ({
    ...state,
    error,
    isLoadingThreads: false,
  })),
  on(clearThreadsList, (state) => ({
    ...state,
    threadsList: {} as GQLThreadList,
  })),
  // CREATE THREAD
  on(createThread, (state) => ({
    ...state,
    isThreadLoading: true,
  })),
  on(createThreadSuccess, (state, { thread }) => {
    return {
      ...state,
      threadsList: {
        ...state.threadsList,
        items: [thread, ...(state?.threadsList?.items || [])],
        total: (state?.threadsList?.total || 0) + 1,
      },
      isThreadLoading: false,
    };
  }),
  on(createThreadFailure, (state, { error }) => ({
    ...state,
    isThreadLoading: false,
  })),
  // EDIT THREAD
  on(editThread, (state) => ({
    ...state,
    isThreadLoading: true,
  })),
  on(editThreadSuccess, (state, { thread }) => {
    return {
      ...state,
      threadsList: {
        ...state.threadsList,
        items: (state?.threadsList?.items || []).map((threadElement) =>
          thread.id === threadElement.id ? thread : threadElement,
        ),
      },
      thread: state.thread ? { ...state.thread, ...thread } : null,
      isThreadLoading: false,
    };
  }),
  on(editThreadFailure, (state, { error }) => ({
    ...state,
    isThreadLoading: false,
  })),
  // DELETE THREAD

  on(deleteThreadSuccess, (state, { removeThread }) => {
    return {
      ...state,
      threadsList: {
        ...state.threadsList,
        items: [
          ...(state.threadsList.items || []).filter(
            (thread: GQLThread) => thread?.id !== removeThread.id,
          ),
        ],
        total: (state?.threadsList?.total || 0) - 1,
      },
    };
  }),
  on(deleteThreadFailure, (state, { error }) => ({
    ...state,
  })),
  // LOAD THREAD
  on(loadThread, (state) => ({
    ...state,
  })),
  on(loadThreadSuccess, (state, { thread }) => {
    return {
      ...state,
      thread,
    };
  }),
  on(loadThreadFailure, (state, { error }) => ({
    ...state,
  })),
  on(clearThread, (state) => ({
    ...state,
    thread: null,
  })),
  // APPROVE THREAD
  on(approveThreadFailure, (state, { error }) => ({
    ...state,
  })),
  // APPROVE THREAD
  on(disapproveThreadSuccess, (state) => ({
    ...state,
  })),
  on(disapproveThreadFailure, (state, { error }) => ({
    ...state,
  })),
  // MARK_THREAD_AS_VIEWED THREAD
  on(markAsViewed, (state) => ({
    ...state,
  })),
  on(markAsViewedFailure, (state, { error }) => ({
    ...state,
  })),
  on(canMessageEdit, (state, { isMessageEditable }) => ({
    ...state,
    isThreadMessageEditorOpen: isMessageEditable,
  })),
  on(markVersionAsViewedSuccess, (state, { versions }) => {
    const { thread } = state;

    return {
      ...state,
      thread: thread
        ? {
            ...thread,
            files: {
              ...thread.files,
              items: unselectViewedFiles(thread.files.items, versions[0].id),
            },
            ownFiles: unselectViewedFiles(thread.ownFiles, versions[0].id),
          }
        : thread,
    };
  }),
  on(createFileVersionSuccess, (state, { fileVersion, fileId }) => {
    const { thread } = state;
    return {
      ...state,
      thread: thread
        ? {
            ...thread,
            files: {
              ...thread.files,
              items: thread.files.items.map((file) => {
                if (file && file.id === fileId) {
                  return {
                    ...file,
                    versions: [
                      fileVersion,
                      ...(file.versions as GQLFileVersion[]),
                    ],
                  };
                }
                return file;
              }),
            },
          }
        : thread,
    };
  }),
);

export function ThreadReducer(
  state: ThreadsState | undefined,
  action: Action,
): ThreadsState {
  return threadsReducer(state, action);
}
