import { createSelector } from '@ngrx/store';
import { getPlatformState, PlatformState } from '../index';
import {
  GQLComment,
  GQLMarker,
  GQLMarkerGroup,
} from '../../../../schemas/schemas';
import { IActiveMarker } from '../../types/IActiveMarker';
import { IChatCoordinates, IMarkerPositions } from '../../types/interfaces';
import { MarkersState } from './markers.reducer';
import { isJSON } from '../../helpers';

export const getMarkerState = createSelector(
  getPlatformState,
  (state: PlatformState) => state.markersState,
);

export const getMarkers = createSelector(
  getMarkerState,
  (state: MarkersState): GQLMarker[] => {
    return state.markers;
  },
);

export const getMarkersWithParsedCoords = createSelector(
  getMarkerState,
  ({ markers }: MarkersState): GQLMarker[] => {
    return markers.map((marker: GQLMarker) => {
      return {
        ...marker,
        coordinates: isJSON(marker.coordinates)
          ? JSON.parse(marker.coordinates)
          : marker.coordinates,
      };
    });
    // .filter((marker) => !(marker as any).isNew);
  },
);

export const getMarkerCommentCreateLoading = createSelector(
  getMarkerState,
  (state: MarkersState): boolean => {
    return state.markerCommentCreateLoading;
  },
);

export const getMarkersGroups = createSelector(
  getMarkerState,
  (state: MarkersState): GQLMarkerGroup[] => {
    return state.markersGroups;
  },
);

export const getMarkersAmount = createSelector(
  getMarkerState,
  (state: MarkersState): number => {
    return state.markers.length ?? 0;
  },
);

export const getStreamMarkersAmount = createSelector(
  getMarkersGroups,
  (markersGroups): number => {
    return (
      markersGroups.reduce((acc, { markers }) => acc + markers.length, 0) ?? 0
    );
  },
);

export const getActiveMarker = createSelector(
  getMarkerState,
  ({ activeMarker }): GQLMarker | null => {
    return activeMarker;
  },
);

export const getMarkerComments = createSelector(
  getMarkers,
  (markers: GQLMarker[], id: string) => {
    return (
      markers.find((marker: GQLMarker) => marker.id === id)?.comments || []
    );
  },
);

export const getChatCoordinates = createSelector(
  getMarkerState,
  (state: MarkersState): IChatCoordinates | null => {
    return state?.chatCoordinates || null;
  },
);

export const getMarkerIdForOpeningFromSide = createSelector(
  getMarkerState,
  (state: MarkersState): string | null => {
    return state.markerIdForOpeningFromSide || null;
  },
);

export const getMarkersPositions = createSelector(
  getMarkerState,
  (state: MarkersState): any | null => {
    return state.markersPositions || null;
  },
);

export const getCurrentMarker = createSelector(
  getMarkers,
  getMarkerIdForOpeningFromSide,
  (
    markers: GQLMarker[],
    markerIdForOpeningFromSide,
  ): GQLMarker | any | null => {
    return markerIdForOpeningFromSide
      ? markers.find(
          (marker: GQLMarker) => marker.id === markerIdForOpeningFromSide,
        )
      : null;
  },
);

export const getMarkerGroupsCreating = createSelector(
  getMarkerState,
  ({ markerGroupsCreating }) => {
    return markerGroupsCreating;
  },
);

export const getCurrentMarkerPosition = createSelector(
  getMarkersPositions,
  getChatCoordinates,
  (
    markersPositions: IMarkerPositions[],
    chatCoorditanes,
  ): GQLMarker | any | null => {
    return (
      markersPositions.find(
        (marker: IMarkerPositions) =>
          marker.markerId === chatCoorditanes?.markerId,
      ) || null
    );
  },
);

// export const getCurrentMarkerStatus = createSelector(
//   getCurrentMarker,
//   (marker: GQLMarker) => {
//     return marker?.status;
//   },
// );

export const getCurrentMarkerStatus = createSelector(
  getActiveMarker,
  (marker) => {
    return marker?.status;
  },
);

export const getCurrentMarkerSerialNumber = createSelector(
  getCurrentMarker,
  (marker) => {
    return marker?.serialNumber || 1;
  },
);

export const getMaxSerialNumber = createSelector(
  getMarkers,
  (markers): number => {
    return Math.max(...markers.map((marker) => marker.serialNumber));
  },
);

export const getCurrentMarkerId = createSelector(
  getActiveMarker,
  (activeMarker): string => {
    return activeMarker?.id ?? '';
  },
);

export const getNextMarkerId = createSelector(
  getCurrentMarkerId,
  getMarkers,
  (currentMarkerId, markers): string | null => {
    if (!currentMarkerId) {
      return null;
    }
    return (
      markers[markers.findIndex((marker) => marker.id === currentMarkerId) + 1]
        ?.id || null
    );
  },
);

export const getPrevMarkerId = createSelector(
  getCurrentMarkerId,
  getMarkers,
  (currentMarkerId, markers): string | null => {
    if (!currentMarkerId) {
      return null;
    }
    return (
      markers[markers.findIndex((marker) => marker.id === currentMarkerId) - 1]
        ?.id || null
    );
  },
);

export const getNextPrevViewData = createSelector(
  getNextMarkerId,
  getPrevMarkerId,
  (nextMarkerId, prevMarkerId) => ({
    nextMarkerId,
    prevMarkerId,
  }),
);

export const getActiveMarkerWithParsedCoords = createSelector(
  getActiveMarker,
  (activeMarker): GQLMarker | null => {
    const marker = activeMarker as unknown as GQLMarker;

    if (marker) {
      return {
        ...marker,
        coordinates: isJSON(marker?.coordinates)
          ? JSON.parse(marker?.coordinates)
          : marker?.coordinates,
      } as GQLMarker;
    }
    return null;
  },
);

export const getActiveComments = createSelector(
  getActiveMarkerWithParsedCoords,
  (activeMarker): GQLComment[] => {
    return activeMarker?.comments || [];
  },
);

export const chatViewModel = createSelector(
  getActiveComments,
  getCurrentMarkerStatus,
  (commentList, markerStatus) => ({
    commentList,
    markerStatus,
    isCommentListEmpty: commentList.length === 0,
  }),
);
