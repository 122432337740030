import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() searchString: string = '';
  @Input() isSearchFieldOpen = false;

  @Output() searchQuery: EventEmitter<string> = new EventEmitter<string>();
  @Output() isSearchOpen: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('search') searchInput!: ElementRef;

  searchForm: FormGroup = this.formBuilder.group({
    search: [this.searchString, [Validators.maxLength(255)]],
  });

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.clearSearchInput();
    this.handleSearch();
    this.checkForSearchStringChange(this.searchString);

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ searchQuery }) => {
        this.checkForSearchStringChange(searchQuery);
      });
  }

  get isSearchEmpty(): boolean {
    return this.search?.value.length === 0;
  }

  get search(): AbstractControl | null {
    return this.searchForm.get('search');
  }

  toggleSearchInput(): void {
    if (this.searchForm.touched && this.isSearchFieldOpen) {
      this.clearSearchInput();
    }
    this.isSearchFieldOpen = !this.isSearchFieldOpen;
    this.isSearchOpen.emit(this.isSearchFieldOpen);

    if (this.isSearchFieldOpen) {
      this.searchInput.nativeElement.focus();
    }

    if (!this.isSearchFieldOpen) {
      this.clearSearchInput();
    }
  }

  clearSearchInput(): void {
    const formSearchString: string = this.search?.value;

    if (formSearchString !== '') {
      this.search?.setValue('');
      this.searchQuery.emit('');
      this.isSearchFieldOpen = false;
    }
  }

  checkForSearchStringChange(query: string): void {
    if (!query) {
      return;
    }

    this.isSearchFieldOpen = true;
    this.search?.setValue(query);
  }

  private handleSearch(): void {
    this.searchForm.valueChanges
      .pipe(debounceTime(500), takeUntil(this.unsubscribe$))
      .subscribe((formValue: string) => {
        const searchQuery: string = String(formValue.search);

        if (this.searchForm.dirty) {
          this.searchQuery.emit(searchQuery);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
