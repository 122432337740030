<ng-container *ngIf="fileListView$ | async as fileListView">
  <div class="layout layout--projects">
    <ng-container *ngIf="threadMainInfo$ | async as threadMainInfo">
      <div class="layout__heading">
        <div class="layout__title">
          <button type="button" class="back-link" (click)="goBack()">
            <svg class="icon" width="24" height="24">
              <use xlink:href="#icon-chevrone-left"></use>
            </svg>
          </button>
          <h2>Files of thread#{{ threadMainInfo?.serialNumber }}</h2>
        </div>
      </div>
    </ng-container>
    <div class="layout__filter-row">
      <app-files-filter
        [filesCounter]="filesQuantity$ | async"
        (searchQuery$)="setSearchQuery($event)"
        [filterLocation]="filePlace.THREAD_FILE_PAGE"
      ></app-files-filter>
    </div>
    <div class="layout__content">
      <!-- .files--list -->
      <div class="files" [class.files--list]="fileListView === viewType.LIST">
        <div class="grid-box">
          <div class="grid-box__labels">
            <span class="grid-box__labels-item">Image</span>
            <span class="grid-box__labels-item grid-box__labels-item--title"
              >Image title</span
            >
            <span class="grid-box__labels-item">Last updated</span>
            <span class="grid-box__labels-item">Creator</span>
            <span class="grid-box__labels-item center">Versions</span>
            <span class="grid-box__labels-item center">Comments</span>
            <span class="grid-box__labels-item"> </span>
          </div>
          <ng-container
            *ngIf="(filesListLoading$ | async) === false; else skeleton"
          >
            <ng-container *ngIf="threadFiles$ | async as threadFiles">
              <ng-container *ngIf="threadFiles.length > 0; else emptyList">
                <div class="grid-box__content">
                  <app-file-card
                    *ngFor="let file of threadFiles"
                    [file]="file"
                    (click)="openGallery(file)"
                    [filePlace]="filePlace.THREAD_FILE_PAGE"
                    [isListView]="fileListView === viewType.LIST"
                  >
                  </app-file-card>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #skeleton>
    <div class="empty-message">Loading...</div>
  </ng-template>
  <ng-template #emptyList>
    <div class="empty-message">No results</div>
  </ng-template>
</ng-container>
