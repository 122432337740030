import { GQLFileVersion } from '../../../../schemas/schemas';

export function nextFileVersion(
  versions: GQLFileVersion[] = [],
  currentVersionId: string = '',
): GQLFileVersion | null {
  const fileVersionIndex = versions.findIndex(
    (version: GQLFileVersion) => version.id === currentVersionId,
  );
  if (fileVersionIndex === -1 || fileVersionIndex === undefined) {
    return null;
  }
  return versions[
    versions.length > fileVersionIndex + 1 ? fileVersionIndex + 1 : 0
  ];
}
