/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
import { SimpleChanges } from '@angular/core';

export enum ChangesStrategy {
  First = 'firstTime',
  Each = 'eachTime',
  NonFirst = 'nonFirst',
}

export function TrackChanges<Type>(
  key: string,
  methodName: string,
  strategy: ChangesStrategy = ChangesStrategy.Each,
) {
  return (targetClass: any, functionName: string, descriptor: any): void => {
    const source = descriptor.value;

    descriptor.value = function (changes: SimpleChanges) {
      if (changes && changes[key] && changes[key].currentValue != null) {
        const isFirstChange = changes[key].firstChange;

        if (
          strategy === ChangesStrategy.Each ||
          (strategy === ChangesStrategy.First && isFirstChange) ||
          (strategy === ChangesStrategy.NonFirst && !isFirstChange)
        ) {
          targetClass[methodName].call(this, changes[key].currentValue as Type);
        }
      }

      return source.call(this, changes);
    };

    return descriptor;
  };
}
