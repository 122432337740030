export const enum StreamCommands {
  CHANGE_RESOLUTION = 'changeResolution',
  TAKE_RENDER = 'takeRender',
  SELECT_PRODUCT = 'selectProduct',
  SET_MATERIAL = 'setMaterial',
  TAKE_LAYERED_SCREENSHOT = 'takeLayeredScreenshot',
  TAKE_360_SCREENSHOT = 'take360Screenshot',
  TAKE_360_PREVIEW = 'take360Preview',
  FULL_VISION_ENABLE = '360Enable',
  FULL_VISION_DISABLE = '360Disable',
  VerticalRotate = '360VerticalRotate',
  LOAD_LEVEL = 'loadLevel',
  HIDE_LEVEL = 'hideLevel',
  REMOVE_FOCUSED = 'removeFocused',
  RESET_SCENE = 'resetScene',
  ZOOM = 'zoom',
  PING = 'ping',
}

export enum EUIInteractionCommand {
  VARIANT_MANAGER = 'variant',
  CHANGE_OPTION = 'change_option',
  TAKE_RENDER = 'takeRender',
  TAKE_360_PREVIEW = 'take360Preview',
  CHANGE_RESOLUTION = 'changeResolution',
  CHANGE_FOV = 'changeFov',
  CUSTOM_CAMERA = 'customCamera',
  PRESET_CAMERA = 'presetCamera',
  FLIP_CAMERA = 'flipCamera',
  LOAD_MODEL = 'loadModel',
  SELECT_PRODUCT = 'selectProduct',
  CHANGE_HDR = 'changeHdr',
  SET_MATERIAL = 'setMaterial',
  FOCUS_OBJECT = 'onFocusObject',
  REMOVE_FOCUSED = 'removeFocused',
  RESET_SCENE = 'resetScene',
  REQUEST_CAMERA_PRESET = 'requestCameraPreset',
  APPLY_CAMERA_PRESET = 'applyCameraPreset',
  SAVE_STREAM_STATE = 'saveStreamState',
  LOAD_STREAM_STATE = 'loadStreamState',
  PING = 'ping',
  RESET_ROTATION_SELECTED = 'resetRotationSelected',
  SET_TIME_OF_DAY = 'setTimeOfDay',
  MOVE_SELECTED = 'moveSelected',
  ROTATE_SELECTED = 'rotateSelected',
  CAMERA_BOX = 'cameraBox',
  TAKE_360_SCREENSHOT = 'take360Screenshot',
  ZOOM = 'zoom',
  FIT = 'fit',
  ON_ZOOM_CHANGED = 'onZoomChanged',
  PLATFORM_INIT = 'appInit',
  REMOVE_ALL_PRODUCTS = 'removeAllProducts',
  COMMAND_CALLBACK = 'commandCallback',
}

export enum ResponsesFromUnreal {
  SCENE_READY = 'sceneReady',
  VIDEO_INITIALIZED = 'OnVideoInitialized',
  NEW_INSTANCE = 'NewInstance',
  WEBSOCKET_ERROR = 'WebSocket Error',
  PONG = 'pong',
  ON_CAMERA_ACTION = 'onCameraAction',
}

export enum EntryProject {
  METABOX = 'metabox',
  PLATFORM = 'platform',
  VPS = 'vps',
}

export enum VphEvents {
  PROGRESS = 'VphEvents.PROGRESS',
  COMPLETE = 'VphEvents.COMPLETE',
  COMPLETE_MATERIAL = 'VphEvents.COMPLETE_MATERIAL',
  PROGRESS_MATERIAL = 'VphEvents.PROGRESS_MATERIAL',
  SCENE_READY = 'VphEvents.SCENE_READY',
  PROGRESS_IMAGE = 'VphEvents.PROGRESS_IMAGE',
}

export interface IUIInteractionData {
  command: EUIInteractionCommand;

  [key: string]: string | number | any;
}

export const LOAD_LEVEL_COMMAND = {
  command: StreamCommands.LOAD_LEVEL,
  payload: {
    levelName: 'Scene0',
    levelPak: {
      assetName: 'Maps/Scene0',
      pakFilePath: 'vps_paks/Scene0.pak',
      name: 'PakE',
      mountPath: '../../../PakExport/Plugins/',
    },
    type: 'LEVEL',
    hideLastLevel: true,
    levelType: ['env', 'light'],
    clickable: false,
  },
};

export const LOAD_LEVEL_COMMAND_UNREAL_5 = {
  command: StreamCommands.LOAD_LEVEL,
  payload: {
    levelName: 'Scene0',
    levelPak: {
      assetName: 'Maps/Scene0',
      pakFilePath: 'vps5_paks/Scene0.pak',
      name: 'PakE',
      mountPath: '../../../PakExport/Plugins/',
    },
    type: 'LEVEL',
    hideLastLevel: true,
    levelType: ['env', 'light'],
    clickable: false,
  },
};
