<div class="file-create">
  <form
    [formGroup]="filesAddForm"
    (ngSubmit)="submitForm()"
    class="file-create__form form"
  >
    <div class="file-create__body" [class.loading]="isLoading$ | async">
      <div
        class="form__elem"
        *ngIf="config.data.fileListType === fileListType.PROJECT"
      >
        <label class="form__label" name="threadTitle">
          Select a thread
          <em>(required)</em>
        </label>
        <div class="form__input">
          <select formControlName="selectedThread">
            <option [value]="null" selected disabled>Select thread</option>
            <option
              *ngFor="let thread of threadsList$ | async"
              [value]="thread.id"
              [disabled]="!thread.canAddFile"
            >
              {{ thread.title }} • Thread #{{ thread.serialNumber }}
            </option>
            <option
              [value]="'others'"
              [disabled]="(canAddFileWithoutThread$ | async) === false"
            >
              Others Files
            </option>
          </select>
        </div>
        <div
          class="form__hint"
          *ngIf="
            filesAddForm.controls.selectedThread.invalid &&
            filesAddForm.controls.selectedThread.touched
          "
          [class.invalid]="filesAddForm.controls.selectedThread.invalid"
        >
          Thread title is required
        </div>
      </div>

      <div class="form__elem form__elem--uploader">
        <div class="form__input">
          <div class="uploader">
            <app-file-uploader
              (filesAttached)="filesAttached($event)"
              [fileType]="null"
            ></app-file-uploader>
          </div>
        </div>
      </div>
    </div>

    <div class="form__buttons">
      <button
        type="reset"
        class="button button--clear"
        (click)="close()"
        [disabled]="!isFileUpload"
      >
        Cancel
      </button>
      <button type="submit" class="button" [disabled]="isFormValid">Add</button>
    </div>
  </form>
</div>
