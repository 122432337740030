import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { EFileExtension, EFromFilePlace } from '@common/helpers';
import { GQLFile, GQLFileTypeEnum } from '@schemas';
import { Observable, of } from 'rxjs';

import { DialogService } from 'primeng/dynamicdialog';
import { EFileListType } from '@common/types/EFileListType';
import { FullscreenGalleryComponent } from '../../../fullscreen-gallery/components/fullscreen-gallery/fullscreen-gallery.component';
import { Store } from '@ngrx/store';
import { tryRemoveFile } from '@common/store/files/files.actions';
import { getProjectFilesTotal } from '@common/store/projects/projects.selectors';
import { getThreadFilesTotal } from '@common/store/threads/threads.selectors';

@Component({
  selector: 'app-files-widget',
  templateUrl: './files-widget.component.html',
  styleUrls: ['./files-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesWidgetComponent implements OnInit {
  @Input() fileListType: EFileListType = EFileListType.PROJECT;
  @Input() files$: Observable<GQLFile[] | null> = of(null);

  filesTotal$!: Observable<number | null>;
  readonly projectFilesTotal$ = this.store.select(getProjectFilesTotal);
  readonly threadFilesTotal$ = this.store.select(getThreadFilesTotal);

  readonly fileTypeEnum = GQLFileTypeEnum;

  constructor(
    private readonly store: Store,
    private readonly dialogService: DialogService,
  ) {}

  ngOnInit() {
    this.filesTotal$ =
      this.fileListType === EFileListType.PROJECT
        ? this.projectFilesTotal$
        : this.threadFilesTotal$;
  }

  get filePlace(): EFromFilePlace {
    return this.fileListType === EFileListType.PROJECT
      ? EFromFilePlace.PROJECT_WIDGET
      : EFromFilePlace.THREAD_WIDGET;
  }

  openFileViewer(file: GQLFile): void {
    if (file?.versions?.[0].extension === EFileExtension.PDF) {
      const dialogConfig = {
        data: {
          fileList$: this.files$,
          activeFile: file,
          isPrevNextArrowsNeed: false,
        },
        styleClass: 'gallery',
        width: '100%',
        height: '100%',
      };

      this.dialogService.open(FullscreenGalleryComponent, dialogConfig);
    }
  }

  removeFile(file: GQLFile): void {
    this.store.dispatch(tryRemoveFile({ id: file.id, fileName: file.name }));
  }

  getFilesShortList(files: GQLFile[]): GQLFile[] {
    return files?.slice(0, 6);
  }
}
