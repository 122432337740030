<div class="subscribers">
  <ul class="subscribers-list">
    <li
      class="subscribers-list__item"
      *ngFor="let profile of profiles | slice: 0:3"
      pTooltip="{{ profile?.fullName }}"
    >
      <img
        *ngIf="profile?.avatarPath"
        [src]="profile?.avatarPath || 'assets/images/user-default.svg'"
        [alt]="profile?.lastName || ''"
      />
      <ngx-avatars
        class="my-avatar"
        [style]="{ width: '100%', height: '100%' }"
        [name]="getAuthorAvatarName(profile)"
      ></ngx-avatars>
    </li>

    <li
      class="subscribers-list__item subscribers-list__item--more"
      *ngIf="(profiles || []).length > 3"
    >
      <button
        type="button"
        class="button-more"
        [class.active]="isSubscribersPopupOpen"
        (click)="toggleSubscribersPopup()"
      >
        <svg
          class="icon icon-more"
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.25 14c0 7.318-5.932 13.25-13.25 13.25S.75 21.318.75 14 6.682.75 14 .75 27.25 6.682 27.25 14Z"
            id="round"
            stroke-width="1.5"
          />
          <path
            d="M15.7 20.4a1.6 1.6 0 1 0-3.2 0 1.6 1.6 0 0 0 3.2 0ZM15.7 14a1.6 1.6 0 1 0-3.2 0 1.6 1.6 0 0 0 3.2 0ZM14.1 6a1.6 1.6 0 1 1 0 3.2 1.6 1.6 0 0 1 0-3.2Z"
            id="dots"
          />
        </svg>
      </button>
    </li>
  </ul>
  <div
    class="droplist add-subscribers add-subscribers--readonly"
    *ngIf="isSubscribersPopupOpen"
  >
    <div class="droplist__header">
      <button type="button" class="close" (click)="closeSubscribersPopup()">
        <svg class="icon" width="24" height="24">
          <use xlink:href="#icon-chevrone-left"></use>
        </svg>
      </button>
      List of users
    </div>
    <div class="droplist__content">
      <p-scrollPanel [style]="{ maxHeight: '190px' }">
        <div class="add-subscribers__list">
          <div
            class="add-subscribers__item"
            *ngFor="let subscriber of profiles; let i = index"
          >
            <div class="image">
              <div
                class="avatar-container"
                *ngIf="subscriber?.avatarPath; else defaultUserImg"
              >
                <img
                  class="avatar-content"
                  [src]="subscriber?.avatarPath"
                  [alt]="subscriber?.firstName"
                />
              </div>
            </div>

            <ng-template #defaultUserImg>
              <ngx-avatars
                class="my-avatar"
                [name]="getAuthorAvatarName(subscriber)"
              ></ngx-avatars>
            </ng-template>

            <div class="name">{{ subscriber?.fullName }}</div>
          </div>
        </div>
      </p-scrollPanel>
    </div>
  </div>
</div>
