import { Pipe, PipeTransform } from '@angular/core';

/*
Usage
{{ number | thousandSuffix }} (number = 12 < 1000) result 12: number
{{ number | thousandSuffix  }} (number = 10000) result '10k': string
{{ number | thousandSuffix  }} (number = 1234) result '1k+': string
 */

@Pipe({
  name: 'thousandSuffix',
})
export class ThousandSuffixPipe implements PipeTransform {
  transform(input: number): string | number {
    const suffix = 'k';
    const lineNumberPart: number = (input - (input % 1000)) / 1000;

    if (input < 1000) {
      return input;
    }

    if (input % 1000 === 0) {
      return `${lineNumberPart}${suffix}`;
    }

    return `${lineNumberPart}${suffix}+`;
  }
}
