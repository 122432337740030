import { Injectable, Query } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GQLThread } from '../../../../../schemas/schemas';
import { GET_THREAD } from '../../../graphQL/graphql-requests/queries';
import { BaseApi } from '../../../services/base-api';

@Injectable({
  providedIn: 'root',
})
export class ThreadsService {
  constructor(private readonly api: BaseApi) {}

  loadThread(id: string): Observable<GQLThread> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_THREAD,
        variables: { id },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.thread;
        }),
      );
  }
}
