/* eslint-disable no-console */
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  Observable,
  fromPromise,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { CookieHandlerService } from '@common/services/cookies-handler.service';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { extractFiles } from 'extract-files';
import { APP_CONFIG } from '../helpers';
import { REFRESH_TOKEN, TOKEN } from '../helpers/constants/token.constants';
import { AuthService } from '../services/auth.service';
import { CurrentUserService } from '../services/current-user.service';
import { AppConfig } from '../types/app-config.model';
const showConsoles = !!sessionStorage.getItem('show_stream_logs');

import { CachePersistor } from 'apollo-cache-persist';

const tokenErrors = ['Invalid JWT Token', 'Expired JWT Token'];

let isRefreshToken = false;
let isRefreshing = false;

let pendingRequests: Array<(value?: any) => void> = [];

const resolvePendingRequests = () => {
  pendingRequests.forEach((callback) => callback());
  pendingRequests = [];
};

export function createApollo(
  httpLink: HttpLink,
  config: AppConfig,
  user: CurrentUserService,
  injector: Injector,
  cookieHandler: CookieHandlerService,
): ApolloClientOptions<any> {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));
  const tokenPrefix = config.tokenPrefix;

  const http = httpLink.create({ uri: config.api.graphql, extractFiles });

  const errorHandler = onError(
    ({ response, forward, networkError, operation }): any => {
      let forward$: Observable<any>;
      const error = networkError as HttpErrorResponse;
      // const isInvalidCredentials: boolean = !!error?.error?.message.includes(
      //   'Invalid credentials',
      // );

      // if (isInvalidCredentials) {
      //   // user.goToNoPermissionsPage();
      // }

      if (tokenErrors.includes(error?.error?.message)) {
        if (!isRefreshing) {
          isRefreshToken = true;
          isRefreshing = true;
          const authService = injector.get<AuthService>(AuthService);
          const token = user.getToken(REFRESH_TOKEN(tokenPrefix));

          forward$ = fromPromise(
            authService
              .refreshToken(token)
              .toPromise()
              .then((resp) => {
                resolvePendingRequests();
                return resp?.refreshToken;
              })
              .catch((_error) => {
                pendingRequests = [];
                return;
              })
              .finally(() => {
                isRefreshing = false;
              }),
          ).filter((value) => Boolean(value));
        } else {
          forward$ = fromPromise(
            new Promise((resolve) => {
              console.log(pendingRequests);

              pendingRequests.push(() => resolve(1));
            }),
          );
        }
        return forward$.flatMap(() => forward(operation));
      } else {
        const errorMessage = error?.error?.message;
        if (error?.error?.message) {
          if (errorMessage.includes('Invalid credentials.')) {
            user.logout();
          }

          console.log(
            `%c ${errorMessage} `,
            'color: white; background: red; font-size: 24px;',
          );
        }
        if (
          response?.errors?.some(({ message }) => {
            return (
              message.includes('Invalid refresh token') ||
              message.includes('Refresh token not found') ||
              message.includes('User not found')
            );
          })
        ) {
          user.logout();
        }
      }
    },
  );

  const retryLink = new RetryLink({
    delay: {
      initial: 1500,
      max: 5,
      jitter: false,
    },
    attempts: {
      max: 5,
      retryIf: (error, operation) => {
        return !!error && operation.operationName === 'refreshToken';
      },
    },
  });

  const responseLink = new ApolloLink((operation, forward) => {
    if (isRefreshToken || !cookieHandler.get(TOKEN(tokenPrefix))) {
      isRefreshToken = false;
      return forward(operation);
    }

    operation.setContext({
      headers: {
        Authorization: 'Bearer ' + cookieHandler.get(TOKEN(tokenPrefix)),
      },
    });
    return forward(operation);
  });

  const cache: InMemoryCache = new InMemoryCache();
  const link: ApolloLink = ApolloLink.from([
    retryLink,
    basic,
    errorHandler,
    responseLink,
    http,
  ]);

  const persistor: CachePersistor<NormalizedCacheObject> = new CachePersistor({
    storage: window.localStorage as any,
    cache: cache as any,
  });

  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link,
    cache,
  });
  user.client = client;
  // user.persistor = persistor;

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [ApolloModule, HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [
        HttpLink,
        APP_CONFIG,
        CurrentUserService,
        Injector,
        CookieHandlerService,
      ],
    },
  ],
})
export class GraphQLModule {}
