import { Action, createReducer, on } from '@ngrx/store';
import {
  GQLComment,
  GQLFileMarkerStatusEnum,
  GQLMarker,
  GQLMarkerGroup,
} from '../../../../schemas/schemas';
import { IChatCoordinates, IMarkerPositions } from '../../types/interfaces';
import {
  addActiveMarker,
  addChatCoordinates,
  addCustomMarker,
  addMarkerGroupToArray,
  changeMarkersPosition,
  createMarker,
  createMarkerComment,
  createMarkerCommentFailure,
  createMarkerCommentSuccess,
  createMarkerFailure,
  createMarkerGroup,
  createMarkerGroupFailure,
  createMarkerGroupSuccess,
  createMarkerSuccess,
  deleteMarker,
  deleteMarkerFailure,
  deleteMarkerSuccess,
  editMarkerComment,
  editMarkerCommentFailure,
  editMarkerCommentSuccess,
  getBackToStream,
  getMarkerGroupFailure,
  getMarkerGroupSuccess,
  loadMarkersSuccess,
  markMarkerAsViewedSuccess,
  removeMarkerComment,
  removeMarkerCommentFailure,
  removeMarkerCommentSuccess,
  resetChatCoordinates,
  resetMarkers,
  resetMarkersState,
  resolveMarkerFailure,
  resolveMarkerSuccess,
  setMarkerIdForOpeningFromSide,
  setMarkersFromGroup,
  toggleResolveMarker,
  unresolveMarkerFailure,
  unresolveMarkerSuccess,
  updateMarkerGroupImage,
} from './markers.actions';

import { unselectActiveGroup } from '../files/files.actions';

function updateMarkerStatus(
  markers: GQLMarker[],
  id: string,
  status: GQLFileMarkerStatusEnum,
) {
  return markers.map((marker) =>
    marker.id === id ? { ...marker, status } : marker,
  );
}

function updateMarkerHasNewComment(
  markers: GQLMarker[],
  id: string,
  hasNewComment: boolean,
) {
  return markers.map((marker) =>
    marker.id === id ? { ...marker, hasNewComment } : marker,
  );
}

function filterMarkersByStatus(markers: GQLMarker[], id: string) {
  return markers.filter((marker) => marker.id !== id);
}

export interface MarkersState {
  markers: Array<any>;
  markersPositions: Array<IMarkerPositions>;
  chatCoordinates: IChatCoordinates | null;
  activeMarker: GQLMarker | null;
  markerIdForOpeningFromSide: string;
  markersGroups: GQLMarkerGroup[];
  markerGroupsCreating: boolean;
  markerCommentCreateLoading: boolean;
}

export const initialMarkersState: MarkersState = {
  markers: [],
  markersPositions: [],
  chatCoordinates: null,
  activeMarker: null,
  markerIdForOpeningFromSide: '',
  markersGroups: [],
  markerGroupsCreating: false,
  markerCommentCreateLoading: false,
};

const markersReducer = createReducer(
  initialMarkersState,

  // CHAT COORDINATES
  on(addChatCoordinates, (state, { chatCoordinates }) => ({
    ...state,
    chatCoordinates:
      state.chatCoordinates?.markerId === chatCoordinates.markerId
        ? null
        : chatCoordinates,
    markerIdForOpeningFromSide: '',
  })),
  on(resetChatCoordinates, (state) => ({
    ...state,
    chatCoordinates: null,
    activeMarker: null,
    markerIdForOpeningFromSide: '',
    markers: state.markers.filter((marker) => !marker.isNew),
  })),

  // CREATE MARKER
  on(createMarker, (state) => ({
    ...state,
    markerCreateLoading: true,
  })),
  on(createMarkerSuccess, (state, { marker, groupId }) => {
    const currentGroup = state.markersGroups.find(({ id }) => id === groupId);
    let groupWithNewMarker: GQLMarkerGroup | null = null;
    if (currentGroup) {
      groupWithNewMarker = {
        ...currentGroup,
        markers: currentGroup ? [marker, ...currentGroup.markers] : [],
      };
    }

    return {
      ...state,
      markers: state.markers.map((_marker) =>
        _marker.isNew ? marker : _marker,
      ),
      activeMarker: {
        ...marker,
      },
      markerCreateLoading: false,
      markersGroups: groupWithNewMarker
        ? [
            groupWithNewMarker,
            ...state.markersGroups.filter(
              ({ id }) => groupWithNewMarker?.id !== id,
            ),
          ]
        : state.markersGroups,
      markerCommentCreateLoading: false,
    };
  }),
  on(createMarkerFailure, (state) => ({
    ...state,
    markerCreateLoading: false,
    markerCommentCreateLoading: false,
  })),

  on(addActiveMarker, (state, { marker }) => {
    if (
      marker.id === state.activeMarker?.id
      // marker.id === state.activeMarker?.markerId
    ) {
      return state;
    }

    const activeMarker = state.markers.find((stateMarker) => {
      if (stateMarker.markerId || marker.markerId) {
        return (
          stateMarker.id === marker.markerId ||
          stateMarker.markerId === marker.id ||
          stateMarker.markerId === marker.markerId
        );
      } else {
        return stateMarker.id === marker.id;
      }
    });

    return {
      ...state,
      markers: state.markers.filter((_marker) => !_marker.isNew),
      activeMarker,
    };
  }),

  // CREATE MARKER COMMENT
  on(createMarkerComment, (state) => ({
    ...state,
    markerCommentCreateLoading: true,
  })),
  on(createMarkerCommentSuccess, (state, { comment, markerId, groupId }) => {
    const currentGroup = state.markersGroups.find(({ id }) => id === groupId);
    const currentMarkerFromGroup = currentGroup?.markers.find(
      (item) => item.id === markerId,
    );
    const currentMarker = state.markers.find((item) => item.id === markerId);

    if (currentGroup && currentMarkerFromGroup) {
      const currentMarkerWithNewComment = {
        ...currentMarkerFromGroup,
        comments: [...currentMarkerFromGroup.comments, comment],
        hasNewComment: false,
      };

      const groupWithNewMarker = {
        ...currentGroup,
        markers: [
          currentMarkerWithNewComment,
          ...currentGroup.markers
            .map((marker) =>
              marker.id === markerId
                ? {
                    ...marker,
                    comments: [...marker.comments, comment],
                  }
                : marker,
            )
            .filter((item) => item.id !== currentMarkerWithNewComment.id),
        ],
      };

      return {
        ...state,
        markerCommentCreateLoading: false,
        activeMarker: {
          ...state.activeMarker,
          comments: [...(state.activeMarker?.comments || []), comment],
        } as GQLMarker,
        markers: groupWithNewMarker.markers,
        markersGroups: [
          groupWithNewMarker,
          ...state.markersGroups.filter(
            ({ id }) => groupWithNewMarker.id !== id,
          ),
        ].filter((group) => group.markers.length > 0),
      };
    }

    return {
      ...state,
      ...(state.activeMarker && {
        activeMarker: {
          ...state.activeMarker,
          comments: [...(state.activeMarker?.comments || []), comment],
          hasNewComment: false,
        },
      }),
      markers: [
        { ...currentMarker, comments: [...currentMarker.comments, comment] },
        ...state.markers.filter((marker: GQLMarker) => marker.id !== markerId),
      ],
      markerCreateLoading: false,
      markerCommentCreateLoading: false,
    };
  }),
  on(createMarkerCommentFailure, (state) => ({
    ...state,
    markerCommentCreateLoading: false,
  })),
  // EDIT MARKER COMMENT
  on(editMarkerComment, (state) => ({
    ...state,
    editMarkerCommentLoading: true,
  })),
  on(editMarkerCommentSuccess, (state, { editComment: { comment } }) => {
    return {
      ...state,
      editMarkerCommentLoading: false,
      markers: state.markers.map((marker: GQLMarker) => {
        return {
          ...marker,
          comments: marker.comments.map((com) =>
            com.id === comment.id ? comment : com,
          ),
        };
      }),
      activeMarker: {
        ...state.activeMarker,
        comments: (state.activeMarker?.comments || []).map((_comment) =>
          _comment.id === comment.id ? comment : _comment,
        ),
      } as GQLMarker,
    };
  }),
  on(editMarkerCommentFailure, (state) => ({
    ...state,
    editMarkerCommentLoading: true,
  })),
  // REMOVE MARKER COMMENT
  on(removeMarkerComment, (state) => ({
    ...state,
    markerCommentRemoveLoading: true,
  })),
  on(removeMarkerCommentSuccess, (state, { removeComment }) => {
    return {
      ...state,
      markerCommentRemoveLoading: false,
      activeMarker: {
        ...state.activeMarker,
        comments: (state.activeMarker?.comments || []).filter(
          (comment: GQLComment) => comment.id !== removeComment?.id,
        ) as GQLComment[],
      } as GQLMarker,
      markers: state.markers.map((marker: GQLMarker) => {
        return {
          ...marker,
          comments: marker.comments.filter(
            (com) => com.id !== removeComment.id,
          ),
        };
      }),
    };
  }),

  on(removeMarkerCommentFailure, (state) => ({
    ...state,
    markerCommentRemoveLoading: false,
  })),
  // REMOVE MARKER
  on(deleteMarker, (state) => ({
    ...state,
    markerCommentRemoveLoading: true,
  })),
  on(deleteMarkerSuccess, (state, { removeMarker }) => {
    return {
      ...state,
      markerCommentRemoveLoading: false,
      markers: filterMarkersByStatus(state.markers, removeMarker.id),
      markersGroups: state.markersGroups
        .map((markerGroup) => ({
          ...markerGroup,
          markers: filterMarkersByStatus(markerGroup.markers, removeMarker.id),
        }))
        .filter((markersGroups) => !!markersGroups.markers.length),
    };
  }),
  on(deleteMarkerFailure, (state) => ({
    ...state,
    markerCommentRemoveLoading: false,
  })),

  // ADD CUSTOM MARKER
  on(addCustomMarker, (state, { marker }) => ({
    ...state,
    activeMarker: marker,
    markers: [
      marker,
      ...(state?.markers || []).filter((_marker) => !_marker.isNew),
    ],
  })),

  // changeMarkersPosition
  on(changeMarkersPosition, (state, { markers }) => ({
    ...state,
    markersPositions: markers,
  })),

  // LOAD MARKERS
  on(loadMarkersSuccess, (state, { markers, markersGroups }) => {
    const { activeMarker, chatCoordinates } = state;
    const newMarker = state.markers.find((marker) => marker.isNew);
    const latestMarkers = newMarker ? [...markers, newMarker] : markers;

    return {
      ...state,
      markers: latestMarkers,
      markersGroups: markersGroups.filter(
        (markersGroup) => !!markersGroup.markers.length,
      ),
      activeMarker: latestMarkers.find(
        (marker: GQLMarker) => activeMarker?.id === marker.id,
      )
        ? activeMarker
        : null,
      chatCoordinates: latestMarkers.find(
        (marker: GQLMarker) => chatCoordinates?.markerId === marker.id,
      )
        ? chatCoordinates
        : null,
    };
  }),
  // MARK MARKER AS VIEWED
  on(markMarkerAsViewedSuccess, (state, { markerId }) => {
    return {
      ...state,
      markers: updateMarkerHasNewComment(state.markers, markerId, false),
      markersGroups: state.markersGroups.map((markerGroup) => ({
        ...markerGroup,
        markers: updateMarkerHasNewComment(
          markerGroup.markers,
          markerId,
          false,
        ),
      })),
      activeMarker: {
        ...state.activeMarker,
        hasNewComment: false,
      } as GQLMarker,
    };
  }),
  // LOAD MARKERS
  on(setMarkerIdForOpeningFromSide, (state, { markerId }) => {
    if (markerId !== state.markerIdForOpeningFromSide) {
      return {
        ...state,
        markerIdForOpeningFromSide: markerId,
        activeMarker: state.markers.find((marker) => marker.id === markerId),
      };
    }
    return state;
  }),

  on(toggleResolveMarker, (state) => ({
    ...state,
    markerCommentRemoveLoading: true,
  })),

  // RESOLVE MARKER
  on(resolveMarkerSuccess, (state, { id, markersStatus }) => {
    if (markersStatus === GQLFileMarkerStatusEnum.ALL) {
      return {
        ...state,
        markers: updateMarkerStatus(
          state.markers,
          id,
          GQLFileMarkerStatusEnum.RESOLVED,
        ),
        activeMarker: {
          ...state.activeMarker,
          status: GQLFileMarkerStatusEnum.RESOLVED,
        } as GQLMarker,
        markersGroups: state.markersGroups
          .map((markerGroup) => ({
            ...markerGroup,
            markers: updateMarkerStatus(
              markerGroup.markers,
              id,
              GQLFileMarkerStatusEnum.RESOLVED,
            ),
          }))
          .filter((group) => group.markers.length > 0),
        markerCommentRemoveLoading: false,
      };
    }

    return {
      ...state,
      markers: filterMarkersByStatus(state.markers, id),
      markersGroups: state.markersGroups
        .map((markerGroup) => ({
          ...markerGroup,
          markers: filterMarkersByStatus(markerGroup.markers, id),
        }))
        .filter((markersGroups) => !!markersGroups.markers.length),
      markerCommentRemoveLoading: false,
    };
  }),
  on(resolveMarkerFailure, (state) => ({
    ...state,
    markerCommentRemoveLoading: false,
  })),
  // UNRESOLVE MARKER
  on(unresolveMarkerSuccess, (state, { id }) => ({
    ...state,
    markers: updateMarkerStatus(
      state.markers,
      id,
      GQLFileMarkerStatusEnum.ACTIVE,
    ),
    activeMarker: {
      ...state.activeMarker,
      status: GQLFileMarkerStatusEnum.ACTIVE,
    } as GQLMarker,
    markersGroups: state.markersGroups
      .map((markerGroup) => ({
        ...markerGroup,
        markers: updateMarkerStatus(
          markerGroup.markers,
          id,
          GQLFileMarkerStatusEnum.ACTIVE,
        ),
      }))
      .filter((group) => group.markers.length > 0),
    markerCommentRemoveLoading: false,
  })),
  on(unresolveMarkerFailure, (state) => ({
    ...state,
    markerCommentRemoveLoading: false,
  })),
  on(createMarkerGroup, (state) => {
    return {
      ...state,
      markerGroupsCreating: true,
      markerCommentCreateLoading: true,
    };
  }),
  on(addMarkerGroupToArray, (state, { markerGroup }) => {
    return {
      ...state,
      markersGroups: [markerGroup, ...state.markersGroups],
    };
  }),
  on(
    getMarkerGroupSuccess,
    getMarkerGroupFailure,
    createMarkerGroupFailure,
    createMarkerGroupSuccess,
    (state) => {
      return {
        ...state,
        markerGroupsCreating: false,
        markerCommentCreateLoading: false,
      };
    },
  ),
  on(setMarkersFromGroup, (state, { markers }) => {
    return {
      ...state,
      markers,
    };
  }),
  on(resetMarkers, (state) => {
    return {
      ...state,
      markers: [],
      activeMarker: null,
    };
  }),
  on(resetMarkersState, () => ({
    ...initialMarkersState,
  })),
  on(unselectActiveGroup, (state) => ({
    ...initialMarkersState,
    markersGroups: state.markersGroups,
  })),
  on(updateMarkerGroupImage, (state, { imagePath, previewPath, id }) => {
    return {
      ...state,
      markersGroups: state.markersGroups.map((group) =>
        group.id === id ? { ...group, imagePath, previewPath } : group,
      ),
    };
  }),
  on(getBackToStream, (state) => {
    return {
      ...state,
      markersGroups: state.markersGroups.filter(
        (group) => group.markers.length > 0,
      ),
    };
  }),
);

export function MarkersReducer(
  state: MarkersState | undefined,
  action: Action,
): MarkersState {
  return markersReducer(state, action);
}
