import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
interface BroadcastEvent {
  key: any;
  data?: any;
}

/**
 * SingleTone Private class.
 * Event-Driving pattern for sending signals from component to component.
 */
class SignalClass {
  private static eventBus: Subject<BroadcastEvent>;

  constructor() {
    if (SignalClass.eventBus) {
      throw new Error(`Check that 'SignalClass' is not instantiated again!`);
    }
    SignalClass.eventBus = new Subject<BroadcastEvent>();
  }

  broadcast<K, D>(key: K, data?: D) {
    SignalClass.eventBus.next({ key, data });
  }

  on<K, D = any>(key: K): Observable<D> {
    return SignalClass.eventBus.asObservable().pipe(
      filter((event) => event.key === key),
      map((event) => event.data),
    );
  }
}

export const Signal = new SignalClass();
