<ng-container *ngIf="fileList$ | async as fileList">
  <div class="viewer" [class.isFullScreen]="isFullscreen">
    <!-- header -->
    <div class="viewer__header">
      <!-- header left part -->
      <div class="viewer__title-block">
        <button type="button" class="back-link" (click)="closeImageViewer()">
          <app-icon href="#icon-chevrone-left"></app-icon>
        </button>
        <div class="title">
          <h3
            [pTooltip]="tooltipText(fileName)"
            tooltipZIndex="100000000"
            style="cursor: default"
          >
            {{ fileName | shortener }}
          </h3>
          <span class="file-size">{{ firstVersion.size | fileSizePipe }}</span>
        </div>
        <div class="project">
          <div class="project__title" [pTooltip]="tooltipText(projectName)">
            Project:
            <strong>{{ projectName | shortener }}</strong>
          </div>
          <span class="dot-divider"></span>
          <div class="project__title" [hidden]="!threadName">
            Thread:
            <strong [pTooltip]="tooltipText(threadName)">{{
              threadName | shortener
            }}</strong>
          </div>
        </div>
      </div>
      <!-- /header left part -->

      <!-- header center part -->
      <ng-container *ngIf="isImage">
        <div class="viewer__zoom-box zoom">
          <button type="button" class="zoom__button" (click)="zoomOut()">
            <app-icon href="#icon-minus"></app-icon>
          </button>
          <div class="zoom__input">
            <input
              type="text"
              value="{{ getZoomValue(myPinch.scale$ | async) }}%"
              disabled
            />
          </div>
          <button type="button" class="zoom__button" (click)="zoomIn()">
            <app-icon href="#icon-plus"></app-icon>
          </button>
        </div>
      </ng-container>
      <!--  buttons for PDF files-->
      <ng-container *ngIf="isPdfFile">
        <div class="viewer__zoom-box zoom">
          <button type="button" class="zoom__button" (click)="pdfZoomOut()">
            <app-icon href="#icon-minus"></app-icon>
          </button>
          <div class="zoom__input">
            <input
              type="text"
              value="{{ pdfZoomScaleValue | number : '1.0-0' }}%"
              disabled
            />
          </div>
          <button type="button" class="zoom__button" (click)="pdfZoomIn()">
            <app-icon href="#icon-plus"></app-icon>
          </button>
        </div>
      </ng-container>
      <!--     end of buttons for PDF files-->
      <!-- /header center part -->

      <!-- header right part -->
      <div class="viewer__actions">
        <!-- trigger for mobile menu -->
        <button
          type="button"
          class="viewer-mobile-menu"
          (click)="toggleMobileMenu()"
        >
          <app-icon href="#icon-dots-vertical"></app-icon>
        </button>

        <!-- wrap for mobile menu -->
        <div class="viewer__actions-menu" [class.active]="isMobileMenuOpen">
          <div class="mobile-header">
            <h3>More</h3>
            <button type="button" class="close" (click)="toggleMobileMenu()">
              <app-icon href="#icon-close" width="32" height="32"></app-icon>
            </button>
          </div>

          <button
            type="button"
            class="viewer-button"
            (click)="downloadFile(activeFile)"
          >
            <app-icon href="#icon-download"></app-icon>
            <span class="viewer-button__name">Download</span>
          </button>

          <button
            type="button"
            class="viewer-button"
            [disabled]="!canRemove"
            (click)="deleteFile(activeFile)"
          >
            <app-icon href="#icon-remove"></app-icon>
            <span class="viewer-button__name">Delete</span>
          </button>

          <button
            type="button"
            class="viewer-button viewer-button--info"
            [class.active]="isSidebarOpen"
            [disabled]="isFullscreen"
            (click)="toggleSidebar()"
          >
            <app-icon href="#icon-info" height="20px" width="20px"></app-icon>
            <span class="viewer-button__name">Info</span>
          </button>

          <!-- aside\collapsible box for information -->
          <div class="info-panel" [class.active]="isSidebarOpen">
            <div class="info-panel__header">
              <h4>Info</h4>
              <button type="button" class="close" (click)="toggleSidebar()">
                <app-icon href="#icon-close" width="32" height="32"></app-icon>
              </button>
            </div>
            <div class="info-panel__body">
              <div class="info">
                <div class="info__item">
                  <app-icon
                    href="#icon-folder"
                    width="16"
                    height="16"
                  ></app-icon>
                  <span>Name:</span>
                  <strong>{{ fileName }}</strong>
                </div>
                <div class="info__item">
                  <app-icon
                    href="#icon-folder"
                    width="16"
                    height="16"
                  ></app-icon>
                  <span>Project:</span>
                  <strong>{{ projectName }}</strong>
                </div>
                <div class="info__item" [hidden]="!threadName">
                  <app-icon
                    href="#icon-comment"
                    width="16"
                    height="16"
                  ></app-icon>
                  <span>Thread:</span>
                  <strong>{{ threadName }}</strong>
                </div>
                <div class="info__item" *ngIf="fileAuthor">
                  <app-icon href="#icon-user" width="16" height="16"></app-icon>
                  <span>Created by:</span>
                  <strong
                    ><span>{{ fileAuthor }}</span></strong
                  >
                </div>
                <div class="info__item">
                  <app-icon
                    href="#icon-creator"
                    width="16"
                    height="16"
                  ></app-icon>
                  <span>Size:</span>
                  <strong>{{ firstVersion?.size | fileSizePipe }}</strong>
                </div>
                <div class="info__item">
                  <app-icon
                    href="#icon-clock"
                    width="16"
                    height="16"
                  ></app-icon>
                  <ng-container>
                    <span>Added:</span>
                    <strong>{{ fileCreatedAt | timeCreated }}</strong>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>

          <button
            type="button"
            class="viewer-button viewer-button--fs"
            (click)="toggleFullscreen()"
            [disabled]="isOtherFile"
          >
            <app-icon
              height="20px"
              width="20px"
              href="#icon-fullscreen-{{ isFullscreen ? 'off' : 'on' }}"
            ></app-icon>
          </button>

          <ng-container *ngIf="false">
            <button
              type="button"
              class="viewer-button viewer-button--tool"
              [class.active]="isSidebarOpen"
              [disabled]="isFullscreen"
              (click)="goToTools()"
            >
              <app-icon href="#icon-comment-mode"></app-icon>
              <span class="viewer-button__name">
                <ng-container *ngIf="commentsLength > 0; else noComments">
                  <span class="viewer-button__name-mobile"
                    >Go to the comment tool: {{ commentsLength }}</span
                  >
                  <span class="viewer-button__name-desktop"
                    >Comments: {{ commentsLength }}</span
                  >
                </ng-container>

                <ng-template #noComments> Add comment </ng-template>
              </span>
            </button>

            <button
              type="button"
              class="approve-control approve-control--button"
              [class.approved]="isApproved"
            >
              <span class="circle">
                <svg
                  fill="none"
                  viewBox="0 0 16 16"
                  width="16"
                  height="16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm0 14.4A6.4 6.4 0 1 1 8 1.6a6.4 6.4 0 0 1 0 12.8Z"
                    id="circle"
                  />
                  <path
                    d="m6.9 8.935 3.65-3.686a.847.847 0 0 1 1.385.28.862.862 0 0 1-.187.937l-4.252 4.285a.847.847 0 0 1-1.2 0L4.297 8.73a.856.856 0 0 1-.238-.964.858.858 0 0 1 .481-.485.845.845 0 0 1 .957.24L6.9 8.935Z"
                    id="check"
                  />
                </svg>
              </span>
              {{ isApproved ? 'Approved by...' : 'Approve' }}
              <div class="approved-persone" *ngIf="isApproved">
                <svg class="icon" width="15" height="15">
                  <use xlink:href="#icon-info"></use>
                </svg>
                <div class="approved-persone__hint">Name</div>
              </div>
            </button>
          </ng-container>
        </div>
      </div>
      <!-- /header right part -->
    </div>
    <!-- /header -->

    <!-- container -->
    <div
      class="viewer__body"
      [class.wide]="!isSidebarOpen"
      (click)="tryCloseGallery($event)"
    >
      <pinch-zoom
        class="pinch-box"
        [class]="'pinch-box__' + zoomedStep"
        #myPinch="pinchZoom"
        [class.loading]="imageLoading"
        [limit-zoom]="0"
        [hidden]="!isImage"
        [zoomControlScale]="0.05"
        [wheelZoomFactor]="0.05"
      >
        <img
          src="{{ firstVersion?.path }}"
          class="pinch-box__image class_for_close_gallery"
          (load)="imageLoaded()"
        />
      </pinch-zoom>
      <!-- If file.type != IMAGE and not PDF -->
      <div class="pinch-box__unsupported-file" *ngIf="isOtherFile">
        <img [src]="fileName | fileType : true" />
      </div>
      <!-- If file is PDF -->
      <div
        class="pinch-box__pdf-file"
        *ngIf="isPdfFile"
        [class.loading]="imageLoading"
      >
        <ngx-extended-pdf-viewer
          src="{{ firstVersion.path }}"
          height="100%"
          [showBorders]="false"
          [showToolbar]="false"
          [showSidebarButton]="false"
          [showFindButton]="false"
          [showPagingButtons]="false"
          [showZoomButtons]="false"
          [showPresentationModeButton]="false"
          [showOpenFileButton]="false"
          [showPrintButton]="false"
          [showSecondaryToolbarButton]="false"
          [showRotateButton]="false"
          [showHandToolButton]="false"
          [showScrollingButton]="false"
          [showSpreadButton]="false"
          [showPropertiesButton]="false"
          [sidebarVisible]="false"
          [showDownloadButton]="false"
          [minZoom]="minPdfZoom"
          [maxZoom]="maxPdfZoom"
          [scrollMode]="0"
          [backgroundColor]="'transparent'"
          [(zoom)]="pdfZoomScale"
          (progress)="imageLoaded()"
          (click)="pdfClickHandler($event)"
          (currentZoomFactor)="setPdfZoomScale($event)"
        >
        </ngx-extended-pdf-viewer>
      </div>
      <div class="gallery-buttons">
        <button
          type="button"
          class="gallery-buttons__item"
          *ngIf="isPrevNextArrowsNeed"
          (click)="prevFile($event, fileList)"
          [disabled]="disablePrevButton"
        >
          <app-icon href="#icon-chevrone-left"></app-icon>
        </button>
        <button
          type="button"
          class="gallery-buttons__item"
          *ngIf="isPrevNextArrowsNeed"
          (click)="nextFile($event, fileList)"
          [disabled]="disableNextButton"
        >
          <app-icon href="#icon-chevrone-right"></app-icon>
        </button>
      </div>
    </div>
    <!-- /container -->
  </div>
</ng-container>
