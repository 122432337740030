import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { NgScrollbar } from 'ngx-scrollbar';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Activity } from '@common/types/acivity.model';
import {
  clearAllNotifications,
  loadMoreNotifications,
  loadNotifications,
  markNotificationsAsViewed,
  markNotificationsAsViewedSuccess,
} from '../../store/notifications.actions';
import {
  getNotificationsViewModel,
  getUnreadNotifications,
  isNotificationsLoading,
} from '../../store/notifications.selectors';
import { GQLActivity } from '@schemas';
import { EIndices } from '../../types/EIndices';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar;
  @ViewChild('unreadScrollbarRef') unreadScrollbarRef!: NgScrollbar;

  @Input() isSeparatePage = false;

  prevNotesCount = 0;
  notifications: Activity[] = [];
  unreadNotificationsList: Activity[] = [];
  tabIndex: number = EIndices.UNREAD;

  readonly isNotificationsLoading$: Observable<boolean> = this.store.select(
    isNotificationsLoading,
  );

  readonly notifications$: Observable<Activity[]> = this.store
    .select(getNotificationsViewModel)
    .pipe(
      tap(({ notifications }) => {
        setTimeout(() => {
          const el = this.documentRef.getElementById(
            `notification${this.prevNotesCount}`,
          );
          this.prevNotesCount = notifications.length;
        });
      }),
      map(({ notifications }) => {
        this.notifications = [...notifications];
        return notifications;
      }),
    );

  private readonly unsubscribe$: Subject<boolean> = new Subject<boolean>();
  private readonly stopSubscriber$: Subject<boolean> = new Subject();
  private timer: ReturnType<typeof setTimeout> | null = null;

  constructor(
    readonly ref: DynamicDialogRef,
    private readonly router: Router,
    private readonly store: Store,
    private readonly updates$: Actions,
    private readonly cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private readonly documentRef: Document,
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        window.location.reload();
        // TODO: bad solution, should refactor
      }
    });

    this.store.dispatch(loadNotifications({ unreadOnly: true }));
  }

  ngAfterViewInit(): void {
    this.tryMarkAsViewed();

    this.unreadScrollbarRef.verticalScrolled
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.tryLoadMoreNotifications(event);
      });

    this.scrollbarRef.verticalScrolled
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((event) => {
        this.tryLoadMoreNotifications(event);
      });

    this.scrollbarRef.scrolled
      .pipe(debounceTime(100), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.tryMarkAsViewed();
      });
  }

  private tryLoadMoreNotifications(event: any) {
    if (
      event.target.offsetHeight + event.target.scrollTop >=
      event.target.scrollHeight - 2
    ) {
      this.store.dispatch(loadMoreNotifications());
    }
  }

  get isUnreadTabOpen(): boolean {
    return this.tabIndex === EIndices.UNREAD;
  }

  isActiveClassNeed(notification: Activity): boolean {
    return this.isUnreadTabOpen ? false : !notification.isViewed;
  }

  isListEmpty(list: Activity[]): boolean {
    return list.length === 0;
  }

  isNotificationListEmpty(activity: GQLActivity[]): boolean {
    return activity.length === 0;
  }

  tryMarkAsViewed(): void {
    if (this.isUnreadTabOpen) {
      return;
    }

    if (this.getArrayOfUnViewedIds().length > 0) {
      this.timer = setTimeout(() => {
        this.markAsViewed();
      }, 200);
    } else if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  markAsViewed(): void {
    const input = { ids: this.getArrayOfUnViewedIds() };
    if (input.ids.length === 0) {
      return;
    }
    this.store.dispatch(markNotificationsAsViewed({ input }));
    this.updates$
      .pipe(
        ofType(markNotificationsAsViewedSuccess),
        switchMap(() => {
          return this.store.select(getUnreadNotifications);
        }),
        filter((unreadMessage: number) => !!unreadMessage),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.stopSubscriber$.next(true);
      });
  }

  getArrayOfUnViewedIds(): string[] {
    const latestIndexInViewPort = this.getLatestIndexIntoView();
    const finalArray: string[] = [];
    this.notifications.some((el, index) => {
      if (!el.isViewed && el.id) {
        finalArray.push(el.id);
      }
      return latestIndexInViewPort === index;
    });
    return finalArray;
  }

  getLatestIndexIntoView(): number {
    let latestIndex = 0;
    this.notifications.forEach((el, index) => {
      const element: HTMLElement | null = document.getElementById(
        `notification${index}`,
      );
      if (element) {
        const rect = element.getBoundingClientRect();
        const topShown = rect.top >= 0;
        const bottomShown = rect.bottom <= window.innerHeight;
        if (topShown && bottomShown) {
          latestIndex = index;
        }
      }
    });
    return latestIndex;
  }

  clearAll(): void {
    this.store.dispatch(clearAllNotifications());
  }

  updateNotificationsList(): void {
    this.store.dispatch(
      loadNotifications({ unreadOnly: this.isUnreadTabOpen }),
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.stopSubscriber$.complete();
  }
}
