<div class="notification">
  <button
    type="button"
    class="notification__button"
    (click)="openNotifications()"
    data-test-id="openNotificationsButton"
  >
    <svg class="icon" width="32" height="32">
      <use
        attr.xlink:href="{{
          (isNotificationsOpened$ | async)
            ? '#icon-notification-filled'
            : '#icon-notification'
        }}"
      ></use>
    </svg>
    <ng-container *ngIf="unreadNotifications$ | async as unreadNotifications">
      <span [hidden]="!unreadNotifications" class="notification__number">{{
        unreadNotifications | thousandSuffix
      }}</span>
    </ng-container>
  </button>
</div>
<div class="notification-panel" *ngIf="isNotificationsOpened$ | async">
  <div class="notification-panel__head">
    <h2>Notifications</h2>
    <button
      type="button"
      class="notification-panel__close"
      (click)="closeDispatch()"
      data-test-id="closeDispatchButton"
    >
      <svg class="icon" width="24" height="24">
        <use xlink:href="#icon-close"></use>
      </svg>
    </button>
  </div>
  <div class="notification-panel__container">
    <app-notification-list></app-notification-list>
  </div>
</div>
