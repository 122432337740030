/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 4/9/21, 5:01 PM
 * Copyright 2021 3D Source
 */

import { gql } from 'apollo-angular';

export const GET_PROJECT_FILES = gql`
  query (
    $id: ID!
    $status: FileStatusEnum
    $unviewedOnly: Boolean
    $useTools: Boolean
    $searchQuery: String
    $dateRange: DateRange
    $types: [FileTypeEnum!]
  ) {
    projectFiles(
      id: $id
      status: $status
      unviewedOnly: $unviewedOnly
      useTools: $useTools
      searchQuery: $searchQuery
      dateRange: $dateRange
      types: $types
    ) {
      count
      title
      files {
        commentCount
        id
        name
        type
        createdAt
        updatedAt
        useTools
        hasNewVersion
        hasNewComment
        hasNewApprove
        extension
        streamUrl
        unrealVersion
        canRemove
        status
        versions {
          id
          isViewed
          hasNewComment
          previewPath
          path
          extension
          status
          size
          serialNumber
          createdAt
        }
      }
      thread {
        id
        title
        serialNumber
      }
    }
  }
`;
