import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  GQLFileStatusEnum,
  GQLFileVersion,
  GQLFileVersionStatusEnum,
} from '@schemas';
import { EStringToBoolean } from '../../types/EStringToBoolean';
import {
  getMarkersStatus,
  loadFilesForCarousel,
  resetCommentingToolStateWithoutFilesAndFullScreen,
  setCurrentMarkersStatus,
  turnCommentingModeOn,
  turnMarkersVisibleOn,
} from '../../../commenting-tool/store';
import {
  loadMarkers,
  loadMarkersFailure,
  loadMarkersSuccess,
  resetMarkersState,
} from '../markers/markers.actions';
import { saveCurrentProjectId } from '../projects/projects.actions';
import { getCurrentProjectId } from '../projects/projects.selectors';
import { saveCurrentThreadId } from '../threads/threads.actions';
import { getCurrentThreadId } from '../threads/threads.selectors';
import { getCurrentUser } from '../users/users.selectors';
import {
  addFiles,
  addFilesFailure,
  addFilesSuccess,
  createFileVersion,
  createFileVersionSuccess,
  getAllFileCollection,
  getAllFileCollectionFailure,
  getAllFileCollectionSuccess,
  getInitFileListView,
  getInitFileListViewSuccess,
  loadFile,
  loadFileSuccess,
  loadFileVersion,
  loadFileVersionFailure,
  loadFileVersionSuccess,
  loadMarkerAutoincrementValue,
  loadMarkerAutoincrementValueFailure,
  loadMarkerAutoincrementValueSuccess,
  loadProjectFiles,
  loadProjectFilesFailure,
  loadProjectFilesSuccess,
  loadProjectsFiles,
  loadProjectsFilesFailure,
  loadProjectsFilesSuccess,
  loadThreadFiles,
  loadThreadFilesFailure,
  loadThreadFilesSuccess,
  markApprovedFileAsViewed,
  markApprovedFileAsViewedFailure,
  markApprovedFileAsViewedSuccess,
  markVersionAsViewed,
  markVersionAsViewedFailure,
  markVersionAsViewedSuccess,
  removeFile,
  removeFileFailure,
  removeFileSuccess,
  removeFileVersion,
  removeFileVersionFailure,
  removeFileVersionSuccess,
  selectCurrentVersion,
  toggleFileListView,
  toggleFileListViewSuccess,
  toggleFileVersionStatus,
  toggleFileVersionStatusFailure,
  toggleFileVersionStatusSuccess,
  tryRemoveFile,
  tryRemoveFileVersion,
} from './files.actions';
import {
  getCurrentFileId,
  getCurrentFileListStatus,
  getCurrentFileVersion,
  getCurrentFileVersionId,
  getFile,
  getFileStatus,
  getFileVersions,
  getNextFileVersion,
  isFileTypeImageOrModel,
  isFileTypeStreamOrVideo,
} from './files.selectors';
import { FilesService } from './services/files.service';
import {
  EFromFilePlace,
  EViewType,
  getVersionName,
  isDefined,
  stringToBoolean,
} from '@common/helpers';
import { IgraphQLErrorsObject } from '@common/types/IgraphQLErrorsObject';

@Injectable()
export class FilesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly filesService: FilesService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  tryRemoveFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tryRemoveFile),
      withLatestFrom(this.store.select(getFileStatus)),
      switchMap(([{ id, fileName }, fileStatus]) =>
        this.filesService.tryRemoveFile(fileStatus, false, [], fileName).pipe(
          filter(isDefined),
          map(() => removeFile({ id })),
        ),
      ),
    ),
  );

  tryRemoveFileVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tryRemoveFileVersion),
      withLatestFrom(
        this.store.select(getFileVersions),
        this.store.select(getFileStatus),
      ),
      switchMap(([{ fileVersion, fileId }, fileVersions, fileStatus]) =>
        this.filesService
          .tryRemoveFile(
            fileStatus,
            true,
            fileVersions,
            getVersionName(fileVersion),
          )
          .pipe(
            filter(isDefined),
            map(({ deleteAllVersion }) => {
              if (deleteAllVersion) {
                return removeFile({ id: fileId, redirect: true });
              }
              return removeFileVersion({ id: fileVersion.id });
            }),
          ),
      ),
    ),
  );

  removeFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFile),
      switchMap(({ id, redirect }) =>
        this.filesService.removeFile(id).pipe(
          map(() => removeFileSuccess({ id, redirect })),
          catchError((error: unknown) => of(removeFileFailure({ error }))),
        ),
      ),
    ),
  );

  removeFileVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeFileVersion),
      switchMap(({ id }) =>
        this.filesService.removeFileVersion(id).pipe(
          map(() => {
            return removeFileVersionSuccess({ id });
          }),
          catchError((error: unknown) =>
            of(removeFileVersionFailure({ error })),
          ),
        ),
      ),
    ),
  );

  removeFileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeFileSuccess),
        tap(({ redirect }) => {
          if (!redirect) {
            this.reload();
          } else {
            const prevPath: string | undefined =
              this.route.snapshot.queryParams.parentPath;
            if (prevPath) {
              this.router.navigateByUrl(prevPath);
            } else {
              this.router.navigate(['projects']);
            }
          }
        }),
      ),
    { dispatch: false },
  );

  removeFileVersionSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeFileVersionSuccess),
        withLatestFrom(
          this.store.select(getNextFileVersion),
          this.store.select(getCurrentFileId),
        ),
        tap(([, nextFileVersion, fileId]) => {
          const queryParams = this.route.snapshot.queryParams;

          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() => {
              this.router.navigate(['tools', fileId], {
                queryParams: {
                  ...queryParams,
                  version: nextFileVersion?.id || '',
                },
              });
            });
        }),
      ),
    { dispatch: false },
  );

  loadProjectFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProjectFiles),
      withLatestFrom(
        this.store.select(getCurrentProjectId),
        this.store.select(getCurrentFileListStatus),
      ),
      switchMap(([{ searchQuery, dateRange }, projectId, status]) => {
        const requestInput = {
          id: projectId,
          status,
          ...(stringToBoolean(this.route.snapshot.queryParams.image) && {
            image: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.video) && {
            video: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.unreal) && {
            unreal: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.webgl) && {
            webgl: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.otherFiles) && {
            otherFiles: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.isUnviewed) && {
            unviewedOnly: true,
          }),
          searchQuery,
          dateRange,
        };
        return projectId
          ? this.filesService.loadProjectFiles(requestInput).pipe(
              map((projectFiles) =>
                loadProjectFilesSuccess({
                  filesList: projectFiles,
                }),
              ),
              catchError((error: unknown) =>
                of(loadProjectFilesFailure({ error })),
              ),
            )
          : of(loadProjectFilesFailure({ error: 'No project id specified' }));
      }),
    ),
  );

  loadMarkerAutoincrementValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMarkerAutoincrementValue),
      withLatestFrom(this.store.select(getCurrentFileVersionId)),
      switchMap(([_, fileVersionId]) => {
        return fileVersionId
          ? this.filesService.loadMarkerAutoincrementValue(fileVersionId).pipe(
              map(({ markerAutoincrementValue }) =>
                loadMarkerAutoincrementValueSuccess({
                  markerAutoincrementValue,
                }),
              ),
              catchError((error: unknown) =>
                of(loadMarkerAutoincrementValueFailure({ error })),
              ),
            )
          : of(
              loadMarkerAutoincrementValueFailure({
                error: 'No File Version Id',
              }),
            );
      }),
    ),
  );

  loadThreadFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadThreadFiles),
      withLatestFrom(
        this.store.select(getCurrentThreadId),
        this.store.select(getCurrentFileListStatus),
      ),
      switchMap(([{ searchQuery, dateRange }, threadId, status]) => {
        const requestInput = {
          id: threadId,
          status,
          ...(stringToBoolean(this.route.snapshot.queryParams.image) && {
            image: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.video) && {
            video: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.unreal) && {
            unreal: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.webgl) && {
            webgl: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.otherFiles) && {
            otherFiles: true,
          }),
          ...(stringToBoolean(this.route.snapshot.queryParams.isUnviewed) && {
            unviewedOnly: true,
          }),
          searchQuery,
          dateRange,
        };
        return threadId
          ? this.filesService.loadThreadFiles(requestInput).pipe(
              map((threadFiles) =>
                loadThreadFilesSuccess({
                  filesList: threadFiles,
                }),
              ),
              catchError((error: unknown) =>
                of(loadThreadFilesFailure({ error })),
              ),
            )
          : of(loadThreadFilesFailure({ error: 'No threadId specified' }));
      }),
    ),
  );

  loadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadFile),
      withLatestFrom(this.store.select(getCurrentFileId)),
      switchMap(([{ resetState }, fileId]) => {
        if (!fileId) {
          console.error('no file id provided');
        }
        return this.filesService.loadFile(fileId).pipe(
          map((file) => {
            return loadFileSuccess({
              file,
              resetState,
            });
          }),
          catchError((error: unknown) =>
            of(loadProjectFilesFailure({ error })),
          ),
        );
      }),
    ),
  );

  selectCurrentVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectCurrentVersion),
      withLatestFrom(this.store.select(getFile)),
      mergeMap(([{ activeFileVersionId, markerStatus, resetState }, file]) => {
        const actions: Action[] = [
          markVersionAsViewed({ input: { ids: [activeFileVersionId] } }),
        ];
        if (file?.hasNewApprove) {
          actions.push(markApprovedFileAsViewed({ fileId: file.id }));
        }
        return [
          loadFileVersion({
            versionId: activeFileVersionId,
            markerStatus,
            resetState,
          }),
          setCurrentMarkersStatus({
            status: this.route.snapshot.queryParams.markerStatus,
          }),
          ...actions,
        ];
      }),
    ),
  );

  loadFileVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFileVersion),
      withLatestFrom(this.store.select(isFileTypeStreamOrVideo)),
      switchMap(([action, isFileStreamOrVideo]) => {
        return this.filesService
          .loadFileVersion(action.versionId, action.markerStatus)
          .pipe(
            mergeMap((fileVersion: GQLFileVersion) => {
              return [
                loadFileVersionSuccess({
                  fileVersion,
                  resetState: action.resetState,
                }),
                loadMarkersSuccess({
                  markers: isFileStreamOrVideo
                    ? []
                    : fileVersion.markerGroups[0]?.markers || [],
                  markersGroups: fileVersion.markerGroups,
                }),
              ];
            }),
            catchError((error: unknown) =>
              of(loadFileVersionFailure({ error })),
            ),
          );
      }),
    );
  });

  loadMarkers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadMarkers),
      withLatestFrom(this.store.select(isFileTypeStreamOrVideo)),
      switchMap(([action, isFileStreamOrVideo]) => {
        return this.filesService
          .loadMarkers(action.versionId, action.markerStatus)
          .pipe(
            map((fileVersion) => {
              return loadMarkersSuccess({
                markers: isFileStreamOrVideo
                  ? fileVersion.markerGroups.find(
                      (group) => group.id === action.markersGroupId,
                    )?.markers || []
                  : fileVersion.markerGroups[0]?.markers || [],
                markersGroups: fileVersion.markerGroups,
              });
            }),
            catchError((error: unknown) => of(loadMarkersFailure({ error }))),
          );
      }),
    );
  });

  resetByLoadFileVersion$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFileVersion),
      mergeMap(({ resetState }) => {
        const actions: Action[] = [resetMarkersState()];
        if (resetState) {
          actions.push(resetCommentingToolStateWithoutFilesAndFullScreen());
        }
        return actions;
      }),
    );
  });

  loadFileVersionSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFileVersionSuccess),
      withLatestFrom(
        this.store.select(getCurrentUser),
        this.store.select(isFileTypeImageOrModel),
      ),
      filter(([, isAuth, isImage]) => !isAuth && isImage),
      map(() => turnMarkersVisibleOn()),
    );
  });

  turnCommentingModeOn$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFileVersionSuccess),
      concatLatestFrom(() => [
        this.store.select(getCurrentUser),
        this.store.select(isFileTypeImageOrModel),
      ]),
      filter(
        ([{ resetState }, isAuth, isImage]) =>
          !!isAuth && isImage && resetState,
      ),
      map(() => {
        return turnCommentingModeOn();
      }),
    );
  });

  loadFileSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadFileSuccess),
      mergeMap(({ file, resetState }) => {
        const currentVersion =
          (file.versions || []).find(
            (ver) => ver.id === this.route.snapshot.queryParams.version,
          ) || file.versions?.[0];
        const actions: Action[] = [];

        const placeType = this.route.snapshot.queryParamMap.get(
          'from',
        ) as EFromFilePlace;
        if (placeType) {
          actions.push(loadFilesForCarousel({ placeType }));
        }

        return [
          saveCurrentProjectId({ currentProjectId: file.project.id }),
          saveCurrentThreadId({ currentThreadId: file.thread?.id || '' }),
          selectCurrentVersion({
            activeFileVersionId:
              currentVersion?.id || file.versions?.[0].id || '',
            markerStatus: this.route.snapshot.queryParams.markerStatus,
            resetState,
          }),
          ...actions,
        ];
      }),
    );
  });

  addFiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addFiles),
      switchMap(({ threadFiles, fileInput }) =>
        this.filesService.createFile(fileInput).pipe(
          mergeMap(() => [
            addFilesSuccess(),
            threadFiles
              ? loadThreadFiles({ searchQuery: '', dateRange: {} })
              : loadProjectFiles({ searchQuery: '', dateRange: {} }),
          ]),
          catchError((error: IgraphQLErrorsObject) =>
            of(addFilesFailure({ error })),
          ),
        ),
      ),
    ),
  );

  createFileVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createFileVersion),
      switchMap(({ fileVersionInput }) =>
        this.filesService.createFileVersion(fileVersionInput).pipe(
          mergeMap((fileVersion) => {
            return [
              createFileVersionSuccess({
                fileVersion,
                fileId: fileVersionInput.id,
              }),
              selectCurrentVersion({
                activeFileVersionId: fileVersion.id,
                markerStatus: this.route.snapshot.queryParams.markerStatus,
                resetState: true,
              }),
            ];
          }),
          catchError((error: IgraphQLErrorsObject) => {
            return of(addFilesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  markVersionAsViewed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(markVersionAsViewed),
      withLatestFrom(this.store.select(getCurrentUser)),
      filter(([, currentUser]) => !!currentUser),
      exhaustMap(([{ input }]) =>
        this.filesService.markVersionAsViewed(input).pipe(
          map(({ fileVersions }) =>
            markVersionAsViewedSuccess({ versions: fileVersions }),
          ),
          catchError((error: unknown) =>
            of(markVersionAsViewedFailure({ error })),
          ),
        ),
      ),
    ),
  );

  toggleFileVersionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleFileVersionStatus),
      withLatestFrom(
        this.store.select(getCurrentFileVersion),
        this.store.select(getMarkersStatus),
      ),
      exhaustMap(([, currentFileVersion, markerStatus]) => {
        const isVersionApproved =
          currentFileVersion?.status === GQLFileVersionStatusEnum.APPROVED;
        return this.filesService
          .approveDisaproveFileVersion(
            isVersionApproved,
            currentFileVersion?.id,
            markerStatus,
          )
          .pipe(
            mergeMap((fileVersion) => {
              return [
                loadFileVersion({
                  versionId: currentFileVersion?.id,
                  markerStatus,
                  resetState: true,
                }),
                toggleFileVersionStatusSuccess({
                  fileVersion,
                }),
              ];
            }),
            catchError((error: unknown) =>
              of(toggleFileVersionStatusFailure({ error })),
            ),
          );
      }),
    ),
  );

  getFileCollection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getAllFileCollection),
      switchMap(({ searchQuery, dateRange }) => {
        const {
          sortBy,
          isUnviewed,
          image,
          video,
          unreal,
          webgl,
          otherFiles,
          projectsIds,
        } = this.route.snapshot.queryParams;
        const ids: string[] = projectsIds?.split(',');
        const request = {
          ...(sortBy && { status: sortBy as GQLFileStatusEnum }),
          ...(stringToBoolean(isUnviewed as EStringToBoolean) && {
            unviewedOnly: stringToBoolean(isUnviewed as EStringToBoolean),
          }),
          ...(stringToBoolean(image as EStringToBoolean) && {
            image: stringToBoolean(image as EStringToBoolean),
          }),
          ...(stringToBoolean(video as EStringToBoolean) && {
            video: stringToBoolean(video as EStringToBoolean),
          }),
          ...(stringToBoolean(unreal as EStringToBoolean) && {
            unreal: stringToBoolean(unreal as EStringToBoolean),
          }),
          ...(stringToBoolean(webgl as EStringToBoolean) && {
            webgl: stringToBoolean(webgl as EStringToBoolean),
          }),
          ...(stringToBoolean(otherFiles as EStringToBoolean) && {
            otherFiles: stringToBoolean(otherFiles as EStringToBoolean),
          }),
          ids,
          searchQuery,
          dateRange,
        };

        return this.filesService.getFileCollection(request).pipe(
          map((fileCollection) =>
            getAllFileCollectionSuccess({ fileCollection }),
          ),
          catchError((error: unknown) =>
            of(getAllFileCollectionFailure({ error })),
          ),
        );
      }),
    );
  });

  loadProjectsFiles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadProjectsFiles),
      switchMap(() => {
        return this.filesService.getAllProjectInFileCollection().pipe(
          map((totalProjectList) =>
            loadProjectsFilesSuccess({ totalProjectList }),
          ),
          catchError((error: unknown) =>
            of(loadProjectsFilesFailure({ error })),
          ),
        );
      }),
    );
  });

  markApprovedFileAsViewed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(markApprovedFileAsViewed),
      withLatestFrom(this.store.select(getCurrentUser)),
      filter(([, currentUser]) => !!currentUser),
      switchMap(([{ fileId }]) =>
        this.filesService.markApprovedFileAsViewed(fileId).pipe(
          map((file) => {
            return markApprovedFileAsViewedSuccess({ file });
          }),
          catchError((error: unknown) =>
            of(markApprovedFileAsViewedFailure({ error })),
          ),
        ),
      ),
    );
  });

  getFileListView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getInitFileListView),
      switchMap(() => {
        const viewType =
          (localStorage.getItem('fileListView') as EViewType) || EViewType.CARD;
        return of(getInitFileListViewSuccess({ viewType }));
      }),
    );
  });

  toggleFileListView$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(toggleFileListView),
      switchMap(({ viewType }) => {
        localStorage.setItem('fileListView', viewType);
        return of(toggleFileListViewSuccess({ viewType }));
      }),
    );
  });

  reload() {
    // todo try to avoid hard reload page
    window.location.reload();
  }
}
