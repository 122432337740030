<div class="add-thread">
  <div class="form-modal__body">
    <div
      class="add-thread__author"
      pTooltip="{{ userName$ | async }}"
      tooltipZIndex="10000000"
    >
      <ngx-avatars
        class="my-avatar"
        [src]="userAvatar$ | async"
        [name]="currentUserAvatarName$ | async"
        [attr.data-test-id]="
          'currentUserAvatarName' + (currentUserAvatarName$ | async)
        "
      ></ngx-avatars>
    </div>
    <form class="add-thread__form form" [formGroup]="threadEditForm">
      <div [class.loading]="isLoading$ | async">
        <div class="form__elem">
          <label
            class="form__label form__label--hinted"
            name="title"
            ngDefaultControl
          >
            <span>
              Title
              <em>(required)</em>
            </span>
            <span class="form__label-hint" data-test-id="threadEditFormHint">
              The header must contain a maximum of 60 characters
            </span>
          </label>
          <div class="form__input">
            <input
              type="text"
              formControlName="title"
              [class.invalid]="isTitleInvalid"
              [placeholder]="'Enter title'"
              maxLength="60"
              required
              data-test-id="threadEditInputTitle"
            />
          </div>

          <div
            class="form__hint"
            *ngIf="isTitleInvalid"
            [class.invalid]="isTitleInvalid"
            data-test-id="threadEditFormHintTitleInvalid"
          >
            Thread title is required
          </div>
        </div>
        <div class="form__elem form__elem--description">
          <label class="form__label" for> Description </label>
          <div class="form__input">
            <p-editor
              [style]="{ height: '107px' }"
              formControlName="body"
              [placeholder]="placeholder"
              data-test-id="threadDescriptionInput"
            >
              <p-header>
                <span class="ql-formats">
                  <button class="ql-bold" tabindex="-1" data-test-id="pEditorFormatsBold"></button>
                  <button class="ql-italic" tabindex="-1" data-test-id="pEditorFormatsItalic"></button>
                  <button class="ql-underline" tabindex="-1" data-test-id="pEditorFormatsUnderline"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-align" value="" tabindex="-1" data-test-id="pEditorFormatsAlignLeft"></button>
                  <button
                    class="ql-align"
                    value="center"
                    tabindex="-1"
                    data-test-id="pEditorFormatsAlignCenter"
                  ></button>
                  <button class="ql-align" value="right" tabindex="-1" data-test-id="pEditorFormatsAlignRight"></button>
                </span>
                <span class="ql-formats">
                  <button class="ql-blockquote" tabindex="-1" data-test-id="pEditorFormatsBlockquote"></button>
                </span>
              </p-header>
            </p-editor>
          </div>
        </div>

        <div class="form__elem form__elem--uploader">
          <div class="form__input">
            <app-file-uploader
              [inputFiles]="inputFilesUrls"
              [fileType]="null"
              (filesAttached)="filesAttached($event)"
              (filesIdsForDeletingOutput)="filesIdsForDeletingOutput($event)"
              data-test-id="appFileUploader"
            ></app-file-uploader>
          </div>
        </div>

        <div class="form__elem form__elem--subscribers">
          <div *ngIf="canCreatePrivate$ | async" class="private">
            <div class="private__input">
              <div class="toggle">
                <input
                  type="checkbox"
                  id="toggle-button"
                  [checked]="isPrivate"
                  (change)="toggleVisibility()"
                  class="toggle__button"
                  data-test-id="createPrivateThreadInp"
                />
                <label for="toggle-button" class="toggle__label">
                  Private thread
                </label>
              </div>
            </div>
            <div class="private-box" *ngIf="isPrivate">
              <div class="private-box__label" data-test-id="privateThreadLabel">
                *Choose who can see your thread:
              </div>
              <app-subscribers
                [setConnectedUsers]="thread?.contributors || null"
                [alreadySelectedUsers]="selectedPrivateUsers"
                [availableSubscribers]="(subscribers | async) || []"
                (privateUsers)="setPrivateUsers($event)"
                data-test-id="appSubscribers"
              ></app-subscribers>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div
    class="form-modal__footer form-modal__footer--right"
    [class.disabled]="isLoading$ | async"
  >
    <button
      type="reset"
      class="button button--clear"
      (click)="close()"
      data-test-id="editThreadCancelButton"
    >
      Cancel
    </button>
    <button
      [disabled]="threadEditForm.invalid"
      class="button"
      (click)="submitForm()"
      data-test-id="editThreadCreateButton"
    >
      {{ this.inputData.data.isEditing ? 'Save' : 'Create' }}
    </button>
  </div>
</div>
