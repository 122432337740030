export const AUTHOR = `
id
email
firstName
lastName
fullName
avatarPath
role
availableRoles`;

export const MARKER = `
 id
  serialNumber
  coordinates
  hasNewComment
  status
  comments {
    id
    body
    author {
      ${AUTHOR}
    }
    isViewed
    isEdited
    createdAt
    canEdit
    canRemove
  }
  createdAt`;

export const COMMENT = `
  id
  body
  author {
    ${AUTHOR}
  }
  isViewed
  isEdited
  createdAt
  canEdit
  canRemove
`;

export const FILE_VERSION = `
createdAt
id
path
previewPath
size
isViewed
hash
title
serialNumber
extension
status
hasNewComment
canRemove
author {
  id
  fullName
}
markerGroups {
  id
  markers(status: ACTIVE){
   ${MARKER}
  }
}
`;

export const FILE_VERSION_ALL = `
createdAt
id
path
hash
previewPath
size
isViewed
serialNumber
markers(status: ALL){
 ${MARKER}
}
`;
// TODO refactor this

export const FILE = `
id
name
type
updatedAt
createdAt
useTools
hash
status
canRemove
canComment
extension
streamUrl
unrealVersion
hasNewVersion
hasNewComment
hasNewApprove
canAddVersion
project {
        id
        title
      }
thread {
        id
        title
      }
  versions {
       ${FILE_VERSION}
  }
`;

export const FILES = `
  items {
      id
      name
      extension
      type
      updatedAt
      createdAt
      useTools
      status
      canRemove
      thread {
        id
        title
      }
      project {
        id
        title
      }
       hasNewVersion
       hasNewComment
       hasNewApprove
        versions {
          ${FILE_VERSION}
        }
    }
    limit
    skip
    total
`;

export const PROJECT_IN_PROJECT_LIST = `
id
title
description
logoPath
author {
  ${AUTHOR}
}
profiles {
  ${AUTHOR}
}
status
canEdit
canRemove
startDate
expirationDate
createdAt
updatedAt
`;

export const PROJECT = `
id
title
description
logoPath
author {
  ${AUTHOR}
}
threads {
  items {
    id
    title
    canAddFile
    ownFiles {
      id
    }
  }
}
profiles {
  ${AUTHOR}
}
invitations {
  id
  email
  role
  status
}
status
canEdit
canRemove
canAddFileWithoutThread
startDate
expirationDate
createdAt
files(limit: 999999999) {
  ${FILES}
}
`;

export const THREAD = `
id
title
body
isViewed
status
accessLevel
serialNumber
updatedAt
canEdit
canRemove
canApprove
canCreateMessage
createdAt
canDisapprove
canAddFile
files(limit: 999, skip: 0) {
  items {
    id
    name
    type
    updatedAt
    createdAt
    useTools
    author {
      id
      fullName
    }
    project {
      id
      title
    }
    thread {
      id
      title
    }
    status
    canRemove
    extension
    hasNewVersion
    hasNewComment
    hasNewApprove
      versions {
        ${FILE_VERSION}
      }
  }
  limit
  skip
  total
}
ownFiles {
    id
    name
    type
    updatedAt
    createdAt
    useTools
    status
    project {
      id
      title
    }
    thread {
      id
      title
    }
    hasNewVersion
    hasNewComment
    hasNewApprove
    extension
    canRemove
      versions {
        ${FILE_VERSION}
      }
}
author{
    ${AUTHOR}
}
  messages{
    items{
      id
      isViewed
    }
    limit
    skip
    total
    unread
}
contributors {
    ${AUTHOR}
}`;

export const MESSAGE_FULL = `
id
body
isViewed
canEdit
canRemove
canReply
isEdited
files {
    id
    name
    type
    updatedAt
    status
    hasNewVersion
    hasNewComment
    hasNewApprove
    extension
    canRemove
    project {
      id
      title
    }
    thread {
      id
      title
    }
    useTools
      versions {
        ${FILE_VERSION}
      }
}
parent {
  id
  body
  author{
    ${AUTHOR}
  }
  createdAt
  updatedAt
  isEdited
}
updatedAt
createdAt
author{
  ${AUTHOR}
}
`;

export const MESSAGE_SHORT = `
  id
  body
  isViewed
  canEdit
  canRemove
  canReply
  isEdited
  files {
    id
    name
    type
    updatedAt
    extension
    status
    useTools
    versions {
      createdAt
      id
      path
      previewPath
      size
      isViewed
      hash
      serialNumber
      extension
      status
      hasNewComment
      isViewed
      canRemove
      author {
        id
        fullName
      }
    }
  }
  updatedAt
  createdAt
  author{
    id
    fullName
  }
`;
