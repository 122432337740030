import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GQLAccessLevelEnum,
  GQLApproveThreadInput,
  GQLDisapproveThreadInput,
  GQLProfile,
  GQLRemoveInput,
  GQLThread,
  GQLThreadStatusEnum,
} from '../../../../schemas/schemas';
import { getCuttedUserName } from '@common/helpers';
import { getCurrentProjectId } from '@common/store/projects/projects.selectors';
import {
  approveThread,
  deleteThread,
  disapproveThread,
} from '@common/store/threads/threads.actions';
import {
  ConfirmModalComponent,
  EconfirmModalType,
} from '@common/modules/confirm-modal/components/confirm-modal/confirm-modal.component';
import { ThreadAddComponent } from '../thread-add/thread-add.component';

@Component({
  selector: 'app-threads-item',
  templateUrl: './treads-item.component.html',
  styleUrls: ['./treads-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreadsItemComponent implements OnDestroy, OnInit {
  @ViewChild('op', { static: false }) private overlayPanel!: OverlayPanel;
  @Input() thread: GQLThread = {} as GQLThread;
  isDotsActive = false;
  timeFromNow: any;
  isApproved = false;
  skipLocationChange = false;

  readonly threadStatus = GQLThreadStatusEnum;
  readonly accessLevel = GQLAccessLevelEnum;

  projectId$ = this.store.select(getCurrentProjectId);

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly dialogService: DialogService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
  ) {}

  ngOnInit() {
    this.isApproved =
      this.thread?.status === GQLThreadStatusEnum.APPROVED ?? false;
  }

  get author(): GQLProfile {
    return this.thread.author;
  }

  get authorAvatarName(): string {
    return getCuttedUserName(this.author);
  }

  get isApproveBtnDisabled(): boolean {
    return !this.thread.canApprove && !this.thread.canDisapprove;
  }

  openConfirmModal(thread: GQLThread): void {
    const dialogConfig = {
      data: {
        id: thread.id,
        title: thread.title,
        buttonText: 'Delete',
        text: 'This thread will be deleted permanently!',
        type: EconfirmModalType.DELETE,
      },
      header: `Delete thread #${thread.serialNumber}?`,
      styleClass: 'service-modal',
    };

    this.dialogService
      .open(ConfirmModalComponent, dialogConfig)
      .onClose.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isDelete: boolean) => {
        if (isDelete) {
          this.removeThread(thread);
        }
      });
  }

  openEditModal(thread: GQLThread): void {
    const dialogConfig = {
      data: {
        isEditing: true,
        thread,
      },
      header: `Edit thread #${thread.serialNumber}`,
      styleClass: 'form-modal',
    };
    const ref = this.dialogService.open(ThreadAddComponent, dialogConfig);
  }

  openApproveModal(thread: GQLThread, event: Event): void {
    event.stopPropagation();
    const dialogApproveConfig = {
      data: {
        id: thread.id,
        title: thread.title,
        buttonText: 'Approve',
        type: EconfirmModalType.CONFIRM,
      },
      header: `Approve thread #${thread.serialNumber}?`,
      styleClass: 'service-modal',
    };

    const dialogUnapproveConfig = {
      data: {
        id: thread.id,
        title: thread.title,
        buttonText: 'Unapprove',
        type: EconfirmModalType.CONFIRM,
      },
      header: `Unapprove thread #${thread.serialNumber}?`,
      styleClass: 'service-modal',
    };

    const dialogConfig = this.isApproved
      ? dialogUnapproveConfig
      : dialogApproveConfig;

    this.dialogService
      .open(ConfirmModalComponent, dialogConfig)
      .onClose.pipe(takeUntil(this.unsubscribe$))
      .subscribe((isConfirm) => {
        if (isConfirm && !this.isApproved) {
          this.approveThread(thread);
        } else if (isConfirm && this.isApproved) {
          this.unapproveThread(thread);
        }
      });
  }

  get getQueryParams(): Params | null {
    if (this.thread?.messages?.unread) {
      this.skipLocationChange = true;
      return {
        message_index:
          (this.thread?.messages?.total || 0) -
          (this.thread?.messages?.unread || 0),
      };
    }
    this.skipLocationChange = false;
    return {
      threadStatus: this.thread.status,
    };
  }

  private removeThread(thread: GQLThread): void {
    const input: GQLRemoveInput = {
      id: thread.id,
    };
    this.store.dispatch(deleteThread({ input }));
  }

  private approveThread(thread: GQLThread): void {
    const input: GQLApproveThreadInput = {
      id: thread.id,
    };

    this.store.dispatch(approveThread({ input }));
  }

  private unapproveThread(thread: GQLThread): void {
    const input: GQLDisapproveThreadInput = {
      id: thread.id,
    };
    this.store.dispatch(disapproveThread({ input }));
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: GQLThreadStatusEnum.ACTIVE },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
