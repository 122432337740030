/* eslint-disable guard-for-in */
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, FormGroup, Validators } from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { GQLFile, GQLFileUpload } from '../../../../schemas/schemas';
import { EFileListType } from '@common/types/EFileListType';
import {
  addFiles,
  addFilesSuccess,
  resetFileLoadingFlag,
} from '@common/store/files/files.actions';
import { getFileListLoading } from '@common/store/files/files.selectors';
import {
  canAddFileWithoutThread,
  getCurrentProjectId,
} from '@common/store/projects/projects.selectors';
import {
  getCurrentThreadId,
  getThreadsList,
} from '@common/store/threads/threads.selectors';

@Component({
  selector: 'app-file-create',
  templateUrl: './file-create.component.html',
  styleUrls: ['./file-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCreateComponent implements OnInit, OnDestroy {
  readonly fileListType = EFileListType;

  filesAddForm: FormGroup = new FormGroup({});
  attachments: GQLFile[] = [];
  projectId = '';
  threadId = '';
  isFileUpload = false;
  threadsList$ = this.store.select(getThreadsList);

  isLoading$: Observable<boolean> = this.store.select(getFileListLoading).pipe(
    tap((isLoading: boolean) => {
      this.isFileUpload = !isLoading;
    }),
  );

  canAddFileWithoutThread$ = this.store.select(canAddFileWithoutThread);

  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef,
    private store: Store,
    private updates$: Actions,
  ) {}

  close(): void {
    this.store.dispatch(resetFileLoadingFlag());
    this.ref.close();
  }

  ngOnInit() {
    this.initForm();
    this.updates$
      .pipe(ofType(addFilesSuccess), takeUntil(this.unsubscribe$))
      .subscribe(() => this.close());

    this.store
      .select(getCurrentProjectId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((projectId) => {
        this.projectId = projectId;
      });

    this.store
      .select(getCurrentThreadId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((threadId) => {
        this.threadId = threadId || '';
      });
  }

  private initForm(): void {
    if (this.config.data.fileListType === EFileListType.PROJECT) {
      this.filesAddForm.addControl(
        'selectedThread',
        new UntypedFormControl(null, [Validators.required]),
      );
    }
  }

  filesAttached(files: GQLFileUpload[]) {
    this.attachments = files;
  }

  get isFormValid(): boolean {
    return !this.attachments.length || !this.isFileUpload;
  }

  submitForm() {
    if (this.filesAddForm.invalid) {
      // eslint-disable-next-line no-restricted-syntax
      for (const controlsKey in this.filesAddForm.controls) {
        this.filesAddForm.controls[controlsKey].markAsTouched();
      }
      return;
    }
    const fileInput = {
      projectId: this.projectId,
      threadId: this.threadId
        ? this.threadId
        : this.filesAddForm?.controls?.selectedThread?.value,
      attachments: this.attachments.map((file: any) => {
        return {
          file: file?.file,
          useTools: file.useTools,
          type: file.type,
        };
      }),
    };

    if (this.filesAddForm?.controls?.selectedThread?.value === 'others') {
      const { threadId, ...other } = fileInput;
      this.store.dispatch(
        addFiles({ threadFiles: !!this.threadId, fileInput: { ...other } }),
      );
    } else {
      this.store.dispatch(
        addFiles({ threadFiles: !!this.threadId, fileInput }),
      );
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(resetFileLoadingFlag());
  }
}
