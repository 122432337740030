import { gql } from 'apollo-angular';
import { MARKER } from '../variables';

export const GET_MARKERS = gql`
      query Markers($id: ID!, $markerStatus: FileMarkerStatusEnum) {
            fileVersion(id: $id) {
                  id
                  markerGroups {
                        id
                        imagePath
                        previewPath
                        configuration
                        markers(status: $markerStatus){
                              ${MARKER}
                        }
                  }
            }
      }
      
`;
