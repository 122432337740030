import { gql } from 'apollo-angular';

export const GET_SIMPLIFIED_FILE_COLLECTION = gql`
  query files(
    $status: FileStatusEnum
    $useTools: Boolean
    $unviewedOnly: Boolean
    $limit: Int
    $skip: Int
    $ids: [ID!]
  ) {
    files(
      status: $status
      useTools: $useTools
      unviewedOnly: $unviewedOnly
      limit: $limit
      skip: $skip
      ids: $ids
    ) {
      items {
        files {
          files {
            id
            type
            versions {
              id
            }
          }
        }
      }
    }
  }
`;
