/*
 *  3D Source AMS/VPS
 *  http://3dsource.com/
 *
 *  3D Source is the only company that exists exclusively to help product manufacturers
 *  evolve their sales and marketing assets from photography to photorealistic
 *  3D and CGI.
 *  3D Source provides access to a scalable network of content developers and
 *  groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 *  Copyright 2020 3D Source
 */
import { Inject, Injectable, Injector } from '@angular/core';
import { FetchPolicy } from '@apollo/client/core';
import { Apollo, Query } from 'apollo-angular';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { APP_CONFIG } from '../helpers/constants';
import { AppConfig, AppEnv } from '../types';

@Injectable({
  providedIn: 'root',
})
export class BaseApi {
  constructor(
    public apollo: Apollo,
    public toastr: ToastrService,
    @Inject(APP_CONFIG) readonly appConfig: AppConfig,
  ) {}

  mutate(
    mutation: any,
    variables: any = {},
    context?: any,
    update?: any,
  ): Observable<any> {
    const mutation$: Observable<any> = this.apollo
      .mutate<Query>({
        mutation,
        context,
        variables,
        update,
      })
      .pipe(
        catchError((err: any) => {
          console.error(err);
          if (this.appConfig.app_env !== AppEnv.PROD) {
            this.toastr.error(err, 'Error');
          }
          return throwError(err);
        }),
      );
    return mutation$;
  }

  query(
    query: any,
    variables: any = {},
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Observable<any> {
    return this.apollo
      .query<Query>({
        query,
        fetchPolicy,
        errorPolicy: 'ignore',
        variables,
      })
      .pipe(
        catchError((err) => {
          if (this.appConfig.app_env !== AppEnv.PROD) {
            this.toastr.error(err, 'Error');
          }
          return of([]);
        }),
      );
  }
}
