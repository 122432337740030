import { gql } from 'apollo-angular';

export const REMOVE_FILE_VERSION = gql`
  mutation ($input: RemoveInput!) {
    removeFileVersion(input: $input) {
      id
      success
    }
  }
`;
