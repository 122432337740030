import { Inject, Injectable } from '@angular/core';
import { Query, QueryRef } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  GQLCreateMarkerGroupInput,
  GQLCreateMarkerPayload,
} from '../../../../../schemas/schemas';
import { CREATE_MARKER_COMMENT } from '../../../graphQL/graphql-requests/mutations/CREATE_MARKER_COMMENT';
import { CREATE_MARKER_GROUP } from '../../../graphQL/graphql-requests/mutations/CREATE_MARKER_GROUP';
import { RESOLVE_MARKER } from '../../../graphQL/graphql-requests/mutations/RESOLVE_MARKER';
import { UNRESOLVE_MARKER } from '../../../graphQL/graphql-requests/mutations/UNRESOLVE_MARKER';
import { GET_MARKER_GROUP } from '../../../graphQL/graphql-requests/queries/GET_MARKER_GROUP';
import { APP_CONFIG } from '../../../helpers';
import { AppConfig } from '../../../types';
import { BaseApi } from '../../../services/base-api';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  constructor(
    private api: BaseApi,
    @Inject(APP_CONFIG) private readonly appConfig: AppConfig,
  ) {}

  toggleResolveMarker(
    isResolved: boolean,
    id: string,
  ): Observable<GQLCreateMarkerPayload> {
    return this.api
      .mutate(isResolved ? UNRESOLVE_MARKER : RESOLVE_MARKER, {
        input: { id },
      })
      .pipe(
        map(({ data }: any) => {
          return isResolved ? data.unresolveMarker : data.resolveMarker;
        }),
      );
  }

  createMarkerComment(id: string, description: string): Observable<any> {
    const link = `${this.appConfig.api.url}#/tools/%file_id%?version=%version_id%&markersGroupId=%group_id%&markerId=%marker_id%&commentId=%comment_id%`;

    return this.api.mutate(
      CREATE_MARKER_COMMENT,
      {
        input: {
          markerId: id,
          body: description,
          link,
        },
      },
      'network-only',
    );
  }

  createMarkerGroup(input: GQLCreateMarkerGroupInput): Observable<any> {
    return this.api
      .mutate(
        CREATE_MARKER_GROUP,
        {
          input,
        },
        'network-only',
      )
      .pipe(
        map(({ data }) => {
          return data.createMarkerGroup;
        }),
      );
  }

  getMarkerGroup(id: string): Observable<any> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_MARKER_GROUP,
        variables: {
          id,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map(({ data }: any) => {
          return data.markerGroup;
        }),
      );
  }
}
