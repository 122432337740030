/*
 * 3D Source Platform
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 7/9/21, 3:55 PM
 * Copyright 2021 3D Source
 */

import { NavigationExtras } from '@angular/router';
import { GQLFileMarkerStatusEnum } from '../../../schemas/schemas';
import { EFromFilePlace } from './constants';

export function setQueryParametersForImageTools(
  fileVersionId: string,
  parentPath: string,
  from: EFromFilePlace,
): NavigationExtras {
  const isAllMarkersSelected = !!localStorage.getItem(
    'allMarkersInToolsSelected',
  );
  return {
    queryParams: {
      version: fileVersionId,
      parentPath: parentPath.toString(),
      markerStatus: isAllMarkersSelected
        ? GQLFileMarkerStatusEnum.ALL
        : GQLFileMarkerStatusEnum.ACTIVE,
      from,
    },
    queryParamsHandling: 'merge',
    skipLocationChange: true,
  };
}

export function setQueryParametersForFilesPage(
  parentPath: string,
): NavigationExtras {
  return {
    queryParams: {
      parentPath: parentPath.toString(),
    },
    queryParamsHandling: 'merge',
  };
}
