import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { getCurrentUserPermissions } from '../../store/users/users.selectors';
import { IUserPermissions } from '@common/types/IUserPermisions';

@Injectable()
export class ProjectGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private store: Store,
  ) {}

  canActivate(): Observable<boolean> {
    return this.store.select(getCurrentUserPermissions).pipe(
      filter((permissions) => !!permissions),
      map((permissions: IUserPermissions) => {
        if (!permissions?.project?.create) {
          this.router.navigate(['no-access']);
        }
        return !!permissions?.project?.create;
      }),
    );
  }
}
