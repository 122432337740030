/* eslint-disable no-param-reassign */
/*
 * 3D Source Configurator
 * http://3dsource.com/
 *
 * 3D Source is the only company that exists exclusively to help product manufacturers
 * evolve their sales and marketing assets from photography to photorealistic
 * 3D and CGI.
 * 3D Source provides access to a scalable network of content developers and
 * groundbreaking software that supports trillions of possible design options in catalog quality.
 *
 * Last modified 17.06.2021, 18:32
 * Copyright 2021 3D Source
 */

export class Utils {
  static loadImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve: any, reject) => {
      const img = new Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => {
        setTimeout(() => resolve(img), 1);
      };
      img.src = url;
    });
  }

  static sleep(time: number): Promise<any> {
    return new Promise((r) => setTimeout(r, time));
  }

  static expandRect(objectRect: Rect, areaRect: Rect): Rect {
    const ratio: number = objectRect.w / objectRect.h;
    const result: Rect = { x: 0, y: 0, w: 0, h: 0 };
    if (areaRect.w / ratio < areaRect.h) {
      result.w = areaRect.h * ratio;
      result.h = areaRect.h;
    } else {
      result.w = areaRect.w;
      result.h = areaRect.w / ratio;
    }
    result.x = areaRect.x + (areaRect.w - result.w) / 2;
    result.y = areaRect.y + (areaRect.h - result.h) / 2;

    return result;
  }

  static checkProportions(
    objectRect: Rect,
    areaRect: Rect,
    round: boolean = true,
    fixedRatio?: number,
  ): Area {
    const result: Rect = { x: 0, y: 0, w: 0, h: 0 };
    const ratio: number = objectRect.h / objectRect.w;

    if (objectRect.h > objectRect.w) {
      result.h = areaRect.h;
      result.w = areaRect.h / ratio;

      if (result.w > areaRect.w) {
        result.w = areaRect.w;
        result.h = areaRect.w * ratio;
      }
    } else {
      result.w = areaRect.w;
      result.h = areaRect.w * ratio;

      if (result.h > areaRect.h) {
        result.h = areaRect.h;
        result.w = areaRect.h / ratio;
      }
    }

    if (fixedRatio) {
      result.w = objectRect.w * fixedRatio;
      result.h = objectRect.h * fixedRatio;
    }
    const wOut: number = round ? Math.floor(result.w) : result.w;
    const hOut: number = round ? Math.floor(result.h) : result.h;
    return {
      x: areaRect.x + (areaRect.w - wOut) / 2,
      y: areaRect.y + (areaRect.h - hOut) / 2,
      w: wOut,
      h: hOut,
      scale: objectRect.w / wOut,
    };
  }

  static clampf(min: number, max: number, value: number): number {
    return Math.min(max, Math.max(min, value));
  }

  static lerp(min: number, max: number, value: number): number {
    value = Utils.clampf(0, 1, value);
    return value * (max - min) + min;
  }

  static pad(num: number | string, size: number): string {
    let s = `${num}`;
    while (s.length < size) {
      s = `0${s}`;
    }
    return s;
  }
}

export interface Size {
  w: number;
  h: number;
}

export interface ICanvasSize {
  maxHeigth: number;
  maxWidth: number;
}

export interface Rect extends Size {
  x: number;
  y: number;
}

export interface Area extends Rect {
  scale: number;
}
