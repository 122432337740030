import { gql } from 'apollo-angular';

export const GET_THREAD_FILES_IDS_WITH_ORIGIN_ORDER = gql`
  query ($id: ID!) {
    threadFilesWithOriginOrder(id: $id) {
      id
      type
      versions {
        id
      }
    }
  }
`;
