import { gql } from 'apollo-angular';

export const MARK_APPROVED_FILE_AS_VIEWED = gql`
  mutation ($input: MarkFileApproveAsViewedInput!) {
    markFileApproveAsViewed(input: $input) {
      file {
        id
        hasNewApprove
        hasNewComment
        hasNewVersion
      }
    }
  }
`;
