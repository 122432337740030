import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { EFileListType } from '@common/types/EFileListType';
import { getCurrentProjectFiles } from '@common/store/projects/projects.selectors';

@Component({
  selector: 'threads-sidebar',
  templateUrl: './treads-sidebar.component.html',
  styleUrls: ['./treads-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreadsSidebarComponent {
  readonly files$ = this.store.select(getCurrentProjectFiles);

  readonly fileListType = EFileListType;

  constructor(private readonly store: Store) {}
}
