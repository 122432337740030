import { Injectable } from '@angular/core';
import {
  EUIInteractionCommand,
  ResponsesFromUnreal,
} from '@common/helpers/constants';
import { UnrealService } from './unreal.service';
import {
  distinctUntilChanged,
  filter,
  Observable,
  Subject,
  switchMap,
  of,
  combineLatest,
} from 'rxjs';
import { MetaBoxMessagePayload } from '../interfaces/metabox-message-payload.interface';
import { MetaBoxMessage } from '../interfaces/metabox-message.interface';
import { EmbedProductConfiguration } from '../../common/types/embed-product-configuration.interface';
import { Store } from '@ngrx/store';
import { getCurrentFileVersionPath } from '@common/store/files/files.selectors';
import { isDefined } from '@common/helpers';
import { HttpClient } from '@angular/common/http';
import { MetaBoxApiAction } from '../enums/MetaBoxApiAction.enum';
import { getFileType } from '../store';
import { GQLFileTypeEnum } from '@schemas';

declare const window: any;
const messageType = 'metaboxData';

/**
 * API class to handle communication with the embedded content.
 */
class Api {
  // Initialize a storage for callbacks keyed by message types
  listeners: { [key: string]: (data: any) => any } = {};

  constructor() {
    // Listen for messages from the iframe
    window.addEventListener(
      'message',
      this.handleMessageReceived.bind(this, messageType),
    );
  }

  /**
   * Sends a message to the embedded content (MetaBox).
   *
   * @param {Object} data The data to send.
   */
  sendMessageToMetaBox(data: MetaBoxMessagePayload): void {
    const iframe = document.getElementById(
      'embeddedContent',
    ) as HTMLIFrameElement | null;
    const dataToSend: MetaBoxMessage = {
      host: 'metaBoxHost',
      payload: data,
    };
    iframe?.contentWindow?.postMessage(dataToSend, '*'); // In production, replace "*" with the actual origin
    //console.log(`[PLATFORM] ${JSON.stringify(dataToSend)} was sent to metabox`);
  }

  /**
   * Adds an event listener for receiving specific types of messages.
   *
   * @param {string} messageType The message type to listen for.
   * @param {Function} callback The callback function to execute when a message is received.
   */
  addEventListener(msgType: string, callback: (data: any) => any): void {
    this.listeners[msgType] = callback;
  }

  /**
   * Handles incoming messages by invoking the appropriate callback.
   *
   * @param {MessageEvent} event The incoming message event.
   */
  handleMessageReceived(msgType: string, event: any): void {
    // Optional: Perform origin check here for added security

    if (event?.data?.host !== 'metabox') {
      return;
    }

    // Trigger the listener if the messageType is recognized
    if (this.listeners[msgType]) {
      this.listeners[msgType](event.data.payload);
    }
  }

  /**
   * Removes an event listener for a specific type of message.
   *
   * @param {string} type The message type.
   */
  removeEventListener(msgType: string): void {
    delete this.listeners[msgType];
  }
}

@Injectable({
  providedIn: 'root',
})
export class UnrealEmbedService extends UnrealService {
  embedFile$: Subject<{ url: string; image: string }> = new Subject();
  productSlotsMaterials$: Subject<EmbedProductConfiguration> = new Subject();

  api!: Api;
  appLoaded$: Subject<void> = new Subject<void>();
  private responseEventListeners: Map<string, (data: any) => void> = new Map();

  constructor(
    private readonly store: Store,
    private readonly http: HttpClient,
  ) {
    super();
    this.addExternalApi();
    this.addExternalListeners();
    this.getEmbedFile();
  }

  getEmbedFile(): void {
    combineLatest([
      this.store.select(getCurrentFileVersionPath),
      this.store.select(getFileType),
    ])
      .pipe(
        filter(([path, fileType]) => isDefined(path) && isDefined(fileType)),
        switchMap(([path, fileType]) => {
          if (fileType === GQLFileTypeEnum.EMBED && !!path) {
            return this.fetchFile(path);
          } else {
            return of('');
          }
        }),
      )
      .subscribe((data) => {
        try {
          const embedFile = JSON.parse(data);
          this.embedFile$.next({ url: embedFile.url, image: embedFile.image });
        } catch (e) {
          console.error('Invalid JSON');
          return;
        }
      });
  }

  fetchFile(path: string): Observable<string> {
    return this.http
      .get(path, { responseType: 'text' })
      .pipe(distinctUntilChanged());
  }

  override changeResolution(): void {}

  override addResponseEventListener(
    name: EUIInteractionCommand | ResponsesFromUnreal,
    listener: (data: any) => void,
  ): void {
    this.responseEventListeners.set(name, listener);
    this.api.sendMessageToMetaBox({
      action: MetaBoxApiAction.ADD_RESPONSE,
      name,
    });
  }

  removeResponseEventListener(name: EUIInteractionCommand): void {
    this.responseEventListeners.delete(name);
  }

  private addExternalListeners(): void {}

  private addExternalApi(): void {
    // Define the apiReady function to initialize the API interaction
    this.api = new Api();

    // Add an event listener for MetaBox data
    this.api.addEventListener(messageType, (data: MetaBoxMessagePayload) => {
      if (data.action === MetaBoxApiAction.APP_LOADED) {
        this.appLoaded$.next();
      }

      if (data.action === MetaBoxApiAction.UNREAL_RESPONSE) {
        this.responseEventListeners.get(data.name as string)?.(
          data.responseEvent,
        );
      }

      if (data.action === MetaBoxApiAction.VIDEO_SIZE) {
        this.videoSize.w = data.payload.width;
        this.videoSize.h = data.payload.height;
      }

      if (
        data.action === MetaBoxApiAction.STORE_ACTION &&
        data.eventType === 'menuData'
      ) {
        this.productSlotsMaterials$.next({
          productName: data.payload.productName,
          selectedMaterials: data.payload.selectedMaterials,
          availableMaterials: data.payload.availableMaterials,
        });
      }
    });

    // Example of how to unsubscribe if required
    // api.removeEventListener("metaboxData");

    window.emitUIInteraction = (data: any) => {
      this.api.sendMessageToMetaBox(data);
    };
  }
}
