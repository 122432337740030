import { gql } from 'apollo-angular';
import { MARKER } from '../variables';

export const GET_FILE_VERSION = gql`
  query($id: ID!, $markerStatus: FileMarkerStatusEnum) {
    fileVersion(id: $id) {
      createdAt
      id
      markerAutoincrementValue
      title
      path
      hash
      previewPath
      size
      isViewed
      serialNumber
      canDisapprove
      canApprove
      canRemove
      status
      extension
      streamConfig
      approvedBy {
        fullName
        email
      }
      markerGroups {
        id
        imagePath
        previewPath
        configuration
        markers(status: $markerStatus){
          ${MARKER}
        }
      }
      author {
        id
        fullName
      }
    }
  }
`;
