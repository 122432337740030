import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GQLThread } from '../../../../schemas/schemas';
import { getProjectTitle } from '@common/store/projects/projects.selectors';
import {
  getIsLoadingThreads,
  getThreadsList,
} from '@common/store/threads/threads.selectors';

@Component({
  selector: 'app-treads-list',
  templateUrl: './treads-list.component.html',
  styleUrls: ['./treads-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TreadsListComponent {
  threadList$: Observable<GQLThread[]> = this.store.select(getThreadsList);
  threadListIsLoading$: Observable<boolean> =
    this.store.select(getIsLoadingThreads);

  projectName$: Observable<string> = this.store.select(getProjectTitle);

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  goToProjectList(): void {
    this.router.navigate(['projects'], {
      queryParams: this.route.snapshot.queryParams,
    });
  }
}
