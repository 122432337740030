/* eslint-disable no-sequences */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionCreator, Store } from '@ngrx/store';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { MultiSelect } from 'primeng/multiselect';
import { GQLDateRange, GQLFileStatusEnum, GQLProject } from '@schemas';
import {
  isDefined,
  EViewType,
  stringToBoolean,
  EFromFilePlace,
} from '@common/helpers';
import { EFileListType } from '@common/types/EFileListType';
import {
  getProjectsTitles,
  isProjectDisabledForAddingFiles,
} from '@common/store/projects/projects.selectors';
import { FileCreateComponent } from '../../../../../project-files-list/components/file-create/file-create.component';
import {
  IProjectsFilterSelector,
  IProjectsListSelectorItem,
  IStatusFilterSelector,
} from '@common/types/Filters.model';
import { loadProjectsTitles } from '@common/store/projects/projects.actions';
import { getFileListView } from '@common/store/files/files.selectors';
import {
  getAllFileCollection,
  getInitFileListView,
  loadProjectFiles,
  loadThreadFiles,
  toggleFileListView,
} from '@common/store/files/files.actions';
import { IFilterFileOptions } from '../../types/IFilterFileOptions';
import { TypedAction } from '@ngrx/store/src/models';
import { DatePipe } from '@angular/common';
import { canAddFileToThread } from '@common/store/threads/threads.selectors';

@Component({
  selector: 'app-files-filter',
  templateUrl: './files-filter.component.html',
  styleUrls: ['./files-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class FilesFilterComponent implements OnInit, OnDestroy {
  @ViewChild('multiselect') multiselect!: MultiSelect;

  @Input() filesCounter: number | null = 0;
  @Input() filterLocation: EFromFilePlace = EFromFilePlace.FILE_PAGE;

  @Output() searchQuery$ = new EventEmitter();

  isSearchFieldOpen = false;
  isDateSelectorOpen = false;
  filterStatusOptions: IFilterFileOptions[] = [];
  selectedProjects: string[] = [];

  readonly projectList$ = this.store.select(getProjectsTitles);
  readonly fileListView$ = this.store.select(getFileListView);
  readonly canAddFileInThread$ = this.store.select(canAddFileToThread);
  readonly fileListType: EFileListType = this.route.snapshot.data.fileListType;
  readonly isProjectDisabledForAddingFiles$ = this.store.select(
    isProjectDisabledForAddingFiles,
  );

  filtersStatusForm: FormGroup = this.fb.group({
    dropdownFilter: null,
  });
  projectListForm: FormGroup = this.fb.group({
    projects: [],
  });
  toolsFilterForm: FormGroup = this.fb.group({
    unviewed: false,
    image: false,
    video: false,
    unreal: false,
    webgl: false,
    otherFiles: false,
  });
  dateSelectorForm!: FormGroup;

  initialSearchQuery = '';
  canAddFileInThread = true;
  todayDate: Date = new Date();
  minDateTo!: Date;
  maxDateTo: Date = new Date();
  minDateFrom!: Date;
  maxDateFrom: Date = new Date();

  default: Array<{ id: string; title: string }> = [];
  loadFilesAction!: ActionCreator<
    string,
    (props: {
      searchQuery: string;
      dateRange: GQLDateRange;
    }) => { searchQuery: string; dateRange: GQLDateRange } & TypedAction<string>
  >;

  readonly viewType = EViewType;
  private searchQuery = '';
  private minAbsoluteDate = 0;
  private dateRange!: GQLDateRange;
  private unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly dialogService: DialogService,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(getInitFileListView());
    if (this.isAllFilesPage) {
      this.loadProjectsTitles();
    }
    this.setCanAddFileInThread();
    this.setInitFilterStatusOptions();
    this.setInitFiltersState();
    this.initProjectListSelector();
    this.initDateSelectorForm();
    this.handleDateSelectorForm();
    this.setToolsFilterForm();
  }

  get isAllFilesPage(): boolean {
    return this.filterLocation === EFromFilePlace.FILE_PAGE;
  }

  get isThreadFiles(): boolean {
    return this.filterLocation === EFromFilePlace.THREAD_FILE_PAGE;
  }

  get isProjectFiles(): boolean {
    return this.filterLocation === EFromFilePlace.PROJECT_FILE_PAGE;
  }

  get isDateSelectorFormEmpty(): boolean {
    return (
      this.dateSelectorForm.get('dateFrom')?.value === null &&
      this.dateSelectorForm.get('dateTo')?.value === null
    );
  }

  get isToolsFilterEmpty(): boolean {
    const formValue = this.toolsFilterForm.value;
    // all checkboxes mast be unselected
    return Object.values(formValue).every((field) => !field);
  }

  get isTouchedMarkerVisible(): boolean {
    return !this.isToolsFilterEmpty && !this.isSearchFieldOpen;
  }

  get isAddFilesButtonVisible(): boolean {
    return !this.isAllFilesPage && this.canAddFileInThread;
  }

  projectSelectAll(projects: GQLProject[]) {
    this.selectedProjects = Array.from(projects, ({ id }) => id);
    this.multiselect.checkAll();
    this.multiselect.updateLabel();
    this.setQueryParamsForProjectListFilter();
  }

  projectSelectNone() {
    this.selectedProjects = [];
    this.multiselect.uncheckAll();
    this.multiselect.updateLabel();

    this.setQueryParamsForProjectListFilter();
  }

  selectedProjectsHandle(event: IProjectsFilterSelector): void {
    this.selectedProjects = event.value.map(
      (project: IProjectsListSelectorItem) => project.id,
    );
    this.filtersStatusForm.patchValue({ projects: [] });
    this.setQueryParamsForProjectListFilter();
  }

  toggleView(viewType: EViewType): void {
    this.store.dispatch(toggleFileListView({ viewType }));
  }

  toggleDropdown(): void {
    this.isSearchFieldOpen = !this.isSearchFieldOpen;
  }

  toggleDateSelector(): void {
    this.isDateSelectorOpen = !this.isDateSelectorOpen;
  }

  openAddFileDialog(): void {
    const dialogConfig: DynamicDialogConfig = {
      data: {
        isEditing: false,
        fileListType: this.fileListType,
      },
      header: 'Add new file',
      styleClass: 'files',
    };
    this.dialogService.open(FileCreateComponent, dialogConfig);
  }

  clearDateSelector(): void {
    this.dateSelectorForm.reset();
    this.setInitialDateRange();
  }

  changeValue(value: IStatusFilterSelector) {
    this.setQueryParamsForStatusFilter(value.value.type);
  }

  handleSearch(searchQuery: string): void {
    this.searchQuery = searchQuery;
    this.searchQuery$.emit(searchQuery);

    if (this.isAllFilesPage) {
      this.store.dispatch(
        getAllFileCollection({
          searchQuery: this.searchQuery,
          dateRange: this.dateRange,
        }),
      );
    } else if (this.isProjectFiles) {
      this.store.dispatch(
        loadProjectFiles({
          searchQuery: this.searchQuery,
          dateRange: this.dateRange,
        }),
      );
    } else if (this.isThreadFiles) {
      this.store.dispatch(
        loadThreadFiles({
          searchQuery: this.searchQuery,
          dateRange: this.dateRange,
        }),
      );
    } else {
      return;
    }
  }

  private setInitFilterStatusOptions(): void {
    this.filterStatusOptions = [
      { title: 'Last Updated', type: GQLFileStatusEnum.ACTIVE },
      { title: 'Approved', type: GQLFileStatusEnum.APPROVED },
    ];
  }

  private setToolsFilterForm(): void {
    this.toolsFilterForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(({ unviewed, image, video, unreal, webgl, otherFiles }) => {
        this.setQueryParamsForToolsFilter(
          unviewed,
          image,
          video,
          unreal,
          webgl,
          otherFiles,
        );
      });
  }

  private initProjectListSelector(): void {
    this.projectList$
      .pipe(filter(isDefined), takeUntil(this.unsubscribe$))
      .subscribe((projectList) => {
        const selectedProjects: string[] =
          (this.route.snapshot.queryParams.projectsIds || '').split(',') || [];

        this.projectListForm.patchValue({
          projects: projectList.reduce(
            (s: GQLProject[], a: GQLProject) => (
              (selectedProjects || []).includes(a.id) ? s.push(a) : null, s
            ),
            [],
          ),
        });

        setTimeout(() => {
          if (this.multiselect) {
            this.multiselect.updateLabel();
            this.multiselect.cd.detectChanges();
          }
        });
      });
  }

  private setInitFiltersState(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ({
          sortBy,
          isUnviewed,
          image,
          video,
          unreal,
          webgl,
          otherFiles,
          projectsIds,
        }) => {
          this.selectedProjects = this.parseQueryParamList(projectsIds);
          this.filtersStatusForm.controls.dropdownFilter.patchValue(
            this.filterStatusOptions.find((option) => option.type === sortBy),
          );

          this.toolsFilterForm.patchValue({
            unviewed: stringToBoolean(isUnviewed),
            image: stringToBoolean(image),
            video: stringToBoolean(video),
            unreal: stringToBoolean(unreal),
            webgl: stringToBoolean(webgl),
            otherFiles: stringToBoolean(otherFiles),
          });

          this.setQueryParamsForProjectListFilter();
        },
      );
  }

  private parseQueryParamList(paramsString: string = ''): string[] {
    return paramsString.split(',');
  }

  private setQueryParamsForStatusFilter(filterLabel: GQLFileStatusEnum): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { sortBy: filterLabel },
      queryParamsHandling: 'merge',
    });
  }

  private setQueryParamsForProjectListFilter(): void {
    if (this.isAllFilesPage) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          projectsIds:
            this.selectedProjects.filter((el) => !!el).length > 0
              ? String(this.selectedProjects)
              : null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  private setQueryParamsForToolsFilter(
    isUnviewed: boolean,
    image: boolean,
    video: boolean,
    unreal: boolean,
    webgl: boolean,
    otherFiles: boolean,
  ): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        isUnviewed: String(isUnviewed),
        image: String(image),
        video: String(video),
        unreal: String(unreal),
        webgl: String(webgl),
        otherFiles: String(otherFiles),
      },
      queryParamsHandling: 'merge',
    });
  }

  private loadProjectsTitles(): void {
    this.store.dispatch(loadProjectsTitles());
  }

  private initDateSelectorForm(): void {
    this.dateSelectorForm = this.fb.group({
      dateFrom: null,
      dateTo: null,
    });
  }

  private handleDateSelectorForm(): void {
    this.dateSelectorForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dateRange) => {
        this.selectLoadFilesAction(dateRange);
        this.setAvailableDates(dateRange.dateFrom, dateRange.dateTo);
        this.store.dispatch(
          this.loadFilesAction({
            searchQuery: '',
            dateRange: {
              from: this.dateToString(dateRange.dateFrom),
              to: this.dateToString(dateRange.dateTo),
            },
          }),
        );
      });
  }

  private dateToString(date: Date): string | null {
    if (!date) {
      return null;
    }
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  private selectLoadFilesAction(dateRange: GQLDateRange): void {
    switch (this.filterLocation) {
      case EFromFilePlace.FILE_PAGE: {
        this.loadFilesAction = getAllFileCollection;
        break;
      }
      case EFromFilePlace.PROJECT_FILE_PAGE: {
        this.loadFilesAction = loadProjectFiles;
        break;
      }

      case EFromFilePlace.THREAD_FILE_PAGE: {
        this.loadFilesAction = loadThreadFiles;
      }
    }
  }

  private setCanAddFileInThread(): void {
    this.canAddFileInThread$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((canAddFileInThread) => {
        if (this.isThreadFiles) {
          this.canAddFileInThread = canAddFileInThread;
        }
      });
  }

  private setAvailableDates(from: Date, to: Date = this.todayDate): void {
    if (!from && !to) {
      this.setInitialDateRange();
    }

    if (!from && to) {
      this.minDateFrom = new Date(this.minAbsoluteDate);
      this.maxDateFrom = to;

      this.minDateTo = new Date(this.minAbsoluteDate);
      this.maxDateTo = this.todayDate;
    }

    if (from && !to) {
      this.minDateFrom = new Date(this.minAbsoluteDate);
      this.maxDateFrom = this.todayDate;

      this.minDateTo = from;
      this.maxDateTo = this.todayDate;
    }

    if (from && to) {
      this.minDateFrom = new Date(this.minAbsoluteDate);
      this.maxDateFrom = to;

      this.minDateTo = from;
      this.maxDateTo = this.todayDate;
    }
  }

  private setInitialDateRange(): void {
    this.minDateFrom = new Date(this.minAbsoluteDate);
    this.maxDateFrom = this.todayDate;

    this.minDateTo = new Date(this.minAbsoluteDate);
    this.maxDateTo = this.todayDate;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
