import { Injectable, Query } from '@angular/core';
import { QueryRef } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { BaseApi } from '../../../services/base-api';
import { GET_PROJECTS_TITLES } from '../../../graphQL/graphql-requests/queries/GET_PROJECTS_TITLES';
import { GQLProjectList } from '../../../../../schemas/schemas';

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  constructor(private api: BaseApi) {}

  loadProjectsTitles(): Observable<GQLProjectList> {
    return this.api.apollo
      .query<QueryRef<Query>>({
        query: GET_PROJECTS_TITLES,
        variables: { limit: 100000, skip: 0 },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((value: any) => {
          return value.data.projects;
        }),
      );
  }
}
