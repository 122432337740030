import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  EFileExtension,
  EFromFilePlace,
  SHORTENER_LENGTH,
  setQueryParametersForImageTools,
} from '@common/helpers';
import {
  GQLCreateFileVersionInput,
  GQLFile,
  GQLFileStatusEnum,
  GQLFileTypeEnum,
  GQLFileUpload,
  GQLFileVersion,
  GQLFileVersionStatusEnum,
} from '@schemas';
import { Params, Router } from '@angular/router';
import {
  createFileVersion,
  markApprovedFileAsViewed,
  setFile,
  tryRemoveFile,
} from '@common/store/files/files.actions';

import { AddEditVersionComponent } from '../../../../../commenting-tool/components/add-edit-version/add-edit-version.component';
import { DialogService } from 'primeng/dynamicdialog';
import { HttpClient } from '@angular/common/http';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Store } from '@ngrx/store';
import { getFileName } from '@common/helpers/getFileName';
import { take } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileCardComponent implements OnInit {
  @ViewChild('op', { static: false }) private overlayPanel!: OverlayPanel;
  @Input() file!: GQLFile;
  @Input() isListView: boolean | null = false;
  @Input() bigSize = true;
  @Input() filePlace: EFromFilePlace = EFromFilePlace.FILE_PAGE;

  readonly fileType = GQLFileTypeEnum;
  readonly fileStatus = GQLFileStatusEnum;
  skipLocationChange = false;
  editMenuIsOpen = false;
  embedImg!: string;

  constructor(
    private readonly store: Store,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly http: HttpClient,
    private readonly cd: ChangeDetectorRef,
    private readonly destroyRef: DestroyRef,
  ) {}

  get getLastVersion(): GQLFileVersion | null {
    const lastVersionAmount = this.file.versions?.length || 0;
    if (lastVersionAmount > 0) {
      return this.file?.versions?.[0] as GQLFileVersion;
    }
    return null;
  }

  get getApprovedVersion(): GQLFileVersion | null {
    const approvedVersion: GQLFileVersion | undefined = (
      this.file.versions || []
    ).find((version) => version.status === GQLFileVersionStatusEnum.APPROVED);
    return approvedVersion || null;
  }

  get getFileVersion(): GQLFileVersion | null {
    return this.isFileApproved ? this.getApprovedVersion : this.getLastVersion;
  }

  get fileName(): string {
    return getFileName(this.file);
  }

  get unreaded(): boolean {
    return (
      this.file?.hasNewApprove ||
      this.file?.hasNewComment ||
      this.file?.hasNewVersion
    );
  }

  get isVersionsIconNeed(): boolean {
    return this.file.useTools && this.file.type !== this.fileType.OTHER;
  }

  get queryParams(): Params | null | undefined {
    const versionId: string = this.getFileVersion?.id || '';
    if (this.file && (this.file.versions?.length || 0) > 0) {
      const { queryParams } = setQueryParametersForImageTools(
        versionId,
        `${this.router.url}`,
        this.filePlace,
      );

      return queryParams;
    }

    return null;
  }

  get isOpenGallery(): boolean {
    return (
      (this.file.versions?.length || 0) > 0 &&
      this.file.versions?.[0]?.extension === EFileExtension.PDF
    );
  }

  get isRemovedFile(): boolean {
    return this.file.status === this.fileStatus.REMOVED;
  }

  ngOnInit(): void {
    const path = this.file.versions?.[0].path || '';
    const isEmbedFilePath = path.match(/.(embed)$/i);

    if (isEmbedFilePath) {
      this.http
        .get(path, { responseType: 'text' })
        .pipe(take(1))
        .subscribe((data) => {
          try {
            this.embedImg = JSON.parse(data).image;
            this.cd.markForCheck();
          } catch (e) {
            return;
          }
        });
    }
  }

  openGallery(): void {
    if (
      this.file.status === GQLFileStatusEnum.APPROVED &&
      !this.file.hasNewApprove
    ) {
      this.store.dispatch(markApprovedFileAsViewed({ fileId: this.file.id }));
    }
  }

  get getComments(): number {
    return this.file.commentCount;
    // TODO add new logic here
  }

  get lastUpdated(): Date {
    return this.file.updatedAt;
  }

  get createdAt(): Date {
    return this.file.createdAt;
  }

  get fileVersionQuantity(): number {
    const lastVersionAmount = this.file.versions?.length || 0;
    return lastVersionAmount > 0 ? lastVersionAmount : 0;
  }

  get fileAuthorFullName(): string {
    if (this.file.author?.fullName) {
      return this.file.author.fullName;
    }
    return '';
  }

  get isFileApproved(): boolean {
    return this.file.status === GQLFileStatusEnum.APPROVED;
  }

  tooltipText(title: string, length = SHORTENER_LENGTH): string {
    return title.length > length ? title : '';
  }

  toggleEditMenu(event: Event): void {
    this.overlayPanel.toggle(event);
  }

  toggleButtonVisibility(): void {
    this.editMenuIsOpen = !this.editMenuIsOpen;
  }

  removeFile(event: Event): void {
    event.stopPropagation();
    this.store.dispatch(
      tryRemoveFile({ id: this.file.id, fileName: this.fileName }),
    );
    this.overlayPanel.toggle(event);
  }

  addVersion(): void {
    this.store.dispatch(setFile({ file: this.file }));

    const dialogConfig = {
      width: '784px',
      data: {
        isEditing: false,
      },
      header: 'Add new Version',
      fileId: this.file.id,
      isNotFilePage: true,
    };

    const dialog = this.dialogService.open(
      AddEditVersionComponent,
      dialogConfig,
    );

    dialog.onClose
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((props): boolean | GQLFileUpload => {
        if (props && !!props.save) {
          const fileVersionInput: GQLCreateFileVersionInput = {
            id: this.file.id,
            file: props.file,
          };

          this.store.dispatch(createFileVersion({ fileVersionInput }));
        }
        this.store.dispatch(setFile({ file: null }));
      });
  }
}
