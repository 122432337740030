import { gql } from 'apollo-angular';

export const GET_PERMISSIONS = gql`
  query {
    permissions {
      role
      reachableRoles
      canChangeRoles
    }
  }
`;
