import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { AvatarModule } from 'ngx-avatars';
import { TooltipModule } from 'primeng/tooltip';
import { NotificationsRoutingModule } from './notifications-routing.module';
import { NotificationListComponent } from './components/notification-list/notification-list.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationPageComponent } from './components/notification-page/notification-page.component';
import { TimeFromNowPipeModule } from '@common/pipes/time-from-now-pipe/time-from-now-pipe.module';
import { TabViewModule } from 'primeng/tabview';

@NgModule({
  declarations: [
    NotificationListComponent,
    NotificationItemComponent,
    NotificationPageComponent,
  ],
  providers: [DynamicDialogRef],
  imports: [
    CommonModule,
    NotificationsRoutingModule,
    NgScrollbarModule,
    TimeFromNowPipeModule,
    AvatarModule,
    TooltipModule,
    TabViewModule,
  ],
  exports: [NotificationListComponent, NotificationPageComponent],
})
export class NotificationsModule {}
