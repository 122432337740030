import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { TooltipModule } from 'primeng/tooltip';
import { FullscreenGalleryComponent } from './components/fullscreen-gallery/fullscreen-gallery.component';
import { TimeFromNowPipeModule } from '../../pipes/time-from-now-pipe/time-from-now-pipe.module';
import { ShortenerModule } from '../../pipes/shortener/shortener.module';
import { FileSizePipeModule } from '../../pipes/file-size-pipe/file-size-pipe.module';
import { FileTipesPipeModule } from '../../pipes/file-tipes-pipe/file-tipes.pipe.module';
import { PinchZoomModule } from '../../libs/ivypinch-pro/pinch-zoom.module';
import { AppIconModule } from '../icon';
import { TimeCreatedModule } from '@common/pipes/time-created/time-created.module';

@NgModule({
  declarations: [FullscreenGalleryComponent],
  imports: [
    CommonModule,
    TimeFromNowPipeModule,
    ShortenerModule,
    FileSizePipeModule,
    FileTipesPipeModule,
    PinchZoomModule,
    NgxExtendedPdfViewerModule,
    TooltipModule,
    AppIconModule,
    TimeCreatedModule,
  ],
  exports: [FullscreenGalleryComponent],
})
export class FullscreenGalleryModule {}
