import { GQLFile, GQLFileVersion } from '../../../schemas/schemas';

export function getUniqueListBy(arr: Array<any>, key: string) {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const changeHasNewVersionInFile = (
  groupFile: GQLFile,
  versions: GQLFileVersion[],
): GQLFile => {
  return {
    ...groupFile,
    hasNewVersion:
      !groupFile.useTools &&
      (groupFile.versions || []).find((fileVersion) =>
        versions.find(({ id }) => id === fileVersion.id),
      )
        ? false
        : groupFile.hasNewVersion,
  };
};
