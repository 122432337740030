import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { GQLActivityTypeEnum, GQLComment } from '../../../../schemas/schemas';
import { EFromFilePlace } from '@common/helpers';
import { getCuttedUserName } from '@common/helpers/user-name.utils';
import { Activity } from '@common/types/acivity.model';
import { closeNotifications } from '../../store/notifications.actions';

@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationItemComponent {
  @ViewChild('notificationElement', { static: false })
  notificationElement: ElementRef<HTMLDivElement> | null = null;

  @Input() hasClass!: boolean;
  @Input() notification!: Activity;
  @Input() notificationList: Activity[] = [];
  @Input() scrolledEvent!: any;

  get authorName(): string {
    return this.notification.author.fullName || '';
  }

  get authorAvatarPath(): string {
    return this.notification.author.avatarPath || '';
  }

  get authorAvatarName(): string {
    return getCuttedUserName(this.notification.author);
  }

  get projectName(): string {
    return this.notification?.project?.title || '';
  }

  get projectId(): string {
    return this.notification?.project?.id || '';
  }

  get threadId(): string {
    return this.notification?.thread?.id || '';
  }

  get threadName(): string {
    return this.notification?.thread?.title || '';
  }

  get fileVersionName(): string {
    return (
      this.notification?.fileVersion?.title ||
      (this.notification as any)?.newFileVersion?.title ||
      0
    );
  }

  get fileVersionSerialNumber(): number {
    return (
      this.notification?.fileVersion?.serialNumber ||
      (this.notification as any)?.newFileVersion?.serialNumber ||
      0
    );
  }

  get fileId(): string {
    return this.notification?.file?.id || '';
  }

  get fileVersionId(): string {
    return (
      this.notification?.fileVersion?.id ||
      this.notification?.newFileVersion?.id ||
      ''
    );
  }

  get fileName(): string {
    return this.notification.file?.name || '';
  }

  get messageId(): string {
    return this.notification.message?.id || '';
  }

  get groupedNotificationItems(): Activity[] | null {
    return this.notification.items || null;
  }

  get comment(): GQLComment | null {
    return this.notification.comment || null;
  }

  get notificationText(): string {
    switch (this.notification.type) {
      case GQLActivityTypeEnum.FILE_COMMENTED:
        if (this.groupedNotificationItems) {
          return `<span><strong>${this.authorName}</strong> added ${
            this.groupedNotificationItems.length
          } new comment${
            this.groupedNotificationItems.length > 1 ? 's' : ''
          } to the
        <strong>${this.fileName} version ${
            this.fileVersionSerialNumber
          }</strong> in the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
        }
        return `<span><strong>${this.authorName}</strong> added 1 new comment to the
        <strong> ${this.fileName} version ${this.fileVersionSerialNumber}</strong> in the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      case GQLActivityTypeEnum.FILE_UPDATED:
        return `<span><strong>${this.authorName}</strong> uploaded the
        <strong> ${this.fileName} version
         ${this.fileVersionSerialNumber}</strong> in the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      case GQLActivityTypeEnum.MESSAGE_CREATED:
        return `<span><strong>${this.authorName}</strong> posted <strong>a message</strong> in the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      case GQLActivityTypeEnum.THREAD_CREATED:
        return `<span><strong>${this.authorName}</strong> posted the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      case GQLActivityTypeEnum.THREAD_APPROVED:
        return `<span><strong>${this.authorName}</strong> approved the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      case GQLActivityTypeEnum.FILE_APPROVED:
        return `<span><strong>${this.authorName}</strong> approved the
        <strong> ${this.fileName}</strong><strong> version ${this.fileVersionSerialNumber}</strong> in the
        <strong> ${this.threadName}</strong> thread in the
        <strong> ${this.projectName}</strong> project</span>`;
      default:
        return '';
    }
  }

  get routerLink(): string[] {
    switch (this.notification.type) {
      case GQLActivityTypeEnum.FILE_COMMENTED:
      case GQLActivityTypeEnum.FILE_APPROVED:
      case GQLActivityTypeEnum.FILE_UPDATED:
        return ['/', 'tools', this.fileId];
      case GQLActivityTypeEnum.MESSAGE_CREATED:
      case GQLActivityTypeEnum.THREAD_CREATED:
      case GQLActivityTypeEnum.THREAD_APPROVED:
        return ['/', 'projects', this.projectId, this.threadId];
      default:
        return [];
    }
  }

  get queryParams(): { [key: string]: string | null } {
    switch (this.notification.type) {
      case GQLActivityTypeEnum.FILE_COMMENTED:
        return {
          version: this.fileVersionId,
          markerId: this.comment?.marker.id || null,
          markersGroupId: this.comment?.marker?.markerGroup?.id || null,
          commentId: this.comment?.id || null,
        };
      case GQLActivityTypeEnum.FILE_UPDATED:
      case GQLActivityTypeEnum.FILE_APPROVED:
        return {
          version: this.fileVersionId,
          from: EFromFilePlace.THREAD_FILE_PAGE,
        };
      case GQLActivityTypeEnum.MESSAGE_CREATED:
        return {
          message_id: this.messageId,
        };
      case GQLActivityTypeEnum.THREAD_CREATED:
        return {};
      default:
        return {};
    }
  }

  constructor(private readonly store: Store) {}

  closeNotificationSidebar(): void {
    this.store.dispatch(closeNotifications());
  }
}
